import { createSlice } from "@reduxjs/toolkit";

const initialModelState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  filteredEntities: "",
  modelFetchedById: "",
  page:1,
  size:10
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ModelSlice = createSlice({
  name: "dl-model",
  initialState: initialModelState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    modelFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload.items;
      state.totalmodels = action.payload.total;
      state.page=action.payload.page;
      state.size=action.payload.size;
      state.totalCount = action.payload.length;
    },
    modelFetchedForData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload;
      state.totalmodels = action.payload.total;
       state.totalCount = action.payload.length;
    },

    modelFetchedById: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.modelFetchedById = action.payload;
    },

    modelCreated: (state, action) => {
      state.actionsLoading = false;
      state.entities.push(action.payload);
      state.error = null;
    },

    modelUpdated: (state, action) => {
      let data = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map(entity => {
        if (entity.id === data.id) {
          return data;
        }
        return entity;
      });
    },

    clearModelById: (state, action) => {
      state.modelFetchedById = "";
    }
  }
});
