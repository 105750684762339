import React, {useState} from "react";
import {useFormik} from "formik";
import {connect} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import * as auth from "../../../redux/reducers/authRedux";
import {addOtp, requestForgotPassword, validateOtpAPI} from "../../../service/authCrud.api";
import {successToast, warningToast} from "../../../shared/components/ToastMessage";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {extractErrorFromError} from "../../../shared/helper/RequestWrapper";
import SVG from "react-inlinesvg";
import SendEmailNotificationModal from "../../../shared/components/SendEmailNotificationModal";

const initialValues = {
    email: ""
};

// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const otpRules = /^\d{6}$/;

function ForgotPassword() {
    let history = useHistory();
    const [isOtpInitiated, setOtpInitiated] = useState(false);
    const [email, setEmail] = useState("");
    const [isOtpVerified, setOtpVerified] = useState(false);
    const [show, setShow] = useState(false);
    const [textShowOtp, setTextShowOtp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validateOtp, setValidateOtp] = useState(false);

    const [isShownPassword, setIsShownPassword] = useState(false);
    const [isShownConfirmPassword, setIsShownConfirmPassword] = useState(false);

    const togglePassword = () => {
        setIsShownPassword(isShownPassword ? false : true);
    };
    const toggleConfirmPassword = () => {
        setIsShownConfirmPassword(isShownConfirmPassword ? false : true);
    };
    const getInputClasses = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const modalOpen = () => {
        setShow(true);
    };

    const modalClose = () => {
        setShow(false);
    };

    const sendOtpOnEmail = (email, forceResend = false) => {
        if (isOtpInitiated && !forceResend) {
            return;
        }
        setLoading(true);
        setOtpInitiated(true);
        setOtpVerified(false);
        let body = {
            email: email,
            check_email: true,
            otp_type: "forgot_password"
        };
        setEmail(email);
        addOtp(body)
            .then(response => {
                modalOpen();
                setTextShowOtp(true);
                setLoading(false);
            })
            .catch(error => {
                console.log("error::", error);
                const warningMsg = extractErrorFromError(error);
                warningToast(warningMsg);
                modalClose();
                setLoading(false);
                setTextShowOtp(false);
            });
    };

    const ForgotSchema = Yup.object().shape({
        email: Yup.string()
            .matches(/^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim, "Incorrect email format.")
            .max(50, "Maximum 50 characters")
            .required("Email must be required"),

        otp: Yup.string().required("Otp must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(otpRules, {
                    message:
                        "kindly enter a valid OTP."
                })
        }),

    });

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .matches(/^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim, "Incorrect email format.")
            .max(50, "Maximum 50 characters")
            .required("Email must be required"),


        password: Yup.string().required("Password must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(passwordRules, {
                    message:
                        "Please establish a stronger password with at least five characters, one uppercase, one lowercase, and one digit."
                })
                .max(50, "Maximum 50 characters")

        }),
        confirmPassword: Yup.string().required("Confirm Password must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref("password"), null], "Password and Confirm Password didn't match")

        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validateOtp ? ForgotPasswordSchema  : ForgotSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            // enableLoading();
            if (isOtpInitiated && isOtpVerified && textShowOtp && validateOtp) {
                let body = {
                    new_password: values?.password || undefined,
                    email: values?.email
                };
                requestForgotPassword(body)
                    .then(response => {
                        successToast("Your Password Changes Successfully");
                        history.push("/auth/login");
                    })
                    .catch(error => {
                        console.error(">> error", error);
                        const warningMsg = extractErrorFromError(error);
                        warningToast(warningMsg);
                        setStatus(warningMsg);
                    });
            } else if (
                !isOtpInitiated &&
                textShowOtp &&
                !validateOtp
            ) {
                sendOtpOnEmail(values?.email);
            } else if (textShowOtp && !validateOtp) {
                if (values.otp) {
                    setSubmitting(false);
                }
                handleValidateOtp(values?.email, values.otp);
            } else {
            }
        }
    });

    const handleValidateOtp = (email, otp) => {
        setLoading(true);
        if (!email || !otp) {
            setLoading(false);
            formik.setSubmitting(false);
            formik.setStatus("Invalid Otp Please try Again");
            warningToast("Invalid Otp");
            return;
        }
        validateOtpAPI(email, otp)
            .then(res => {
                setValidateOtp(false);
                const isSuccess = res?.data?.success;
                setOtpVerified(isSuccess || false);
                if (isSuccess) {
                    successToast("Otp Validated Successfully");
                    setValidateOtp(true);
                    setLoading(false);
                    // setTimeout(() => formik.submitForm(), 400);
                } else {
                    setLoading(false);
                    formik.setSubmitting(false);
                    formik.setStatus("Invalid Otp Please try Again");
                    warningToast("Invalid Otp");
                }
            })
            .catch(error => {
                console.log("error::", error);
                warningToast(error.request.statusText)
                if (error) {
                    warningToast(error.detail);
                } else {
                    warningToast("Something went Wrong");
                }
                modalClose();
                setLoading(false);
                formik.setSubmitting(false);
                formik.setStatus("Invalid Otp Please try Again");
                warningToast("Invalid Otp");
            });
    };

    const handleOTPFocus = () => {
        formik.setStatus("");
    };

    return (
        <>

            <div className="login-form login-forgot" style={{display: "block"}}>
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">Forgotten Password ?</h3>
                    <div className="text-muted font-weight-bold">
                        Enter your email to reset your password
                    </div>
                </div>

                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    {/* begin: Alert */}
                    {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                                {formik.status}
                            </div>
                        </div>
                    )}
                    {/* end: Alert */}

                    {/* begin: Email */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                            disabled={textShowOtp}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik?.errors?.email}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Email */}

                    {textShowOtp && !validateOtp ? (
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="OTP"
                                type="otp"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "otp"
                                )}`}
                                disabled={validateOtp}
                                name="otp"
                                {...formik.getFieldProps("otp")}
                                onFocus={handleOTPFocus}
                            />
                            {formik.touched.otp && formik.errors.otp ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik?.errors?.otp}</div>
                                </div>
                            ) : null}
                            <a
                                className="text-dark-50 text-hover-primary mr-2"
                                disabled={loading}
                                onClick={() => sendOtpOnEmail(formik.values.email, true)}
                            >
                                <span className={"d-flex justify-content-end"}>ResendOTP</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}

                    {validateOtp ? (
                        <>
                            {/* begin: Password */}
                            <div className="form-group fv-plugins-icon-container">
                                <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                    <input
                                        placeholder="Password"
                                        type={isShownPassword ? "text" : "password"}
                                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            "password"
                                        )}`}
                                        name="password"
                                        {...formik.getFieldProps("password")}

                                    />
                                    {isShownPassword ? (
                                        <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                              onClick={togglePassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
            </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2" onClick={togglePassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
            </span>)}

                                </div>
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.password}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {/* end: Password */}

                            {/* begin: Confirm Password */}
                            <div className="form-group fv-plugins-icon-container">
                                <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                    <input
                                        placeholder="Confirm Password"
                                        type={isShownConfirmPassword ? "text" : "password"}
                                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            "confirmPassword"
                                        )}`}
                                        name="confirmPassword"
                                        {...formik.getFieldProps("confirmPassword")}
                                    />
                                    {isShownConfirmPassword ? (
                                        <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                              onClick={toggleConfirmPassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
            </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                              onClick={toggleConfirmPassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
            </span>)}

                                </div>
                                {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.confirmPassword}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {/* end: Confirm Password */}
                        </>
                    ) : (
                        <></>
                    )}

                    {validateOtp ? (
                        <div className="form-group d-flex flex-wrap flex-center">
                            <button
                                type="submit"
                                disabled={!formik.values.password && !formik.values.confirmPassword}
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                <span>Submit</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>

                            <Link to="/auth/login">
                                <button
                                    type="button"
                                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                >
                                    Cancel
                                </button>
                            </Link>
                        </div>
                    ) : textShowOtp && !validateOtp ? (
                        <div className="form-group d-flex justify-content-end flex-wrap flex-start">

                            <button
                                className="btn btn-primary font-weight-bold col-4"
                                disabled={!formik.values.otp || formik.errors.otp  || loading}
                                onClick={e => {
                                    formik.handleSubmit(e);
                                }}
                            >
                                <span>Verify OTP</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>
                        </div>
                    ) : (
                        <div className="form-group d-flex flex-wrap flex-start">
                            <button
                                type="button"
                                disabled={formik.isSubmitting || !formik.values.email  ||
                                    formik.errors.email || loading}
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                onClick={() => sendOtpOnEmail(formik.values.email, true)}
                            >
                                <span>Send OTP</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>
                        </div>
                    )}
                </form>
            </div>
            <>
                <SendEmailNotificationModal
                    show={show}
                    modalClose={modalClose}
                    email={email}
                />
            </>
        </>
    );
}

export default injectIntl(connect(null, auth.AuthAction)(ForgotPassword));
