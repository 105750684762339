import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModelEditDialogHeader } from "./ModelEditDialogHeader";
import { ModelEditForm } from "./ModelEditForm";
import { ModelSlice } from "../../_redux/ModelSlice";
import * as action from "../../_redux/ModelAction";
import * as frameworkAction from "../../../FrameworkManagement/_redux/FrameworkManagementAction";
import * as modelTypeAction from "../../../ModelType/_redux/ModelTypeAction";
import { SavingDetailsModal } from "../../../../../shared/components/SavingDetailsModal";
import { successToast } from "../../../../../shared/components/ToastMessage";

const { actions } = ModelSlice;

export function ModelEditDialog({ id, show, onHide }) {
  const {
    actionsLoading,
    modelFetchedById,
    frameworks,
    modelTypes,
    currentState
  } = useSelector(
    state => ({
      actionsLoading: state.model.actionsLoading,
      modelFetchedById: state.model.modelFetchedById,
      frameworks: state.frameworkManagement.entities,
      modelTypes: state.modelType.entities,
      currentState: state.model
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id === null) {
      dispatch(frameworkAction.fetchFrameworks(1, 100, ""));
      dispatch(modelTypeAction.fetchModelTypes(1, 100, ""));
    }
    if (id !== null && id !== undefined) {
      dispatch(action.fetchModelById(id));
    } else {
      dispatch(actions.clearModelById());
    }
  }, [id, dispatch]);

  const [loading, setLoading] = useState(false);
  const saveModel = model => {
    setLoading(true);
    if (!id) {
      // server request for creating model
      dispatch(action.createModel(model)).then(() => {
        dispatch(action.fetchModels(currentState.page, currentState.size, ""));

        onHide();
      });
    } else {
      // server request for updating model
      dispatch(action.modelUpdate(model)).then(() => {
        successToast("Model updated successfully");
        onHide();
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SavingDetailsModal show={loading} top={"start"} />
      <ModelEditDialogHeader id={id} />
      <ModelEditForm
        saveModel={saveModel}
        actionsLoading={actionsLoading}
        modelData={modelFetchedById}
        modelTypes={modelTypes}
        frameworks={frameworks}
        onHide={onHide}
      />
    </Modal>
  );
}
