import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import ModalFooterCommon from "../../../../shared/components/ModalFooterCommon";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import InfoIcon from "@material-ui/icons/Info";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import DropDownMenuCommon from "../../../../shared/components/DropDownMenuCommon";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as action from "../../../SuperAdmin/AnnotationType/_redux/AnnotationTypeAction";
import * as actions from "../../../SuperAdmin/DatasetStorageType/_redux/DatasetStorageTypeAction";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {validateEmptyString} from "../../../../shared/helper/UIHelpers";

function AddEditImportDatasetModal({
                                       show,
                                       editModalData,
                                       setAddEditImportDatasetModalShow,
                                       saveDataset,
                                       setEditModalData
                                   }) {
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(true);
    const [datastorageValue, setDatastorageValue] = useState([]);
    // const [isValiDateflag, setIsValiDateflag] = useState(false);
    const [annotationTypes, setAnnotationTypes] = useState([]);
    const [annotaionValue, setAnnotaionValue] = useState([]);
    const [isShownAccessKey, setIsShownAccessKey] = useState(false);
    const [isShownSecretKey, setIsShownSecretKey] = useState(false);
    const [dataStorageDropDown, setDataStorageDropDown] = useState([]);
    const [formData, setFormData] = useState({
        dataset_name: null,
        dataset_description: null,
        zip_url: null,
        s3_bucket: null,
        s3_access_key: null,
        s3_secret_key: null,
        is_verified: false,
        annotation_type_id: "",
        dataset_storage_id: "",
        status: true,
        id: ""
    });
    const [errors, setErrors] = useState({
        dataset_name: null,
        dataset_description: null,
        zip_url: null,
        s3_bucket: null,
        s3_access_key: null,
        s3_secret_key: null
    });

    const {annotationType, datasetStorageTypes} = useSelector(
        state => ({
            annotationType: state?.annotationType?.entities,
            datasetStorageTypes: state?.datasetStorageType?.entities
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(action.fetchEnabledAnnotationTypes());
        dispatch(actions.fetchEnabledDatasetStorageTypes());
    }, []);

    const isValidate = data => {
        let formsDats = {...data};
        let errors = {};
        let isValid = true;

        if (formsDats["dataset_name"] === "" && formsDats.dataset_name !== null) {
            isValid = false;
            errors["dataset_name"] = "Dataset Name must be required.";
        } else if (formsDats["dataset_name"] && formsDats["dataset_name"].match(/^\s+|\s+$/)) {
            isValid = false;
            errors["dataset_name"] = "Dataset Name cannot contain space.";
        } else if (formsDats["dataset_name"] && !formsDats["dataset_name"].trim().match(/^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()_-]+$/)) {
            isValid = false;
            errors["dataset_name"] = "Special characters are not allowed.";
        } else if (formsDats["dataset_name"] && formsDats["dataset_name"].length < 3) {
            isValid = false;
            errors["dataset_name"] = "Minimum 3 characters are allowed.";
        } else if (formsDats["dataset_name"] && formsDats["dataset_name"].length > 50) {
            isValid = false;
            errors["dataset_name"] = "Maximum 50 characters are allowed.";
        }


        if (
            validateEmptyString(formsDats["dataset_description"]) &&
            formsDats.dataset_description !== null
        ) {
            errors["dataset_description"] = "Dataset Description must be required.";
            isValid = false;
        } else if (
            formsDats["dataset_description"] &&
            !formsDats["dataset_description"].match(/(.*[a-z]){3}/i)
        ) {
            errors["dataset_description"] = "Dataset Description should at least contain 3 characters.";
            isValid = false;
        }
        if (
            formsDats.dataset_name === null ||
            formsDats.dataset_description === null
        ) {
            isValid = false;
        } else if (formsDats.annotation_type_id === "") {
            isValid = false;
        } else if (formsDats.dataset_storage_id === "") {
            isValid = false;
        }
        if (
            !formsDats.annotation_type_id ||
            formsDats.annotation_type_id === undefined
        ) {
            isValid = false;
        }
        if (
            formsDats.dataset_storage_id === 1 &&
            (formsDats.zip_url === undefined ||
                formsDats.s3_access_key === undefined ||
                formsDats.s3_secret_key === undefined ||
                formsDats.s3_bucket === undefined ||
                formsDats.zip_url === "" ||
                formsDats.s3_access_key === null ||
                formsDats.s3_secret_key === null ||
                formsDats.s3_bucket === null)
        ) {
            isValid = false;
        }

        if (
            formsDats.dataset_storage_id === 1 &&
            validateEmptyString(formsDats.s3_access_key)
        ) {
            isValid = false;
        }
        if (
            formsDats.dataset_storage_id === 2 &&
            (formsDats.zip_url === undefined ||
                validateEmptyString(formsDats.zip_url))
        ) {
            isValid = false;
        }
        if (
            formsDats.dataset_storage_id === 3 &&
            (formsDats.zip_url === undefined ||
                validateEmptyString(formsDats.zip_url))
        ) {
            isValid = false;
        }
        if (
            formsDats.dataset_storage_id === 4 &&
            (formsDats.zip_url === undefined ||
                validateEmptyString(formsDats.zip_url))
        ) {
            isValid = false;
        }
        if (validateEmptyString(formsDats.zip_url) && formsDats.zip_url !== null) {
            errors["zip_url"] = "Zip Url must be required.";
            isValid = false;
        }
        if (formsDats.dataset_storage_id === 1 && formsDats.s3_access_key === "") {
            errors["s3_access_key"] = "S3 Access key must be required.";
            isValid = false;
        }
        if (formsDats.dataset_storage_id === 1 && formsDats.s3_secret_key === "") {
            errors["s3_secret_key"] = "S3 Secret Key must be required.";
            isValid = false;
        }
        if (formsDats.dataset_storage_id === 1 && formsDats.s3_bucket === "") {
            errors["s3_bucket"] = "S3 Bucket Name must be required.";
            isValid = false;
        }
        if (
            !formsDats.dataset_storage_id ||
            formsDats.dataset_storage_id === undefined
        ) {
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };

    const handleChange = e => {
        let data = {...formData};
        data[e.target.name] = e.target.value;
        setFormData(data);
        let is_valid = isValidate(data);
        is_valid ? setFlag(false) : setFlag(true);
        // setIsValiDateflag(is_valid);
    };

    useEffect(() => {
        if (!show) {
            setDatastorageValue([]);
            setAnnotaionValue();
            setErrors({
                dataset_name: null,
                dataset_description: null,
                zip_url: null,
                s3_bucket: null,
                s3_access_key: null,
                s3_secret_key: null
            });
            setFormData({
                dataset_name: null,
                dataset_description: null,
                zip_url: null,
                s3_bucket: null,
                s3_access_key: null,
                s3_secret_key: null,
                is_verified: false,
                annotation_type_id: "",
                dataset_storage_id: "",
                status: true,
                id: ""
            });
        }
    }, [show]);

    const handleSubmit = () => {
        let data = {
            dataset_name: formData.dataset_name.trim() || null,
            dataset_description: formData.dataset_description.trim() || null,
            zip_url: formData.zip_url.trim() || null,
            s3_bucket: formData.s3_bucket ? formData.s3_bucket.trim() : null,
            s3_access_key: formData.s3_access_key ? formData.s3_access_key.trim() : null,
            s3_secret_key: formData.s3_secret_key ? formData.s3_secret_key.trim() : null,
            is_verified: formData.is_verified || false,
            annotation_type_id: formData.annotation_type_id || "",
            dataset_storage_id: formData.dataset_storage_id || "",
            status: formData.status || true,
            id: formData.id || ""
        }
        saveDataset(data);
    };
    const handleAnnotationChange = hyperParameter => {
        let data = {...formData};
        data["annotation_type_id"] = hyperParameter.value;
        setFormData(data);
        setAnnotaionValue(hyperParameter);
        let is_valid = isValidate(data);
        is_valid ? setFlag(false) : setFlag(true);
        // setIsValiDateflag(is_valid);
    };
    const handleDatastorsgeChange = hyperParameter => {

        let data = {...formData};
        data["dataset_storage_id"] = hyperParameter?.value;
        data["zip_url"] = null
        data["s3_bucket"] = null
        data["s3_access_key"] = null
        data["s3_secret_key"] = null
        setErrors({
            ...errors,
            zip_url: null,
            s3_bucket: null,
            s3_access_key: null,
            s3_secret_key: null
        });
        setFormData(data);
        setDatastorageValue(hyperParameter);
        let is_valid = isValidate(data);
        is_valid ? setFlag(false) : setFlag(true);
        // setIsValiDateflag(is_valid);
    };

    useEffect(() => {
        if (show && editModalData) {
            if (annotationTypes && editModalData) {
                annotationTypes.map(x => {
                    if (
                        x?.label === editModalData?.annotation_details?.annotation_type_name
                    ) {
                        setAnnotaionValue(x);
                    }
                });
            }
            if (dataStorageDropDown && editModalData) {
                dataStorageDropDown.map(x => {
                    if (
                        x?.label ===
                        editModalData?.dataset_storage_details?.dataset_storage_name
                    ) {
                        handleDatastorsgeChange(x);
                    }
                });
            }
            setFormData({
                dataset_name: editModalData?.dataset_name || null,
                dataset_description: editModalData?.dataset_description || null,
                zip_url: editModalData?.zip_url || null,
                s3_bucket: editModalData?.s3_bucket || null,
                s3_access_key: editModalData?.s3_access_key || null,
                s3_secret_key: editModalData?.s3_secret_key || null,
                is_verified: editModalData?.is_verified || false,
                annotation_type_id: editModalData?.annotation_details?.id || null,
                dataset_storage_id: editModalData?.dataset_storage_details?.id || null,
                status:
                    editModalData?.status === undefined
                        ? true
                        : editModalData?.status || null,
                id: editModalData?.id || null
            });
            setFlag(false);
        }
        if (editModalData.id) {
            isValidate();
        }
        setErrors({
            dataset_name: null,
            dataset_description: null,
            zip_url: null,
            s3_bucket: null,
            s3_access_key: null,
            s3_secret_key: null
        });
    }, [editModalData]);

    useEffect(() => {
        let hyperParamsOptions = [];
        annotationType &&
        annotationType.map(obj =>
            hyperParamsOptions.push({
                label: obj?.annotation_type_name,
                value: obj?.id
            })
        );
        setAnnotationTypes(hyperParamsOptions);
    }, [annotationType]);

    useEffect(() => {
        let datastorageoption = [];
        datasetStorageTypes &&
        datasetStorageTypes.map(obj =>
            datastorageoption.push({
                label: obj?.dataset_storage_name,
                value: obj?.id
            })
        );
        setDataStorageDropDown(datastorageoption);
    }, [datasetStorageTypes]);

    const handleClose = () => {
        setFlag(true);
        setFormData({});
        setEditModalData([]);
        setAnnotaionValue();
        setAddEditImportDatasetModalShow(false);
        setErrors({
            dataset_name: null,
            dataset_description: null,
            zip_url: null,
            s3_bucket: null,
            s3_access_key: null,
            s3_secret_key: null
        });
    };

    const toggleAccessKey = () => {
        setIsShownAccessKey(!isShownAccessKey);
    };
    const toggleSecretKey = () => {
        setIsShownSecretKey(!isShownSecretKey);
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                scrollable={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalHeaderCommon
                    title={`${formData && formData?.id ? "Edit " : "Add New"} Dataset`}
                />
                <Modal.Body>
                    <Form>
                        <div className={"mb-5 theme-color"}>
                            <Form.Label>
                                Dataset Name
                                <span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            A dataset (or data set) is a collection of data in various
                                            forms like text,image,video,voice,etc.
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className={`form-control`}
                                name="dataset_name"
                                placeholder="Dataset Name"
                                value={formData.dataset_name}
                                maxLength={"51"}
                                onChange={handleChange}
                                onBlur={handleChange}
                            />
                            <div className={"input-validation-star"}>
                                {errors["dataset_name"]}
                            </div>
                        </div>

                        <div className={"mb-5 theme-color"}>
                            <Form.Label>
                                Dataset Description
                                <span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            It is a description of the dataset which will use by model
                                            to train and test.
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Dataset description"
                                name="dataset_description"
                                value={formData.dataset_description}
                                onChange={handleChange}
                                onBlur={handleChange}
                                maxLength={"250"}
                                rows="6"
                            />

                            <span
                                className={`float-right ${
                                    formData?.dataset_description?.length === 250
                                        ? "input-validation-star"
                                        : ""
                                }`}
                            >
                {250 -
                    (formData?.dataset_description?.length
                        ? formData?.dataset_description?.length
                        : 0)}{" "}
                                characters left
              </span>
                            <div className={"input-validation-star"}>
                                {errors["dataset_description"]}
                            </div>
                        </div>

                        <div className={"mb-5 theme-color"}>
                            <Form.Group className="">
                                <Form.Label className="">
                                    Annotation Type
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                A Dataset (or Data Set) Is A Collection Of Data In
                                                Various Forms Like Text,image,video,voice,etc.
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </Form.Label>
                                <DropDownMenuCommon
                                    isMulti={false}
                                    isLoading={false}
                                    placeholder="Select Annotation type"
                                    value={annotaionValue}
                                    onChange={handleAnnotationChange}
                                    options={annotationTypes}
                                    maxMenuHeight={95}
                                />
                            </Form.Group>
                        </div>

                        <div className={"mb-5 theme-color"}>
                            <Form.Group className="">
                                <Form.Label className="">
                                    Dataset Storage
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                It is the storage provider from where the dataset is
                                                present.
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </Form.Label>
                                <DropDownMenuCommon
                                    isMulti={false}
                                    isLoading={false}
                                    placeholder="Select Datastorage type"
                                    value={datastorageValue}
                                    onChange={handleDatastorsgeChange}
                                    options={dataStorageDropDown}
                                    maxMenuHeight={95}
                                />
                            </Form.Group>
                        </div>

                        {formData?.dataset_storage_id === 1 ? (
                            <>
                                <div className={"mb-5 theme-color"}>
                                    <Form.Label>
                                        Zip URL
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={
                                                <div className="tools">
                                                    Path to the storage provier to fetch the dataset
                                                </div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zip_url"
                                        placeholder="Zip URL"
                                        value={formData.zip_url ? formData.zip_url : ""}
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                    />
                                    <div className={"input-validation-star"}>
                                        {errors["zip_url"]}
                                    </div>
                                </div>

                                <div className={"mb-5 theme-color"}>
                                    <Form.Label>
                                        S3 Bucket Name
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={<div className="tools">AWS S3 bucket name</div>}
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="s3_bucket"
                                        placeholder="S3 Bucket Name"
                                        value={formData.s3_bucket}
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                    />
                                    <div className={"input-validation-star"}>
                                        {errors["s3_bucket"]}
                                    </div>
                                </div>

                                <div className={"mb-5 theme-color"}>
                                    <Form.Label>
                                        S3 Access Key
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={
                                                <div className="tools">AWS account S3 Access Key</div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Form.Label>
                                    <div className={"d-flex align-items-center"}>
                                        <input
                                            placeholder="S3 Access Key"
                                            type={isShownAccessKey ? "text" : "password"}
                                            className={`form-control form-control-solid h-auto  dataset-s3-input`}
                                            name="s3_access_key"
                                            value={formData?.s3_access_key}
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                        />

                                        {isShownAccessKey ? (
                                            <span
                                                className="svg-icon cursor-pointer svg-icon-primary dataset-s3-input-eye"
                                                onClick={toggleAccessKey}
                                            >
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/General/show.svg"
                            )}
                        />
                      </span>
                                        ) : (
                                            <span
                                                className="svg-icon cursor-pointer svg-icon-primary dataset-s3-input-eye"
                                                onClick={toggleAccessKey}
                                            >
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/General/hide.svg"
                            )}
                        />
                      </span>
                                        )}
                                    </div>
                                    <div className={"input-validation-star"}>
                                        {errors["s3_access_key"]}
                                    </div>
                                </div>
                                <div className={"mb-5 theme-color"}>
                                    <Form.Label>
                                        S3 Secret Key
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={
                                                <div className="tools">AWS account S3 secret key</div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Form.Label>
                                    <div className={"d-flex align-items-center"}>
                                        <input
                                            placeholder="S3 Secret Key"
                                            type={isShownSecretKey ? "text" : "password"}
                                            className={`form-control form-control-solid h-auto  dataset-s3-input`}
                                            name="s3_secret_key"
                                            value={formData.s3_secret_key}
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                        />
                                        {isShownSecretKey ? (
                                            <span
                                                className="svg-icon cursor-pointer svg-icon-primary dataset-s3-input-eye"
                                                onClick={toggleSecretKey}
                                            >
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/General/show.svg"
                            )}
                        />
                      </span>
                                        ) : (
                                            <span
                                                className="svg-icon cursor-pointer svg-icon-primary dataset-s3-input-eye"
                                                onClick={toggleSecretKey}
                                            >
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/General/hide.svg"
                            )}
                        />
                      </span>
                                        )}
                                    </div>
                                    <div className={"input-validation-star"}>
                                        {errors["s3_secret_key"]}
                                    </div>
                                </div>
                            </>
                        ) : formData?.dataset_storage_id === 2 ? (
                            <div className={"mb-5 theme-color"}>
                                <Form.Label>
                                    Zip URL
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Path to the storage provier to fetch the dataset
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zip_url"
                                    placeholder="Zip URL"
                                    value={formData.zip_url ? formData.zip_url : ""}
                                    onChange={handleChange}

                                />
                                <div className={"input-validation-star"}>
                                    {errors["zip_url"]}
                                </div>
                            </div>
                        ) : formData?.dataset_storage_id === 3 ? (
                            <div className={"mb-5 theme-color"}>
                                <Form.Label>
                                    Zip URL
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Path to the storage provier to fetch the dataset
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zip_url"
                                    placeholder="path"
                                    value={formData.zip_url ? formData.zip_url : ""}
                                    onChange={handleChange}
                                />
                                <div className={"input-validation-star"}>
                                    {errors["zip_url"]}
                                </div>
                            </div>
                        ) : (
                            formData?.dataset_storage_id === 4 && (
                                <div className={"mb-5 theme-color"}>
                                    <Form.Label>
                                        Zip URL
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={
                                                <div className="tools">
                                                    Path to the storage provier to fetch the dataset
                                                </div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zip_url"
                                        placeholder="Object Name"
                                        value={formData.zip_url ? formData.zip_url : ""}
                                        onChange={handleChange}
                                    />
                                    <div className={"input-validation-star"}>
                                        {errors["zip_url"]}
                                    </div>
                                </div>
                            )
                        )}
                    </Form>
                </Modal.Body>

                <ModalFooterCommon
                    handleClose={handleClose}
                    submitEmployee={handleSubmit}
                    id={formData ? formData?.id : ""}
                    flag={flag}
                />
            </Modal>
        </>
    );
}

export default AddEditImportDatasetModal;
