/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";

export function AsideMenuList({layoutProps}) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu &&
        "menu-item-active"} menu-item-open menu-item-not-hightlighted`
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG title="View Dashboard" src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*custom start*/}
          <li
              className={`menu-item ${getMenuItemActive("/users", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG title="Add User" src={toAbsoluteUrl("/media/svg/icons/General/user.svg")}/>
            </span>
              <span className="menu-text">Users</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/frameworkManagement", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/frameworkManagement">
            <span className="svg-icon menu-icon">
              <SVG title="Add Framework" src={toAbsoluteUrl("/media/svg/icons/Communication/Shield-user.svg")}/>
            </span>
              <span className="menu-text">Frameworks</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/modelType", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/modelType">
            <span className="svg-icon menu-icon">
              <SVG  title="Add Model Type" src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}/>
            </span>
              <span className="menu-text">Model Types</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/models", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/models">
            <span className="svg-icon menu-icon">
              <SVG  title="Add Model" src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}/>
            </span>
              <span className="menu-text">Models</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/annotationType", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/annotationType">
            <span className="svg-icon menu-icon">
              <SVG  title="Add Annotation Types" src={toAbsoluteUrl("/media/svg/icons/Design/Join-1.svg")}/>
            </span>
              <span className="menu-text">Annotation Types</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/datasetStorageType", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/datasetStorageType">
            <span className="svg-icon menu-icon">
              <SVG  title="Add Dataset Storage Types" src={toAbsoluteUrl("/media/svg/icons/Weather/Cloud1.svg")}/>
            </span>
              <span className="menu-text">Dataset Storage Types</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/projects", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/projects">
            <span className="svg-icon menu-icon">
              <SVG title="View Project"  src={toAbsoluteUrl("/media/svg/icons/Devices/Server.svg")}/>
            </span>
              <span className="menu-text">Projects</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/experiments", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/experiments">
            <span className="svg-icon menu-icon">
              <SVG title="Test Model" src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Experiments</span>
            </NavLink>
          </li>
          {/*custom end*/}
        </ul>
      </>
  );
}
