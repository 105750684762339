import React, { Component } from "react";
import { toAbsoluteUrl } from "../../_helpers";
import { warningToast } from "../../../shared/components/ToastMessage";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {getWidgetsData} from "../../../service/dashboard.api";
class Demo1Dashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      graphType: "column",
      graphDuration: "Monthly",
      modalOpen: false,
      showGraph: false,
      graphMessage: "No Data Found",
      widget: null,
      keys: [],
      title: [],
      widgeTitle: null,
      drilldownFromFun: false,
      startDateEndDateFlag: false,
      blocking: false,
      loadInitialGraphFlag: true,
      showBarTable: false,
      tableData: [],
      getTrue: false,
      cameraData: {},
      dashboardGraphName: "Label"
    };
  }
  componentDidMount() {
    this.setWidgetLabelAndValue();
  }
  setWidgetLabelAndValue = () => {
    getWidgetsData()
      .then(response => {
        let widgetData = response.data;
        let arrayOrg = [];
        Object.entries(widgetData).map(([key, value]) => {
          arrayOrg.push(key);
        });
        let title = [];
        let widgetFunData = this.formatWidgetData(arrayOrg, title);
        this.setState(
          {
            widget: widgetData,
            keys: widgetFunData.arrayData,
            widgeTitle: widgetFunData.titleData
          }
        );
      })
      .catch(err => {
        warningToast("Something went wrong");
      });
  };
  formatWidgetData = (arrayOrg, title) => {
    for (let i = 0; i < arrayOrg.length; i++) {
      let wordOne = arrayOrg[i];
      const words = wordOne.split("_");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      let joinedWord = words.join(" ");
      title.push(joinedWord);
    }
    return { titleData: title, arrayData: arrayOrg };
  };
  render() {
    return (
      <>
          <div className="row-xl-12 mt-6">
              <div className={`card card-custom col-lg-12 col-xl-12 my-widget3`}>
                  {/* Body */}
                  <div className="card-body  p-0 position-relative">
                      {/* Chart */}
                      <div
                          id="kt_mixed_widget_1_chart"
                          className="card-rounded-bottom "
                          // height: "90px",
                          style={{ backgroundColor: "white" }}
                      ></div>

                      {/* Stat */}
                      <div className="card-spacer">
                          <div className=" m-0 box-customer-grid">
                              {/*1*/}
                              <div className=" bg-primary px-6 py-8 rounded-xl ml-3 mr-3 box-customer-widget">
                                  <div className={"d-flex"}>
                        <span className="svg-icon svg-icon-3x svg-icon-white d-block my-2">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                              )}
                          ></SVG>
                        </span>
                                      <OverlayTrigger
                                          placement="top"
                                          overlay={
                                              <Tooltip id="user-notification-tooltip">
                                                  {this.state.widget &&
                                                      this.state.widget[
                                                      this.state.keys && this.state.keys[0]
                                                          ]}
                                              </Tooltip>
                                          }
                                      >
                                          <div className="text-white font-weight-bold font-size-h2 mt-3 ml-2 wizard-overFlowView cursor-pointer ">
                                              {this.state.widget &&
                                                  this.state.widget[
                                                  this.state.keys && this.state.keys[0]
                                                      ]}
                                          </div>
                                      </OverlayTrigger>
                                  </div>
                                  <div
                                      className={`text-white font-weight-bold font-size-h6 ml-3`}
                                  >
                                      {this.state.widgeTitle && this.state.widgeTitle[0]}
                                  </div>
                              </div>

                              {/*2*/}
                              <div className=" bg-primary px-6 py-8 rounded-xl ml-3 mr-3 box-customer-widget">
                                  <div className={"d-flex"}>
                        <span className="svg-icon svg-icon-3x svg-icon-white d-block my-2">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                              )}
                          ></SVG>
                        </span>
                                      <OverlayTrigger
                                          placement="top"
                                          overlay={
                                              <Tooltip id="user-notification-tooltip">
                                                  {this.state.widget &&
                                                      this.state.widget[
                                                      this.state.keys && this.state.keys[1]
                                                          ]}
                                              </Tooltip>
                                          }
                                      >
                                          <div
                                              className={`text-white font-weight-bold font-size-h2 mt-3 ml-2 wizard-overFlowView cursor-pointer`}
                                          >
                                              {this.state.widget &&
                                                  this.state.widget[
                                                  this.state.keys && this.state.keys[1]
                                                      ]}
                                          </div>
                                      </OverlayTrigger>
                                  </div>

                                  <div
                                      className={`text-white font-weight-bold font-size-h6 ml-3 `}
                                  >
                                      {this.state.widgeTitle && this.state.widgeTitle[1]}
                                  </div>
                              </div>

                              {/*3*/}
                              <div className=" bg-primary px-6 py-8 rounded-xl ml-3 mr-3 box-customer-widget">
                                  <div className={"d-flex"}>
                        <span className="svg-icon svg-icon-3x svg-icon-white d-block my-2">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                              )}
                          ></SVG>
                        </span>
                                      <OverlayTrigger
                                          placement="top"
                                          overlay={
                                              <Tooltip id="user-notification-tooltip">
                                                  {this.state.widget &&
                                                      this.state.widget[
                                                      this.state.keys && this.state.keys[2]
                                                          ]}
                                              </Tooltip>
                                          }
                                      >
                                          <div
                                              className={`text-white font-weight-bold font-size-h2 ml-2 mt-3 wizard-overFlowView cursor-pointer`}
                                          >
                                              {this.state.widget &&
                                                  this.state.widget[
                                                  this.state.keys && this.state.keys[2]
                                                      ]}
                                          </div>
                                      </OverlayTrigger>
                                  </div>

                                  <div
                                      className={`text-white font-weight-bold font-size-h6 ml-3`}
                                  >
                                      {this.state.widgeTitle && this.state.widgeTitle[2]}
                                  </div>
                              </div>
                              {/*</div>*/}
                              {/*<div className="row m-0 d-flex justify-content-center mb-3 my-widget2">*/}
                              {/*4*/}
                              <div className=" bg-primary px-6 py-8 rounded-xl ml-3 mr-3 box-customer-widget">
                                  <div className={"d-flex"}>
                        <span className="svg-icon svg-icon-3x svg-icon-white d-block my-2">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                              )}
                          ></SVG>
                        </span>
                                      <OverlayTrigger
                                          placement="top"
                                          overlay={
                                              <Tooltip id="user-notification-tooltip">
                                                  {this.state.widget &&
                                                      this.state.widget[
                                                      this.state.keys && this.state.keys[3]
                                                          ]}
                                              </Tooltip>
                                          }
                                      >
                                          <div
                                              className={`text-white font-weight-bold font-size-h2 ml-2 mt-3 wizard-overFlowView cursor-pointer`}
                                          >
                                              {this.state.widget &&
                                                  this.state.widget[
                                                  this.state.keys && this.state.keys[3]
                                                      ]}
                                          </div>
                                      </OverlayTrigger>
                                  </div>
                                  <div
                                      className={`text-white font-weight-bold font-size-h6 ml-3`}
                                  >
                                      {this.state.widgeTitle && this.state.widgeTitle[3]}
                                  </div>
                              </div>

                              {/*5*/}
                              <div className=" bg-primary px-6 py-8 rounded-xl ml-3 mr-3 box-customer-widget">
                                  <div className={"d-flex"}>
                        <span className="svg-icon svg-icon-3x svg-icon-white d-block my-2">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                              )}
                          ></SVG>
                        </span>
                                      <OverlayTrigger
                                          placement="top"
                                          overlay={
                                              <Tooltip id="user-notification-tooltip">
                                                  {this.state.widget &&
                                                      this.state.widget[
                                                      this.state.keys && this.state.keys[4]
                                                          ]}
                                              </Tooltip>
                                          }
                                      >
                                          <div
                                              className={`text-white font-weight-bold font-size-h2 ml-2 mt-3 wizard-overFlowView cursor-pointer`}
                                          >
                                              {this.state.widget &&
                                                  this.state.widget[
                                                  this.state.keys && this.state.keys[4]
                                                      ]}
                                          </div>
                                      </OverlayTrigger>
                                  </div>

                                  <div
                                      className={`text-white font-weight-bold font-size-h6 ml-3`}
                                  >
                                      {this.state.widgeTitle && this.state.widgeTitle[4]}
                                  </div>
                              </div>
                              {/*6*/}
                              <div className=" bg-primary px-6 py-8 rounded-xl ml-3 mr-3 box-customer-widget">
                                  <div className={"d-flex"}>
                        <span className="svg-icon svg-icon-3x svg-icon-white d-block my-2">
                          <SVG
                              src={toAbsoluteUrl(
                                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                              )}
                          ></SVG>
                        </span>
                                      <OverlayTrigger
                                          placement="top"
                                          overlay={
                                              <Tooltip id="user-notification-tooltip">
                                                  {this.state.widget &&
                                                      this.state.widget[
                                                      this.state.keys && this.state.keys[5]
                                                          ]}
                                              </Tooltip>
                                          }
                                      >
                                          <div
                                              className={`text-white font-weight-bold font-size-h2 ml-2 mt-3 wizard-overFlowView cursor-pointer`}
                                          >
                                              {this.state.widget &&
                                                  this.state.widget[
                                                  this.state.keys && this.state.keys[5]
                                                      ]}
                                          </div>
                                      </OverlayTrigger>
                                  </div>
                                  <div
                                      className={`text-white font-weight-bold font-size-h6 ml-3`}
                                  >
                                      {this.state.widgeTitle && this.state.widgeTitle[5]}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div></>
    );
  }
}
function mapStateToProps(state) {
  const { auth } = state;
  return { user: auth.user };
}

export default connect(mapStateToProps)(Demo1Dashboard);
