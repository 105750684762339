import React from "react";
import { Button, Modal } from "react-bootstrap";
function ModalFooterCommon({ id, handleClose, submitEmployee ,flag}) {
  return (
    <>
      <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={e => submitEmployee(e)}
                disabled={flag}>
          {id ? "Update " : "Save"}
        </Button>
      </Modal.Footer>
    </>
  );
}

export default ModalFooterCommon;
