import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export const BoootstrapTable = ({
                                          columns,
                                          data,
                                          sizePerPage,
                                          onTableChange,
                                          page,
                                          totalSize,
                                          sizePerPageChange,
                                          hideSizePerPage,
                                          alwaysShowAllBtns,
                                          hidePageListOnlyOnePage,
                                          sizePerPageList,
                                          showTotal,

                                      }) => {
    return (
        // table-vertical-center table-horizontal-center overflow-hidden
        <BootstrapTable
            responsive
            keyField="_id"
            // striped
            // hover
            // condensed
            remote
            classes="table table-head-custom table-vertical-center table-horizontal-center table-switch"
            bootstrap4
            bordered={false}
            wrapperClasses={`table-responsive  custom-bootstrap-table`}
            data={data}
            hideSizePerPage
            columns={columns}
            pagination={paginationFactory({
                sizePerPage: sizePerPage,
                page: page,
                totalSize: totalSize,
                showTotal: showTotal ? showTotal : true,
                sizePerPageList: sizePerPageList,
                hideSizePerPage: hideSizePerPage ? hideSizePerPage : false,
                alwaysShowAllBtns: alwaysShowAllBtns ? alwaysShowAllBtns : true,
                hidePageListOnlyOnePage: hidePageListOnlyOnePage
                    ? hidePageListOnlyOnePage
                    : true,
                onPageChange: onTableChange,
                onSizePerPageChange: sizePerPageChange
            })}
        />
    );
};
