import React from "react";
import {Button, Modal} from "react-bootstrap";
import * as moment from "moment";

export function UsersDetailsForm({ userDetails, onHide }) {
  return (
    <>
      <Modal.Body>
        <div className="row col-12 text-center modal-contain-title-part">
          <span className="w-100 font-weight-bold">User Details</span>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>User Email</b>
            </span>
          </div>
          <div className="col col-md-6">
            <a
              style={{ wordBreak: "break-all" }}
              href={`mailto:${userDetails.user_email}`}
            >
              {userDetails.user_email}
            </a>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>First Name</b>
            </span>
          </div>
          <div className="col col-md-6">{userDetails.first_name}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Last Name</b>
            </span>
          </div>
          <div className="col col-md-6">{userDetails.last_name}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Provider</b>
            </span>
          </div>
          <div className="col col-md-6">{userDetails.provider}</div>
        </div>

        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(userDetails.company_created_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(userDetails.company_updated_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}
