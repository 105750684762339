import React, { useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Boundingbox from "image-bounding-box-custom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
// eslint-disable-next-line no-restricted-imports
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  getCredits,
  loadInferJob,
  loadVideoInferJob,
  startTesting,
  updateCredits
} from "../../../../../service/experimentManagement.api";
import { Input } from "reactstrap";
import TimerIcon from "@material-ui/icons/Timer";
import BootstrapTable from "react-bootstrap-table-next";
import {warningToast} from "../../../../../shared/components/ToastMessage";
import {urlToFile} from "../../../../../shared/components/FileConverter";

export function StartTestingModal({
  testExperimentDialogData,
  show,
  setShow,
  close
  // showFeedback,
  // setShowFeedback
}) {
  const [jobData, setJobData] = React.useState({});
  const [result, setResult] = React.useState({});
  const [selectedImage, setSelectedImage] = React.useState(null);
  // const [rating, setRating] = React.useState("Love");
  // const [imageFile, setImageFile] = React.useState(null);
  const [totalMills, setTotalMills] = React.useState(1000 * 60 * 5);
  const [loading, setLoading] = React.useState(false);
  const [isFileUploaded, setIsFileUploaded] = React.useState(false);
  const [startTest, setStartTest] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [time, setTime] = React.useState(0);
  const [closeCreditScore, setCloseCreditScore] = React.useState(false);
  const [creditData, setCreditData] = React.useState(null);
  const [isFileSupported, setIsFileSupported] = React.useState(true);
  const [isVideoUploaded, setIsVideoUploaded] = React.useState(false);
  const [imageSelected, setImageSelected] = React.useState({});
  const [isFullView, setIsFullView] = React.useState(false);
  const [isCreditOver, setIsCreditOver] = React.useState(false);
  const [fileErrorMsg, setFileErrorMsg] = React.useState("");
  const [detectionResponse, setDetectionResponse] = React.useState(false);

  const columns = [
    {
      dataField: "label",
      text: "Labels"
    },
    {
      dataField: "total_detection",
      text: "Total Detection"
    }
  ];
  const dataOfTable = [];
  const countLabel = [];
  const totalCount = [];
  const [col, setCol] = React.useState(columns);
  const dt = [];
  const [data, setData] = React.useState(dt);
  const [temp, setTemp] = React.useState(true);
  const checkCount = (dataOfTable, countLabel) => {
    for (let i = 0; i < dataOfTable.length; i++) {
      let counter = 0;
      for (let j = 0; j < countLabel.length; j++) {
        if (dataOfTable[i] === countLabel[j]) {
          counter++;
        }
      }
      totalCount.push(counter);
      let obj = {
        label: dataOfTable[i],
        total_detection: totalCount[i]
      };
      dt.push(obj);
    }
    setCol(columns);
    setData(dt);
    setTemp(false);
  };

  const { isAuthorized = false, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user?.id && new Cookies().get("access_token"),
      user: auth.user
    }),
    shallowEqual
  );

  const onImageClick = img => {
    setImageSelected(img);
    const toggle = !isFullView;
    setIsFullView(toggle);
  };

  const startTimer = time => {
    setTotalMills(time || 1000 * 60 * 5);
    const timer = setInterval(() => {
      setTotalMills(tm => {
        if (tm <= 0) {
          clearInterval(timer);
          closeTestModal();
        }
        return tm - 1000;
      });
    }, 1000);
  };
  const countTime = () => {
    const timer = setInterval(() => {
      setTime(tm => {
        if (closeCreditScore) {
          clearInterval(timer);
        }
        return tm + 1;
      });
    }, 1000);
  };
  const updateCreditsApi = creditsData => {
    try {
      (async () => {
        const { data: updatedCreditDetails, isSuccess } = await updateCredits(
          creditsData
        );
        if (!isSuccess) {
          warningToast("Error in updating credits");
        } else {
          setCreditData(updatedCreditDetails);
          return null;
        }
      })();
    } catch (e) {
      warningToast("Can't update credits");
    }
  };

  const creditScore = useRef(null);
  const updateCreditsScore = creditsData => {
    let credits = creditsData["total_credits"];
    creditScore.current = setInterval(() => {
      if (credits <= 0) {
        clearInterval(creditScore.current);
        // let data = {
        //   notification_message: "Your credits are over ",
        //   type_of_notification: "string",
        //   user_id: user?.id,
        //   status: true,
        //   is_unread: true
        // };
        warningToast("You don't have enough credits to perform this operation");
      } else if (closeCreditScore) {
        clearInterval(creditScore.current);
      } else {
        credits = credits - 1;
        if (credits < 0) {
          credits = 0;
        }
        creditsData["total_credits"] = credits.toFixed(2);
        creditsData["updated_date"] = new Date().toISOString();
        updateCreditsApi(creditsData);
      }
    }, 1000 * 60);
    if (credits <= 0) {
      setIsCreditOver(true);
      // let data = {
      //   notification_message: "Your credits are over ",
      //   type_of_notification: "string",
      //   user_id: user?.id,
      //   status: true,
      //   is_unread: true
      // };
      warningToast("You don't have enough credits to perform this operation");
      clearInterval(creditScore.current);
    }
  };

  useEffect(() => {
    if (show)
      (async () => {
        try {
          // const { imageSize, confThresh, iouThresh } = testData;
          const { data: startedModelData, isSuccess } = await startTesting(
            testExperimentDialogData?.id,
            testExperimentDialogData?.status,
            testExperimentDialogData?.created_date,
            testExperimentDialogData?.updated_date,
            testExperimentDialogData?.model_details.id
          );
          setJobData(jobData => ({ ...jobData, ...startedModelData }));
          if (!isSuccess) {
            warningToast("Error in starting the test for image");
          } else {
            setLoading(true);
            setStartTest(false);
            startTimer();
            countTime();
            const { data: getCreditsScore, isSuccess } = await getCredits();
            if (!isSuccess) {
              warningToast("Error getting credits");
            } else {
              setCreditData(getCreditsScore[0]);
              updateCreditsScore(getCreditsScore[0]);
            }
          }
        } catch (e) {
          warningToast("Something went wrong");
        }
      })();
  }, [show]);

  const closeTestModal = () => {
    try {
      (async () => {
        setCloseCreditScore(true);
        clearInterval(creditScore.current);
        const seconds = time % 60;
        let crData = { ...creditData };
        if (seconds !== 0 && crData.total_credits > 0) {
          crData.total_credits = parseFloat(
            (crData.total_credits - seconds / 60).toFixed(2)
          );
          if (crData.total_credits < 0) {
            crData.total_credits = 0;
          }
          crData.updated_date = new Date().toISOString();
          updateCreditsApi(crData);
        }

        window.addEventListener("beforeunload", null);
      })();
      // if(selectedImage)
      // setShowFeedback(true);
      close();
    } catch (e) {
      warningToast("Something went wrong");
    }
  };

  const uploadFile = (base64, filename, fileExtension) => {
    (async () => {
      try {
        setIsFileUploaded(false);
        if (["mp4", "avi"].includes(fileExtension)) {
          const file = await urlToFile(
            base64,
            "video." + fileExtension,
            fileExtension === "mp4" ? "video/mp4" : "video/x-msvideo"
          );
          setFile(file);
          // setImageFile(file);
        } else {
          const file = await urlToFile(base64, "image.jpeg", "text/jpeg");
          // setImageFile(base64);
          setSelectedImage(base64);
          setFile(file);
        }
        setIsFileUploaded(true);
      } catch (e) {
        warningToast("Error in uploading file");
      }
    })();
  };

  function getMins() {
    const minutes = Math.floor(totalMills / 60000);
    const seconds = ((totalMills % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }
  const testModal = async () => {
    try {
      setStartTest(true);
      setLoading(false);
      setIsFileUploaded(false);
      var isSuccess1, result2;
      if (isVideoUploaded) {
        const { data: result1, isSuccess } = await loadVideoInferJob(
          testExperimentDialogData?.id,
          file
        );
        result2 = result1;
        isSuccess1 = isSuccess;
      } else {
        const { data: result1, isSuccess } = await loadInferJob(
          testExperimentDialogData?.id,
          file
        );
        result2 = result1;
        isSuccess1 = isSuccess;
      }
      if (!isSuccess1) {
        setDetectionResponse(true);
        setLoading(true);
        warningToast("Error loading the job for testing images");
      } else {
        setResult(result2);
        setDetectionResponse(false);
        setLoading(true);
        setTimeout(() => closeTestModal(), totalMills);
        window.addEventListener("beforeunload", ev => {
          ev.preventDefault();
          closeTestModal();
        });
      }
    } catch (e) {
      setDetectionResponse(true);
      setLoading(true);
      setCloseCreditScore(true);
      warningToast("Something went wrong");
    }
  };
  const onPick = e => {
    try {
      setTemp(true);
      setStartTest(false);
      const file = e.target.files[0];
      const filename = document.getElementById("upload").value;
      const filenameSplit = filename.split(".");
      const imageExtension = ["png", "jpeg", "jpg", "jfif"];
      const videoExtension = ["mp4", "avi"];
      const fileExtension = filenameSplit[filenameSplit.length - 1];
      if (imageExtension.includes(fileExtension)) {
        if (file.size > 50000000) {
          setIsFileSupported(false);
          setFileErrorMsg("Please upload a file of less than 50MB");
        } else {
          //setIsFileUploaded(true);
          setIsFileSupported(true);
          setIsVideoUploaded(false);
          read(uploadFile, file, filename, fileExtension);
        }
      } else if (videoExtension.includes(fileExtension)) {
        if (file.size > 50000000) {
          setIsFileSupported(false);
          setFileErrorMsg("Please upload a file of less than 50MB");
        } else {
          setIsVideoUploaded(true);
          //setIsFileUploaded(true);
          setIsFileSupported(true);
          read(uploadFile, file, filename, fileExtension);
        }
      } else {
        setIsVideoUploaded(false);
        setIsFileUploaded(false);
        setIsFileSupported(false);
        setFileErrorMsg("Only .mp4,.jpg,.jpeg,.png,.avi formats are supported");
      }
    } catch (e) {
      warningToast("Can't read file");
    }
  };
  function read(callback, file, filename, fileExtension) {
    try {
      let reader = new FileReader();

      reader.onload = function() {
        callback(reader.result, filename, fileExtension);
      };

      reader.readAsDataURL(file);
    } catch (e) {
      warningToast("Can't read file");
    }
  }

  return (
    <>
      <Modal
        // size="xl"
        // aria-labelledby="example-modal-sizes-title-xl"
        // style={{ marginTop: "0vh" }}
        show={show}
        onHide={close}
        // onExited={() => setShowFeedback(true)}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton onHide={close}>
          <Modal.Title>{testExperimentDialogData?.experiment_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <>
              <div className="overlay-layer bg-transparent text-center">
                <div className="spinner-border text-info text-center" />
              </div>
              <div className="w-100 text-center">
                <b>Processing Input</b>
              </div>
            </>
          ) : !detectionResponse ? (
            <>
              <Row style={{ color: "#17c191" }}>
                <Col xl={6} xs={6} md={6} lg={6} sm={6}>
                  <Input
                    type="file"
                    color="primary"
                    id="upload"
                    onChange={onPick}
                  />
                </Col>
                <Col xl={6} xs={6} md={6} lg={6} sm={6} className="text-right">
                  <div
                    className="d-inline-block"
                    style={{ fontSize: 15, width: 48 }}
                  >
                    {getMins()} s
                  </div>
                  <div
                    className="d-inline-block"
                    style={{ width: 50, fontSize: 18 }}
                  >
                    <TimerIcon />
                  </div>
                </Col>
              </Row>
              <span
                style={{ color: "red", display: isFileSupported ? "None" : "" }}
              >
                {fileErrorMsg}
              </span>

              {startTest ? (
                <Row>
                  <Col xl={8} xs={12} md={12} lg={12} sm={12}>
                    <TransformWrapper
                      defaultScale={1}
                      defaultPositionX={200}
                      defaultPositionY={100}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                          <div
                            className="boundimage-full"
                            style={{ width: "100%", margin: "0 auto" }}
                          >
                            <div className="tools mb-2 text-right">
                              <ButtonGroup
                                size="small"
                                aria-label="Small outlined button group"
                              >
                                <Button onClick={zoomIn}>+</Button>
                                <Button onClick={zoomOut}>-</Button>
                                <Button onClick={resetTransform}>reset</Button>
                              </ButtonGroup>
                            </div>
                            {!isVideoUploaded ? (
                              <TransformComponent>
                                <Boundingbox
                                  className="row m-auto col-12 p-0 text-center "
                                  image={selectedImage}
                                  boxes={result?.detection?.map(d => ({
                                    coord: [
                                      d.location[0],
                                      d.location[1],
                                      d.location[2] - d.location[0],
                                      d.location[3] - d.location[1]
                                    ],
                                    label: d.label
                                  }))}
                                  options={{
                                    colors: {
                                      normal: "red",
                                      selected: "red",
                                      unselected: "red"
                                    },
                                    style: {
                                      maxWidth: "100%",
                                      maxHeight: "90vh",
                                      margin: "auto",
                                      width: 700,
                                      color: "red",
                                      height: 470
                                    }
                                  }}
                                />
                              </TransformComponent>
                            ) : (
                              <div
                                className="w-100"
                                style={{ height: "400px", overflow: "auto" }}
                              >
                                {result.detail !== "No Detection Found" &&
                                result.length > 0 ? (
                                  result.map(img => (
                                    <div onClick={() => onImageClick(img)}>
                                      <div style={{ height: "5px" }}> </div>
                                      <TransformComponent
                                        style={{ width: "100%" }}
                                      >
                                        <Boundingbox
                                          className="row m-auto mt-2 mb-2 col-12 p-0 text-center"
                                          image={img["image_url"]}
                                          boxes={img?.detection?.map(d => ({
                                            coord: [
                                              d.location[0],
                                              d.location[1],
                                              d.location[2] - d.location[0],
                                              d.location[3] - d.location[1]
                                            ],
                                            label: d.label
                                          }))}
                                          options={{
                                            colors: {
                                              normal: "red",
                                              selected: "red",
                                              unselected: "red"
                                            },
                                            style: {
                                              maxWidth: "100%",
                                              maxHeight: "90vh",
                                              margin: "auto",
                                              width: 700,
                                              color: "red",
                                              height: 470
                                            }
                                          }}
                                        />
                                      </TransformComponent>
                                    </div>
                                  ))
                                ) : (
                                  <TransformComponent>
                                    <b style={{ color: "red" }}>
                                      No Results Found
                                    </b>
                                  </TransformComponent>
                                )}
                              </div>
                            )}
                          </div>
                          {!isVideoUploaded && !result?.detection?.length && (
                            <b style={{ color: "red" }}>No Results Found</b>
                          )}
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  </Col>
                  {!isVideoUploaded &&
                    result?.detection?.length > 0 &&
                    result.detection.map(d => {
                      if (!dataOfTable.includes(d.label)) {
                        dataOfTable.push(d.label);
                        countLabel.push(d.label);
                      } else {
                        countLabel.push(d.label);
                      }
                      return true;
                    })}
                  {isVideoUploaded &&
                    result.length > 0 &&
                    result.map(d =>
                      d.detection.map(x => {
                        if (!dataOfTable.includes(x.label)) {
                          dataOfTable.push(x.label);
                          countLabel.push(x.label);
                        } else {
                          countLabel.push(x.label);
                        }
                        return true;
                      })
                    )}
                  {temp ? checkCount(dataOfTable, countLabel) : null}
                  <Col xl={4} xs={12} md={12} lg={12} sm={12} className="mt-2">
                    {!temp ? (
                      <>
                        <BootstrapTable
                          classes="table table-head-custom table-vertical-center overflow-hidden mt-3"
                          bootstrap4
                          wrapperClasses="table-responsive"
                          keyField="label"
                          bordered={false}
                          data={data}
                          columns={col}
                        ></BootstrapTable>
                      </>
                    ) : null}
                  </Col>
                </Row>
              ) : null}
            </>
          ) : (
            <div className="w-100 text-center">
              <b>Please try Again</b>
            </div>
          )}
          {}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={testModal}
            disabled={!isFileUploaded || isCreditOver}
          >
            Test
          </Button>

          <Button variant="secondary" onClick={closeTestModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        // size="lg"
        onHide={() => setIsFullView(false)}
        className="mb-lg-6"
        show={isFullView}
        style={{ marginTop: "13vh", height: "720", width: "820" }}
      >
        <Modal.Header
          style={{ textAlign: "right" }}
          closeButton
          onHide={() => onImageClick({})}
        ></Modal.Header>
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={200}
          defaultPositionY={100}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <div>
              <div className="tools mb-2 text-right">
                <ButtonGroup
                  size="small"
                  aria-label="Small outlined button group"
                >
                  <Button onClick={zoomIn}>+</Button>
                  <Button onClick={zoomOut}>-</Button>
                  <Button onClick={resetTransform}>reset</Button>
                </ButtonGroup>
              </div>
              <div
              //onClick={()=>onImageClick({})}
              >
                <TransformComponent>
                  <Boundingbox
                    className="row m-auto mt-2 mb-2 col-12 p-0 text-center"
                    image={imageSelected["image_url"]}
                    boxes={imageSelected?.detection?.map(d => ({
                      coord: [
                        d.location[0],
                        d.location[1],
                        d.location[2] - d.location[0],
                        d.location[3] - d.location[1]
                      ],
                      label: d.label
                    }))}
                    options={{
                      colors: {
                        normal: "red",
                        selected: "red",
                        unselected: "red"
                      },
                      style: {
                        maxWidth: "100%",
                        maxHeight: "90vh",
                        margin: "auto",
                        width: 620,
                        color: "red",
                        height: 570
                      }
                    }}
                  />
                </TransformComponent>
              </div>
            </div>
          )}
        </TransformWrapper>
      </Modal>
    </>
  );
}
