import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import ModalFooterCommon from "../../../../shared/components/ModalFooterCommon";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import DropDownMenuCommon from "../../../../shared/components/DropDownMenuCommon";
import {ImageSize, Optimizer, YesOrNo} from "../../../../shared/enums/dropDown.enum";
import InfoIcon from "@material-ui/icons/Info";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import {omit} from "lodash";

function AddEditHyperParamsModal({
                                     saveHyperParams, show, handleClose, editModalData
                                 }) {
    const [addHyperParam, setAddHyperParam] = useState({
        hyper_parameter_name: "",
        learning_rate: "",
        momentum: "",
        weight_decay: "",
        optimizer: "",
        batch_size: "",
        img_size: "",
        split_ratio: "",
        is_transfer_learning: "",
        num_epochs: "",
        is_rect: false,
        is_multi_scale: "",
        status: true,
        id: ""
    });
    const [error, setErrors] = useState({
        hyper_parameter_name: "",
        learning_rate: "",
        momentum: "",
        weight_decay: "",
        optimizer: "",
        batch_size: "",
        img_size: "",
        split_ratio: "",
        is_transfer_learning: "",
        num_epochs: "",
        is_rect: false,
        is_multi_scale: ""
    });
    const [flag, setFlag] = useState(true);

    useEffect(() => {
        if (!show) {
            setFlag(true);
            setAddHyperParam({
                hyper_parameter_name: "",
                learning_rate: "",
                momentum: "",
                weight_decay: "",
                optimizer: "",
                batch_size: "",
                img_size: "",
                split_ratio: "",
                is_transfer_learning: "",
                num_epochs: "",
                is_multi_scale: ""
            });
            setErrors({
                hyper_parameter_name: "",
                learning_rate: "",
                momentum: "",
                weight_decay: "",
                optimizer: "",
                batch_size: "",
                img_size: "",
                split_ratio: "",
                is_transfer_learning: "",
                num_epochs: "",
                is_multi_scale: ""
            });
        }
    }, [show]);

    const validation = (name, e) => {
        switch (name) {
            case "hyper_parameter_name":
                if (e.target.value === "") {

                    setErrors({
                        ...error, [name]: "Hyper Parameter name must be required.",
                    })
                } else if (new RegExp(/^\s+|\s+$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Hyper Parameter name cannot contain space.",
                    })
                } else if (!new RegExp(/^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()_-]+$/).test(e.target.value)) {

                    setErrors({
                        ...error, [name]: "Special characters are not allowed.",
                    })
                } else if (e.target.value.length < 3) {
                    setErrors({
                        ...error, [name]: "Minimum 3 characters are allowed.",
                    })
                } else if (e.target.value.length > 50) {
                    setErrors({
                        ...error, [name]: "Maximum 50 characters are allowed.",
                    })
                } else {
                    let newObj = omit(error, "hyper_parameter_name");
                    setErrors(newObj);
                }


                break;
            case "learning_rate":
                if (e.target.value === "") {

                    setErrors({
                        ...error, [name]: "Learning Rate must be required.",
                    })
                } else if (!new RegExp(/^([0-9]{1,})?(\.)([0-9]{1,})?$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Only floating digits are allowed.",
                    })
                } else {
                    let newObj = omit(error, "learning_rate");
                    setErrors(newObj);
                }

                break;
            case "momentum":
                if (e.target.value === "") {

                    setErrors({
                        ...error, [name]: "Momentum must be required.",
                    })
                } else if (!new RegExp(/^([0-9]{1,})?(\.)([0-9]{1,})?$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Only floating digits are allowed.",
                    })
                } else {
                    let newObj = omit(error, "momentum");
                    setErrors(newObj);
                }

                break;
            case "weight_decay":
                if (e.target.value === "") {

                    setErrors({
                        ...error, [name]: "Weight Decay must be required.",
                    })
                } else if (!new RegExp(/^([0-9]{1,})?(\.)([0-9]{1,})?$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Only floating digits are allowed.",
                    })
                } else {
                    let newObj = omit(error, "weight_decay");
                    setErrors(newObj);
                }

                break;
            case "batch_size":
                if (e.target.value === "") {

                    setErrors({
                        ...error, [name]: "Batch Size must be required.",
                    })
                } else if (!new RegExp(/^([0-9]*)$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Only digits are allowed.",
                    })
                } else {
                    let newObj = omit(error, "batch_size");
                    setErrors(newObj);
                }

                break;
            case "split_ratio":
                if (e.target.value === "") {

                    setErrors({
                        ...error, [name]: "Split ratio must be required.",
                    })
                } else if (!new RegExp(/^([0-9]{1,})?(\.)([0-9]{1,})?$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Only floating digits are allowed.",
                    })
                } else {
                    let newObj = omit(error, "split_ratio");
                    setErrors(newObj);
                }

                break;
            case "num_epochs":
                // setFlag(true)
                if (e.target.value === "") {

                    setErrors({
                        ...error, [name]: "Number Epochs must be required.",
                    })
                } else if (!new RegExp(/^([0-9]*)$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Only digits are allowed.",
                    })
                } else {
                    let newObj = omit(error, "num_epochs");
                    setErrors(newObj);
                }

                break;
        }
    };


    useEffect(() => {
        if (
            !error?.hyper_parameter_name &&
            !error?.learning_rate &&
            !error?.momentum &&
            !error?.weight_decay &&
            !error?.optimizer &&
            !error?.batch_size &&
            !error?.img_size &&
            !error?.split_ratio &&
            !error?.is_transfer_learning &&
            !error?.num_epochs &&
            !error?.is_multi_scale
        ) {
            if (
                addHyperParam?.hyper_parameter_name &&
                addHyperParam?.learning_rate &&
                addHyperParam?.momentum &&
                addHyperParam?.weight_decay &&
                addHyperParam?.optimizer.value &&
                addHyperParam?.batch_size &&
                addHyperParam?.img_size.value &&
                addHyperParam?.split_ratio &&
                addHyperParam?.is_transfer_learning &&
                addHyperParam?.num_epochs &&
                addHyperParam?.is_multi_scale
            ) {
                setFlag(false);
            } else if (addHyperParam?.id) {
                if (
                    !addHyperParam?.hyper_parameter_name ||
                    !addHyperParam?.learning_rate ||
                    !addHyperParam?.momentum ||
                    !addHyperParam?.weight_decay ||
                    !addHyperParam?.optimizer?.label ||
                    !addHyperParam?.batch_size ||
                    !addHyperParam?.img_size?.label ||
                    !addHyperParam?.split_ratio ||
                    !addHyperParam?.is_transfer_learning ||
                    !addHyperParam?.num_epochs ||
                    !addHyperParam?.is_multi_scale
                ) {
                    setFlag(true);
                } else if (
                    addHyperParam?.hyper_parameter_name ||
                    addHyperParam?.learning_rate ||
                    addHyperParam?.momentum ||
                    addHyperParam?.weight_decay ||
                    addHyperParam?.optimizer?.label ||
                    addHyperParam?.batch_size ||
                    addHyperParam?.img_size?.label ||
                    addHyperParam?.split_ratio ||
                    addHyperParam?.is_transfer_learning ||
                    addHyperParam?.num_epochs ||
                    addHyperParam?.is_multi_scale
                ) {
                    setFlag(false);
                }
            }

        } else {
            setFlag(true)
        }

    }, [addHyperParam]);
    const changeHyperParameterData = e => {
        let value = e.target.value;
        let name = e.target.name;
        let formData = {...addHyperParam};
        formData[name] = value;
        setAddHyperParam(formData);
        validation(name, e);
    };

    const submitEmployee = e => {
        const data = {
            id: addHyperParam?.id || null,
            is_multi_scale: addHyperParam?.is_multi_scale?.value ? addHyperParam?.is_multi_scale?.value : false,
            is_transfer_learning: addHyperParam?.is_transfer_learning?.value ? addHyperParam?.is_transfer_learning?.value : false,
            img_size: addHyperParam.img_size.value || "",
            optimizer: addHyperParam.optimizer.value || "",
            hyper_parameter_name: addHyperParam.hyper_parameter_name?.trim() || "",
            learning_rate: Number(addHyperParam.learning_rate) || "",
            momentum: Number(addHyperParam?.momentum) || "",
            weight_decay: Number(addHyperParam.weight_decay) || "",
            batch_size: Number(addHyperParam.batch_size) || "",
            // img_size: addHyperParam.img_size.toString().replace(/\s+/g, "") || "",
            split_ratio: Number(addHyperParam.split_ratio) || "",
            num_epochs: Number(addHyperParam.num_epochs) || "",
            is_rect: addHyperParam.is_rect || false,

        };
        saveHyperParams(data);
    };

    const AddDefaultHyperParams = () => {
        setErrors({
            hyper_parameter_name: "",
            learning_rate: "",
            momentum: "",
            weight_decay: "",
            optimizer: "",
            batch_size: "",
            img_size: "",
            split_ratio: "",
            is_transfer_learning: "",
            num_epochs: "",
            is_multi_scale: ""
        });
        setAddHyperParam({
            hyper_parameter_name: "xyz",
            learning_rate: 0.01,
            momentum: 0.9,
            weight_decay: 0.0001,
            optimizer: {label: "SGD", value: "sgd"},
            batch_size: 8,
            img_size: {label: "640", value: 640},
            split_ratio: 0.8,
            is_transfer_learning: {label: "NO", value: "no"},
            num_epochs: 31,
            is_rect: false,
            is_multi_scale: {label: "NO", value: "no"}
        });
    };

    const handleMultiScaleChange = multiScale => {
        let data = {...addHyperParam};
        data["is_multi_scale"] = multiScale;
        setAddHyperParam(data);
    };

    const handleTransferLearningChange = transferLearning => {
        let data = {...addHyperParam};
        data["is_transfer_learning"] = transferLearning;
        setAddHyperParam(data);
    };
    const handleOptimizerChange = optimizer => {
        let data = {...addHyperParam};
        data["optimizer"] = optimizer;
        setAddHyperParam(data);
    };
    const handleImageChange = optimizer => {
        let data = {...addHyperParam};
        data["img_size"] = optimizer;
        setAddHyperParam(data);
    };

    useEffect(() => {
        if (show && editModalData?.id) {
            let selectedoptimizer = {};
            for (let i = 0; i < Optimizer.length; i++) {
                let option = Optimizer[i];
                if (editModalData?.optimizer?.includes(option.value)) {
                    selectedoptimizer = option;
                }
            }

            let selectedImage = {};
            for (let i = 0; i < ImageSize.length; i++) {
                let option = ImageSize[i];
                if (editModalData?.img_size === option.value) {
                    selectedImage = option;
                }
            }

            let selectedIsTransferLearning = {};
            for (let i = 0; i < YesOrNo.length; i++) {
                let option = YesOrNo[i];
                if (editModalData?.is_transfer_learning === option.value) {
                    selectedIsTransferLearning = option;
                }
            }

            let isMultiScale = {};
            for (let i = 0; i < YesOrNo.length; i++) {

                let option = YesOrNo[i];
                if (editModalData?.is_multi_scale === option.value) {
                    isMultiScale = option;
                }

            }


            let addHyperParam = {
                ...editModalData,
                optimizer: selectedoptimizer,
                img_size: selectedImage,
                is_transfer_learning: selectedIsTransferLearning,
                is_multi_scale: isMultiScale
            };
            setAddHyperParam(addHyperParam);
        }
    }, [editModalData]);
    return (<>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            scrollable={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeaderCommon
                title={`${addHyperParam && addHyperParam?.id ? "Edit " : "Add New"} Hyper Parameters`}
            />
            <div className={"m-4 d-flex justify-content-end"}>
                <Button variant="primary" onClick={AddDefaultHyperParams}>
                    Fill Default Values
                </Button>
            </div>
            <Modal.Body>
                <div className={"mb-5 theme-color"}>
                    <Form.Label>
                        Hyper Parameters Name{" "}
                        <span className={"input-validation-star"}>*</span>
                        <Tooltip className={'theme-color'}
                                 title={<div className="tools">The Name Of The Hyper Parameters</div>}
                                 placement={"right"}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Form.Label>

                    <Form.Control
                        type="text"
                        className={`form-control`}
                        name="hyper_parameter_name"
                        placeholder="Hyper Parameters Name"
                        value={addHyperParam["hyper_parameter_name"]}
                        maxLength={"51"}
                        onChange={e => changeHyperParameterData(e)}
                        onBlur={e => validation("hyper_parameter_name", e)}
                    />
                    <div className={"input-validation-star"}>
                        {error["hyper_parameter_name"]}
                    </div>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Label>
                        Learning Rate <span className={"input-validation-star"}>*</span>
                        <Tooltip
                            title={<div className="tools">
                                The Learning Rate Is A Hyper Parameters That Controls How
                                Much Change Required In The Model In Response To The
                                Estimated Error Each Time The Model Weights Are Updated.
                            </div>}
                            placement={"right"}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className={`form-control`}
                        name="learning_rate"
                        placeholder="Learning Rate"
                        value={addHyperParam["learning_rate"]}
                        maxLength={"50"}
                        onChange={e => changeHyperParameterData(e)}
                        onBlur={e => validation("learning_rate", e)}
                    />
                    <div className={"input-validation-star"}>
                        {error["learning_rate"]}
                    </div>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Label>
                        Momentum <span className={"input-validation-star"}>*</span>
                        <Tooltip
                            title={<div className="tools">
                                This Parameters Is Responsible To Accelerate And Optimize
                                The Gradient Descent And Reduce The Noisy Variant From It.
                            </div>}
                            placement={"right"}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className={`form-control`}
                        name="momentum"
                        placeholder="Momentum"
                        value={addHyperParam["momentum"]}
                        maxLength={"50"}
                        onChange={e => changeHyperParameterData(e)}
                        onBlur={e => validation("momentum", e)}
                    />
                    <div className={"input-validation-star"}>{error["momentum"]}</div>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Label>
                        Weight Decay <span className={"input-validation-star"}>*</span>
                        <Tooltip
                            title={<div className="tools">
                                Weight Decay Weight Decay Is A Regularization Technique By
                                Adding A Small Penalty In All The Weights Of The Modelto The
                                Loss Function. Loss = Loss + Weight Decay Parameters * All
                                The Weights Of The Model.
                            </div>}
                            placement={"right"}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className={`form-control`}
                        name="weight_decay"
                        placeholder="Weight Decay"
                        value={addHyperParam["weight_decay"]}
                        maxLength={"50"}
                        onChange={e => changeHyperParameterData(e)}
                        onBlur={e => validation("weight_decay", e)}
                    />
                    <div className={"input-validation-star"}>
                        {error["weight_decay"]}
                    </div>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Group className="">
                        <Form.Label className="">
                            Optimizer<span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={<div className="tools">
                                    An Optimizer Is A Method Or Algorithm To Update The
                                    Various Parameters That Can Reduce The Loss In Much Less
                                    Effort.
                                </div>}
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <DropDownMenuCommon
                            isMulti={false}
                            isLoading={false}
                            placeholder="Select Optimizer"
                            value={addHyperParam["optimizer"]}
                            onChange={handleOptimizerChange}
                            options={Optimizer}
                            maxMenuHeight={95}
                        />
                    </Form.Group>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Label>
                        Split Ratio <span className={"input-validation-star"}>*</span>
                        <Tooltip
                            title={<div className="tools">
                                Split Ratio Dataset Distribution Ratio In Percentage For
                                Three Categories Training=70-75% Testing=15-20%
                                Validation=5-10%
                            </div>}
                            placement={"right"}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className={`form-control`}
                        name="split_ratio"
                        placeholder="Split Ratio"
                        value={addHyperParam["split_ratio"]}
                        onChange={e => changeHyperParameterData(e)}
                        onBlur={e => validation("split_ratio", e)}
                    />
                    <div className={"input-validation-star"}>
                        {error["split_ratio"]}
                    </div>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Group className="">
                        <Form.Label className="">
                            Transfer Learning
                            <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={<div className="tools">
                                    Its The Reuse Of A Pre-trained Model On A New Problem.in
                                    Transfer Learning, A Machine Exploits The Knowledge Gained
                                    From A Previous Task To Improve Generalization About
                                    Another.
                                </div>}
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <DropDownMenuCommon
                            isMulti={false}
                            isLoading={false}
                            placeholder="Select Transfer Learning"
                            value={addHyperParam["is_transfer_learning"]}
                            onChange={handleTransferLearningChange}
                            options={YesOrNo}
                            maxMenuHeight={95}
                        />
                    </Form.Group>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Label>
                        Number Epochs <span className={"input-validation-star"}>*</span>
                        <Tooltip
                            title={<div className="tools">
                                The Number Of Epochs Is The Number Of Complete Passes
                                Through The Training Dataset.
                            </div>}
                            placement={"right"}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className={`form-control`}
                        name="num_epochs"
                        placeholder="Number Epochs"
                        value={addHyperParam["num_epochs"]}
                        onChange={e => changeHyperParameterData(e)}
                        onBlur={e => validation("num_epochs", e)}
                    />
                    <div className={"input-validation-star"}>{error["num_epochs"]}</div>
                </div>

                <div className={"mb-5 theme-color"}>
                    <Form.Group className="">
                        <Form.Label className="">
                            Is Multi Scale{" "}
                            <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={<div className="tools">
                                    Concept Of Multiscale Feature Learning Is To Run Several
                                    Cnn Models With Varying Input Size In Parallel And Later
                                    To Combine The Output From Each Model To The
                                    Fully-connected Layer.
                                </div>}
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <DropDownMenuCommon
                            isMulti={false}
                            isLoading={false}
                            placeholder="Select Multi Scale"
                            value={addHyperParam["is_multi_scale"]}
                            onChange={handleMultiScaleChange}
                            options={YesOrNo}
                            maxMenuHeight={95}
                        />
                    </Form.Group>
                </div>


                <div className={"mb-5 theme-color"}>
                    <Form.Group className="">
                        <Form.Label className="">
                            Image Size<span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={<div className="tools">
                                    Resolution Of The Image Which Will Pass In Model Training.
                                </div>}
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <DropDownMenuCommon
                            isMulti={false}
                            isLoading={false}
                            placeholder="Select imageSize"
                            value={addHyperParam["img_size"]}
                            onChange={handleImageChange}
                            options={ImageSize}
                            maxMenuHeight={95}
                        />
                    </Form.Group>
                </div>
                <div className={"mb-5 theme-color"}>
                    <Form.Label>
                        Batch Size <span className={"input-validation-star"}>*</span>
                        <Tooltip
                            title={<div className="tools">
                                The Batch Size Is A Hyperparameter That Defines The Number
                                Of Samples To Work Through Before Updating The Internal
                                Model Parameters.
                            </div>}
                            placement={"right"}
                        >
                            <InfoIcon/>
                        </Tooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className={`form-control`}
                        name="batch_size"
                        placeholder="Batch Size"
                        value={addHyperParam["batch_size"]}
                        onBlur={e => validation("batch_size", e)}
                        onChange={e => changeHyperParameterData(e)}
                    />
                    <div className={"input-validation-star"}>{error["batch_size"]}</div>
                </div>
            </Modal.Body>
            <ModalFooterCommon
                handleClose={handleClose}
                submitEmployee={e => submitEmployee(e)}
                id={addHyperParam ? addHyperParam?.id : ""}
                flag={flag}
            />
        </Modal>
    </>);
}

export default AddEditHyperParamsModal;
