import React, { Suspense } from "react";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";
import HelpPage from "./HelpPage/HelpPage";

function HelpSection() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact={true} from="/user/help" to="/user/help/helpPage" />}
        <ContentRoute path="/user/help/helpPage" component={HelpPage} />
      </Switch>
    </Suspense>
  );
}
export default HelpSection;
