import {callTypes, ProjectManagementSlice} from "../reducers/projectManagementSlice";
import {successToast, warningToast} from "../../shared/components/ToastMessage";
import {
    addNewProject,
    getAllProject,
    getAllProjectDropDown,
    getProjectById,
    updateProject
} from "../../service/projectManagement.api";

const {actions} = ProjectManagementSlice;

export const fetchProjects = (pageNo,pageSize,search) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return getAllProject(pageNo,pageSize,search)
        .then(response => {
            if (response && response.isSuccess) {
                dispatch(actions.projectFetched(response.data));
            } else {
                warningToast("something went wrong");
            }
        })
        .catch(error => {
            error.clientMessage = "Can't find projects";
            dispatch(actions.catchError({error, callType: callTypes.list}));

        });
};
export const fetchProjectsForDropDown = () => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return getAllProjectDropDown()
        .then(response => {
            if (response && response.isSuccess) {
                dispatch(actions.projectFetchedForData(response.data));
            } else {
                warningToast("something went wrong");
            }
        })
        .catch(error => {
            error.clientMessage = "Can't find projects";
            dispatch(actions.catchError({error, callType: callTypes.list}));

        });
};

export const fetchProjectById = (id) => dispatch => {

    dispatch(actions.startCall({callType: callTypes.action}));
    return getProjectById(id)
        .then(response => {
            if (response && response.isSuccess) {
                dispatch(actions.projectFetchedById(response.data));
            } else {
                throw new Error('Error getting Project details');
            }
        })
        .catch(error => {
            warningToast("Something went wrong");
            console.error('Error: ', error);
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const createProject = (projectData) => dispatch => {

    dispatch(actions.startCall({callType: callTypes.action}));
    // const data = {
    //     "project_name": projectData.projectName,
    //     "project_description": projectData.projectDescription,
    //     "project_status": projectData.projectStatus,
    //     "status": projectData.status
    // }
    return addNewProject(projectData).then(response => {
        if (response && response.isSuccess) {

            dispatch(actions.projectCreated(response.data));
            successToast("Project Added Successfully");
        } else {
            throw new Error('something went wrong');
        }
    }).catch(error => {
        warningToast(error?.response?.data?.detail || "something went wrong");
        console.error('error:', error);
        dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}

export const projectUpdate = (projectData) => dispatch => {

    dispatch(actions.startCall({callType: callTypes.action}));
    const data = {
        "project_name": projectData.projectName || projectData.project_name,
        "project_description": projectData.projectDescription || projectData.project_description,
        "project_status": projectData.projectStatus || projectData.project_status,
        "status": projectData.status,
        "id": projectData.id
    }

    return updateProject(data).then(response => {

        if (response && response.isSuccess) {
            dispatch(actions.projectUpdated(response.data));
            successToast("Project details updated successfully");
        } else {
            throw new Error('Error updating Project details');
        }
    }).catch(error => {
        warningToast("Something went wrong");
        console.error('Error: ', error);
        dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}