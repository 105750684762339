import React, {Suspense} from "react";
import {ContentRoute, LayoutSplashScreen} from "../../../_metronic/layout";
import {Redirect, Switch} from "react-router-dom";
import {FrameworkManagementPage} from "./FrameworkManagementTable/FrameworkManagementPage";

function FrameworkManagement() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from modelType root URL to /modelType/modelTypePage */
                    <Redirect
                        exact={true}
                        from="/frameworkManagement"
                        to="/frameworkManagement/frameworkManagementPage"
                    />
                }
                <ContentRoute path="/frameworkManagement/frameworkManagementPage" component={FrameworkManagementPage}/>
            </Switch>
        </Suspense>
    );
}
export default FrameworkManagement;