import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export function ExperimentSwitchTestingAlertModal({
  showAlert,
  handleAccessUserClose,
  handleUserStatus,
  id,
  status,
  data,
  userStatusLoader,
  successIcon
}) {
  return (
    <>
      <SweetAlert
        info={!successIcon}
        success={successIcon}
        showCancel={!successIcon}
        showConfirm={!successIcon}
        closeOnClickOutside={""}
        confirmBtnText="Confirm"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        title={`${
          successIcon
            ? "Testing Started Successfully"
            : "Are You Sure You Want To Start Testing?"
        }`}
        onConfirm={() => {
          handleUserStatus(data);
        }}
        onCancel={handleAccessUserClose}
        show={showAlert}
        focusCancelBtn
      >
        {userStatusLoader ? (
          <div className="overlay-layer bg-transparent">
            <div className="spinner-border text-info text-center" />
          </div>
        ) : (
          <></>
        )}
      </SweetAlert>
    </>
  );
}
