import { Route } from "react-router-dom";
import React from "react";
import { DatasetStorageTypeUIProvider } from "./DatasetStorageTypeUIContext";
import { DatasetStorageTypeCard } from "./DatasetStorageTypeCard";
import { DatasetStorageTypeEditDialog } from "./dataset-storage-type-edit-dialog/DatasetStorageTypeEditDialog";
import { DatasetStorageTypeStatusDialog } from "./dataset-storage-type-status-dialog/DatasetStorageTypeStatusDialog";

export function DatasetStorageTypePage({ history }) {
  const datasetStorageTypePageBaseUrl =
    "/datasetStorageType/datasetStorageTypePage";

  const datasetStorageTypeUIEvents = {
    newDatasetStorageTypeButtonClick: () => {
      history.push(`${datasetStorageTypePageBaseUrl}/new`);
    },
    openChangeStatusDialog: (id, status) => {
      history.push(
        `${datasetStorageTypePageBaseUrl}/${id}/${status}/changeStatus`
      );
    },
    openEditDatasetStorageTypeDialog: id => {
      history.push(`${datasetStorageTypePageBaseUrl}/${id}/edit`);
    }
  };

  return (
    <DatasetStorageTypeUIProvider
      datasetStorageTypeUIEvents={datasetStorageTypeUIEvents}
    >
      <Route path={`${datasetStorageTypePageBaseUrl}/new`}>
        {({ history, match }) => (
          <DatasetStorageTypeEditDialog
            show={match != null}
            onHide={() => {
              history.push(datasetStorageTypePageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${datasetStorageTypePageBaseUrl}/:id/edit`}>
        {({ history, match }) => (
          <DatasetStorageTypeEditDialog
            show={match != null}
            id={match?.params?.id}
            onHide={() => {
              history.push(datasetStorageTypePageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${datasetStorageTypePageBaseUrl}/:id/:status/changeStatus`}>
        {({ history, match }) => (
          <DatasetStorageTypeStatusDialog
            show={match != null}
            id={match?.params?.id}
            status={match?.params?.status}
            onHide={() => {
              history.push(datasetStorageTypePageBaseUrl);
            }}
          />
        )}
      </Route>
      <DatasetStorageTypeCard />
    </DatasetStorageTypeUIProvider>
  );
}
