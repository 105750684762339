import {request} from "../shared/helper/RequestWrapper";
import {HttpRequest} from "../shared/enums/http.methods.enums";
import axios from "axios";

const GET_DATASET_IMAGES_BY_USER_ID_DATASET_ID ="/get_dataset_images_by_userid_dataset_id";
const UPDATE_ANNOTATION_DATASET_IMAGES="/update_annotation_dataset_images";
const DOWNLOAD_DATASET_IMAGES="/download_dataset";
const GET_UPLOADED_DATASET_FOR_ANNOTATION_CURRENT_USER ="/get_uploaded_dataset_for_annotation_current_user";
const SMART_ASSISTANCE ="https://smartassist.tusker.ai/get-smart-assistance";



export async function getDataSetImageByUserIdWithDataSetId(id,imageType) {
    return await request({
        endpoint: GET_DATASET_IMAGES_BY_USER_ID_DATASET_ID +"?dataset_id="+id + "&image_type=" + imageType ,
        method: HttpRequest.GET,
    });
}
export async function saveAndUpdateAnnotationDataSetImages(imageList) {
    return await request({
        endpoint: UPDATE_ANNOTATION_DATASET_IMAGES,
        method: HttpRequest.POST,
        body :imageList
    });
}

export async function downloadDataSetImage(dataSetId) {
    return await request({
        endpoint: DOWNLOAD_DATASET_IMAGES + "?dataset_id=" +dataSetId,
        method: HttpRequest.GET,
    });
}

export async function  fetchUploadedFolder() {
    return await  request({
        endpoint : GET_UPLOADED_DATASET_FOR_ANNOTATION_CURRENT_USER,
        method : HttpRequest.GET,
    })
}

export async function SmartAssistance(data) {
    return await  axios.request({
        headers: ({
            'Content-Type':  'application/json',
            'Access-Control-Allow-Origin':'*',
            'x-api-key': 'TW21ktpTbX9h5ko0Yi9LeajOQFv0an4e3QemULCJ'
        }),
        url : SMART_ASSISTANCE,
        method : HttpRequest.POST,
        dataType: "json",
        data: data
    })
}