import {callTypes, companyRegistration} from "../reducers/CompanyRegistrationSlice";
import {successToast, warningToast} from "../../shared/components/ToastMessage";
import {getUserCompanyInfo, registerCompany, updateUserCompanyInfo} from "../../service/companyRegistration.api";

const { actions } = companyRegistration;

export const saveCompanyData = regData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    company_name: regData ? regData.company_name : "",
    company_email: regData ? regData.company_email : "",
    company_description: regData ? regData.company_description : "",
    company_address: regData ? regData.company_address : "",
    company_pin_code: regData ? regData.company_pin_code : "",
    company_website: regData ? regData.company_website : "",
    company_contact: regData ? regData.company_contact : "",
    company_poc: regData ? regData.company_poc : "",
    deployment_region: regData ? regData.deployment_region : "",
    company_poc_contact: regData ? regData.company_poc_contact : ""
  };
  if (
    regData === "" ||
    regData.company_name === "" ||
    regData.company_email === "" ||
    regData.company_description === "" ||
    regData.company_address === "" ||
    regData.company_pin_code === "" ||
    regData.company_website === "" ||
    regData.company_contact === "" ||
    regData.company_poc === "" ||
    regData.deployment_region === "" ||
    regData.company_poc_contact === ""
  ) {
    dispatch(actions.companyCreated(data));
  } else {
    return registerCompany(data)
      .then(response => {
        if (response && response.isSuccess) {
          dispatch(actions.companyCreated(response.data));
        } else {
          throw new Error("something went wrong");
        }
      })
      .catch(error => {
        warningToast(error?.response?.data?.detail || "something went wrong");
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  }
};

export const getCompanyInfo = ()=>dispatch =>{
  dispatch(actions.startCall({ callType: callTypes.action }));
  getUserCompanyInfo()
      .then(response => {
        dispatch(actions.companyCreated(response.data));
      })
      .catch(error => {
        if (error) {
          console.log("error::", error);
          warningToast(error.detail);
        } else {
          warningToast("Something went Wrong");
        }
        dispatch(actions.catchError({ error, callType: callTypes.action }));

      });
};
export const UpdateCompanyData = (body)=>dispatch =>{
  dispatch(actions.startCall({ callType: callTypes.action }));
  updateUserCompanyInfo(body)

      .then(response => {
        dispatch(actions.companyCreated(response.data));
        successToast("Updated Company Details Successfully")
      })
      .catch(error => {
        if (error) {
          console.log("error::", error);
          warningToast(error.detail);
        } else {
          warningToast("Something went Wrong");
        }
        dispatch(actions.catchError({ error, callType: callTypes.action }));

      });
};