import {
  callTypes,
  ExperimentManagementAdminSlice
} from "./ExperimentManagementAdminSlice";
import { warningToast } from "../../../../shared/components/ToastMessage";
import {
  getAllExperiment,
  getExperimentById,
  updateExperiment
} from "./ExperimentManagementAPI";

const { actions } = ExperimentManagementAdminSlice;

export const fetchExperiments = (pageNo, pageSize, search) => dispatch => {
  // let data = [];
  // let obj = { items: data, total: 1 };
  let experiment_status = {};
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllExperiment(pageNo, pageSize, search, experiment_status)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.experimentFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find experiments";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchExperimentById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getExperimentById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.experimentFetchedById(response.data));
      } else {
        throw new Error("Error getting Experiment details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const experimentUpdate = experimentData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    experiment_name:
      experimentData.experimentName || experimentData.experiment_name,
    experiment_description:
      experimentData.experimentDescription ||
      experimentData.experiment_description,
    experiment_status:
      experimentData.experimentStatus || experimentData.experiment_status,
    model_id: experimentData.modelId || experimentData.model_id,
    project_id: experimentData.projectId || experimentData.project_id,
    hyper_params_id:
      experimentData.hyperParamsId || experimentData.hyper_params_id,
    annotation_type_id:
      experimentData.annotationTypeId || experimentData.annotation_type_id,
    dataset_id: experimentData.datasetId || experimentData.dataset_id,
    status: experimentData.status,
    id: experimentData.id
  };

  return updateExperiment(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.experimentUpdated(response.data));
      } else {
        throw new Error("Error updating Experiment details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
