import {request} from "../shared/helper/RequestWrapper";
import {HttpRequest} from "../shared/enums/http.methods.enums";

export const LOGIN_URL = "/login/access-token";
export const LOGIN_TEST_URL = "/login/test-token";
export const REGISTER_URL = "/add_user";
export const REQUEST_PASSWORD_URL = "/forgot_password";
export const SIGN_UP = "/sign-up";
export const SIGN_IN = "/sign-in";
export const ADD_OTP = "/add_otp";
export const VALIDATE_OTP = "/verify_otp";
export const GET_ALL_DATASET_STORAGE_TYPE = "/get_user_sign_up_details";
export const VERIFY_SOCIAL_USER = "/verify_social_user"
export const CHECK_EMAIL_EXIST = "/check_email_exist"
export const UPDATE_USER_INFO = "/update_user_info"
export const RESET_PASSWORD = "/reset-password"
export const UPDATE_USER_PROFILE_IMAGE = "/update_user_profile_image"

export const  DELETE_PROFILE_IMAGE="/delete_profile_image"

export function login(data) {
    return request({
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        endpoint: LOGIN_URL,
        method: HttpRequest.POST,
        body: data
    });
}

export function getCurrentUser() {
    return request({
        endpoint: LOGIN_TEST_URL,
        method: HttpRequest.POST
    });
}

export function register(data) {
    return request({
        endpoint: REGISTER_URL,
        method: HttpRequest.POST,
        body: data
    });
}

export function requestForgotPassword(email) {
    return request({
        endpoint: REQUEST_PASSWORD_URL,
        method: HttpRequest.POST,
        body: email
    });
}

export function signUp(data) {
    return request({
        endpoint: SIGN_UP,
        method: HttpRequest.POST,
        body: data
    });
}

export function signIn(data) {
    return request({
        endpoint: SIGN_IN,
        method: HttpRequest.POST,
        body: data
    });
}

export function addOtp(data) {
    return request({
        endpoint: ADD_OTP,
        method: HttpRequest.POST,
        body: data
    });
}

/**
 * This is used to validate the otp
 *
 * @param email
 * @param otp
 * @returns {*}
 */
export function validateOtpAPI(email, otp) {
    return request({
        endpoint: VALIDATE_OTP,
        method: HttpRequest.POST,
        body: {
            email,
            otp
        }
    });
};

export async function getUserSignUpDetail(data) {
    return await request({
        endpoint: GET_ALL_DATASET_STORAGE_TYPE,
        method: HttpRequest.POST,
        body: JSON.stringify(data)
    });
}

export function verifySocialUser(data) {
    return request({
        endpoint: VERIFY_SOCIAL_USER,
        method: HttpRequest.POST,
        body: data
    });
}

export function checkEmailExist(email) {
    return request({
        endpoint: CHECK_EMAIL_EXIST + "?user_email=" + email,
        method: HttpRequest.POST,
    });
}

export function updateUserInfo(data) {
    return request({
        endpoint: UPDATE_USER_INFO,
        method: HttpRequest.POST,
        body: JSON.stringify(data)
    });
}

export function updateUserProfileImage(file) {
    return request({
        endpoint: UPDATE_USER_PROFILE_IMAGE,
        method: HttpRequest.POST,
        body: file
    });
}

export function deleteProfileImage() {
    return request({
        endpoint: DELETE_PROFILE_IMAGE,
        method: HttpRequest.POST,

    });
}
export function resetPassword(data) {
    return request({
        endpoint: RESET_PASSWORD,
        method: HttpRequest.POST,
        body: JSON.stringify(data)
    });
}