/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {Link, Redirect, Switch, useHistory, useLocation} from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ContentRoute } from "../../../_metronic/layout";
import Login from "../Login/Login";
import Registration from "../Registration/Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { shallowEqual, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import RegistrationInformation from "../Registration/RegistrationInformation";

export function AuthPage() {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const today = new Date().getFullYear();
  const history = useHistory()

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useEffect(()=>{
    history.push("/")
  },[])

  // eslint-disable-next-line
  const { isAuthorized = false} = useSelector(
      ({ auth }) => ({
        isAuthorized: auth.user?.id && new Cookies().get("access_token"),
      }),
      shallowEqual
  );
  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login-1 login-signin-on d-flex flex-column flex-md-row flex-lg-row flex-sm-row flex-row-fluid bg-white "
              id="kt_login"
          >
            {/*begin::Aside*/}
            <div
                className="displays1 login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                      "/media/bg/main-banner.jpg"
                  )})`,
                }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <a href="http://tusker.ai/" target="_blank">
                    <h1
                        className={"d-flex justify-content-center"}
                        style={{
                          color: "#fff",
                          fontFamily: "Rubik, sans-serif",
                          fontSize: "60px",
                          lineHeight: "1.1",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                    >
                      Tusker AI
                    </h1>
                  </a>
                  <h3 className="font-size-h1 mb-5 text-white d-flex justify-content-center">
                    No-Code AI Computer Vision Platform
                  </h3>
                  <h4 className="font-size-h1 mb-5 text-white d-flex justify-content-center">
                    Image and Video Analytics
                  </h4>
                </div>

                <div className="mt-10 d-flex">
                  <div className={"flex-column-fluid justify-content-left mr-2"}>
                  <span className="opacity-70 font-weight-bold	text-white">
                    &copy; 2021-{today}
                  </span>
                    <span className="opacity-70 font-weight-bold	text-white">
                    <a
                        href="http://tusker.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white ml-2 text-hover-primary"
                    >
                      TuskerAI
                    </a>
                  </span>
                  </div>
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden disable-scrolling">
              {/*begin::Content header*/}
              <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                {/* eslint-disable-next-line no-mixed-operators */}
                {!isAuthorized && path !== "/auth/registration" && path !== "/auth/registration-information"  ? (
                    <>
                  <span className="font-weight-bold text-dark-50">
                    {" "}
                    Don't have an account yet?
                  </span>
                      <Link
                          to={`/auth/registration`}
                          className="font-weight-bold ml-2"
                          id="kt_login_signup"
                      >
                        Sign Up!
                      </Link>
                    </>
                ) : (
                    <>
                  <span className="font-weight-bold text-dark-50">
                    Already have an account?
                  </span>
                      <Link
                          to={`/auth/login`}
                          className="font-weight-bold ml-2"
                          id="kt_login_signup"
                      >
                        Log In!
                      </Link>
                    </>
                )}
              </div>
              {/*end::Content header*/}
              <div className="delete_large mt-25">
                <a href="http://tusker.ai/" target="_blank">
                  <h1
                      className={"d-flex justify-content-center"}
                      style={{
                        fontFamily: "Rubik, sans-serif",
                        fontSize: "44px",
                        lineHeight: "1.1",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                  >
                    Tusker AI
                  </h1>
                </a>
              </div>
              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <ContentRoute
                      path="/auth/registration"
                      component={Registration}
                  />
                  <ContentRoute
                      path="/auth/registration-information"
                      component={RegistrationInformation}
                  />

                  <ContentRoute
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                  />
                  <Redirect from={"/"} exact={true} to={"/auth/login"} />
                </Switch>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div className="displays1 d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div>
                <span className="delete_large text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  {" "}
                  &copy; 2021-{today}
                  TuskerAI{" "}
                </span>
                </div>
              </div>
              <div className="delete_large">
                <div className="d-flex">
                <span className="opacity-70 font-weight-bold  flex-column-fluid flex-left">
                  {" "}
                  &copy; 2021-{today}
                  <a
                      href="http://tusker.ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="opacity-70 text-dark-75 font-weight-bold ml-2 text-hover-primary"
                  >
                    TuskerAI
                  </a>
                </span>
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
