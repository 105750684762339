/* eslint-disable */
import { request } from "../../../../../shared/helper/RequestWrapper";
import { HttpRequest } from "../../../../../shared/enums/http.methods.enums";
export const ADD_AI_MODEL = "/add_ai_model_from_autoDL";
export const UPDATE_USER_STATUS_FROM_AUTO_DL =
  "/update_user_status_from_autoDL";
export const ADD_TRAINING_SETTING_DETAILS =
  "/add_training_setting_details_from_autoDL";
export const ADD_MODEL_S3_URL = "/add_model_s3_data_from_autoDL";
export async function saveAIModel(data) {
  return await request({
    headers: {
      project: "autoServing",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    endpoint: ADD_AI_MODEL,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}

export async function updateUserStatus(userStatus, userId) {
  return await request({
    headers: {
      project: "autoServing",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    endpoint:
      UPDATE_USER_STATUS_FROM_AUTO_DL +
      "?user_status=" +
      userStatus +
      "&user_id=" +
      userId,
    method: "POST",
    body: JSON.stringify(userStatus, userId)
  });
}

export async function saveTrainingSettings(resData, reqData) {
  let training_data = {
    image_size: reqData.img_size,
    model_training_batch_size: reqData.batch_size,
    batch_size: reqData.batch_size,
    model_epochs: reqData.num_epochs,
    model_labels_list: reqData.model_labels_list,
    model_id: resData.id,
    status: 1
  };
  return await request({
    headers: {
      project: "autoServing",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    endpoint: ADD_TRAINING_SETTING_DETAILS,
    method: "POST",
    body: JSON.stringify(training_data)
  });
}

export async function saveS3DataHandler(resData, reqData) {
  let s3_data = {
    model_s3_url: "",
    model_s3_key:
      "ad/" +
      reqData.user_id +
      "/" +
      "exp0_exp_" +
      reqData.exp_id +
      "/" +
      "weights/" +
      "best_exp_" +
      reqData.exp_id +
      ".pt",
    model_s3_name: "best.pt",
    model_version: "1",
    status: 1,
    model_id: resData.id
  };
  return await request({
    headers: {
      project: "autoServing",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    endpoint: ADD_MODEL_S3_URL,
    method: "POST",
    body: JSON.stringify(s3_data)
  });
}
