import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DatasetStorageTypeEditDialogHeader } from "./DatasetStorageTypeEditDialogHeader";
import { DatasetStorageTypeEditForm } from "./DatasetStorageTypeEditForm";
import { DatasetStorageTypeSlice } from "../../_redux/DatasetStorageTypeSlice";
import * as action from "../../_redux/DatasetStorageTypeAction";
import { SavingDetailsModal } from "../../../../../shared/components/SavingDetailsModal";
import { successToast } from "../../../../../shared/components/ToastMessage";

const { actions } = DatasetStorageTypeSlice;

export function DatasetStorageTypeEditDialog({ id, show, onHide }) {
  const { actionsLoading, datasetStorageTypeFetchedById ,currentState} = useSelector(
    state => ({
      actionsLoading: state.datasetStorageType.actionsLoading,
      datasetStorageTypeFetchedById:
        state.datasetStorageType.datasetStorageTypeFetchedById,
      currentState: state.datasetStorageType

    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== null && id !== undefined) {
      dispatch(action.fetchDatasetStorageTypeById(id));
    } else {
      dispatch(actions.clearDatasetStorageTypeById());
    }
  }, [id, dispatch]);

  const [loading, setLoading] = useState(false);
  const saveDatasetStorageType = datasetStorageType => {
    setLoading(true);
    if (!id) {
      // server request for creating datasetStorageType
      dispatch(action.createDatasetStorageType(datasetStorageType)).then(() => {
        dispatch(action.fetchDatasetStorageTypes(currentState.page,currentState.size,""));
        onHide()
      });
    } else {
      // server request for updating datasetStorageType
      dispatch(action.datasetStorageTypeUpdate(datasetStorageType)).then(() => {
        successToast("Dataset Storage type updated successfully");
        onHide();
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SavingDetailsModal show={loading} top={"start"} />
      <DatasetStorageTypeEditDialogHeader id={id} />
      <DatasetStorageTypeEditForm
        saveDatasetStorageType={saveDatasetStorageType}
        actionsLoading={actionsLoading}
        datasetStorageTypeData={datasetStorageTypeFetchedById}
        onHide={onHide}
      />
    </Modal>
  );
}
