import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

function ProfileCard({}) {
    const user = useSelector(({auth}) => auth.user, shallowEqual);

    return (
        <>
            {user && (
                <div
                    className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                    id="kt_profile_aside"
                >
                    <div className="card card-custom card-stretch">
                        {/* begin::Body */}
                        <div className="card-body py-10">

                            {/* begin::User */}
                            <div className="d-flex justify-content-center">
                                {user?.profile_image_url ?(<div>
                                    <img  className={"image-input-wrapper"} style={{width : '120px', height : '120px',borderRadius : '25px'  }} src={toAbsoluteUrl(user.profile_image_url)} />

                                </div>) :(<div>
                                    <img className={"image-input-wrapper"} style={{width : '120px', height : '120px',borderRadius : '25px'  }} src={toAbsoluteUrl("/media/users/blank.png")} />
                                </div>)}

                                <div>
                                    <a
                                        href="#"
                                        className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                                    >
                                    </a>
                                    <div className="text-muted">{user.occupation}</div>

                                </div>
                            </div>
                            {/* end::User */}
                            {/* begin::Nav */}
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded py-15">

                                <div className="navi-item mb-2">
                                    <NavLink
                                        to="/user/profile/profile-information"
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/General/user.svg"
                            )}
                        ></SVG>{" "}
                      </span>
                    </span>
                                        <span className="navi-text font-size-lg">
                        Profile Information
                    </span>
                                    </NavLink>
                                </div>

                                <div className="navi-item mb-2">
                                    <NavLink
                                        to="/user/profile/company-information"
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                    <span className="navi-icon mr-2 ">
                      <span className="svg-icon">
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/Home/Building.svg"
                            )}
                        ></SVG>{" "}
                      </span>
                    </span>
                                        <span className="navi-text font-size-lg">
                      Company Information
                    </span>
                                    </NavLink>
                                </div>
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to="/user/profile/change-password"
                                        className="navi-link py-4"
                                        activeClassName="active"
                                    >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Shield-user.svg"
                            )}
                        ></SVG>{" "}
                      </span>
                    </span>
                                        <span className="navi-text font-size-lg">
                      Change Password
                    </span>

                                    </NavLink>
                                </div>
                            </div>
                            {/* end::Nav */}
                        </div>
                        {/* end::Body */}
                    </div>


                </div>
            )}

        </>
    );
}

export default ProfileCard;