import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import Folder from "./Folder";
import MainImageFile from "./MainImageFile";
import {
    saveAndUpdateAnnotationDataSetImages,
    SmartAssistance
} from "../../../../service/annotationTools.api";
import {successToast, warningToast} from "../../../../shared/components/ToastMessage";
import {Button, Modal} from "react-bootstrap";
import ReactImageAnnotate from "tusk-annotate-tool";
import { HotKeys } from "react-hotkeys";
import { useState } from "react";
import { v4 as uuid } from 'uuid';
import {cloneDeep} from "lodash";
export const defaultHotkeys = [
    {
        id: "select_tool",
        description: "Switch to the Select Tool",
        binding: "escape"
    },
    {
        id: "zoom_tool",
        description: "Select the Zoom Tool",
        binding: "z"
    },
    {
        id: "create_point",
        description: "Create a point"
    },
    {
        id: "create_bounding_box",
        description: "Create a bounding box",
        binding: "b"
    },
    {
        id: "pan_tool",
        description: "Select the Pan Tool",
        binding: "m"
    },
    {
        id: "create_polygon",
        description: "Create a Polygon",
        binding: "p"
    },
    {
        id: "create_pixel",
        description: "Create a Pixel Mask"
    },
    {
        id: "save_and_previous_sample",
        description: "Save and go to previous sample",
        binding: "ArrowLeft"
    },
    {
        id: "save_and_next_sample",
        description: "Save and go to next sample",
        binding: "ArrowRight"
    },
    {
        id: "save_and_exit_sample",
        description: "Save and exit current sample"
    },
    {
        id: "exit_sample",
        description: "Exit sample without saving"
    },
    {
        id: "delete_region",
        description: "Delete selected region",
        binding: "d"
    },
    {
        id: "undo",
        description: "Undo latest change",
        binding: "Ctrl+z"
    }, {
        id: "new_icon",
        description: "Next To Previous",
        binding: "Ctrl+v"
    }, {
    id: "smart_tool",
    description: "Smart Tools",
    binding: "s"
  },
];

export const defaultKeyMap = {};
for (const {id, binding} of defaultHotkeys) defaultKeyMap[id] = binding;

export function AnnotationToolsPage() {
    const [selectedImage, setSelectedImage] = useState(0);
    const [datsetImageShow, setDatsetImageShow] = useState(false);
    const [annotationToolShow, setAnnotationToolShow] = useState(false);
    const [dataSetId, setDataSetId] = useState(null);
    const [annotationData, setAnnotationData] = useState([]);
    const [activeKey, setActiveKey] = useState("raw");
    const [show, setShow] = useState(false);
    const [event, setEvent] = useState([]);
    const [smartAssistanceData, setSmartAssistanceData] = useState([]);
    // const [status, setStatus] = useState(true);

    const handlesave = event => {
        const annotatedImage = [...event?.images];
        let flag =true
        annotatedImage.map(imagesData =>{
            if (imagesData) {
                if (imagesData) {
                    const images = imagesData?.regions.filter(x => {
                        return !x.cls;
                    });
                    if (images && images.length > 0) {
                        warningToast("Please add a label for your annotation.");
                        flag=false;
                    }
                }
            }
        })
         if(flag){
             saveAndUpdateAnnotationDataSetImage(annotatedImage);
         }
    };
    const handlclose = event => {
        setEvent(event);
        setShow(true);
    };
    const saveAndUpdateAnnotationDataSetImage = annotatedImageList => {
        saveAndUpdateAnnotationDataSetImages(annotatedImageList)
            .then(response => {
                if (response && response.isSuccess) {
                    successToast("Annotation Added Successfully");
                    setDatsetImageShow(true);
                    setAnnotationToolShow(false);
                    setShow(false);
                    setActiveKey("raw");
                }
            })
            .catch(e => {
                warningToast("Something went wrong");
            });
    };
    const onNextImage = event => {
        if (event.images[selectedImage]) {
            if (event.images[selectedImage]?.regions) {
                const images = event.images[selectedImage]?.regions.filter(x => {
                    return !x.cls;
                });
                if (images && images.length > 0) {
                    warningToast("Please add a label for your annotation.");
                } else {
                    if (selectedImage < annotationData.length - 1) {
                        setSelectedImage(selectedImage + 1);
                        if(event.images[selectedImage+2]){
                            let body ={
                                image_url: event.images[selectedImage+2]?.src,
                                annotation_type: "init"
                            }
                                getAssistances(body)

                        }
                    }
                }
            }
        }
    };
    const onPrevImage = event => {
        if (event.images[selectedImage]) {
            if (event.images[selectedImage]?.regions) {
                const images = event.images[selectedImage]?.regions.filter(x => {
                    return !x.cls;
                });
                if (images && images.length > 0) {
                    warningToast("Please add a label for your annotation.");
                } else {
                    if (selectedImage >= 1) {
                        setSelectedImage(selectedImage - 1);
                    }
                }
            }
        }
    };

    const datasetFolderOpen = id => {
        setDatsetImageShow(true);
        setDataSetId(id);
    };

    const annotationShow = selectedImages => {

        let images = Object.keys(selectedImages).map(id => {
            return {...selectedImages[id], _id: id};
        });

        if(images.length === 1){
            getAssistancesApiCall(images[0].src)
        }else if (images.length > 1){
            getAssistancesApiCall(images[0].src)
            getAssistancesApiCall(images[1].src)
        }
        setAnnotationData(images);
        setTimeout(function() {
            setDatsetImageShow(false);
            setAnnotationToolShow(true);
        }, 800);




    };

    const getAssistancesApiCall =(src) =>{
        let body ={
            image_url: src,
            annotation_type: "init"
        }
        getAssistances(body)
    }


    const backToFolder = () => {
        if (!datsetImageShow && annotationToolShow) {
            setDatsetImageShow(true);
            setAnnotationToolShow(false);
        } else {
            setDatsetImageShow(false);
            setAnnotationToolShow(false);
        }
    };

    const onHide = () => {
        setDatsetImageShow(true);
        setAnnotationToolShow(false);
        setShow(false);
        setActiveKey("raw");
    };

    const modalClose = () => {
        setShow(false)
    }

    const onSegment = (data) => {
        getSmartAssistances(data)

  }

    const  getSmartAssistances =(data)  => {
        let body ={
          image_url: data?.src,
          image_point: [
            data?.x, data?.y
          ],
          annotation_type: "box"
        }
        SmartAssistance(body)
          .then(response => {
          if (response && response?.data?.status_code === 200) {
          let userObj = JSON.parse(response?.data?.data);
            const unique_id = uuid();
            let objectNew = {
              cls:  undefined,
              color: "#b92020",
              editingLabels: true,
              h: userObj[3],
              highlighted: true,
              id: unique_id,
              type: "box",
              w: userObj[2],
              x: userObj[0],
              y: userObj[1],
            }

            let data_ann = cloneDeep(data?.stateImages)

            data_ann.map((dataNew) => {
              if(dataNew?._id === data?._id){
                dataNew.regions = [...dataNew.regions ,objectNew]
              }
              return dataNew
            })
            setAnnotationData([...data_ann])
            // setStatus(false)
            // setStatus(true)
            setSmartAssistanceData(userObj)
          }
        })
        .catch(error => {
          console.error('Error: ', error);
          warningToast("Something went wrong");
        });
  }

    const  getAssistances =(data)  => {
            SmartAssistance(data)
                .then(response => {
                    if (response && response?.data?.status_code === 200) {
                    }
                })
                .catch(error => {
                    console.error('Error: ', error);
                });


    }

    return (
        <>
            <Card>
                <CardHeader title="Annotation Tools">
                    <CardHeaderToolbar>
                        {!datsetImageShow && !annotationToolShow ? (
                            <></>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary ml-3"
                                onClick={backToFolder}
                            >
                                Back
                            </button>
                        )}
                    </CardHeaderToolbar>
                </CardHeader>

                <>
                    {!datsetImageShow && !annotationToolShow ? (
                        <CardBody>
                            <Folder datasetFolderOpen={datasetFolderOpen}/>
                        </CardBody>
                    ) : datsetImageShow && !annotationToolShow ? (
                        <CardBody>
                            <MainImageFile
                                activeKey={activeKey}
                                setActiveKey={setActiveKey}
                                annotationToolShow={annotationShow}
                                dataSetId={dataSetId}
                            />
                        </CardBody>
                    ) : (
                        <CardBody>
                            <div className={"annotation-tool-full-screen"}>
                                <HotKeys keyMap={defaultKeyMap}>

                  {/*{status &&*/}
                  <ReactImageAnnotate
                    labelImages
                    regionClsList={["Person"]}
                    images={[...annotationData]}
                    selectedImage={selectedImage}
                    enabledTools={[
                      "select",
                      "create-box",
                      "create-polygon",
                      "create-expanding-line",
                      "create-pixel",
                      "show-mask",
                      "create-line",
                        "smart-tool"
                    ]}
                    selectedTool ="create-box"
                    onNextImage={onNextImage}
                    onPrevImage={onPrevImage}
                    OnSegment={(data) => {
                      onSegment(data)
                    }}
                    segmentPoint={smartAssistanceData}
                    onExit={handlesave}
                    onClose={handlclose}
                    hideSettings={true}
                    hideClone={false}
                    hideFullScreen={true}
                    showMask={true}
                    autoSegmentationOptions={"autoseg"}
                  />
                                     {/*}*/}
                                </HotKeys>
                            </div>
                        </CardBody>
                    )}
                </>
            </Card>

            <Modal
                style={{marginTop: "0vh"}}
                show={show}
                onHide={modalClose}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h4 id="example-modal-sizes-title-lg">
                        Annotation Data
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"} style={{margin: "50"}}>
                        <div className={"text-center"}>
                            <h3>Are You Sure You Want To Change The Status?</h3>
                        </div>
                        <div className={"text-center"}>
                            <Button variant="secondary" className={'px-9 py-4 my-3 mx-4'} onClick={onHide}>
                                Force Exit
                            </Button>
                            <Button variant="primary" className={'px-9 py-4 my-3 mx-4'}
                                    onClick={() => handlesave(event)}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
