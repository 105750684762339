import React, {Suspense} from "react";
import {ContentRoute, LayoutSplashScreen} from "../../../_metronic/layout";
import {Redirect, Switch} from "react-router-dom";
import DatasetManagementPage from "./component/DatasetManagementPage";

function DatasetManagement() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    <Redirect
                        exact={true}
                        from="/user/datasets"
                        to="/user/datasets/datasetManagementPage"
                    />
                }
                <ContentRoute path="/user/datasets/datasetManagementPage" component={DatasetManagementPage}/>
            </Switch>
        </Suspense>
    );
}
export default DatasetManagement;
