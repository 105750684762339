import React, { Component } from "react";
import {Container} from "react-bootstrap";

class DataSetCreate extends Component {
  render() {
    let { choiceDataset, datasetName } = this.props;
    return (
      <>
        <div style={{ marginBottom: "30px" }}>
          <div className={""}>
            <h2>Train Modal</h2>
          </div>
          <div>
            <h6>* is.</h6>
          </div>
        </div>

        <div className={"centered-element-default"}>
          <h2 className={"text-center"} style={{ marginBottom: "30px" }}>
            Select Dataset
          </h2>
          <div
            className="row-xl-12 mb-5"
            onClick={() => choiceDataset("importDataSet")}
          >
            <div
              className={`col-lg-12 col-xl-12 d-flex justify-content-center`}
            >
              <div className="p-0 position-relative overflow-hidden">
                <div
                  id="kt_mixed_widget_1_chart"
                  className="card-rounded-bottom "
                  style={{ backgroundColor: "white" }}
                ></div>
                <div className=" m-0 box-customer-grid">
                  <div
                    className={`rounded-xl rounded-xl-focus ${
                      datasetName === "importDataSet"
                        ? "rounded-xl-focus-active"
                        : ""
                    }`}
                  >
                    <Container className={"d-flex p-5"}>
                      <div>
                        <i className="fa fa-cloud-download-alt  fa-6x"></i>
                      </div>
                      <div className={"p-5  d-flex align-items-center"}>
                        <div className={"font-weight-bold font-size-h5"}>
                          Import Dataset
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row-xl-12 "
            onClick={() => choiceDataset("uploadDataSet")}
          >
            <div
              className={`col-lg-12 col-xl-12 d-flex justify-content-center`}
            >
              <div className="p-0 position-relative overflow-hidden">
                <div
                  id="kt_mixed_widget_1_chart"
                  className="card-rounded-bottom "
                  style={{ backgroundColor: "white" }}
                ></div>
                <div className=" m-0 box-customer-grid">
                  <div
                    className={`rounded-xl rounded-xl-focus ${
                      datasetName === "uploadDataSet"
                        ? "rounded-xl-focus-active"
                        : ""
                    }`}
                  >
                    <Container className={"d-flex p-5"}>
                      <div>
                        <i className="fa  fa-cloud-upload-alt fa-6x"></i>
                      </div>
                      <div className={"p-5 d-flex align-items-center"}>
                        <div className={"font-weight-bold font-size-h5"}>
                          Upload Dataset
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DataSetCreate;
