import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect, useDispatch} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import Cookies from "universal-cookie";
import * as auth from "../../../redux/reducers/authRedux";
import {login} from "../../../service/authCrud.api";
import {successToast, warningToast} from "../../../shared/components/ToastMessage";
import {ACCESS_TOKEN, TOKEN_TYPE} from "../../../shared/enums/auth.enums";
import LoginWithSocialMedia from "../Pages/LoginWithSocialMedia";
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const initialValues = {
    email: "",
    password: ""
};

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

function Login(props) {
    const dispatch = useDispatch();
    const {intl} = props;
    const [loading, setLoading] = useState(false);
    const [isShownPassword, setIsShownPassword] = useState(false);

    const togglePassword = () => {
        setIsShownPassword(isShownPassword ? false : true);
    };
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .matches(/^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim, "Incorrect email format.")
            .max(50, "Maximum 50 characters")
            .required(
                intl.formatMessage({
                    id: "AUTH.INPUT.EMAIL"
                })
            ),
        password: Yup.string().when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(passwordRules, {
                    message:
                        "Please establish a stronger password with at least five characters, one uppercase, one lowercase, and one digit."
                })
                .max(50, "Maximum 50 characters")
                .required("Required"),
            otherwise: Yup.string().required(
                intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD.NOT.VALID"
                })
            )
        }),
    });

    const getInputClasses = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const setTokenCookies = (token, tokenType) => {
        const cookies = new Cookies();
        cookies.set(ACCESS_TOKEN, token, {httpOnly: false, path: "/"});
        cookies.set(TOKEN_TYPE, tokenType, {httpOnly: false, path: "/"});
        dispatch(props.requestUser(undefined));
    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            let authData = new FormData();
            authData.append("username", values.email);
            authData.append("password", values.password);
            login(authData)
                .then(response => {
                    let {access_token, token_type} = response.data;
                    setTokenCookies(access_token, token_type);
                    disableLoading();
                })
                .catch(error => {
                    if (error.response.data.detail === "Inactive user") {
                        successToast("Ask admin to enable the user");
                        disableLoading();
                        setSubmitting(false);
                        setStatus();
                    } else {
                        if (error.response.data.detail) {
                            disableLoading();
                            setSubmitting(false);
                        } else {
                            warningToast("Something went Wrong");
                            setSubmitting(false);
                            disableLoading();
                        }
                    }
                });
        }
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE"/>
                </h3>
                <p className="text-muted font-weight-bold">
                    Enter your username and password
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status ? (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                ) : null}

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Email"
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                        <input
                            placeholder="Password"
                            type={isShownPassword ? "text" : "password"}
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}

                        />
                        {isShownPassword ? (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                  onClick={togglePassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
            </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2" onClick={togglePassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
            </span>)}

                    </div>
                    <span
                        className={'float-right'}>
                                <Link
                                    to="/auth/forgot-password"
                                    className="text-dark-50 text-hover-primary d-flex justify-content-end "
                                >
                                    Forgot password ?

                                </Link>
                            </span>
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting || loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Sign In</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </form>
            <LoginWithSocialMedia
                enableLoading={enableLoading}
                disableLoading={disableLoading}
            />
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.AuthAction)(Login));
