import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as action from "../../_redux/ModelAction";
import * as frameworkAction from "../../../FrameworkManagement/_redux/FrameworkManagementAction";
import * as modelTypeAction from "../../../ModelType/_redux/ModelTypeAction";
import { ModelDetailsForm } from "./ModelDetailsForm";
import { ModelSlice } from "../../_redux/ModelSlice";

const { actions } = ModelSlice;

export function ModelDetailsDialog({ id, show, onHide }) {
  const dispatch = useDispatch();
  const {
    actionsLoading,
    modelDetails,
    frameworkDetails,
    modelTypeDetails
  } = useSelector(
    state => ({
      actionsLoading: state.model.actionsLoading,
      modelDetails: state.model.modelFetchedById,
      frameworkDetails: state.frameworkManagement.frameworkFetchedById,
      modelTypeDetails: state.modelType.modelTypeFetchedById
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) dispatch(action.fetchModelById(id));
    return () => {
      dispatch(actions.clearModelById());
    };
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (modelDetails.framework_id)
      dispatch(frameworkAction.fetchFrameworkById(modelDetails.framework_id));
    if (modelDetails.model_type_id)
      dispatch(modelTypeAction.fetchModelTypeById(modelDetails.model_type_id));
    // eslint-disable-next-line
  }, [modelDetails]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Model Details
        </Modal.Title>
      </Modal.Header>
      <ModelDetailsForm
        actionsLoading={actionsLoading}
        modelDetails={modelDetails}
        frameworkDetails={frameworkDetails}
        modelTypeDetails={modelTypeDetails}
        onHide={onHide}
      />
    </Modal>
  );
}
