import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "../../../../shared/helper/UIHelpers";

const ProjectManagementUIContext = createContext();

export function useProjectManagementUIContext() {
  return useContext(ProjectManagementUIContext);
}

export function ProjectManagementUIProvider({
  projectManagementUIEvents,
  children
}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParams,
    newProjectManagementButtonClick:
      projectManagementUIEvents.newProjectManagementButtonClick,
    openChangeProjectManagementStatusDialog:
      projectManagementUIEvents.openChangeProjectManagementStatusDialog,
    openEditProjectManagementDialog:
      projectManagementUIEvents.openEditProjectManagementDialog
  };

  return (
    <ProjectManagementUIContext.Provider value={value}>
      {children}
    </ProjectManagementUIContext.Provider>
  );
}
