import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import ModalFooterCommon from "../../../../shared/components/ModalFooterCommon";
import { makeStyles, TextField } from "@material-ui/core";
import { Row } from "reactstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import * as moment from "moment";
import { checkRTSPURL } from "../../../../service/datasetManagement.api";
import {
  infoToastShort,
  warningToast
} from "../../../../shared/components/ToastMessage";
import { weekdays } from "../../../../shared/enums/dropDown.enum";
import BlockUi from "react-block-ui";

const useStyles = makeStyles({
  media: {
    height: 270
  },
  header: {
    paddingBottom: "0rem"
  },
  card: {
    maxWidth: 380,
    height: "100%"
  },
  title: {
    display: "inline-flex",
    margin: "1rem 0"
  },
  learnMore: {
    position: "absolute",
    bottom: 0
  },
  cards: {
    backgroundColor: "#f5f5f5"
  }
});

function RTSPDataSetUpload({
                             showModal,
                             setShow,
                             rtspList,
                             setRtspList,
                             remove
                           }) {
  const classes = useStyles();
  const [repeatDays, setRepeatDays] = useState([]);
  const [rtspUrl, setRTSPUrl] = React.useState(null);
  // const [rtspList, setRtspList] = useState([]);
  const [state, setState] = React.useState({
    start_time: "07:30",
    end_time: "07:30"
  });
  const [fpsForRtsp, setFpsForRtsp] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [loaderState, setLoaderState] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [id, setId] = useState();
  const [flag, setFlag] = useState(true);
  const [validateUrl, setValidateUrl] = useState({
    isTouched: false,
    isValid: false,
    msg: (
        <>
          Please enter <b>RTSP URL</b>
        </>
    )
  });
  const [errors, setErrors] = useState({
    fpsForRtsp: "",
    expiryDate: "",
    repeatDays: "",
    rtspUrl: "",
    start_time: ""
  });
  useEffect(() => {
    setRtspList([]);
  }, [remove]);
  useEffect(() => {
    let is_valid = validate();
    is_valid ? setFlag(false) : setFlag(true);
  }, [fpsForRtsp,rtspUrl,expiryDate,repeatDays,validateUrl]);


  const validate = () => {

    let errors = {};
    let isValid = true;

    if (!validateUrl.isValid) {
      isValid = false;
    }


    if (!fpsForRtsp && fpsForRtsp !==null) {
      isValid = false;
      errors.fpsForRtsp = "FPS must be required.";

    }

    if(fpsForRtsp && !new RegExp(/^([0-9]*)$/).test(fpsForRtsp)){
        isValid = false;
        errors.fpsForRtsp = "Enter FPS in number.";
      }
    if (state.start_time.length === 0 || state.end_time.length === 0) {
      isValid = false;

      warningToast("Please enter start-time and end-time.");
    }
    if (state.start_time > state.end_time) {
      isValid = false;

      errors.start_time = "Please enter end-time greater then start-time.";
    }

    if(expiryDate===null || fpsForRtsp === null || repeatDays.length===0 || validateUrl===false){
      isValid = false;
    }
    if (!repeatDays && repeatDays !==null) {
      isValid = false;
      errors.repeatDays = "Please select days of week.";
    }
    if (!expiryDate && expiryDate !==null) {
      isValid = false;
      errors.expiryDate = "Please enter expiry date.";
    }

    setErrors(errors);

    return isValid;
  };
  const validateRTSPURL = async () => {

    if(rtspUrl!==null){
      setLoaderState(true);
    const { data, isSuccess ,operand, trimmedValue } = await checkRTSPURL(rtspUrl);

    if (!isSuccess) {
      setLoaderState(false);
      warningToast("Something went wrong while validating rtsp url");
      setValidateUrl({
        isTouched: true,
        isValid: false,
        msg: "something went wrong while validating RTSP URL"
      });
      return false;
    } else if (!data) {
      setLoaderState(false);
      setValidateUrl({
        isTouched: true,
        isValid: false,
        msg: (
            <>
              Please enter valid <b>RTSP URL</b>
            </>
        )
      });
      return false;
    } else {
      setLoaderState(false);
      setValidateUrl({
        isTouched: true,
        isValid: true,
        msg: (
            <>
              Entered <b>RTSP URL</b> is valid
            </>
        )
      });

      return true;
    }
    }
    else {
      setValidateUrl({
        isTouched: true,
        isValid: false,
        msg: (
            <>
              Please enter valid <b>RTSP URL</b>
            </>
        )
      });
    }

  };

  const handleChange = e => {
    const value = e.target.value;
    setState({ ...state, [e.target.name]: value });
    let data = { ...state };
    if (e.target.name === "start_time") {
      data[e.target.name] = e.target.value.trim();
      if (data.start_time && data.end_time === "") {
        data[e.target.name] = e.target.value.trim();
        setState(data);
      }
    } else if (e.target.name === "end_time") {
      if (data.start_time < e.target.value) {
        data[e.target.name] = e.target.value.trim();
        setState(data);
      } else if (data.start_time > e.target.value) {
        warningToast("Please Enter An End Time Greater Than The Start Time.");
      }
    } else {
      data[e.target.name] = e.target.value.trim();
      setState(data);
    }
  };
  const handleChangeExpDate = e => {
    if (e.target.name === "fps") {
      setFpsForRtsp(e.target.value);
    }
    if (e.target.name === "expiry_date") {
      setExpiryDate(e.target.value);
    }

    let is_valid = validate();
    is_valid ? setFlag(false) : setFlag(true);
  };

  const handleRepeatDaysChange = e => {
    let data = [...repeatDays];
    if (!data.includes(e)) {
      data.push(e);
    } else {
      let myArray = data.filter(function(obj) {
        return obj !== e;
      });
      data = myArray;
    }
    setRepeatDays(data);
  };

  const addRtspUrl = () => {
    if (validate()) {
      let rtspObj = {
        start_time: state.start_time,
        end_time: state.end_time,
        fps: fpsForRtsp,
        rtsp_url: rtspUrl,
        service_stop_date: expiryDate,
        repeat_days: repeatDays.join(",")
      };

      if (isUpdate) {
        setRtspList([
          ...rtspList.slice(0, id),
          rtspObj,
          ...rtspList.slice(id + 1)
        ]);
      } else {
        setRtspList([...rtspList, rtspObj]);
      }
      handleCloseRtsp();
      setErrors({
        fpsForRtsp: null,
        expiryDate: null,
        repeatDays: null,
        rtspUrl: null,
        start_time: ""
      });
      setRTSPUrl(null);
      setState({ start_time: "07:30", end_time: "07:30" });
      setFpsForRtsp(null);
      setExpiryDate(null);
      setRepeatDays([]);
      validateUrl.isTouched = false;
      // }
    }
  };
  const openEditDatasetStorageTypeDialog = (id, item) => {
    var b = item.repeat_days.split(",").map(function(item) {
      return parseInt(item, 10);
    });
    setId(id);
    setIsUpdate(true);
    setShow(true);
    setRTSPUrl(item.rtsp_url);
    setState({ start_time: item.start_time, end_time: item.end_time });
    setFpsForRtsp(item.fps);
    setExpiryDate(item.service_stop_date);
    setRepeatDays(b);
  };

  const copyLink = (e, link) => {
    e.preventDefault();
    copyTextToClipboard(link).then(res => {
      infoToastShort("Link Copied!");
    });
  };

  const handleCloseRtsp = () => {
    validateUrl.isTouched = false;
    setShow(false);
    setLoaderState(false);
    setId();
    setIsUpdate(false);
    setRTSPUrl("");
    setState({ start_time: "07:30", end_time: "07:30" });
    setFpsForRtsp(null);
    setExpiryDate(null);
    setRepeatDays([]);
     };

  const minDateTime = new Date();

  return (
      <>
        <div className={"mt-3 d-flex justify-content-space-between flex-wrap RtspDatsetCard"}>
          {rtspList &&
              rtspList.map((item, index) => (
                  <>
                    <div className={"rtsp-detail-card"}>
                      <div>
                        <a
                            className="btn btn-icon btn-light btn-hover-primary btn-sm rtsp-edit-btn"
                            onClick={id =>
                                openEditDatasetStorageTypeDialog(index, item)
                            }
                        >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                          title="Edit Dataset Storage Type"
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                          )}
                      />
                    </span>
                        </a>
                      </div>
                      <div className={"my-1 d-flex"}>
                        <span className={"field-title"}>URL:</span>{" "}
                        <Tooltip
                            title={<div className="tooltip-font">{item.rtsp_url}</div>}
                            placement={"bottom"}
                        >
                          <a
                              className={"rtsp-url ml-2"}
                              href={item.rtsp_url}
                              onClick={e => copyLink(e, item.rtsp_url)}
                          >
                            {item.rtsp_url}
                          </a>
                        </Tooltip>
                      </div>
                      <div className={"my-1"}>
                        <span className={"field-title"}>Time:</span> {item.start_time}{" "}
                        to {item.end_time}
                      </div>
                      <div className={"my-1"}>
                        <span className={"field-title"}>Expiry Date:</span>{" "}
                        {moment
                            .utc(item.service_stop_date)
                            .local()
                            .format("MMMM DD YYYY")}
                      </div>
                      <div className={"my-1"}>
                        <span className={"field-title"}>FPS:</span> {item.fps}
                      </div>
                      <hr />
                      <div>
                        {weekdays.map(data => (
                            <span
                                className="mr-2"
                                style={{
                                  fontWeight: `${
                                      item?.repeat_days?.includes(data.id) ? 900 : 100
                                  }`
                                }}
                            >
                      {data.value}
                    </span>
                        ))}
                      </div>
                    </div>
                  </>
              ))}
        </div>

        {showModal && (
            <Modal
                show={showModal}
                onHide={handleCloseRtsp}
                backdrop="static"
                keyboard={false}
                scrollable={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter "
                className={"modal-background-color"}
                centered
            >
              <ModalHeaderCommon
                  title={`${rtspList && rtspList.length > 0 ? "Edit " : "Add"} Rtsp`}
              />
              <Modal.Body>
                <BlockUi tag="div" blocking={loaderState} color="#147b82">
                  <div className="mr-3 mt-5" style={{ paddingLeft: "14px" }}>
                    <Form.Label>RTSP URL</Form.Label>
                    <Form.Control
                        type="text"
                        className={`form-control ${
                            validateUrl.isTouched && validateUrl.isValid
                                ? " is-valid"
                                : `${validateUrl.isTouched ? " is-invalid" : ""}`
                        }`}
                        name="rtsp_url"
                        placeholder="RTSP URL"
                        value={rtspUrl}
                        onChange={e => setRTSPUrl(e.target.value)}
                        onBlur={validateRTSPURL}
                    />
                    <div
                        className={`${
                            validateUrl.isTouched && validateUrl.isValid
                                ? " valid-feedback"
                                : `${
                                    validateUrl.isTouched
                                        ? " invalid-feedback"
                                        : "feedback"
                                }`
                        }`}
                    >
                      {validateUrl.msg}
                      <div className={"input-validation-star pl-4"}>
                        {errors.rtspUrl}
                      </div>
                    </div>

                    <div className={"row"}>
                      <div className={"col-6 mt-3"}>
                        <div className={"mr-2 mb-2"}>
                          <form className={classes.container} noValidate>
                            <Form.Label>Start Time (IST)</Form.Label>
                            <TextField
                                id="time"
                                // label="Start Time"
                                type="time"
                                variant={"outlined"}
                                name={"start_time"}
                                value={state.start_time}
                                className={"form-control"}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                  style: { height: "2px" }
                                }}
                            />
                          </form>
                        </div>
                      </div>
                      <div className={"col-6 mt-3"}>
                        <div className={"mr-2 mb-4"}>
                          <form className={classes.container} noValidate>
                            <Form.Label>End Time (IST)</Form.Label>
                            <TextField
                                id="time"
                                variant={"outlined"}
                                type="time"
                                name={"end_time"}
                                value={state.end_time}
                                className={"form-control"}
                                style={{ height: "2px" }}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                  style: { height: "2px" }
                                }}
                            />
                          </form>
                        </div>
                      </div>
                      <div className={"input-validation-star"}>
                        {errors.start_time}
                      </div>
                    </div>

                    <div className={"row"}>
                      <div className={"col-6 mt-1"}>
                        <div>
                          <Form.Group
                              controlId="SPS"
                              as={Row}
                              style={{ width: "195px" }}
                              className={"ml-1 mt-0 mb-0"}
                          >
                            <Form.Label>Enter FPS</Form.Label>
                            <Form.Control
                                type="text"
                                name="fps"
                                placeholder="FPS"
                                value={fpsForRtsp}
                                onChange={handleChangeExpDate}
                                onBlur={handleChangeExpDate}
                            />
                          </Form.Group>
                          <div className={"input-validation-star"}>
                            {errors.fpsForRtsp}
                          </div>
                        </div>

                      </div>
                      <div className={"col-6 mt-1"}>
                        <div className={"mr-2"}>
                          <div>
                            <label>Expiry date</label>
                          </div>
                          <TextField
                              id="time"
                              variant={"outlined"}
                              type="datetime-local"
                              name={"expiry_date"}
                              value={expiryDate}
                              className={"form-control"}
                              style={{ height: "2px" }}
                              onChange={handleChangeExpDate}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                step: 300, // 5 min
                                style: { height: "2px" },
                                min: minDateTime.toISOString().slice(0, 16)
                              }}
                              disableFuture = {true}
                          />
                        </div>
                        <div className={"input-validation-star mt-6"}>
                          {errors.expiryDate}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="ml-1 mb-1">
                        <span>Repeats On</span>
                      </div>
                      <div className="mb-2">
                        {weekdays.map((item) => (
                            <Button
                                className="btn btn-elevate weekday-scheduler-btn mr-1 text-white"
                                name="repeat_days"
                                style={{
                                  backgroundColor: `${
                                      repeatDays?.includes(item.id)
                                          ? "#5DBFC4"
                                          : "#147b82"
                                  }`
                                }}
                                value={repeatDays}
                                onClick={e => handleRepeatDaysChange(item.id)}
                            >
                              {item.value}
                            </Button>
                        ))}
                      </div>
                      <div className={"input-validation-star mt-2"}>
                        {errors.repeatDays}
                      </div>
                    </div>
                  </div>
                </BlockUi>
              </Modal.Body>
              <ModalFooterCommon
                  handleClose={handleCloseRtsp}
                  submitEmployee={addRtspUrl}
                  id={rtspList.length }
                  flag={flag}
              />
            </Modal>
        )}
      </>
  );
}

export default RTSPDataSetUpload;

async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
