import React from "react";

export const SearchText = ({onChangeHandler, reference, onKeyDown,searchField}) =>
    // <div className="offset-lg-9 col-lg-3 ">
    // <div className="mr-5 mt-5">
        <input
            type="text"
            className="form-control"
            name="searchText"
            // placeholder="Search"
            placeholder={searchField}
            onChange={onChangeHandler}
            ref={reference}
            onKeyDown={onKeyDown}
        />
        {/*<small className="form-text text-muted text-right">*/}
        {/*    <b>Search {searchField}</b>*/}
        {/*</small>*/}
    // </div>