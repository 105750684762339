export const appConfigs = {
  APP_ENV: process.env.REACT_APP_ENV,
  APP_IP: process.env.REACT_APP_IP,
  API_HOST: process.env.REACT_APP_API_HOST,
  APP_TITLE_PREFIX: "Easy AI",
  APP_AUTO_SERVING_IP: process.env.REACT_APP_AUTO_SERVING_IP,
  APP_AUTO_SERVING_API_HOST: process.env.REACT_APP_AUTO_SERVING_API_HOST,
  APP_REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,

  APP_GG_APP_ID: process.env.REACT_APP_GG_APP_ID,
  APP_GITHUB_CLIENT_ID: process.env.REACT_APP_GITHUB_CLIENT_ID,
  APP_LINKEDIN_APP_ID: process.env.REACT_APP_LINKEDIN_APP_ID,
  APP_MICROSOFT_APP_ID: process.env.REACT_APP_MICROSOFT_APP_ID,
  APP_RE_REDIRECT_URL: process.env.REACT_APP_RE_REDIRECT_URL,

};
