import React from "react";
import AIModelMultiStepWizard from "./wizard";
import SignUpPage from "./SignUpPage";
import LoginPage from "./LoginPage";
import ModelDetails from "./ModelDetails";

export default function StartWizardPage({
  onHide,
  // id,
  experimentDetails,
  onStepsCompleted,
  // companyDetails,
  // setCompanyDetails,
  // userDetails,
  // setUserDetails,
  // modelDetails,
  // setModelDetails,
  accountType,
  deploymentType
}) {
  const stepsForSignUp = [
    {
      name: "Company Registration",
      component: (
        <SignUpPage
          // companyDetails={companyDetails}
          // setCompanyDetails={setCompanyDetails}
          onHide={onHide}
          accountType={accountType}
        />
      )
    },
    {
      name: "user Registration",
      component: (
        <LoginPage
          // companyDetails={companyDetails}
          // setCompanyDetails={setCompanyDetails}
          // userDetails={userDetails}
          // setUserDetails={setUserDetails}
          onHide={onHide}
          accountType={accountType}
        />
      )
    },
    {
      name: "Model Details",
      component: (
        <ModelDetails
          // modelDetails={modelDetails}
          // setModelDetails={setModelDetails}
          experimentDetails={experimentDetails}
          // id={id}
          onHide={onHide}
          accountType={accountType}
          deploymentType={deploymentType}
        />
      )
    }
  ];
  const stepsForLogin = [
    {
      name: "user Registration",
      component: (
        <LoginPage
          // companyDetails={companyDetails}
          // setCompanyDetails={setCompanyDetails}
          // userDetails={userDetails}
          // setUserDetails={setUserDetails}
          onHide={onHide}
          accountType={accountType}
        />
      )
    },
    {
      name: "Model Details",
      component: (
        <ModelDetails
          // modelDetails={modelDetails}
          // setModelDetails={setModelDetails}
          experimentDetails={experimentDetails}
          // id={id}
          onHide={onHide}
          accountType={accountType}
          deploymentType={deploymentType}
        />
      )
    }
  ];
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const { currentState } = useSelector(
  //   state => ({ currentState: state.companyRegistration }),
  //   shallowEqual
  // );
  // useEffect(() => {}, []);
  //
  // const { uploadedImagesArr } = currentState;
  return (
    <>
      <div className="forms-wizard-alt">

        <AIModelMultiStepWizard
          // id={id}
          // companyDetails={companyDetails}
          // setCompanyDetails={setCompanyDetails}
          // userDetails={userDetails}
          // setUserDetails={setUserDetails}
          // modelDetails={modelDetails}
          // setModelDetails={setModelDetails}
          onStepsCompleted={onStepsCompleted}
          onHide={onHide}
          showNavigation={true}
          accountType={accountType}
          deploymentType={deploymentType}
          steps={accountType === "no" ? stepsForSignUp : stepsForLogin}
        />
      </div>
    </>
  );
}
