/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/Pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {Layout} from "../../../_metronic/layout";
import {AuthPage, Logout} from "../../Account";
import ErrorsPage from "../../../shared/components/ErrorsExamples/ErrorsPage";
import Cookies from "universal-cookie";
import UserBasePage from "./SubRoutes/UserBasePage";
import BasePage from "./SubRoutes/BasePage";
import {browserName, browserVersion, deviceType, mobileModel, osName} from "react-device-detect";

export function Routes() {
    const history = useHistory();
    const location = useLocation();
    const [context, setContext] = useState("");
    const {isAuthorized = false, user} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user?.id && new Cookies().get("access_token"),
            user: auth.user
        }),
        shallowEqual
    );

    useEffect(() => {
        if (!isAuthorized && !location.pathname.includes("/auth")) {
            history.push("/auth/login");
        }
        const userContext = {
            kind: "user",
            key: user && user?.id,
            firstName: user && user?.first_name ? user?.first_name : "",
            lastName: user && user?.last_name ? user?.last_name : "",
            groups: ["Google", "Microsoft", "Linkedin", "Github"]
        };

        const deviceContext = {
            kind: "device",
            key: user && user?.id,
            type: deviceType,
            osName: osName,
            browserName: browserName,
            browserVersion: browserVersion,
            mobileModel: mobileModel
        };
        const multiContext = {
            kind: "multi",
            user: userContext,
            device: deviceContext
        };
        setContext(multiContext);
    }, [isAuthorized, location.pathname]);

    return (
        <>

            <Switch>
                <Route path="/error" component={ErrorsPage}/>
                <Route path="/logout" component={Logout}/>

                {!isAuthorized ? (
                    <AuthPage/>
                ) : (
                    <>
                        <Layout>
                            {user?.roles[0].role === "superadmin" ? (
                                <BasePage/>
                            ) : (
                                <UserBasePage/>
                            )}
                        </Layout>
                    </>
                )}
            </Switch>
        </>
    );
}
