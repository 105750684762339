import {callTypes, FrameworkManagementSlice} from "./FrameworkManagementSlice";
import {successToast, warningToast} from "../../../../shared/components/ToastMessage";
import {addNewFramework, getAllFramework, getFrameworkById, updateFramework} from "./FrameworkManagementAPI";

const {actions} = FrameworkManagementSlice;

export const fetchFrameworks = (pageNo ,pageSize,search) => dispatch => {
    dispatch(actions.startCall({callType: callTypes.list}));
    return getAllFramework(pageNo ,pageSize,search)
        .then(response => {
            if (response && response.isSuccess) {
                dispatch(actions.frameworkFetched(response.data));
            } else {
                warningToast("something went wrong");
            }
        })
        .catch(error => {
            error.clientMessage = "Can't find frameworks";
            dispatch(actions.catchError({error, callType: callTypes.list}));

        });
};

export const fetchFrameworkById = (id) => dispatch => {

    dispatch(actions.startCall({callType: callTypes.action}));
    return getFrameworkById(id)
        .then(response => {
            if (response && response.isSuccess) {
                dispatch(actions.frameworkFetchedById(response.data));
            } else {
                throw new Error('Error getting framework details');
            }
        })
        .catch(error => {
            warningToast("Something went wrong");
            console.error('Error: ', error);
            dispatch(actions.catchError({error, callType: callTypes.action}));
        });
};

export const createFramework = (frameworkData) => dispatch => {

    dispatch(actions.startCall({callType: callTypes.action}));

    const data = {
        "framework_name": frameworkData.frameworkName,
        "framework_description": frameworkData.frameworkDescription,
        "framework_version": frameworkData.frameworkVersion,
        "status": frameworkData.status
    }
    return addNewFramework(data).then(response => {
        if (response && response.isSuccess) {
            dispatch(actions.frameworkCreated(response.data));
            successToast("Framework Added Successfully");
        } else {
            throw new Error('something went wrong');
        }
    }).catch(error => {
        warningToast("something went wrong");
        console.error('error:', error);
        dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}

export const frameworkUpdate = (frameworkData) => dispatch => {

    dispatch(actions.startCall({callType: callTypes.action}));
    const data = {
        "framework_name": frameworkData.frameworkName || frameworkData.framework_name,
        "framework_description": frameworkData.frameworkDescription || frameworkData.framework_description,
        "framework_version": frameworkData.frameworkVersion || frameworkData.framework_version,
        "status": frameworkData.status,
        "id": frameworkData.id
    }

    return updateFramework(data).then(response => {

        if (response && response.isSuccess) {
            dispatch(actions.frameworkUpdated(response.data));
        } else {
            throw new Error('Error updating framework details');
        }
    }).catch(error => {
        warningToast("Something went wrong");
        console.error('Error: ', error);
        dispatch(actions.catchError({error, callType: callTypes.action}));
    });
}