import React, { useEffect } from "react";
import { ProjectManagementCard } from "./ProjectManagementCard";
import * as action from "../../../../redux/actions/projectManagementAction";
import { useDispatch } from "react-redux";
export function ProjectManagementPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(action.fetchProjects(1, 10, ""));
  }, []);

  return <ProjectManagementCard />;
}
