import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import * as action from "../../_redux/FrameworkManagementAction";
import {AutoServingStatusChangeAlert} from "../../../../../shared/components/AutoServingStatusChangeAlert";

export function FrameworkManagementStatusDialog({id, status, show, onHide}) {

    const {entities} = useSelector(
        (state) => ({
            entities: state.frameworkManagement.entities
        }),
        shallowEqual
    );

    return (
        <AutoServingStatusChangeAlert
            id={id}
            status={status}
            show={show}
            onHide={onHide}
            entities={entities}
            updateMethod={action.frameworkUpdate}
        />
    );
}
