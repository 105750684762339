import { callTypes, DatasetStorageTypeSlice } from "./DatasetStorageTypeSlice";
import { successToast, warningToast } from "../../../../shared/components/ToastMessage";
import {
  addNewDatasetStorageType,
  getAllDatasetStorageType,
  getAllEnabledDatasetStorageType,
  getDatasetStorageTypeById,
  updateDatasetStorageType
} from "./DatasetStorageTypeAPI";



const { actions } = DatasetStorageTypeSlice;

export const fetchDatasetStorageTypes = (pageNo ,pageSize,search) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllDatasetStorageType(pageNo ,pageSize,search)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetStorageTypeFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Dataset storage types";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEnabledDatasetStorageTypes = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllEnabledDatasetStorageType()
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetStorageTypeFetchedForData(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Dataset storage types";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createDatasetStorageType = datasetStorageTypeData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  const data = {
    dataset_storage_name: datasetStorageTypeData.datasetStorageTypeName,
    dataset_storage_description:
      datasetStorageTypeData.datasetStorageTypeDescription,
    status: datasetStorageTypeData.status
  };
  return addNewDatasetStorageType(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetStorageTypeCreated(response.data));
        successToast("Dataset Storage Type Added Successfully");
      } else {
        throw new Error("something went wrong");
      }
    })
    .catch(error => {
      warningToast("something went wrong");
      console.error("error:", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDatasetStorageTypeById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getDatasetStorageTypeById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetStorageTypeFetchedById(response.data));
      } else {
        throw new Error("Error getting Dataset storage type details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const datasetStorageTypeUpdate = datasetStorageTypeData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    dataset_storage_name:
      datasetStorageTypeData.datasetStorageTypeName ||
      datasetStorageTypeData.dataset_storage_name,
    dataset_storage_description:
      datasetStorageTypeData.datasetStorageTypeDescription ||
      datasetStorageTypeData.dataset_storage_description,
    status: datasetStorageTypeData.status,
    id: datasetStorageTypeData.id
  };

  return updateDatasetStorageType(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetStorageTypeUpdated(response.data));
      } else {
        throw new Error("Error updating Dataset storage type details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
