import React from "react";
import {Col, Row} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Tooltip} from "@material-ui/core";
import {infoToastShort} from "../../../../shared/components/ToastMessage";

function Folder({
                    uploadedDataset, datasetFolderOpen
                }) {

    return (<>
            {uploadedDataset && uploadedDataset.length > 0 ? (<Row>
                {uploadedDataset && uploadedDataset.map((item, index) => (<Col xl={1} md={2} xs={6} sm={4}>
                        <div className={'text-center'}>
                            <div className="icon d-flex justify-content-center" id={index}>
                                <div>
                    <span
                        title={index + 1}
                        style={{position: "relative"}}
                        className="
                      svg-icon
                      svg-icon-xxl
                      svg-icon-2x
                      svg-icon-primary
                      cursor-pointer folderBox"
                        onClick={() => {
                            item.status ? datasetFolderOpen(item.id) : infoToastShort("Please wait while upload process going on");
                        }}
                    >
                      <SVG
                          title={item.dataset_name}
                          className="upload-dataset-folder showHover"
                          src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")}
                      />
                      <SVG
                          title={item.dataset_name}
                          className="upload-dataset-folder showN"
                          src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")}
                      />
                    </span>
                                </div>
                            </div>
                            <Tooltip
                                title={<div>{item.dataset_name}</div>}
                                placement={"top"}
                            >
                  <span className="annotation-tool-dataset-name">
                    {item.dataset_name}
                  </span>
                            </Tooltip>
                            <div className={"d-flex justify-content-center"}>
                                {!item.status ? (<span
                                        className="ml-3 label label-lg label-light-warning label-inline font-weight-bold py-4">
                    processing
                  </span>) : (<span className="ml-3 label label-lg label-light-success label-inline mt-1">
                    completed
                  </span>)}
                            </div>

                        </div>
                    </Col>))}
            </Row>) : (<h3 className={" mt-5 d-flex justify-content-center"}>
                    No Data Found
                </h3>)}

        </>);
}

export default Folder;
