import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Button} from "react-bootstrap";
import {addOtp, requestForgotPassword, validateOtpAPI} from "../../../../service/authCrud.api";
import {extractErrorFromError} from "../../../../shared/helper/RequestWrapper";
import {successToast, warningToast} from "../../../../shared/components/ToastMessage";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import SendEmailNotificationModal from "../../../../shared/components/SendEmailNotificationModal";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

const otpRules = /^\d{6}$/;
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

function ForgotPassword() {
    let history = useHistory();
    const [isOtpInitiated, setOtpInitiated] = useState(false);
    const [email, setEmail] = useState("");
    const [isOtpVerified, setOtpVerified] = useState(false);
    const [show, setShow] = useState(false);
    const [textShowOtp, setTextShowOtp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validateOtp, setValidateOtp] = useState(false);
    const [isShownPassword, setIsShownPassword] = useState(false);
    const [isShownConfirmPassword, setIsShownConfirmPassword] = useState(false);

    const togglePassword = () => {
        setIsShownPassword(isShownPassword ? false : true);
    };
    const toggleConfirmPassword = () => {
        setIsShownConfirmPassword(isShownConfirmPassword ? false : true);
    };
    const initialValues = useSelector(state => {
        return {
            email: "",

        };
    });


    const ForgotSchema = Yup.object().shape({
        email: Yup.string()
            .matches(/^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim, "Incorrect email format.")
            .max(50, "Maximum 50 characters")
            .required("Email must be required"),

        otp: Yup.string().required("Otp must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(otpRules, {
                    message:
                        "kindly enter a valid OTP."
                })
        }),

    });

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .matches(/^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim, "Incorrect email format.")
            .max(50, "Maximum 50 characters")
            .required("Email must be required"),


        password: Yup.string().required("Password must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(passwordRules, {
                    message:
                        "Please establish a stronger password with at least five characters, one uppercase, one lowercase, and one digit."
                })
                .max(50, "Maximum 50 characters")

        }),
        confirmPassword: Yup.string().required("Confirm Password must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref("password"), null], "Password and Confirm Password didn't match")

        }),
    });

    const modalOpen = () => {
        setShow(true);
    };

    const modalClose = () => {
        setShow(false);
    };

    const sendOtpOnEmail = (email, forceResend = false) => {
        if (isOtpInitiated && !forceResend) {
            return;
        }
        setLoading(true);
        setOtpInitiated(true);
        setOtpVerified(false);
        let body = {
            email: email,
            check_email: true,
            otp_type: "forgot_password"
        };
        setEmail(email);
        addOtp(body)
            .then(response => {
                modalOpen();
                setTextShowOtp(true);
                setLoading(false);
            })
            .catch(error => {
                console.log("error::", error);
                const warningMsg = extractErrorFromError(error);
                warningToast(warningMsg);
                modalClose();
                setLoading(false);
                setTextShowOtp(false);
            });
    };

    const handleValidateOtp = (email, otp) => {
        setLoading(true);
        if (!email || !otp) {
            setLoading(false);
            formik.setSubmitting(false);
            formik.setStatus("Invalid Otp Please try Again");
            warningToast("Invalid Otp");
            return;
        }
        validateOtpAPI(email, otp)
            .then(res => {
                setValidateOtp(false);
                const isSuccess = res?.data?.success;
                setOtpVerified(isSuccess || false);
                if (isSuccess) {
                    successToast("Otp Validated Successfully");
                    setValidateOtp(true);
                    setLoading(false);
                    // setEditUser(false)
                    // setTimeout(() => formik.submitForm(), 400);
                } else {
                    setLoading(false);
                    formik.setSubmitting(false);
                    formik.setStatus("Invalid Otp Please try Again");
                    warningToast("Invalid Otp");
                }
            })
            .catch(error => {
                console.log("error::", error);
                if (error) {
                    warningToast(error.detail);
                } else {
                    warningToast("Something went Wrong");
                }
                modalClose();
                setLoading(false);
                formik.setSubmitting(false);
                formik.setStatus("Invalid Otp Please try Again");
                warningToast("Invalid Otp");
            });
    };

    const handleOTPFocus = () => {
        formik.setStatus("");
    };


    const formik = useFormik({
        initialValues,
        validationSchema: validateOtp ? ForgotPasswordSchema : ForgotSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            if (isOtpInitiated && isOtpVerified && textShowOtp && validateOtp) {
                let body = {
                    new_password: values?.password || undefined,
                    email: values?.email
                };
                requestForgotPassword(body)
                    .then(response => {
                        successToast("Your Password Changes Successfully");
                        setLoading(false)
                        history.push("/user/profile");

                    })
                    .catch(error => {
                        console.error("error::", error);
                        const warningMsg = extractErrorFromError(error);
                        warningToast(warningMsg);
                        setLoading(false)
                        setStatus(warningMsg);
                    });
            } else if (
                !isOtpInitiated &&
                textShowOtp &&
                !validateOtp
            ) {
                sendOtpOnEmail(values?.email);
            } else if (textShowOtp && !validateOtp) {
                if (values.otp) {
                    setSubmitting(false);
                }
                handleValidateOtp(values?.email, values.otp);
            } else {
            }
        }
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const handleClose = () => {
        history.push("/user/profile/change-password")
    }
    const handleChangeClose = () => {
        setTextShowOtp(false)
    }


    return (
        <>

            <form
                className="card card-custom card-stretch"
                onSubmit={formik.handleSubmit}
            >
                {/* begin::Header */}
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            Forgot Password
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            Change your account password
                        </span>
                    </div>
                    <div className="card-toolbar">
                        {validateOtp ?
                            (
                                <div className=" d-flex flex-wrap flex-center">
                                    <Button variant="primary" className={"mr-2 px-6 py-2"}
                                            type="submit"
                                            disabled={!formik.values.password && !formik.values.confirmPassword}>

                                        <span>Submit</span>
                                        {loading && (
                                            <span className="ml-3 spinner spinner-white"></span>
                                        )}
                                    </Button>


                                </div>
                            ) :
                            (textShowOtp && !validateOtp) ?
                                (
                                    <div className=" d-flex  flex-wrap flex-center">

                                        <Button
                                            variant="primary" className={"mr-2 px-6 py-2"}
                                            disabled={!formik.values.otp || formik.errors.otp || loading}
                                            onClick={e => {
                                                // setValidateOtp(true);
                                                formik.handleSubmit(e);
                                            }}
                                        >
                                            <span>Verify OTP</span>
                                            {loading && (
                                                <span className="ml-3 spinner spinner-white"></span>
                                            )}
                                        </Button>

                                    </div>
                                ) :
                                (
                                    <div className=" d-flex flex-wrap flex-center">

                                        <Button

                                            variant="primary" className={"mr-2 px-6 py-2"}
                                            disabled={formik.isSubmitting || !formik.values.email || formik.errors.email || loading}
                                            onClick={() => sendOtpOnEmail(formik.values.email, true)}
                                        >
                                            <span>Verify Email</span>
                                            {loading && (
                                                <span className="ml-3 spinner spinner-white"></span>
                                            )}
                                        </Button>
                                    </div>
                                )}

                        {validateOtp ? (<Button variant="secondary" className={"px-6 py-2"} onClick={handleClose}>
                            cancel
                        </Button>) : (textShowOtp && !validateOtp) ? (
                            <Button variant="secondary" className={"px-6 py-2"} onClick={handleChangeClose}>
                                cancel
                            </Button>) : (<Button variant="secondary" className={"px-6 py-2"} onClick={handleClose}>
                            cancel
                        </Button>)}

                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Form */}
                <div className="form">
                    {/* begin::Body */}
                    <div className="card-body">
                        <div className="row">
                            <label className="col-xl-3"></label>
                            <div className="col-lg-9 col-xl-6">
                                <h5 className="font-weight-bold mb-6">Customer Info</h5>
                            </div>
                        </div>


                        <div className="form-group row theme-color">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                Email Address
                                <span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            Enter your registered Email Address {" "}
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </label>
                            <div className="col-lg-9 col-xl-6">
                                <div className="input-group input-group-lg input-group-solid">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                            "email"
                                        )}`}
                                        name="email"
                                        disabled={textShowOtp}
                                        {...formik.getFieldProps("email")}

                                    />
                                </div>
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="input-validation-star">{formik.errors.email}

                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {textShowOtp && !validateOtp ? (
                            <div className="form-group row theme-color">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    OTP
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Enter OTP received on your email {" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <div className="input-group input-group-lg input-group-solid">
                                        <input
                                            placeholder="OTP"
                                            type="otp"
                                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                "otp"
                                            )}`}
                                            disabled={validateOtp}
                                            name="otp"
                                            {...formik.getFieldProps("otp")}
                                            onFocus={handleOTPFocus}
                                        />
                                    </div>
                                    {formik.touched.otp && formik.errors.otp ? (
                                        <div className="input-validation-star">{formik?.errors?.otp}</div>
                                    ) : null}
                                    <a
                                        className="text-dark-50 text-hover-primary mr-2"
                                        disabled={loading}
                                        onClick={() => sendOtpOnEmail(formik.values.email, true)}
                                    >
                                        <span className={"d-flex justify-content-end"}>ResendOTP</span>
                                        {loading && (
                                            <span className="ml-3 spinner spinner-white"></span>
                                        )}
                                    </a>
                                </div>

                            </div>
                        ) : (
                            <></>
                        )}
                        {validateOtp ? (
                            <>
                                {/* begin: Password */}
                                <div className="form-group row theme-color">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                        New Password
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={
                                                <div className="tools">
                                                    Create New Password {" "}
                                                </div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </label>
                                    <div className=" fv-plugins-icon-container col-lg-9 col-xl-6">
                                        <div className={"d-flex"}
                                             style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                            <input
                                                placeholder="Password"
                                                type={isShownPassword ? "text" : "password"}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "password"
                                                )}`}
                                                name="password"
                                                {...formik.getFieldProps("password")}

                                            />
                                            {isShownPassword ? (
                                                <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                      onClick={togglePassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
                                    </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                      onClick={togglePassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
                                    </span>)}

                                        </div>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.password}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* end: Password */}

                                {/* begin: Confirm Password */}
                                <div className="form-group row theme-color">
                                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                        Confirm Password
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={
                                                <div className="tools">
                                                    Re-Enter New Password {" "}
                                                </div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </label>
                                    <div className=" fv-plugins-icon-container col-lg-9 col-xl-6">
                                        <div className={"d-flex"}
                                             style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                            <input
                                                placeholder="Confirm Password "
                                                type={isShownConfirmPassword ? "text" : "password"}
                                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                                    "confirmPassword"
                                                )}`}
                                                name="cPassword"
                                                {...formik.getFieldProps("confirmPassword")}

                                            />
                                            {isShownConfirmPassword ? (
                                                <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                      onClick={toggleConfirmPassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
                                    </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                      onClick={toggleConfirmPassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
                                    </span>)}

                                        </div>
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.confirmPassword}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* end: Confirm Password */}
                            </>
                        ) : (
                            <></>
                        )}


                    </div>
                    {/* end::Body */}
                </div>
                {/* end::Form */}
            </form>
            <>
                <SendEmailNotificationModal
                    show={show}
                    modalClose={modalClose}
                    email={email}
                />

            </>
        </>


    );
}

export default ForgotPassword;