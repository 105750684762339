import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AnnotationTypeEditDialogHeader } from "./AnnotationTypeEditDialogHeader";
import { AnnotationTypeEditForm } from "./AnnotationTypeEditForm";
import { AnnotationTypeSlice } from "../../_redux/AnnotationTypeSlice";
import * as action from "../../_redux/AnnotationTypeAction";
import { SavingDetailsModal } from "../../../../../shared/components/SavingDetailsModal";
import { successToast } from "../../../../../shared/components/ToastMessage";

const { actions } = AnnotationTypeSlice;

export function AnnotationTypeEditDialog({ id, show, onHide }) {
  const { actionsLoading, annotationTypeFetchedById,currentState } = useSelector(
    state => ({
      actionsLoading: state.annotationType?.actionsLoading,
      annotationTypeFetchedById: state?.annotationType?.annotationTypeFetchedById,
      currentState: state?.annotationType

    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== null && id !== undefined) {
      dispatch(action.fetchAnnotationTypeById(id));
    } else {
      dispatch(actions.clearAnnotationTypeById());
    }
  }, [id, dispatch]);

  const [loading, setLoading] = useState(false);
  const saveAnnotationType = annotationType => {
    setLoading(true);
    if (!id) {
      dispatch(action.createAnnotationType(annotationType)).then(() =>
      {
        dispatch(action.fetchAnnotationTypes(currentState.page,currentState.size,""));
        onHide()}
      );
    } else {
      dispatch(action.annotationTypeUpdate(annotationType)).then(() => {
        successToast("Annotation type updated successfully");
        onHide();
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SavingDetailsModal show={loading} top={"start"} />
      <AnnotationTypeEditDialogHeader id={id} />
      <AnnotationTypeEditForm
        saveAnnotationType={saveAnnotationType}
        actionsLoading={actionsLoading}
        annotationTypeData={annotationTypeFetchedById}
        onHide={onHide}
      />
    </Modal>
  );
}
