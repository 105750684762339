/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {connect, shallowEqual, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Button} from "react-bootstrap";
import {resetPassword} from "../../../../service/authCrud.api";
import {AuthAction as action} from "../../../../redux/reducers/authRedux";
import {successToast, warningToast} from "../../../../shared/components/ToastMessage";
import {Link, useHistory} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

function ChangePassword() {
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector((state) => state.auth.user, shallowEqual);
    useEffect(() => {
    }, [user]);

    const [isShownCurrentPassword, setIsShownCurrentPassword] = useState(false);
    const [isShownPassword, setIsShownPassword] = useState(false);
    const [isShownConfirmPassword, setIsShownConfirmPassword] = useState(false);

    const togglePassword = () => {
        setIsShownPassword(isShownPassword ? false : true);
    };
    const toggleConfirmPassword = () => {
        setIsShownConfirmPassword(isShownConfirmPassword ? false : true);
    };
    const toggleCurrentPassword = () => {
        setIsShownCurrentPassword(isShownCurrentPassword ? false : true);
    };

    // UI Helpers
    const initialValues = {
        currentPassword: "",
        password: "",
        cPassword: "",
    };
    const Schema = Yup.object().shape({
        currentPassword: Yup.string().matches(passwordRules, {
            message:
                "Please establish a stronger password with at least five characters, one uppercase, one lowercase, and one digit."
        }).required("Current password must be required"),
        password: Yup.string().matches(passwordRules, {
            message:
                "Please establish a stronger password with at least five characters, one uppercase, one lowercase, and one digit."
        }).required("New Password must be required")
            .when("currentPassword", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().notOneOf(
                    [Yup.ref("currentPassword")],
                    "Password must be different from the current password."
                ),
            }),
        cPassword: Yup.string()
            .required("Confirm Password must be required")
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            let body = {
                old_password: values?.currentPassword,
                new_password: values?.password,
            };
            resetPassword(body)
                .then(response => {
                    if (response && response.isSuccess) {
                        dispatch(action.setUser(response.data));
                        successToast("Password Updated Successfully")
                    } else {
                        throw new Error('Error updating Password ');
                    }
                })
                .catch(error => {
                    console.log("error::", error);
                    if (error) {
                        warningToast(error.response.data.detail);
                    } else {
                        warningToast("Something went Wrong");
                    }

                });
        },
    });

    const handleClose = () => {
        history.push("/user/dashboard")
    }

    return (
        <form className="card card-custom" onSubmit={formik.handleSubmit}>


            {/* begin::Header */}
            <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                        Change Password
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
            Change your account password
          </span>
                </div>
                <div className="card-toolbar">
                    <Button variant="primary" type={"submit"} className={"mr-2"}
                            disabled={!formik.values.currentPassword
                                || !formik.values.password || !formik.values.cPassword || !formik.isValid}>

                        update Password
                        {formik.isSubmitting}
                    </Button>
                    <Button variant="secondary"
                            onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                <div className="card-body">
                    {/* begin::Alert */}
                    {isError && (
                        <div
                            className="alert alert-custom alert-light-danger fade show mb-10"
                            role="alert"
                        >
                            <div className="alert-icon">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                  ></SVG>{" "}
                </span>
                            </div>
                            <div className="alert-text font-weight-bold">
                                Configure user passwords to expire periodically. Users will need
                                warning that their passwords are going to expire,
                                <br/>
                                or they might inadvertently get locked out of the system!
                            </div>
                            <div className="alert-close" onClick={() => setIsError(false)}>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="alert"
                                    aria-label="Close"
                                >
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                                </button>
                            </div>
                        </div>
                    )}
                    {/* end::Alert */}
                    <div className="form-group row theme-color">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            Current Password
                            <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={
                                    <div className="tools">
                                        Enter your Current Password {" "}
                                    </div>
                                }
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </label>
                        <div className=" fv-plugins-icon-container col-lg-9 col-xl-6">
                            <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                <input
                                    placeholder="Current Password"
                                    type={isShownCurrentPassword ? "text" : "password"}
                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                        "currentPassword"
                                    )}`}
                                    name="currentPassword"
                                    {...formik.getFieldProps("currentPassword")}

                                />
                                {isShownCurrentPassword ? (
                                    <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                          onClick={toggleCurrentPassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
                                    </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                      onClick={toggleCurrentPassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
                                    </span>)}

                            </div>
                            <span
                                className={'float-right'}>
                                <Link
                                    to="/user/profile/forgot-password"
                                    className="text-dark-50 text-hover-primary d-flex justify-content-end "
                                >
                                    Forgot password ?

                                </Link>
                            </span>
                            {formik.touched.currentPassword && formik.errors.currentPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.currentPassword}
                                    </div>
                                </div>
                            ) : null}


                        </div>


                    </div>

                    <div className="form-group row theme-color">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            New Password
                            <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={
                                    <div className="tools">
                                        Create new Password {" "}
                                    </div>
                                }
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </label>
                        <div className=" fv-plugins-icon-container col-lg-9 col-xl-6">
                            <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                <input
                                    placeholder="Password"
                                    type={isShownPassword ? "text" : "password"}
                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                        "password"
                                    )}`}
                                    name="password"
                                    {...formik.getFieldProps("password")}

                                />
                                {isShownPassword ? (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                          onClick={togglePassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
                                    </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                      onClick={togglePassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
                                    </span>)}

                            </div>
                            {formik.touched.password && formik.errors.password ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.password}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>


                    <div className="form-group row theme-color">
                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                            Confirm Password
                            <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={
                                    <div className="tools">
                                        Re-Enter New Password {" "}
                                    </div>
                                }
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </label>
                        <div className=" fv-plugins-icon-container col-lg-9 col-xl-6">
                            <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                <input
                                    placeholder="Confirm Password "
                                    type={isShownConfirmPassword ? "text" : "password"}
                                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                        "cPassword"
                                    )}`}
                                    name="cPassword"
                                    {...formik.getFieldProps("cPassword")}

                                />
                                {isShownConfirmPassword ? (
                                    <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                          onClick={toggleConfirmPassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
                                    </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                                      onClick={toggleConfirmPassword}>
                                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
                                    </span>)}

                            </div>
                            {formik.touched.cPassword && formik.errors.cPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.cPassword}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                </div>
            </div>
            {/* end::Form */}
        </form>
    );
}

export default connect(null)(ChangePassword);
