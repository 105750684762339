import React, { useMemo, useState } from "react";
import { UsersTable } from "./users-table/UsersTable";
import { useUsersUIContext } from "./UsersUIContext";
import BlockUi from "react-block-ui";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { Col, Row } from "reactstrap";
import CardHeader from "@material-ui/core/CardHeader";
import { Button, Form } from "react-bootstrap";
import {
  ClickAwayListener,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@material-ui/core";
import Popper from "@material-ui/core/Popper/Popper";
import Tooltip from "@material-ui/core/Tooltip";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { warningToast } from "../../../../shared/components/ToastMessage";
import { getAllRoles } from "../_redux/UserAPI";
import * as actions from "../_redux/UserAction";
import { useDispatch } from "react-redux";

export function UsersCard() {
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      newUserButtonClick: usersUIContext.newUserButtonClick
    };
  }, [usersUIContext]);
  const [showFilter, setShowFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  // const [companyListLoader, setCompanyListLoader] = useState(false);
  // const [searchNameFilter, setSearchNameFilter] = useState("");
  const [allRoles, setAllRoles] = useState([]);
  const [roleValue, setRoleValue] = useState("");
  const [roleId, setRoleId] = useState(null);
  // const [pageSize, setPageSize] = useState(10);
  // const [pageNo, setPageNo] = useState(1);
  const [statusValue, setStatusValue] = useState("");


  const dispatch = useDispatch();
  const handleClickFilterSubmenu = searchName => {
    // setSearchNameFilter(searchName);
    if (searchName === "roles") {
      setSelectedFilter("roles");
      getAllRolsList();
    } else if (searchName === "status") {
      setSelectedFilter("status");
    }
  };

  const clearFilter = () => {
    setRoleValue("");
    setSelectedFilter("");
    setStatusValue("");
    setRoleId(null);
    var obj = {};
    dispatch(actions.fetchFilterUsers(1, 10, "", obj));
  };
  const getAllRolsList = () => {
    getAllRoles()
      .then(response => {
        if (response && response.isSuccess) {
          setAllRoles(response.data);
        }
      })
      .catch(e => {
        warningToast("Something went wrong");
      });
  };
  const applyFilter = () => {
    if (selectedFilter === "roles") {
      if (roleId) {
        getAllUserListByRoldId(1, 10, "");
      }
    } else if (selectedFilter === "status") {
      getAllUserListByRoldId(1, 10, "");
    }
    setShowFilter(false);

  };

  const getAllUserListByRoldId = () => {
    if (statusValue.length === 0) {
      var obj = { user_role: roleValue };
    } else {
       obj = { user_role: roleValue, status: statusValue };
    }

    dispatch(actions.fetchFilterUsers(1, 10, "", obj));
  };

  const handleRoleChange = data => {
    if (roleValue) {
      setRoleValue("");
    }
    setRoleValue(data?.role);
    setRoleId(data?.id);
  };
  const handleClickAway = () => {
    setShowFilter(false);
  };
  const handleClickFilter = event => {
    setShowFilter(true);
    setAnchorEl(event.currentTarget);
  };
  const handleStatusChange = event => {
    setStatusValue(event.target.value);
  };
  return (
    <>
      <Card className="example example-compact">
        <CardBody style={{ padding: "10px 10px" }}>
          <Row>
            <Col xl={8} xs={12} md={6}>
              <CardHeader title="Users Data" />
            </Col>
            <Col xl={4} xs={12} md={6}>
              <div className={"d-flex"} style={{ justifyContent: "flex-end" }}>
                <div>
                  <Tooltip title={"Filter"}>
                    <button
                      type="button"
                      className="btn btn-light mt-5"
                      onClick={handleClickFilter}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary m-0">
                        <SVG
                          title="Filter"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Text/Filter.svg"
                          )}
                        />
                      </span>
                    </button>
                  </Tooltip>

                  <Popper
                    open={showFilter}
                    anchorEl={anchorEl}
                    placement={"bottom-end"}
                    transition
                    className={"popper-card"}
                  >
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={handleClickAway}
                    >
                      <div className={"user-mainCard"}>
                        <Grid container className={"user-popper-width"}>
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={5}
                            className={"borderStyle flex content"}
                            style={{
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5
                            }}
                          >
                            <div>
                              <div
                                className={
                                  selectedFilter === "roles"
                                    ? "filter-menu-onclick"
                                    : "filter-menu"
                                }
                                onClick={() =>
                                  handleClickFilterSubmenu("roles")
                                }
                              >
                                <span>Roles</span>
                                <span
                                  className="svg-icon svg-icon-md"
                                  style={{ float: "right" }}
                                >
                                  <SVG
                                    title="Filter"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Angle-right.svg"
                                    )}
                                  />
                                </span>
                              </div>
                              <div
                                className={
                                  selectedFilter === "status"
                                    ? "filter-menu-onclick"
                                    : "filter-menu"
                                }
                                onClick={() =>
                                  handleClickFilterSubmenu("status")
                                }
                              >
                                <span>Status</span>
                                <span
                                  className="svg-icon svg-icon-md"
                                  style={{ float: "right" }}
                                >
                                  <SVG
                                    title="Filter"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Angle-right.svg"
                                    )}
                                  />
                                </span>
                              </div>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={7}
                            sm={7}
                            lg={7}
                            className={"borderStyle flex content"}
                            style={{
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              maxHeight: "200px",
                              overflow: "auto"
                            }}
                          >
                            <BlockUi
                              tag="div"
                              // blocking={companyListLoader}
                              blocking={false}
                              color="#147b82"
                            >
                              {selectedFilter === "roles" ? (
                                <>
                                  <div
                                    className={
                                      allRoles && allRoles.length > 3
                                        ? " filter-search-scroll"
                                        : ""
                                    }
                                  >
                                    {allRoles &&
                                      allRoles.map(data => (
                                        <Col
                                          xl={12}
                                          md={12}
                                          lg={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Form.Group
                                            controlId="status"
                                            className={"m-0"}
                                          >
                                            <Col sm={12}>
                                              <RadioGroup
                                                aria-labelledby="demo-error-radios"
                                                name="roles"
                                                className={"filter-radio"}
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: 500,
                                                  overflow: "auto",
                                                  maxHeight: 207
                                                }}
                                                value={roleValue}
                                                onChange={() =>
                                                  handleRoleChange(data)
                                                }
                                              >
                                                <FormControlLabel
                                                  value={data?.role}
                                                  control={<Radio />}
                                                  label={data?.role}
                                                  color="#147b82"
                                                />
                                              </RadioGroup>
                                            </Col>
                                          </Form.Group>
                                        </Col>
                                      ))}
                                  </div>
                                </>
                              ) : selectedFilter === "status" ? (
                                <div style={{ height: "150px" }}>
                                  <Col xl={12} md={12} lg={12} sm={12} xs={12}>
                                    <Form.Group controlId="status">
                                      <Col sm={12}>
                                        <RadioGroup
                                          aria-labelledby="demo-error-radios"
                                          name="status"
                                          className={"filter-radio"}
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: 500
                                          }}
                                          value={statusValue}
                                          onChange={handleStatusChange}
                                        >
                                          <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            label="Active"
                                            color="#147b82"
                                          />
                                          <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            label="Deactive"
                                          />
                                        </RadioGroup>
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                </div>
                              ) : (
                                <div style={{ height: "150px" }}></div>
                              )}
                            </BlockUi>
                          </Grid>
                        </Grid>

                        <Grid container className={"user-popper-width"}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={"borderStyle flex content"}
                            style={{
                              borderBottomLeftRadius: 5,
                              borderBottomRightRadius: 5,
                              padding: "10px"
                            }}
                          >
                            <div className={"d-flex justify-content-end"}>
                              <Button
                                size="small"
                                onClick={clearFilter}
                                className={"reset-filter mr-2"}
                              >
                                Clear Filters
                              </Button>
                              <Button
                                size="small"
                                onClick={applyFilter}
                                className={"reset-filter mr-2"}
                              >
                                Apply
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </ClickAwayListener>
                  </Popper>
                </div>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn mt-5 btn-primary"
                  onClick={usersUIProps.newUserButtonClick}
                >
                  Add User
                </button>
              </div>
            </Col>
          </Row>
          <hr />
          <UsersTable />
        </CardBody>
      </Card>
    </>
  );
}
