import React, { Suspense } from "react";
import { DatasetStorageTypePage } from "./DatasetStorageTypeTable/DatasetStorageTypePage";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";

function DatasetStorageType() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from datasetStorageType root URL to /datasetStorageType/datasetStorageTypePage */
          <Redirect
            exact={true}
            from="/datasetStorageType"
            to="/datasetStorageType/datasetStorageTypePage"
          />
        }
        <ContentRoute
          path="/datasetStorageType/datasetStorageTypePage"
          component={DatasetStorageTypePage}
        />
      </Switch>
    </Suspense>
  );
}
export default DatasetStorageType;