import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ExperimentManagementEditDialogHeader } from "./ExperimentManagementEditDialogHeader";
import { ExperimentManagementEditForm } from "./ExperimentManagementEditForm";
import { ExperimentManagementAdminSlice } from "../../_redux/ExperimentManagementAdminSlice";
import * as action from "../../_redux/ExperimentManagementAdminAction";
import { SavingDetailsModal } from "../../../../../shared/components/SavingDetailsModal";
import { successToast } from "../../../../../shared/components/ToastMessage";

const { actions } = ExperimentManagementAdminSlice;

export function ExperimentManagementEditDialog({ id, show, onHide }) {
  const { actionsLoading, experimentFetchedById } = useSelector(
    state => ({
      actionsLoading: state.experimentManagement.actionsLoading,
      experimentFetchedById: state.experimentManagement.experimentFetchedById
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== null && id !== undefined) {
      dispatch(action.fetchExperimentById(id));
    } else {
      dispatch(actions.clearExperimentById());
    }
    // eslint-disable-next-line
  }, [id, dispatch]);

  const [loading, setLoading] = useState(false);
  const saveExperiment = experiment => {
    setLoading(true);
    if (id) {
      // server request for updating Experiment
      dispatch(action.experimentUpdate(experiment)).then(() => {
        successToast("Experiment Details updated successfully");
        onHide();
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SavingDetailsModal show={loading} top={"start"} />
      <ExperimentManagementEditDialogHeader id={id} />
      <ExperimentManagementEditForm
        saveExperiment={saveExperiment}
        actionsLoading={actionsLoading}
        experimentData={experimentFetchedById}
        onHide={onHide}
      />
    </Modal>
  );
}
