import React, {useEffect, useMemo, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  entityFilter,
  getFilteredAndPaginatedEntities,
  headerSortingClasses,
  sortCaret
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import {useDatasetStorageTypeUIContext} from "../DatasetStorageTypeUIContext";
import * as actions from "../../_redux/DatasetStorageTypeAction";
import {SearchText} from "../../../../../shared/components/SearchText";
import * as moment from "moment";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import {BoootstrapTable} from "../../../../../shared/components/BoootstrapTable";

export function DatasetStorageTypeTable() {
  const datasetStorageTypeUIContext = useDatasetStorageTypeUIContext();
  const datasetStorageTypeUIProps = useMemo(() => datasetStorageTypeUIContext, [
    datasetStorageTypeUIContext
  ]);

  const columns = [
    {
      dataField: "#",
      text: "Index",
      formatter: (cell, row, rowIndex) => {
        return <span>{(page - 1) * size + (rowIndex + 1)}</span>;
      }
    },
    {
      dataField: "dataset_storage_name",
      text: "Dataset Storage Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "165px"
      }
    },
    {
      dataField: "dataset_storage_description",
      text: "Dataset Storage Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "created_date",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (_, row) =>
        moment
          .utc(row?.created_date)
          .local()
            .format("DD MMMM YYYY, HH:mm:ss"),
      style: {
        minWidth: 180
      }
    },
    {
      dataField: "updated_date",
      text: "Updated Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (_, row) =>
        moment
          .utc(row?.updated_date)
          .local()
            .format("DD MMMM YYYY, HH:mm:ss"),
      style: {
        minWidth: 180
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        openChangeStatusDialog: datasetStorageTypeUIProps.openChangeStatusDialog
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditDatasetStorageTypeDialog:
          datasetStorageTypeUIProps.openEditDatasetStorageTypeDialog
      },
      style: {
        minWidth: "100px"
      }
    }
  ];

  // Getting current state of Dataset storage types from store (Redux)
  const { currentState } = useSelector(
    state => ({ currentState: state.datasetStorageType }),
    shallowEqual
  );
    const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);

  const { entities,  totalDataSetStorage,page,size } = currentState;
  const [filterEntities, setFilterEntities] = useState(entities);
  const searchInput = useRef("");
  let currentItems = getFilteredAndPaginatedEntities(
    filterEntities || entities,
    datasetStorageTypeUIProps.queryParams
  );

  //Filter data for Dataset storage type
  const filterDatasetStorageType = e => {
    // const searchStr = e?.target?.value || searchInput.current.value;
    const keys = [
      "dataset_storage_name",
      "dataset_storage_description",
      "created_date",
      "updated_date"
    ];
    currentItems = entityFilter(
      entities || filterEntities,
      null,
      keys,
      datasetStorageTypeUIProps.queryParams,
      setFilterEntities
    );
  };
  //State Change Handlers
  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
      dispatch(actions.fetchDatasetStorageTypes(1, 10, search));
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    filterDatasetStorageType();
    // eslint-disable-next-line
  }, [entities]);
  const onPageChange = (page, sizePerPage) => {
    dispatch(actions.fetchDatasetStorageTypes(page, sizePerPage, search))
  };

  const onSizePerPageChange = (sizePerPage) => {
    dispatch(actions.fetchDatasetStorageTypes(1, sizePerPage, search))
  };
  const onKeyPressHandler = e => {
    if (e.key === "Enter") {
      dispatch(actions.fetchDatasetStorageTypes(1, size, search))
    }
  };

  const onChangeSearch = e => {
    const searchStr = e?.target?.value || searchInput.current.value;
    setSearch(searchStr);
  };
  return (
    <>
      <div>
        <div className=" mb-5 pagination-tab" />
        <BlockUi tag="div" blocking={loader} color="#147b82">
          <SearchText
            reference={searchInput}
            onChangeHandler={onChangeSearch}
            onKeyDown={onKeyPressHandler}
            searchField={"on DATASET STORAGE NAME"}

          />
          {currentItems && currentItems.length > 0 ? (
            <BoootstrapTable
              sizePerPageList={[
                { text: "10", value: 10 },
                { text: "5", value: 5 },
                { text: "3", value: 3 }
              ]}
              hideSizePerPage={false}
              showTotal={true}
              alwaysShowAllBtns={true}
              hidePageListOnlyOnePage={true}
              columns={columns}
              data={currentItems}
              sizePerPage={size}
              page={page}
              totalSize={totalDataSetStorage}
              onTableChange={onPageChange}
              sizePerPageChange={onSizePerPageChange}
            />
          ) : (
            <h3 className={"table-center"}>No Data Available</h3>
          )}
        </BlockUi>
      </div>
    </>
  );
}
