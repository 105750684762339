import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { ProjectManagementTable } from "./project-management-table/ProjectManagementTable";

export function ProjectManagementCard() {
  return (
    <Card>
      <CardHeader title="Project Details">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProjectManagementTable />
      </CardBody>
    </Card>
  );
}
