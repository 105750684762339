import React, {useEffect, useRef, useState} from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {Button, Tab, Tabs} from "react-bootstrap";
import {SearchText} from "../../../../shared/components/SearchText";
import ImportDatasetTable from "./ImportDatasetTable";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Folder from "./Folder";
import UploadedImageModal from "./UploadedImageModal";
import AddEditImportDatasetModal from "./AddEditImportDatasetModal";
import * as actions from "../../../../redux/actions/DatasetManagementAction";
import UploadDatasetModal from "./UploadDatasetModal";
import {useHistory} from "react-router-dom";


function DatasetManagementCard() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [activeKey, setActiveKey] = useState("import-dataset");
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [addEditImportDatasetModalShow, setAddEditImportDatasetModalShow] = useState(false);
    const [editModalData, setEditModalData] = useState([]);
    const [uploadedDataset, setUploadedDataset] = useState([]);
    const [uploadDatasetLoading, setUploadDatasetLoading] = useState(false);
    const [datsetImageShow, setDatsetImageShow] = useState(false);
    const [dataSetId, setDataSetId] = useState(null);
    const [search, setSearch] = useState("");
    const searchInput = useRef("");
    const {user} = useSelector((state) => state.auth, shallowEqual);
    const {
        importDatasetManagement, importListLoading,
        uploadedDatasetManagement, uploadedListLoading
    } = useSelector(
        state => ({
            importDatasetManagement: state.datasetManagement,
            importListLoading: state.datasetManagement?.listLoading,
            uploadedDatasetManagement: state.datasetManagement?.uploadedDataset,
            uploadedListLoading: state.datasetManagement?.uploadDatasetLoader

        }),
        shallowEqual
    );

    useEffect(() => {
        setTotalCount(importDatasetManagement?.datasetTotalSize);
        setPageNo(importDatasetManagement?.page);
        setPageSize(importDatasetManagement?.size);
        setCurrentItems(importDatasetManagement?.entities);
        setShowTable(importListLoading);
    }, [importDatasetManagement]);


    useEffect(() => {
        setUploadedDataset(uploadedDatasetManagement)
        setUploadDatasetLoading(uploadedListLoading)
    }, [uploadedDatasetManagement]);

    const pageChange = (page, sizePerPage) => {
        dispatch(actions.fetchDatasets(page, sizePerPage, search));
    };

    const sizePerPageChangeApiCall = (page, sizePerPage) => {
        dispatch(actions.fetchDatasets(1, sizePerPage, search));
    };

    const onChangeSearch = e => {
        const searchStr =
            e?.target?.value.trim() || searchInput.current.value.trim();
        setSearch(searchStr)
        dispatch(actions.fetchDatasets(1, 10, searchStr));
    };

    useEffect(() => {
        if (activeKey && activeKey === "import-dataset") {
            dispatch(actions.fetchDatasets(1, 10, search));
        } else {
            dispatch(actions.fetchUploadedDatasets());
        }
    }, [activeKey]);

    const datasetFolderOpen = id => {
        setDataSetId(id);
        setDatsetImageShow(true);

    };

    const datasetFolderClose = () => {
        setDataSetId();
        setDatsetImageShow(false);

    };

    const addEditImportDatasetModalOpen = () => {
        if(!user?.user_email){
            history.push(`/user/profile/profile-information`);
        }else {
            setAddEditImportDatasetModalShow(true)
        }
    }

    const editImportDatasetModal = (_, row) => {
        setEditModalData(row);
        setAddEditImportDatasetModalShow(true);
    };

    const saveImportDataset = ImportDataset => {
        if (!ImportDataset?.id) {
            dispatch(actions.createDataset(ImportDataset)).then(() => {
                dispatch(
                    actions.fetchDatasets(1, 10, search)
                );
                setAddEditImportDatasetModalShow(false)
            })
        } else {
            dispatch(actions.datasetUpdate(ImportDataset)).then(() => {
                dispatch(actions.fetchDatasets(pageNo, pageSize, search));
                setAddEditImportDatasetModalShow(false)
            });
        }
    };

    const UploadDatasetModalClose = () => {
        setAddEditImportDatasetModalShow(false)
    }

    return (
        <>
            <Card>
                <CardHeader title="Dataset Details">
                    <CardHeaderToolbar>
                        <div className={activeKey && activeKey === "import-dataset" ? 'd-flex' : ""}>
                            {activeKey && activeKey === "import-dataset" &&
                                <SearchText
                                    reference={searchInput}
                                    onChangeHandler={onChangeSearch}
                                    searchField={"Search on Name"}
                                />}
                            {activeKey === "import-dataset" ?

                                <Button color={"primary"}
                                        className={'ml-2 col-lg-4'}
                                        onClick={addEditImportDatasetModalOpen}
                                >
                                    Import Dataset
                                </Button>
                                :

                                <Button color={"primary"}
                                    // className={'ml-2 col-lg-4'}
                                        onClick={addEditImportDatasetModalOpen}
                                >
                                    Upload Dataset
                                </Button>

                            }
                        </div>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={activeKey}
                        onSelect={e => setActiveKey(e)}
                        style={{fontSize: "1.275rem", fontWeight: "500"}}
                        className={"round-tab"}
                    >
                        <Tab eventKey="import-dataset" title="Imported Dataset">
                            {activeKey && activeKey === "import-dataset" &&
                                <>
                                    <ImportDatasetTable
                                        currentItems={currentItems}
                                        showTable={showTable}
                                        pageNo={pageNo}
                                        pageSize={pageSize}
                                        totalCount={totalCount}
                                        pageChange={(page, sizePerPage) => pageChange(page, sizePerPage)}
                                        sizePerPageChangeApiCall={(page, sizePerPage) =>
                                            sizePerPageChangeApiCall(page, sizePerPage)
                                        }
                                        editImportDatasetModal={editImportDatasetModal}
                                    />
                                </>
                            }
                        </Tab>
                        <Tab eventKey="upload-dataset" title="Uploaded Dataset">
                            {activeKey && activeKey === "upload-dataset" &&
                                <Folder
                                    uploadedDataset={uploadedDataset}
                                    uploadedDatasetLoading={uploadDatasetLoading}
                                    datasetFolderOpen={datasetFolderOpen}
                                />
                            }
                        </Tab>
                    </Tabs>
                </CardBody>
            </Card>

            {activeKey === "import-dataset" ?
                <AddEditImportDatasetModal
                    show={addEditImportDatasetModalShow}
                    editModalData={editModalData}
                    saveDataset={saveImportDataset}
                    setAddEditImportDatasetModalShow={setAddEditImportDatasetModalShow}
                    setEditModalData={setEditModalData}
                /> :

                <UploadDatasetModal
                    show={addEditImportDatasetModalShow}
                    saveDataset={saveImportDataset}
                    handleClose={UploadDatasetModalClose}
                />
            }

            <UploadedImageModal
                show={datsetImageShow}
                handleClose={datasetFolderClose}
                dataSetId={dataSetId}
            />
        </>
    );
}

export default DatasetManagementCard;