import React, { Component } from "react";
import {Container} from "react-bootstrap";

class ChoiceForCompunyAndStudent extends Component {
  render() {
    let { choiceAccount, accountName } = this.props;
    return (
      <div>
        <div style={{ marginBottom: "30px" }}>
          <div className={""}>
            <h2> Choose Account Type</h2>
          </div>
          <div>
            <h6>If you need more info, please check out Help Page.</h6>
          </div>
        </div>
        <div className={"centered-element-default"}>
          <div
            className="row-xl-12 mb-5"
            onClick={() => choiceAccount("student")}
          >
            <div className={`col-lg-12 col-xl-12`}>
              <div className="p-0 position-relative overflow-hidden">
                <div
                  id="kt_mixed_widget_1_chart"
                  className="card-rounded-bottom "
                  style={{ backgroundColor: "white" }}
                ></div>
                <div className=" m-0 box-customer-grid">
                  <div
                    className={`rounded-xl rounded-xl-focus ${
                      accountName === "student" ? "rounded-xl-focus-active" : ""
                    }`}
                  >
                    <Container className={"d-flex p-5"}>
                      <div>
                        <i className="fa fa-user-graduate fa-6x"></i>
                      </div>
                      <div className={"p-5"}>
                        <div className={"font-weight-bold font-size-h4"}>
                          Student Account
                        </div>
                        <div className={"font-size-h6"}>
                          If you need, please check it out
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row-xl-12 "
            onClick={() => choiceAccount("corporate")}
          >
            <div className={`col-lg-12 col-xl-12`}>
              <div className="p-0 position-relative overflow-hidden">
                <div
                  id="kt_mixed_widget_1_chart"
                  className="card-rounded-bottom "
                  style={{ backgroundColor: "white" }}
                ></div>
                <div className=" m-0 box-customer-grid">
                  <div
                    className={`rounded-xl rounded-xl-focus ${
                      accountName === "corporate"
                        ? "rounded-xl-focus-active"
                        : ""
                    }`}
                  >
                    <Container className={"d-flex p-5"}>
                      <div>
                        <i className="fa fa-suitcase fa-6x"></i>
                      </div>
                      <div className={"p-5"}>
                        <div className={"font-weight-bold font-size-h4 "}>
                          Corporate Account
                        </div>
                        <div className={"font-size-h6"}>
                          If you need, please check it out
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChoiceForCompunyAndStudent;
