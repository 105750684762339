import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../../../redux/reducers/authRedux";
import {useFormik} from "formik";
import {addOtp, register, validateOtpAPI} from "../../../service/authCrud.api";
import {successToast, warningToast} from "../../../shared/components/ToastMessage";
import * as Yup from "yup";
import {useHistory} from "react-router-dom";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {extractErrorFromError} from "../../../shared/helper/RequestWrapper";
import {RegisterAndLoginSlice} from "../../../redux/reducers/registerAndLoginSlice";
import {authProvider} from "../../../shared/enums";
import {registerUser} from "../../User/ExperimentManagement/_wizard_redux/userReg/UserRegistrationAPI";
import Cookies from "universal-cookie";
import {ACCESS_TOKEN, TOKEN_TYPE} from "../../../shared/enums/auth.enums";
import SVG from "react-inlinesvg";
import SendEmailNotificationModal from "../../../shared/components/SendEmailNotificationModal";

const {actions} = RegisterAndLoginSlice;

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const otpRules = /^\d{6}$/;

function RegistrationInformation(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const {intl} = props;
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [textShowOtp, setTextShowOtp] = useState(false);
    const [isOtpInitiated, setOtpInitiated] = useState(false);
    const [validateOtp, setValidateOtp] = useState(false);
    const [isOtpVerified, setOtpVerified] = useState(false);

    const [emailManullyOtp, setEmailManullyOtp] = useState(false);
    const providerProfile = useSelector(
        state => state?.registerAndLogin?.UserProfileDetails || {}
    );
    const [isShownPassword, setIsShownPassword] = useState(false);
    const [isShownConfirmPassword, setIsShownConfirmPassword] = useState(false);

    const togglePassword = () => {
        setIsShownPassword(isShownPassword ? false : true);
    };
    const toggleConfirmPassword = () => {
        setIsShownConfirmPassword(isShownConfirmPassword ? false : true);
    };


    useEffect(() => {
        if (providerProfile?.provider === authProvider.GMAIL_MANUALLY) {
            setEmailManullyOtp(true);
        }
    }, [providerProfile]);
    const initialValues = useSelector(state => {
        const providerProfile = state?.registerAndLogin?.UserProfileDetails || {};
        return {
            firstName: providerProfile?.first_name || "",
            lastName: providerProfile?.last_name || "",
            email: providerProfile?.user_code || "",
            otp: providerProfile?.otp
        };
    });

    const RegistrationSchema = Yup.object().shape({
        firstName: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "First name should atleast contain 3 characters")
            .max(50, "Maximum 50 characters")
            .required(
                "First name must be required"
            ),
        lastName: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "Last Name Should Contain Atleast 3 Characters")
            .max(50, "Maximum 50 characters")
            .required(
                "Last name must be required"
            ),
        email: Yup.string()
            .matches(/^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim, "Incorrect email Format")
            .max(50, "Maximum 50 characters")
            .required(
                "Email must be required"
            ),
        otp: Yup.string().required("Otp must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(otpRules, {
                    message: "kindly enter a valid OTP."
                })
        }),

        password: Yup.string().required("password must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(passwordRules, {
                    message:
                        "Please establish a stronger password with at least five characters, one uppercase, one lowercase, and one digit."
                })

        }),
        confirmPassword: Yup.string().required("Confirm Password must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref("password"), null], "Password and Confirm Password didn't match")
        })
    });

    const SocialSchema = Yup.object().shape({
        firstName: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "First name should atleast contain 3 characters")
            .max(50, "Maximum 50 characters")
            .required(
                "First name must be required"
            ),
        lastName: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "Last Name Should Contain Atleast 3 Characters")
            .max(50, "Maximum 50 characters")
            .required(
                "Last name must be required"
            ),
        email: Yup.string()
            .matches(/^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim, "Incorrect email Format")
            .max(50, "Maximum 50 characters")
            .required(
                "Email must be required"
            ),
        otp: Yup.string().required("Otp must be required").when({
            is: value => value && value.length > 0,
            then: Yup.string()
                .matches(otpRules, {
                    message: "kindly enter a valid OTP."
                })
        }),

    });
    const getInputClasses = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const modalOpen = () => {
        setShow(true);
    };

    const modalClose = () => {
        setShow(false);
    };

    const setTokenCookies = (token, tokenType) => {
        const cookies = new Cookies();
        cookies.set(ACCESS_TOKEN, token, {httpOnly: false, path: "/"});
        cookies.set(TOKEN_TYPE, tokenType, {httpOnly: false, path: "/"});
        dispatch(props.requestUser(undefined));
    };

    const formik = useFormik({
        initialValues,
        validationSchema:
            providerProfile?.provider === "email" ? RegistrationSchema : SocialSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            const providerEmailExists = providerProfile?.provider === 'email' ? true : false;
            if (
                (providerEmailExists && providerProfile.provider === "google") ||
                (providerEmailExists && providerProfile.provider === "microsoft") ||
                isOtpVerified
            ) {


                let body = {
                    provider: providerProfile?.provider,
                    provider_id: providerProfile?.provider_id,
                    access_token: providerProfile?.access_token,
                    password: values?.password || undefined,
                    first_name: values?.firstName,
                    last_name: values?.lastName,
                    user_email: values?.email,
                    otp_verified: true
                };
                register(body)
                    .then(response => {
                        let {access_token, token_type} = response.data;
                        setTokenCookies(access_token, token_type);
                        // disableLoading();
                        dispatch(props.requestUser(undefined));
                        // dispatch(props.setUser(response.data));
                        // disableLoading();
                        setLoading(false);
                        successToast("Signed up Successfully");
                        dispatch(actions.clearUserProfileDetails());
                        history.push("/auth/login");
                        let authData = new FormData();
                    })
                    .catch(error => {
                        //
                        console.log("error::", error);
                        setSubmitting(false);
                        setStatus(undefined);
                        const warningMsg = extractErrorFromError(error);
                        warningToast(warningMsg);
                        setStatus(warningMsg);
                    })
                    .finally(() => {
                        setSubmitting(false);
                        setStatus(undefined);
                        // disableLoading();
                        setLoading(false);
                    });
            } else if (
                !isOtpInitiated &&
                !providerEmailExists &&
                textShowOtp &&
                !validateOtp
            ) {


                sendOtpOnEmail(values?.email);
            } else if ((providerProfile?.provider === authProvider.GMAIL_MANUALLY) ? providerEmailExists && textShowOtp && !validateOtp :
                (providerProfile.provider === authProvider.GITHUB) ? textShowOtp && !validateOtp :
                    (providerProfile.provider === authProvider.LINKED_IN) ? textShowOtp && !validateOtp : null) {
                if (values.otp) {
                    setSubmitting(false);
                }
                handleValidateOtp(values?.email, values.otp);
            }
        }
    });

    const handleValidateOtp = (email, otp) => {

        setLoading(true);
        if (!email || !otp) {
            setLoading(false);
            formik.setSubmitting(false);
            formik.setStatus("Invalid Otp Please try Again");
            warningToast("Invalid Otp");
            return;
        }
        validateOtpAPI(email, otp)
            .then(res => {
                setValidateOtp(false);
                const isSuccess = res?.data?.success;
                setOtpVerified(isSuccess || false);
                if (isSuccess) {
                    successToast("Otp Validated Successfully");
                    setTimeout(() => formik.submitForm(), 400);
                } else {
                    setLoading(false);
                    formik.setSubmitting(false);
                    formik.setStatus("Invalid Otp Please try Again");
                    warningToast("Invalid Otp");
                }
            })
            .catch(error => {
                console.log("error::", error);
                warningToast(error.request.statusText);
                if (error) {
                    warningToast(error.detail);
                } else {
                    warningToast("Something went Wrong");
                }
                modalClose();
                setLoading(false);
                formik.setSubmitting(false);
                formik.setStatus("Invalid Otp Please try Again");
                warningToast("Invalid Otp");
            });
    };

    const sendOtpOnEmail = (email, forceResend = false) => {
        if (isOtpInitiated && !forceResend) {
            return;
        }
        setLoading(true);
        setOtpInitiated(true);
        setOtpVerified(false);
        let body = {
            email: email,
            check_email: false,
            otp_type: "register_user"
        };
        setEmail(email);
        addOtp(body)
            .then(response => {
                modalOpen();
                setTextShowOtp(true);
                setLoading(false);
            })
            .catch(error => {
                console.log("error::", error);
                warningToast(error.request.statusText);
                if (error) {
                    warningToast(error.detail);
                } else {
                    warningToast("Something went Wrong");
                }
                modalClose();
                setLoading(false);
            });
    };
    const handleCancel = () => {
        dispatch(actions.clearUserProfileDetails());
        history.push("/auth/login");
    };
    const backPage = () => {
        history.push("/auth/registration");
    };

    const handleOTPFocus = () => {
        formik.setStatus("");
    };

    return (
        <>

            <div className="login-form login-signin" style={{display: "block"}}>
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        <FormattedMessage id="AUTH.REGISTER.TITLE"/>
                    </h3>
                    <p className="text-muted font-weight-bold">
                        Enter your details to create your account
                    </p>
                </div>

                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    {/* begin: Alert */}
                    {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    )}
                    {/* end: Alert */}

                    {/* begin: Firstname */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="First name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "firstName"
                            )}`}
                            name="firstName"
                            {...formik.getFieldProps("firstName")}
                            disabled={initialValues.firstName || false}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.firstName}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Firstname */}

                    {/* begin: Lastname */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Last name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "lastName"
                            )}`}
                            name="lastName"
                            {...formik.getFieldProps("lastName")}
                            disabled={initialValues.lastName || false}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.lastName}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Lastname */}

                    {/* begin: Email */}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                            disabled={initialValues.email || isOtpInitiated || false}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik?.errors?.email}</div>
                            </div>
                        ) : null}
                    </div>
                    {/* end: Email */}
                    {providerProfile?.provider === "email" ? (
                        <>
                            {/* begin: Password */}
                            <div className="form-group fv-plugins-icon-container">
                                <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                    <input
                                        placeholder="Password"
                                        type={isShownPassword ? "text" : "password"}
                                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            "password"
                                        )}`}
                                        name="password"
                                        {...formik.getFieldProps("password")}

                                    />
                                    {isShownPassword ? (
                                        <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                              onClick={togglePassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
            </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2" onClick={togglePassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
            </span>)}

                                </div>
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.password}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {/* end: Password */}

                            {/* begin: Confirm Password */}
                            <div className="form-group fv-plugins-icon-container">

                                <div className={"d-flex"} style={{backgroundColor: "#F3F6F9", borderRadius: "0.42rem"}}>
                                    <input
                                        placeholder="Confirm Password"
                                        type={isShownConfirmPassword ? "text" : "password"}
                                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            "confirmPassword"
                                        )}`}
                                        name="confirmPassword"
                                        {...formik.getFieldProps("confirmPassword")}
                                    />
                                    {isShownConfirmPassword ? (
                                        <span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                                              onClick={toggleConfirmPassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/show.svg")}/>
            </span>) : (<span className="svg-icon cursor-pointer svg-icon-primary py-5 mr-2"
                              onClick={toggleConfirmPassword}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/hide.svg")}/>
            </span>)}

                                </div>
                                {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.confirmPassword}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {/* end: Confirm Password */}
                        </>
                    ) : (
                        <></>
                    )}
                    {textShowOtp ? (
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="OTP"
                                type="otp"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "otp"
                                )}`}
                                name="otp"
                                onFocus={handleOTPFocus}
                                {...formik.getFieldProps("otp")}
                            />
                            {formik.touched.otp && formik.errors.otp ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik?.errors?.otp}</div>
                                </div>
                            ) : null}
                            <a
                                className="text-dark-50 text-hover-primary mr-2"
                                // disabled={formik.isSubmitting || !formik.isValid || loading}
                                disabled={loading}
                                onClick={() => sendOtpOnEmail(formik.values.email, true)}
                            >
                                <span className={"d-flex justify-content-end"}>ResendOTP</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                                {/*{loading && <span className="ml-3 spinner spinner-white"></span>}*/}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {providerProfile?.email && !emailManullyOtp ? (
                        <div className="form-group d-flex flex-wrap flex-center">
                            {providerProfile.provider === "google" ||
                            providerProfile.provider === "microsoft" ? (
                                <button
                                    type="submit"
                                    disabled={
                                        !formik.values.firstName ||
                                        !formik.values.lastName ||
                                        loading
                                    }
                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                >
                                    <span>Submit</span>
                                    {loading && (
                                        <span className="ml-3 spinner spinner-white"></span>
                                    )}
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid || loading}
                                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                >
                                    <span>Submit</span>
                                    {loading && (
                                        <span className="ml-3 spinner spinner-white"></span>
                                    )}
                                </button>
                            )}

                            {/*<Link to="/auth/Login">*/}
                            <button
                                type="button"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            {/*</Link>*/}
                        </div>
                    ) : textShowOtp ? (
                        <div className="form-group d-flex justify-content-end flex-wrap flex-start">

                            <button
                                className="btn btn-primary font-weight-bold col-4"
                                disabled={!formik.values.otp || formik.errors.otp|| loading}
                                onClick={e => {
                                    formik.handleSubmit(e);
                                }}
                            >
                                <span>Verify OTP</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>

                        </div>
                    ) : (
                        <div className="form-group ">
                            <button
                                type="button"
                                disabled={
                                    formik.isSubmitting ||
                                    loading ||
                                    !formik.values.firstName ||
                                    !formik.values.lastName ||
                                    !formik.values.email ||
                                    !formik.values.password ||
                                    !formik.values.confirmPassword ||
                                    formik.errors.firstName || formik.errors.lastName ||
                                    formik.errors.email || formik.errors.password ||
                                    formik.errors.confirmPassword
                                }
                                className="btn btn-primary flex-start font-weight-bold px-9 py-4 my-3 mx-4"
                                onClick={() => sendOtpOnEmail(formik.values.email, true)}
                            >
                                <span>Send OTP</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>

                            <button
                                type="button"
                                className="btn btn-light px-9 py-4 my-3 mx-4 float-right"
                                onClick={backPage}
                            >
                                Back
                            </button>


                        </div>
                    )}
                </form>
            </div>

            <>
                <SendEmailNotificationModal
                    show={show}
                    modalClose={modalClose}
                    email={email}
                />
            </>
        </>
    );
}

export default injectIntl(
    connect(null, auth.AuthAction)(RegistrationInformation)
);
