import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import {
  CardContent,
  CardMedia,
  makeStyles,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: "flex"
  // },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width: 151
  }
}));
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export function ChoicePage4() {
  const classes = useStyles();

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2>Recommend a Modal</h2>
        </div>
        <div>
          <h6>
            Answer a few questions and we will suggest a Modal typethat works
            best for you and your team.
          </h6>
        </div>
      </div>

      <div className={"centered-element"}>
        <div className="scrollbar" id="style-2" style={{ height: "700px" }}>
          <div className="force-overflow" style={{ minHeight: "800px" }}>
            {numbers.map((number, index) => (
              <Row className={"mt-3 mb-2"}>
                <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                  <Card className={"d-flex"}>
                    <CardMedia
                      className={classes.cover}
                      image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                      title="Live from space album cover"
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                          Live From Space
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          Mac Miller
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Col>
                <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                  <Card className={"d-flex"}>
                    <CardMedia
                      className={classes.cover}
                      image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                      title="Live from space album cover"
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                          Live From Space
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          Mac Miller
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
