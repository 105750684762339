import { createSlice } from "@reduxjs/toolkit";

const initialDatasetStorageTypeState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  filteredEntities: "",
  datasetStorageTypeFetchedById: "",
  totalDataSetStorage:0,
  page:1,
  size:10
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const DatasetStorageTypeSlice = createSlice({
  name: "dl-datasetStorageType",
  initialState: initialDatasetStorageTypeState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    datasetStorageTypeFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload.items;
      state.totalDataSetStorage = action.payload.total;
      state.page=action.payload.page;
      state.size=action.payload.size;
      state.totalCount = action.payload.length;
    },
    datasetStorageTypeFetchedForData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload;
      state.totalDataSetStorage = action.payload.total;
      // state.page=action.payload.page;
      // state.size=action.payload.size;
      state.totalCount = action.payload.length;
    },

    datasetStorageTypeFetchedById: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.datasetStorageTypeFetchedById = action.payload;
    },

    datasetStorageTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.entities.push(action.payload);
      state.error = null;
    },

    datasetStorageTypeUpdated: (state, action) => {
      let data = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map(entity => {
        if (entity.id === data.id) {
          return data;
        }
        return entity;
      });
    },

    clearDatasetStorageTypeById: (state, action) => {
      state.datasetStorageTypeFetchedById = "";
    }
  }
});
