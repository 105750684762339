import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import * as action from "../../../../redux/actions/CompanyRegistrationAction";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";


function CompanyInformation() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [editCompany, setEditCompany] = useState(false)
    const [updateFlag, setUpdateFlag] = useState(false)
    let history = useHistory();
    const {companyRegistration} = useSelector(
        state => ({
            companyRegistration: state.companyRegistration?.companyRegistrationData,
            // companyRegistrationData: state.companyRegistration,
            // listLoading: state.companyRegistration?.actionsLoading,
        }),
        shallowEqual
    );
    const [changeFlagCompany,setChangeFlagCompany]=useState({
        company_name: false,
        company_email: false,
        company_description: false,
        company_address: false,
        company_website: false,
        company_contact: false,
    })
    const { actions } = companyRegistration;

    const initialValues = useSelector(state => {
        return {
            company_name: companyRegistration?.company_name || "",
            company_description: companyRegistration?.company_description || "",
            company_address: companyRegistration?.company_address || "",
            company_website: companyRegistration?.company_website || "",
            company_email: companyRegistration?.company_email || "",
            company_contact: companyRegistration?.company_contact || "",
            id: companyRegistration?.id || "",
        };
    });

    const Schema = Yup.object().shape({
        company_name: Yup.string().matches(/^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()_-]+$/,'Special characters and contain space are not allowed.')
            .min(3, " Company name should at least contain three characters.")
            .max(50, "Maximum 50 characters.").required("Company name must be required."),
        company_description: Yup.string().min(3, "Minimum 3 symbols")
            .max(250, "Maximum 500 characters.").required("Company description must be required."),
        company_email: Yup.string()
            .matches(  /^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim,"Incorrect email format.")
            .max(50, "Maximum 50 symbols")
            .required("Company email must be required."),
        company_address: Yup.string().min(3, "Minimum 3 symbols")
            .max(250, "Maximum 500 symbols").required("Company address must be required."),
        company_website: Yup.string().matches(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gim, "Incorrect website format.")
            .max(50, "Maximum 50 symbols")
            .required("Company website must be required."),
        company_contact: Yup.string()
            .matches(/^(0|[1-9][0-9]*)$/, "Please enter only digit.")
            .min(10, "Minimum 10 digits.")
            .max(10, "Maximum 10 digits.")
            .required("Company contact must be required."),

    });


    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setUpdateFlag(false)
            let body = {
                company_name: values?.company_name.trim() || "",
                company_email: values?.company_email.trim() || "",
                company_description: values?.company_description.trim() || "",
                company_address: values?.company_address.trim() || "",
                company_website: values?.company_website.trim() || "",
                company_contact: values?.company_contact.trim() || "",
                id: companyRegistration?.id,
            };
                if (editCompany && !updateFlag) {

                    dispatch(action.UpdateCompanyData(body));
                    setLoading(false);
                    setEditCompany(false);
                }
        },

    });



    useEffect(() => {
        dispatch(action.getCompanyInfo());
        }, [])


    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    const handleClose = () => {
        history.push("/user/dashboard")
    }
    const changeCompanyDetails = () => {
        formik.setTouched({});
        setEditCompany(true)
        setUpdateFlag(true)
        return formik.resetForm()


    }

    const handleChangeClose = () => {
        setEditCompany(false)
        formik.resetForm();

    }

    return (
        <>
            <form
                className="card card-custom card-stretch"
                onSubmit={formik.handleSubmit}
            >

                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            Company Information
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            Update your company Information
                        </span>
                    </div>

                    <div className="card-toolbar">
                        {editCompany ? (
                            <Button variant="primary" className={"mr-2 "}
                                    type="submit"
                                    disabled={formik.touched && !formik.isValid}
                            >
                                Update Company
                                {formik.isSubmitting}
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </Button>) : (<Button variant="primary" className={"mr-2"} type="button"
                                                  onClick={changeCompanyDetails}
                            >
                                Edit Company

                            </Button>
                        )}
                        {editCompany ? (<Button variant="secondary" type="button" onClick={handleChangeClose}>
                            Cancel
                        </Button>) : (<Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>)}
                    </div>

                </div>
                {/* begin::Form */}


                <div className="form">
                    {/* begin::Body */}
                    <div className="card-body">
                        <div className="row">
                            <label className="col-xl-3"></label>
                            <div className="col-lg-9 col-xl-6">
                                <h5 className="font-weight-bold mb-6">Company Info</h5>
                            </div>

                        </div>
                        <div className="form-group row theme-color">
                            {editCompany ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Name
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Enter Company Name{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Name
                                </label>)}
                            {editCompany ? (
                                <div className="col-lg-9 col-xl-6">

                                    <input
                                        placeholder="Company Name"
                                        type="text"
                                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                            "company_name"
                                        )}`}
                                        name="company_name"
                                        {...formik.getFieldProps("company_name")}
                                    />
                                    {formik.touched.company_name && formik.errors.company_name ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.company_name}
                                        </div>
                                    ) : null}
                                </div>

                            ) : (<div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                    {initialValues.company_name ? initialValues.company_name : '-'}
                                </div>
                            )}
                        </div>
                        <div className="form-group row theme-color">
                            {editCompany ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Email
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Enter Company's Email Address{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Email
                                </label>)}
                            {editCompany ? (
                                <div className="col-lg-9 col-xl-6">
                                    <input
                                        type="text"
                                        placeholder="Company Email "
                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                            "company_email"
                                        )}`}
                                        name="company_email"
                                        {...formik.getFieldProps("company_email")}
                                    />

                                    {formik.touched.company_email && formik.errors.company_email ? (
                                        <div className="invalid-feedback">{formik.errors.company_email}</div>
                                    ) : null}
                                </div>
                            ) : (
                                <div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                    {initialValues.company_email ? initialValues.company_email : '-'}
                                </div>
                            )}
                        </div>
                        <div className="form-group row theme-color">
                            {editCompany ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Description
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Enter Description of Company{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Description
                                </label>
                            )}
                            {editCompany ? (
                                <div className="col-lg-9 col-xl-6">
                                <textarea
                                    type="text"
                                    placeholder="Company Description"
                                    rows="3"
                                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                        "company_description"
                                    )}`}
                                    name="company_description"
                                    maxLength={"250"}
                                    {...formik.getFieldProps("company_description")}
                                />
                                    <span
                                        className={`float-right ${
                                            formik?.values?.company_description?.length === 250
                                                ? "input-validation-star"
                                                : ""
                                        }`}
                                    >{250 - ( formik?.values?.company_description?.length ?  formik?.values?.company_description?.length : 0)} characters left
                                     </span>
                                    {formik.touched.company_description && formik.errors.company_description ? (
                                        <span className="invalid-feedback">
                                            {formik.errors.company_description}
                                        </span>

                                    ) : null}
                                </div>
                            ) : (
                                <div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                    {initialValues.company_description ? initialValues.company_description : '-'}
                                </div>
                            )}

                        </div>
                        <div className="form-group row theme-color">
                            {editCompany ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Address
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Enter Address of Company{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Address
                                </label>)}
                            {editCompany ? (
                                <div className="col-lg-9 col-xl-6">

                                <textarea
                                    type="text"
                                    placeholder="Company Address"
                                    rows="3"
                                    maxLength={"250"}
                                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                        "company_address"
                                    )}`}
                                    name="company_address"
                                    {...formik.getFieldProps("company_address")}
                                />

                                    <span
                                        className={`float-right ${
                                            formik?.values?.company_address?.length === 250
                                                ? "input-validation-star"
                                                : ""
                                        }`}
                                    >
                {250 - ( formik?.values?.company_address?.length ?  formik?.values?.company_address?.length : 0)} characters left
              </span>
                                    {formik.touched.company_address && formik.errors.company_address ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.company_address ? formik.errors.company_address : '-'}
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                    {initialValues.company_address ? initialValues.company_address : '-'}
                                </div>
                            )}
                        </div>
                        <div className="form-group row theme-color">
                            {editCompany ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Website
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Enter Company's Website URL{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Website
                                </label>)}
                            {editCompany ? (
                                <div className="col-lg-9 col-xl-6">

                                    <input
                                        type="text"
                                        placeholder="Company Website"
                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                            "company_website"
                                        )}`}
                                        name="company_website"
                                        {...formik.getFieldProps("company_website")}


                                    />

                                    {formik.touched.company_website && formik.errors.company_website ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.company_website}
                                        </div>
                                    ) : null}
                                </div>) : (
                                <div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                    {initialValues.company_website ? initialValues.company_website : '-'}
                                </div>
                            )}

                        </div>
                        <div className="form-group row theme-color">
                            {editCompany ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Contact
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Company Contact Number{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Company Contact
                                </label>)}
                            {editCompany ? (
                                <div className="col-lg-9 col-xl-6">

                                    <input
                                        type="text"
                                        placeholder="Company Contact"
                                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                            "company_contact"
                                        )}`}
                                        name="company_contact"
                                        {...formik.getFieldProps("company_contact")}
                                    />
                                    {formik.touched.company_contact && formik.errors.company_contact ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.company_contact}
                                        </div>
                                    ) : null}
                                </div>) : (
                                <div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                    {initialValues.company_contact ? initialValues.company_contact : '-'}
                                </div>

                            )}
                        </div>
                    </div>
                    {/* end::Body */}
                </div>
                {/* end::Form */}
            </form>
        </>
    );
}

export default CompanyInformation;