import React, {useEffect, useRef, useState} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { SearchText } from "../../../../shared/components/SearchText";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import AddEditExperimentModal from "./AddEditExperimentModal";
import ExperimentTable from "./ExperimentTable";
import * as action from "../../../../redux/actions/experimentManagementAction";
import {Button} from "reactstrap";
import {useHistory} from "react-router-dom";
export function ExperimentManagementCard() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [search, setSearch] = useState("")

  const searchInput = useRef("");
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth, shallowEqual);
  const { listLoading, experimentData,} = useSelector(
    state => ({
      listLoading: state.experimentManagement.listLoading,
      experimentData: state.experimentManagement,
      // experimentTotalSize: state.experimentManagement.experimentTotalSize
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentItems(experimentData?.entities);
    setTotalCount(experimentData?.experimentTotalSize);
    setPageNo(experimentData?.page);
    setPageSize(experimentData?.size);
    setShowTable(listLoading);
  }, [experimentData]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if(!user?.user_email){
      history.push(`/user/profile/profile-information`);
    }else {
      setShow(true)
    }
  };

  const saveExperiment = experiment => {
    if (!experiment.id) {
      dispatch(action.createExperiment(experiment)).then(() => {
        dispatch(action.fetchExperiments(1, 10, search));
        handleClose();
      });
    } else {
      dispatch(action.experimentUpdate(experiment)).then(() => {
        dispatch(action.fetchExperiments(pageNo, pageSize, search));
        handleClose();
      });
    }
  };

  const pageChange = (page, sizePerPage) => {
    dispatch(action.fetchExperiments(page, sizePerPage, search));
  };

  const sizePerPageChangeApiCall = (page, sizePerPage) => {
    dispatch(action.fetchExperiments(1, sizePerPage, search));
  };

  const onChangeSearch = e => {
    const searchStr =
      e?.target?.value.trim() || searchInput.current.value.trim();
    setSearch(searchStr)
    dispatch(action.fetchExperiments(1, 10, searchStr));
  };
  const editModalDataTable = (_, row) => {
    setEditModalData(row);
    setShow(true);
  };

  return (
    <>
      <Card>
        <CardHeader title="Experiment Details">
          <CardHeaderToolbar>
            <div className={'d-flex'}>
            <SearchText
              reference={searchInput}
              onChangeHandler={onChangeSearch}
              searchField={"Search on Name or Status"}
            />

            <Button
                color={"primary"}
                className={'ml-2 col-lg-4'}
                onClick={handleShow}
            >
              Add Experiment
            </Button>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody style={{ padding: "5px" }}>
          <ExperimentTable
            currentItems={currentItems}
            showTable={showTable}
            pageNo={pageNo}
            pageSize={pageSize}
            totalCount={totalCount}
            pageChange={(page, sizePerPage) => pageChange(page, sizePerPage)}
            sizePerPageChangeApiCall={(page, sizePerPage) =>
              sizePerPageChangeApiCall(page, sizePerPage)
            }
            editModalDataTable={editModalDataTable}
          />
        </CardBody>
      </Card>

      <AddEditExperimentModal
        show={show}
        saveExperiment={saveExperiment}
        handleClose={handleClose}
        editModalData={editModalData}
        setEditModalData={setEditModalData}
      />
    </>
  );
}
