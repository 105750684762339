import React from "react";
export function DeployColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openTestExperimentDialog }
) {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary col-xl-12"
        disabled={
          row.experiment_status.toLowerCase() === "pending" ||
          row.experiment_status.toLowerCase() === "requested" ||
          row.experiment_status.toLowerCase() === "processing" ||
          row.experiment_status.toLowerCase() === "error"
        }
        onClick={() => openTestExperimentDialog(row.id)}
      >
        Deploy
      </button>
    </>
  );
}
