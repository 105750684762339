import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { AnnotationTypeTable } from "./annotation-type-table/AnnotationTypeTable";
import { useAnnotationTypeUIContext } from "./AnnotationTypeUIContext";

export function AnnotationTypeCard() {
  const annotationTypeUIContext = useAnnotationTypeUIContext();
  const annotationTypeUIProps = useMemo(() => {
    return {
      newAnnotationTypeButtonClick:
        annotationTypeUIContext.newAnnotationTypeButtonClick
    };
  }, [annotationTypeUIContext]);

  return (
    <Card>
      <CardHeader title="Annotation Type Details">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={annotationTypeUIProps.newAnnotationTypeButtonClick}
          >
            Add Annotation Type
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <AnnotationTypeTable />
      </CardBody>
    </Card>
  );
}
