import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

export function HyperParamsCreate() {
  const [formData, setFormData] = useState({
    hyperParamName: "",
    learningRate: "",
    momentum: "",
    weightDecay: "",
    optimizer: "sgd",
    batchSize: "",
    imgSize: "",
    splitRatio: "",
    isTransferLearning: false,
    numEpochs: "",
    isRect: false,
    isMultiScale: false,
    status: true,
    id: ""
  });
  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const onDefault = () => {
    setFormData({
      hyperParamName: "xyz",
      learningRate: 0.01,
      momentum: 0.9,
      weightDecay: 0.0001,
      optimizer: "sgd",
      batchSize: 8,
      imgSize: 640,
      splitRatio: 0.8,
      isTransferLearning: "yes",
      numEpochs: 31,
      isRect: "NO",
      isMultiScale: "NO"
    });
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2>Train Modal</h2>
        </div>
        <div>
          <h6>* is.</h6>
        </div>
      </div>

      <div className={"centered-element"}>
        <h2 className={"text-center"} style={{ marginBottom: "30px" }}>
          Add Hyper Params
        </h2>
        <div className="scrollbar" id="style-2">
          <div className="force-overflow">
            <Form className={"m-5"}>
              <Row>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="hyperParamName" as={Row}>
                    <Form.Label sm={12}>
                      Hyper Parameter Name
                      <Tooltip
                        title={
                          <div className="tools">
                            The name of the hyper parameter
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="hyperParamName"
                        placeholder="Hyper Parameter Name"
                        value={formData.hyperParamName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  {/*{!id ? (*/}
                  <div className={"d-flex justify-content-end mb-2"}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onDefault}
                    >
                      Add Default hyper Params
                    </button>
                  </div>
                  {/*) : null}*/}
                </Col>
              </Row>

              <Row>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="learningRate" as={Row}>
                    <Form.Label sm={12}>
                      Learning Rate
                      <Tooltip
                        title={
                          <div className="tools">
                            The learning rate is a hyperparameter that controls
                            how much change required in the model in response to
                            the estimated error each time the model weights are
                            updated.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="learningRate"
                        placeholder="Learning Rate"
                        value={formData.learningRate}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="momentum" as={Row}>
                    <Form.Label sm={12}>
                      Momentum
                      <Tooltip
                        title={
                          <div className="tools">
                            This parameter is responsible to accelerate and
                            optimize the gradient descent and reduce the noisy
                            variant from it.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="momentum"
                        placeholder="Momentum"
                        value={formData.momentum}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="weight_decay" as={Row}>
                    <Form.Label sm={12}>
                      Weight Decay
                      <Tooltip
                        title={
                          <div className="tools">
                            Weight Decay Weight decay is a regularization
                            technique by adding a small penalty in all the
                            weights of the modelto the loss function. loss =
                            loss + weight decay parameter * all the weights of
                            the model.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="weightDecay"
                        placeholder="Weight Decay"
                        value={formData.weightDecay}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="optimizer" as={Row}>
                    <Form.Label sm={12}>
                      Optimizer
                      <Tooltip
                        title={
                          <div className="tools">
                            An optimizer is a method or algorithm to update the
                            various parameters that can reduce the loss in much
                            less effort.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Select
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#5DBFC4",
                            primary: "#147b82"
                          }
                        })}
                        placeholder={"Select Optimizer"}
                        name="optimizer"
                        className="select-react-dropdown"
                        // options={levelOfStudyOptions}
                        // onChange={(e) => this.changeLocationOptions(e)}
                        // defaultValue={this.state.locationDropDownDetails}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="batch_size" as={Row}>
                    <Form.Label sm={12}>
                      Batch Size
                      <Tooltip
                        title={
                          <div className="tools">
                            The batch size is a hyperparameter that defines the
                            number of samples to work through before updating
                            the internal model parameters.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="batchSize"
                        placeholder="Batch Size"
                        value={formData.batchSize}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="img_size" as={Row}>
                    <Form.Label sm={12}>
                      Image Size
                      <Tooltip
                        title={
                          <div className="tools">
                            Resolution of the image which will pass in model
                            training.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="imgSize"
                        placeholder="Image Size"
                        value={formData.imgSize}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="split_ratio" as={Row}>
                    <Form.Label sm={12}>
                      Split Ratio
                      <Tooltip
                        title={
                          <div className="tools">
                            Split Ratio dataset distribution ratio in percentage
                            for three categories training=70-75% testing=15-20%
                            validation=5-10%
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="splitRatio"
                        placeholder="Split Ratio"
                        value={formData.splitRatio}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="is_transfer_learning" as={Row}>
                    <Form.Label sm={12}>
                      Is Transfer Learning ?{/*<Tooltip*/}
                      <Tooltip
                        title={
                          <div className="tools">
                            Its the reuse of a pre-trained model on a new
                            problem.In transfer learning, a machine exploits the
                            knowledge gained from a previous task to improve
                            generalization about another.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Select
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#5DBFC4",
                            primary: "#147b82"
                          }
                        })}
                        placeholder={"Select isTransferLearning"}
                        name="isTransferLearning"
                        className="select-react-dropdown"
                        // options={levelOfStudyOptions}
                        // onChange={(e) => this.changeLocationOptions(e)}
                        // defaultValue={this.state.locationDropDownDetails}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="num_epochs" as={Row}>
                    <Form.Label sm={12}>
                      Number Epochs
                      <Tooltip
                        title={
                          <div className="tools">
                            The number of epochs is the number of complete
                            passes through the training dataset.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Form.Control
                        type="text"
                        name="numEpochs"
                        placeholder="Number Epochs"
                        value={formData.numEpochs}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} xs={12} sm={12}>
                  <Form.Group controlId="is_multi_scale" as={Row}>
                    <Form.Label sm={12}>
                      Is Multi Scale ?{/*<Tooltip*/}
                      <Tooltip
                        title={
                          <div className="tools">
                            Concept of multiscale feature learning is to run
                            several CNN models with varying input size in
                            parallel and later to combine the output from each
                            model to the fully-connected layer.
                          </div>
                        }
                        placement={"right"}
                      >
                        <InfoIcon className={"icon-color"} />
                      </Tooltip>
                    </Form.Label>
                    <Col sm={12} className={"pl-0"}>
                      <Select
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#5DBFC4",
                            primary: "#147b82"
                          }
                        })}
                        placeholder={"Select isMultiScale"}
                        name="isMultiScale"
                        className="select-react-dropdown"
                        // options={levelOfStudyOptions}
                        // onChange={(e) => this.changeLocationOptions(e)}
                        // defaultValue={this.state.locationDropDownDetails}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
