import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import store, {persistor} from "./redux/store";
import App from "./containers/App/App";
import "./scss/index.scss"; // Standard version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";

import "../src/_metronic/_assets/sass/components/custom/_my-widget.scss"

const {PUBLIC_URL} = process.env;

ReactDOM.render(
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <App store={store} persistor={persistor} basename={PUBLIC_URL}/>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>,
    document.getElementById("root")
);
