import React, {useEffect, useState} from "react";
import BlockUi from "react-block-ui";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Switch} from "@material-ui/core";
import {SwitchAlertModal} from "../../../../shared/components/SwitchAlertModal";
import {useDispatch} from "react-redux";
import * as moment from "moment/moment";
import {Button} from "react-bootstrap";
import * as columnFormatters from "./ExperimentStatusFormatter";
import ExprimentInfoModal from "./ExprimentInfoModal";
import ExprimentChartModal from "./ExprimentChartModal";
import {StartTestingModal} from "./Testing/StartTestingModal";
import TrainConformationModal from "./Train/TrainConformationModal";
import axios from "axios";
import {successToast, warningToast} from "../../../../shared/components/ToastMessage";
import {ExperimentSwitchTestingAlertModal} from "./Testing/ExperimentSwitchTestingAlertModal";
import DeployConformationModal from "./Deploy/DeployConformationModal";
import DeployeUserMainModal from "./Model/DeployeUserMainModal";
import SweetAlert from "react-bootstrap-sweetalert";
import {appConfigs} from "../../../../configs";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import {trainModel, UpdateExperimentStatus} from "../../../../service/experimentManagement.api";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {BoootstrapTable} from "../../../../shared/components/BoootstrapTable";
import * as action from "../../../../redux/actions/experimentManagementAction";

function ExperimentTable({
                             currentItems,
                             showTable,
                             totalCount,
                             pageNo,
                             pageSize,
                             pageChange,
                             sizePerPageChangeApiCall,
                             editModalDataTable
                         }) {
    const [filterEntities, setFilterEntities] = useState([]);
    const [infoModalShow, setInfoModalShow] = useState(false);
    const [infoModalData, setInfoModalData] = useState([]);
    const [chartModalShow, setChartModalShow] = useState(false);
    const [successIcon, setSuccessIcon] = useState(false);
    const [experimentStatus, setExperimentStatus] = useState(false);
    const [experimentStatusData, setExperimentStatusData] = useState([]);

    const [
        openTestExperimentDialogStatus,
        setOpenTestExperimentDialogStatus
    ] = useState(false);
    const [testExperimentDialogData, setTestExperimentDialogData] = useState([]);
    const [testingModalShow, setTestingModalShow] = useState(false);
    const [
        openTrainExperimentDialogStatus,
        setOpenTrainExperimentDialogStatus
    ] = useState(false);
    const [trainExperimentDialogData, setTrainExperimentDialogData] = useState(
        []
    );
    const [trainOnChangeValue, setTrainOnChangeValue] = useState([]);
    const [
        openDeployAccountDialogStatus,
        setOpenDeployAccountDialogStatus
    ] = useState(false);
    const [deployOnChangeValue, setDeployOnChangeValue] = useState([]);
    const [
        openModalDeploymentDialogStatus,
        setOpenModalDeploymentDialogStatus
    ] = useState(false);
    const [modalDeployOnChangeValue, setModalDeployOnChangeValue] = useState([]);
    const [openSignUpWizard, setOpenSignUpWizard] = useState(false);
    const [experimentDetails, setExperimentDetails] = useState([]);
    const [allStepsCompleted, setAllStepsCompleted] = useState(false);
    const [showSuccess, setshowSuccess] = useState(false);
    const [userStatusLoader, setUserStatusLoader] = useState(false);
    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "#",
            text: "Index",
            formatter: (cell, row, rowIndex) => {
                return <span>{(pageNo - 1) * pageSize + (rowIndex + 1)}</span>;
            }
        },
        {
            dataField: "experiment_name",
            text: "EXPERIMENT NAME",
            style: {
                minWidth: "55px"
            },
            formatter: (cell, _) => {
                return (
                    <>
                        <Tooltip
                            title={<div className="tools"> {cell}</div>}
                            placement={"top-end"}
                        >
                            <span className={"experiment-table-name-length"}>{cell}</span>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            dataField: "experiment_description",
            text: " Description",
            style: {
                minWidth: "55px"
            },
            formatter: (cell, _) => {
                return (
                    <>
                        <Tooltip
                            title={<div className="tools"> {cell}</div>}
                            placement={"top-end"}
                        >
                            <span className={"experiment-table-name-length"}>{cell}</span>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            dataField: "experiment_status",
            text: "Experiment Status",
            style: {
                minWidth: "55px"
            },
            formatter: columnFormatters.ExperimentStatusFormatter
        },
        {
            dataField: "created_date",
            text: "Created Date",
            style: {
                minWidth: "180px"
            },
            formatter: (_, row) =>
                moment
                    .utc(row?.created_date)
                    .local()
                    .format("DD MMMM YYYY, HH:mm:ss")
        },

        {
            dataField: "test",
            text: "Test",
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Button
                            type="button"
                            className="btn btn-primary col-xl-12 py-2"
                            disabled={
                                row.experiment_status.toLowerCase() === "pending" ||
                                row.experiment_status.toLowerCase() === "requested" ||
                                row.experiment_status.toLowerCase() === "processing" ||
                                row.experiment_status.toLowerCase() === "error"
                            }
                            onClick={() => openTestExperimentDialog(row)}
                        >
                            Test
                        </Button>
                    </>
                );
            }
        },
        {
            dataField: "train",
            text: "Train",
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Button
                            type="button"
                            className="btn btn-primary col-xl-12  py-2"
                            disabled={
                                row.experiment_status.toLowerCase() !== "pending" &&
                                row.experiment_status.toLowerCase() !== "error"
                            }
                            onClick={() => openTrainExperimentDialog(row)}
                        >
                            Train
                        </Button>
                    </>
                );
            }
        },
        {
            dataField: "deploy",
            text: "Deploy",
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Button
                            type="button"
                            className="btn btn-primary col-xl-12  py-2"
                            disabled={
                                row.experiment_status.toLowerCase() === "pending" ||
                                row.experiment_status.toLowerCase() === "requested" ||
                                row.experiment_status.toLowerCase() === "processing" ||
                                row.experiment_status.toLowerCase() === "error"
                            }
                            onClick={() => openDeployExperimentDialog(row)}
                        >
                            Deploy
                        </Button>
                    </>
                );
            }
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cellContent, row) => {
                return (
                    <>
                        <Switch
                            checked={row.status}
                            onChange={() => handleExperimentStatus(row)}
                            color="primary"
                        />
                    </>
                );
            },
            style: {
                minWidth: "55px"
            }
        },
        {
            dataField: "action",
            text: "Actions",
            style: {
                minWidth: "55px"
            },
            formatter: (cellContent, row) => {
                return (
                    <>
                        <UncontrolledDropdown direction="left">
                            <DropdownToggle className="dropdown-toggle-btn">
                                <span className="svg-icon svg-icon-md svg-icon-primary icon-hover " style={{color: "#B5B5C3"}}>
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/three-dots-vertical.svg"
                                        )}
                                    />
                                </span>
                            </DropdownToggle>

                                <DropdownMenu>

                                    <DropdownItem className="text-hover-btn  dropdown-menu-color">

                                        <a className="text-dark-75  text-hover-primary  "
                                           onClick={() => editModalDataTable(cellContent, row)}>
                                            <a className="svg-icon svg-icon-md svg-icon-primary mr-2 "

                                            >
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Communication/Write.svg"
                                                    )}
                                                />
                                            </a>Edit Experiment
                                        </a>
                                    </DropdownItem>
                                    <DropdownItem className="text-hover-btn dropdown-menu-color">

                                        <a className="text-dark-75  text-hover-primary  "
                                           onClick={() => handleExperimentInfo(cellContent, row)}>
                                            <a className="svg-icon svg-icon-md svg-icon-primary mr-2 "

                                            >
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                                                />
                                            </a>Experiment Info
                                        </a>


                                    </DropdownItem>
                                    {row.experiment_status === "completed" ? (
                                    <DropdownItem className="text-hover-btn dropdown-menu-color">


                                            <a className="text-dark-75  text-hover-primary "
                                           onClick={() => handleExperimentChart(cellContent, row)}
                                        >
                                            <a className="svg-icon svg-icon-md svg-icon-primary mr-2 "

                                            >
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Code/chart.svg")}
                                                />
                                            </a>Modal Details
                                        </a>
                                    </DropdownItem>
                                    ):(<div></div>
                                    )}
                                </DropdownMenu>


                        </UncontrolledDropdown>
                    </>
                );
            }
        }
    ];

    useEffect(() => {
        setFilterEntities(currentItems);
    }, [currentItems]);

    const onPageChange = (page, sizePerPage) => {
        pageChange(page, sizePerPage);
    };
    const sizePerPageChange = (page, sizePerPage) => {
        sizePerPageChangeApiCall(sizePerPage, page);
    };

    const handleExperimentInfo = (cellContent, row) => {
        setInfoModalShow(true);
        setInfoModalData(row);
    };
    const handleExperimentChart = (cellContent, row) => {
        dispatch(action.fetchExperimentChartById(row.id));
        setChartModalShow(true);
    };

    const handleInfoClose = () => {
        setInfoModalShow(false);
    };
    const handleChartClose = () => {
        setChartModalShow(false);
    };

    const openTestExperimentDialog = row => {
        setTestExperimentDialogData(row);
        setOpenTestExperimentDialogStatus(true);
    };

    const openTestExperimentDialogClose = row => {
        setOpenTestExperimentDialogStatus(false);
        setSuccessIcon(false);
    };

    const handleTestExperimentDialogStatus = hyperParamsData => {
        const data = {...hyperParamsData};
        data["status"] = !data.status;
        if (data.status) {
            openTestExperimentDialogClose();
            setTestingModalShow(true);
        }
    };

    const testingModalClose = () => {
        setTestingModalShow(false);
    };

    const openTrainExperimentDialog = row => {
        setTrainExperimentDialogData(row);
        setOpenTrainExperimentDialogStatus(true);
    };

    const openTrainExperimentDialogClose = row => {
        setOpenTrainExperimentDialogStatus(false);
        setSuccessIcon(false);
        setTrainOnChangeValue("");
    };

    const handleTrainOnChange = event => {
        if (event === "localmachine") {
            setTrainOnChangeValue(event);
            //setURL(true)
            // setURL(false);
            //
            // setStorage(e);
            //setBtn(true)
            // setBtn(false);
        } else if (event === "cloud") {
            setTrainOnChangeValue(event);
            // setURL(false);
            // setStorage(e);
            // setBtn(false);
            // setValue();
        }
    };

    const checkStatus = async id => {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Content-Security-Policy": "upgrade-insecure-requests",
                "Access-Control-Allow-Origin": "*"
            }
        };
        axios
            .post("http://trainer-api.tusker.ai" + "/check_availability", axiosConfig)
            .then(res => {
                if (res) {
                    if (res.data === 1) {
                        trainModelLocalmachine(id);
                        // dispatch(actions.fetchExperiments(page, size, search));
                    } else {
                        warningToast("GPU is busy, please try again later");
                    }
                } else {
                    warningToast("Error getting model details");
                    throw new Error("error getting model id");
                }
            })
            .catch(e => {
                warningToast("Something went wrong");
            });
    };

    const trainModelLocalmachine = async id => {
        // eslint-disable-next-line
        axios
            .post(
                "http://trainer-api.tusker.ai" + "/start_training" + "?exp_id=" + id
            )
            .then(res => {
                if (res) {
                    successToast(
                        "Experiment training requested successfully, training time could be 3 to 48 hours depends on Dataset size and number of iterations."
                    );
                    // dispatch(actions.fetchExperiments(page, size, search))
                } else {
                    warningToast("Error getting model details");
                    throw new Error("error getting model id");
                }
            })
            .catch(e => {
                warningToast("Something went wrong");
            });
    };

    const openTrainExperimentDialogSubmit = () => {
        // setOpenTrainExperimentDialogStatus(false)
        if (trainOnChangeValue === "localmachine") {
            setOpenTrainExperimentDialogStatus(false);
        } else if (trainOnChangeValue === "cloud") {
            trainAPI(trainExperimentDialogData?.id);
            setOpenTrainExperimentDialogStatus(false);
        }
    };

    const trainAPI = async id => {
        try {
            const res = await trainModel(id, "requested");
            if (res.isSuccess) {
                successToast(
                    "Experiment training requested successfully, training time could be 3 to 48 hours depends on Dataset size and number of iterations."
                );
                dispatch(action.fetchExperiments(pageNo, pageSize, ""));
            } else {
                warningToast("GPU is busy, please try again later");
                throw new Error("error getting model id");
            }
        } catch (e) {
            warningToast("Something went wrong");
        }
    };

    const handleDeployAccountOnChange = event => {
        setDeployOnChangeValue(event);
    };

    const handleModalDeployAccountOnChange = event => {
        setModalDeployOnChangeValue(event);
    };

    const openDeployExperimentDialog = row => {
        setExperimentDetails(row);
        setOpenDeployAccountDialogStatus(true);
    };

    const openDeployAccountDialogClose = () => {
        setOpenDeployAccountDialogStatus(false);
        setSuccessIcon(false);
        setDeployOnChangeValue("");
    };

    const openModalDeployAccountDialogClose = () => {
        setOpenModalDeploymentDialogStatus(false);
        setSuccessIcon(false);
        setModalDeployOnChangeValue("");
    };

    const openDeployAccountDialogSubmit = () => {
        if (deployOnChangeValue === "yes") {
            setOpenDeployAccountDialogStatus(false);
            setOpenModalDeploymentDialogStatus(true);
        } else if (deployOnChangeValue === "no") {
            setOpenDeployAccountDialogStatus(false);
            setOpenModalDeploymentDialogStatus(true);
        }

    };

    const openModalDeployDialogSubmit = () => {
        if (modalDeployOnChangeValue === "public") {
            setOpenModalDeploymentDialogStatus(false);
            setOpenSignUpWizard(true);
        } else if (modalDeployOnChangeValue === "private") {
            setOpenModalDeploymentDialogStatus(false);
            setOpenSignUpWizard(true);
        }
    };

    const openSignUpWizardClose = () => {
        setOpenSignUpWizard(false);
    };

    const onStepsCompleted = () => {
        setAllStepsCompleted(true);
        setshowSuccess(true);
    };

    const handleServiceAccessStatus = (id, status) => {
        UpdateUserExperimentStatusa(id, status);
    };

    const UpdateUserExperimentStatusa = (id, status) => {
        setUserStatusLoader(true);
        // setToggleTitle("Updating Status!");
        UpdateExperimentStatus(!status, id)
            .then(response => {
                if (response && response.isSuccess) {
                    setUserStatusLoader(false);
                    // setToggleTitle("Status Updated Successfully!");
                    setSuccessIcon(true);
                    setTimeout(function () {
                        handleServiceAccessClose();
                        dispatch(action.fetchExperiments(1, 10, ""));
                    }, 1000);
                }
            })
            .catch(e => {
                setUserStatusLoader(false);
                warningToast("Something went wrong");
            });
    };

    const handleExperimentStatus = row => {
        setExperimentStatus(true);
        setExperimentStatusData(row);
    };

    const handleServiceAccessClose = () => {
        setExperimentStatus(false);
        setSuccessIcon(false);
        setExperimentStatusData("");
    };

    return (
        <>
            <BlockUi tag="div" blocking={showTable} color="#147b82">
                {filterEntities.length > 0 && (
                    <BoootstrapTable
                        sizePerPageList={[
                            {text: "10", value: 10},
                            {text: "5", value: 5},
                            {text: "3", value: 3}
                        ]}
                        hideSizePerPage={false}
                        showTotal={true}
                        alwaysShowAllBtns={true}
                        hidePageListOnlyOnePage={true}
                        columns={columns}
                        data={filterEntities}
                        sizePerPage={pageSize}
                        page={pageNo}
                        totalSize={totalCount}
                        onTableChange={onPageChange}
                        sizePerPageChange={sizePerPageChange}
                    />
                )}
                {filterEntities.length === 0 && (
                    <h3 className={"mt-5 d-flex justify-content-center"}>
                        No Data Found
                    </h3>
                )}
            </BlockUi>

            <ExprimentInfoModal
                show={infoModalShow}
                infoModalData={infoModalData}
                handleClose={handleInfoClose}
            />

            <ExprimentChartModal
                show={chartModalShow}
                handleClose={handleChartClose}
            />

            <SwitchAlertModal
                showAlert={experimentStatus}
                handleAccessUserClose={handleServiceAccessClose}
                handleUserStatus={(id, status) => handleServiceAccessStatus(id, status)}
                id={experimentStatusData.id}
                status={experimentStatusData?.status}
                userStatusLoader={userStatusLoader}
                successIcon={successIcon}
            />

            <ExperimentSwitchTestingAlertModal
                showAlert={openTestExperimentDialogStatus}
                handleAccessUserClose={openTestExperimentDialogClose}
                handleUserStatus={hyperParamsData =>
                    handleTestExperimentDialogStatus(hyperParamsData)
                }
            />

            <StartTestingModal
                show={testingModalShow}
                close={testingModalClose}
                testExperimentDialogData={testExperimentDialogData}
            />

            <TrainConformationModal
                show={openTrainExperimentDialogStatus}
                handleTrainOnChange={handleTrainOnChange}
                openTrainExperimentDialogClose={openTrainExperimentDialogClose}
                openTrainExperimentDialogSubmit={openTrainExperimentDialogSubmit}
            />

            <DeployConformationModal
                show={openDeployAccountDialogStatus}
                title={"Select Training Medium"}
                handleDeployOnChange={handleDeployAccountOnChange}
                openDeployDialogClose={openDeployAccountDialogClose}
                openDeployDialogSubmit={openDeployAccountDialogSubmit}
                status={"training"}
            />

            <DeployConformationModal
                show={openModalDeploymentDialogStatus}
                title={"Select Model Deployment"}
                handleDeployOnChange={handleModalDeployAccountOnChange}
                openDeployDialogClose={openModalDeployAccountDialogClose}
                openDeployDialogSubmit={openModalDeployDialogSubmit}
                status={"model"}
            />

            <DeployeUserMainModal
                openSignUpWizard={openSignUpWizard}
                openSignUpWizardClose={openSignUpWizardClose}
                onStepsCompleted={onStepsCompleted}
                experimentDetails={experimentDetails}
                accountType={deployOnChangeValue}
                deploymentType={modalDeployOnChangeValue}
            />

            {allStepsCompleted ? (
                <>
                    <SweetAlert
                        success
                        showConfirm
                        closeOnClickOutside={false}
                        confirmBtnText={"Okay"}
                        confirmBtnBsStyle="primary"
                        title={"Model Added to Marketplace"}
                        show={showSuccess}
                        //onConfirm={()=> history.push(ADMIN_URL+'/subscriptions/deploymentJobsPage')}
                        onConfirm={() => {
                            setshowSuccess(false);
                            window.open(
                                appConfigs.APP_REDIRECT_URL,
                                "_blank",
                                "noopener,noreferrer"
                            );
                        }}
                    />
                </>
            ) : null}
        </>
    );
}

export default ExperimentTable;
