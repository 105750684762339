import { Col, Form, Row } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import { FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
import { StartTestingModal } from "./StartTestingModal";
import { useHistory } from "react-router-dom";
import { CardBody } from "../../../../../../_metronic/_partials/controls/Card";
import { BootstrapInput } from "../../../../../../shared/components/BootstrapInput";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    height: 40,
    fontSize: 12
  },
  dropdown: {
    width: "100%",
    marginBottom: "2rem"
  },
  dropdownHeader: {
    fontSize: 18
  }
}));

const imageSizeOptions = [416, 512, 640];
const confOrIouOptions = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];

export function TestNowTab({ model }) {
  const history = useHistory();
  const classes = useStyles();

  const [show, setShow] = React.useState(false);
  const [showFeedback, setShowFeedback] = React.useState(false);

  const [alertData, setAlertData] = React.useState({
    show: false,
    title: "Are You Sure You Want To Start Testing?"
  });

  const [state, setState] = React.useState({
    imageSize: imageSizeOptions[2],
    confThresh: confOrIouOptions[2],
    iouThresh: confOrIouOptions[4]
  });

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const showAlert = () => {
    setAlertData({ ...alertData, show: true });
  };
  const handleGoBack = () => {
    history.push("/experiments/experimentManagementPage");
  };

  return (
    <>
      {(show || showFeedback) && (
        <StartTestingModal
          testData={state}
          model={model}
          show={show}
          setShow={setShow}
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
        />
      )}

      <Row>
        <Col className="offset-3 col-6">
          <Card style={{ marginBottom: 50 }}>
            <CardBody>
              <Form>
                <FormControl className={classes.dropdown}>
                  <InputLabel htmlFor="age-customized-native-simple">
                    Image Size
                  </InputLabel>
                  <NativeSelect
                    value={state.imageSize}
                    onChange={handleChange}
                    name="imageSize"
                    input={
                      <BootstrapInput
                        name="age"
                        id="age-customized-native-simple"
                      />
                    }
                  >
                    {imageSizeOptions.map(val => (
                      <option value={val}>{val}</option>
                    ))}
                  </NativeSelect>
                </FormControl>

                <FormControl className={classes.dropdown}>
                  <InputLabel htmlFor="age-customized-native-simple">
                    Confidence Threshold
                  </InputLabel>
                  <NativeSelect
                    value={state.confThresh}
                    name="confThresh"
                    onChange={handleChange}
                    input={
                      <BootstrapInput
                        name="age"
                        id="age-customized-native-simple"
                      />
                    }
                  >
                    {confOrIouOptions.map(val => (
                      <option value={val}>{val}</option>
                    ))}
                  </NativeSelect>
                </FormControl>

                <FormControl className={classes.dropdown}>
                  <InputLabel htmlFor="age-customized-native-simple">
                    IOU Threshold
                  </InputLabel>
                  <NativeSelect
                    value={state.iouThresh}
                    name="iouThresh"
                    onChange={handleChange}
                    input={
                      <BootstrapInput
                        name="age"
                        id="age-customized-native-simple"
                      />
                    }
                  >
                    {confOrIouOptions.map(val => (
                      <option value={val}>{val}</option>
                    ))}
                  </NativeSelect>
                </FormControl>

                <Row>
                  <Col
                    xl={6}
                    xs={12}
                    md={12}
                    lg={12}
                    sm={12}
                    className="text-lg-right text-md-right text-xl-right text-sm-right  text-right"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={showAlert}
                      className="col-xl-6"
                    >
                      Start Testing
                    </Button>
                  </Col>
                  <Col xl={6} xs={12} md={12} lg={12} sm={12}>
                    <Button
                      type="button"
                      className="btn btn-primary col-xl-6"
                      onClick={handleGoBack}
                    >
                      Go Back
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <SweetAlert
          info
          showConfirm
          showCancel
          closeOnClickOutside
          confirmBtnText="Yes"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          cancelBtnStyle={{ color: "black" }}
          title={alertData.title}
          show={alertData.show}
          focusCancelBtn
          onConfirm={() => {
            setAlertData({ ...alertData, show: false });
            setShow(true);
          }}
          onCancel={() => {
            setAlertData({ ...alertData, show: false });
          }}
        />
      </Row>
    </>
  );
}
