import React, { useState } from "react";
import { Chip, makeStyles } from "@material-ui/core";
import {Card, Col, Form} from "react-bootstrap";
import "react-multi-date-picker/styles/colors/teal.css";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: "#147b82",
    color: "#fff"
  }
}));

export function ChoicePage1() {
  const [textBoxShow, setTextBoxShow] = useState(false);
  const [text, setText] = useState("");
  const [submitted, setSubmitted] = useState("");

  const classes = useStyles();

  const handleClick = labalName => {
    alert("You clicked " + labalName);
  };

  function handleChange(e) {
    alert("You clicked ", e.target.value);
    setText(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(text);
    setText("");
    setTextBoxShow(false);
  }

  const handleClickOther = () => {
    setTextBoxShow(!textBoxShow);
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <h2>Tell us a bit about yourself</h2>
        <h5 c>This helps us personalise your experience.</h5>
      </div>
      <div className={"centered-element"}>
        <Card
          style={{
            width: "40rem",
            border: "2px dashed #147b82",
            height: "30rem",
            overflow: "auto"
          }}
          className={"custome-chip"}
        >
          <Card.Body>
            <Card.Title className={"text-center"}>
              What type of team do you work in?
            </Card.Title>
            {/*<Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>*/}
            <Card.Text>
              <div className={classes.root}>
                <Chip
                  label="Software Developement"
                  className={classes.chip}
                  onClick={() => handleClick("Software Developement")}
                />

                <Chip
                  label="Legal"
                  className={classes.chip}
                  onClick={() => handleClick("Legal")}
                />

                <Chip
                  label="Marketing"
                  className={classes.chip}
                  onClick={() => handleClick("Marketing")}
                />

                <Chip
                  label="IT Support "
                  className={classes.chip}
                  onClick={() => handleClick("IT Support")}
                />

                <Chip
                  label="Sales"
                  className={classes.chip}
                  onClick={() => handleClick("Sales")}
                />

                <Chip
                  label="Human Resources"
                  className={classes.chip}
                  onClick={() => handleClick("Human Resources")}
                />

                <Chip
                  label="Operations"
                  className={classes.chip}
                  onClick={() => handleClick("Operations")}
                />
                <Chip
                  label="Customer Service"
                  className={classes.chip}
                  onClick={() => handleClick("Customer Service")}
                />
                <Chip
                  label="Finance"
                  className={classes.chip}
                  onClick={() => handleClick("Finance")}
                />

                <Chip
                  label="Other"
                  className={classes.chip}
                  onClick={handleClickOther}
                />
              </div>
            </Card.Text>

            <div className={""}>
              {textBoxShow ? (
                <>
                  <form onSubmit={handleSubmit}>
                    {/*<input type="text" value={text} onChange={handleChange} />*/}
                    <Form.Group controlId="other">
                      <Col sm={12}>
                        <Form.Control
                          type="text"
                          name="other"
                          placeholder="Other"
                          value={text}
                          onChange={handleChange("Other")}
                        />
                      </Col>
                    </Form.Group>
                  </form>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card.Body>
          {submitted ? <div>submitted: {submitted} </div> : <></>}
        </Card>
      </div>
    </>
  );
}
