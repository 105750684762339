import React from "react";
export function ReqTrainColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openTrainExperimentDialog }
) {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary col-xl-12"
        disabled={row.experiment_status.toLowerCase() !== "requested"}
        onClick={() => openTrainExperimentDialog(row.id, "processing")}
      >
        Start Training
      </button>
    </>
  );
}
