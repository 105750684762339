import { Route } from "react-router-dom";
import React from "react";
import { ExperimentManagementUIProvider } from "./ExperimentManagementUIContext";
import { ExperimentManagementCard } from "./ExperimentManagementCard";
import { ExperimentManagementEditDialog } from "./experiment-management-edit-dialog/ExperimentManagementEditDialog";
import { ExperimentDetailsDialog } from "./experiment-management-details-dialog/ExperimentDeatilsDialog";
export function ExperimentManagementPage({ history }) {
  const experimentManagementPageBaseUrl =
    "/experiments/experimentManagementPage";

  const experimentManagementUIEvents = {
    openEditExperimentManagementDialog: id => {
      history.push(`${experimentManagementPageBaseUrl}/${id}/edit`);
    },
    openViewExperimentManagementDialog: id => {
      history.push(`${experimentManagementPageBaseUrl}/${id}/view`);
    }
  };

  return (
    <ExperimentManagementUIProvider
      experimentManagementUIEvents={experimentManagementUIEvents}
    >
      <Route path={`${experimentManagementPageBaseUrl}/:id/edit`}>
        {({ history, match }) => (
          <ExperimentManagementEditDialog
            show={match != null}
            id={match?.params?.id}
            onHide={() => {
              history.push(experimentManagementPageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${experimentManagementPageBaseUrl}/:id/view`}>
        {({ history, match }) => (
          <ExperimentDetailsDialog
            show={match != null}
            id={match?.params?.id}
            onHide={() => {
              history.push(experimentManagementPageBaseUrl);
            }}
          />
        )}
      </Route>
      <ExperimentManagementCard />
    </ExperimentManagementUIProvider>
  );
}
