import {request} from "../shared/helper/RequestWrapper";
import {HttpRequest} from "../shared/enums/http.methods.enums";


const GET_ALL_DATASET_FOR_CURRENT_USER = "/get_dataset_for_current_user";
const GET_ALL_DATASET_FOR_DROPDOWN_CURRENT_USER = "/get_dataset_for_dropdown_current_user";
const GET_DATASET_BY_ID = "/get_user_dataset_by_id";
const ADD_DATASET = "/add_user_dataset";
const UPDATE_DATASET = "/update_user_dataset";
const GET_UPLOADED_IMAGES_FROM_USER = "/get_uploaded_images_from_user";
const ADD_USER_UPLOADED_DATASET = "/add_user_uploaded_dataset";
const UPDATE_USER_UPLOADED_DATASET = "/update_user_uploaded_dataset";
const GET_USER_UPLOADED_DATASET_BY_ID = "/get_user_uploaded_dataset_by_id";
const GET_UPLOADED_DATASET_FOR_CURRENT_USER =
  "/get_uploaded_dataset_for_current_user";
const GET_DATASET_IMAGES_BY_USERID_DATASET_ID =
  "/get_dataset_images_by_userid_dataset_id";
const CHECK_RTSP_URL = "/check_rtsp_status";
const GET_UPLOAD_IMAGES_FROM_USER_RTSP = "/get_uploaded_images_from_user_rtsp";
const UPDATE_USER_DATASET_STATUS = "/update_user_dataset_status";

export async function getDatasetById(id) {
  return await request({
    endpoint: GET_DATASET_BY_ID + "?user_dataset_id=" + id,
    method: "GET"
  });
}

export async function addNewDataset(data) {
  return await request({
    endpoint: ADD_DATASET,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}

export async function updateDataset(data) {
  return await request({
    endpoint: UPDATE_DATASET,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}

export async function getAllDataset(pageNo ,pageSize,search) {
  return await request({
    endpoint: GET_ALL_DATASET_FOR_CURRENT_USER + `?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: HttpRequest.GET
  });
}
export async function getAllDatasetForDropDown() {
  return await request({
    endpoint: GET_ALL_DATASET_FOR_DROPDOWN_CURRENT_USER ,
    method: HttpRequest.GET
  });
}

export async function getUploadedFilesFromUser(i, name, files,file_type,fps) {
  if(file_type==="image") {
    fps=0
  }

  var formData = new FormData();
  files.map((file) => {
    formData.append(`files`, file);
  });
  formData.append("dataset_id", i);
  formData.append("dataset_name", name);
  formData.append("file_type", file_type);
  formData.append("fps", fps);
  return await request({
    endpoint: GET_UPLOADED_IMAGES_FROM_USER,
    method: HttpRequest.POST,
    body: formData
  });
}

export async function addUploadedDataset(data) {
  return await request({
    endpoint: ADD_USER_UPLOADED_DATASET,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}
export async function addImagesFromUserRtsp(data,id,dataset_name) {
  let datas={"dataset_details":data ,"dataset_id":id,"dataset_name":dataset_name  }

  return await request({
    endpoint: GET_UPLOAD_IMAGES_FROM_USER_RTSP ,
    method: HttpRequest.POST,
    body: JSON.stringify(datas)
  });
}

export async function updateUploadedDataset(data) {
  return await request({
    endpoint: UPDATE_USER_UPLOADED_DATASET,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}

export async function getUploadedDatasetById(id) {
  return await request({
    endpoint: GET_USER_UPLOADED_DATASET_BY_ID + "?user_dataset_id=" + id,
    method: HttpRequest.GET,
  });
}
export async function getAllUploadedDataset() {
  return await request({
    endpoint: GET_UPLOADED_DATASET_FOR_CURRENT_USER,
    method: HttpRequest.GET,
  });
}
export async function getUserUploadedImages(id) {
  return await request({
    endpoint: GET_DATASET_IMAGES_BY_USERID_DATASET_ID + "?dataset_id=" + id,
    method:  HttpRequest.GET,
  });
}



export async function checkRTSPURL(rtspURL) {
  return await request({
    endpoint: CHECK_RTSP_URL + "?rtsp_url=" + rtspURL.replace(/&/g, "%26"),
    method:  HttpRequest.POST,
  });
}


export async function UpdateDatasetStatus(status , id) {
  return await request({
    endpoint: UPDATE_USER_DATASET_STATUS +`?update_status=${status}&update_id=${id}`,
    method: HttpRequest.POST,
  });
}
