import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  LoginSocialGoogle,
  LoginSocialGithub,
  LoginSocialLinkedin,
  LoginSocialMicrosoft
} from "reactjs-social-login";
import { AuthAction } from "../../../redux/reducers/authRedux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../../../redux/reducers/authRedux";
import { appConfigs as appConfig } from "../../../configs";
import { authProvider } from "../../../shared/enums";
import { successToast, warningToast } from "../../../shared/components/ToastMessage";
import { verifySocialUser} from "../../../service/authCrud.api";
import Cookies from "universal-cookie";
import { ACCESS_TOKEN, TOKEN_TYPE } from "../../../shared/enums/auth.enums";

const LoginWithSocialMedia = props => {
  // const { enableLoading = () => {}, disableLoading = () => {} } = props;
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const googleRef = useRef();
  const dispatch = useDispatch();

  const onLoginStart = useCallback(() => {
    dispatch(AuthAction.setLoginInitiated(true));
  }, []);

  const onLogoutFailure = useCallback(() => {
    warningToast("Something Went Wrong");
    dispatch(AuthAction.setLoginInitiated(false));
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    dispatch(AuthAction.setLoginInitiated(false));
    alert("logout success");
  }, []);

  const onProviderResolve = useCallback(
    ({ provider, data }) => {
      setProvider(provider);
      setProfile(data);
      dispatch(AuthAction.setLoginInitiated(false));

      // let profileInfo = { provider };
      // if (provider === authProvider.GOOGLE) {
      //   profileInfo = {
      //     ...profileInfo,
      //     id: data?.sub,
      //     email: data?.email || undefined,
      //     accessToken: data?.access_token || undefined
      //   };
      // } else if (provider === authProvider.GITHUB) {
      //   profileInfo = {
      //     ...profileInfo,
      //     id: data?.id,
      //     email: data?.email || undefined,
      //     accessToken: data?.access_token || undefined
      //   };
      // } else if (provider === authProvider.LINKED_IN) {
      //   profileInfo = {
      //     ...profileInfo,
      //     id: data?.id,
      //     email: data?.email || undefined,
      //     accessToken: data?.access_token || undefined
      //   };
      // } else if (provider === authProvider.MICROSOFT) {
      //   profileInfo = {
      //     ...profileInfo,
      //     id: data?.id,
      //     email: data?.email || undefined,
      //     accessToken: data?.access_token || undefined
      //   };
      // }
      // let payload = { ...profileInfo };
      if (data) {
        let userDetails;
        if (provider === authProvider.GOOGLE) {
          userDetails = {
            user_code: data.access_token,
            provider: provider,
            redirect_url: appConfig.APP_RE_REDIRECT_URL || ""

          };
        } else if (provider === authProvider.GITHUB) {
          userDetails = {
            user_code: data.code,
            provider: provider,
            redirect_url: appConfig.APP_RE_REDIRECT_URL || ""

          };
        } else if (provider === authProvider.LINKED_IN) {
          userDetails = {
            user_code: data.code,
            provider: provider,
            redirect_url: appConfig.APP_RE_REDIRECT_URL || ""
          };
        } else if (provider === authProvider.MICROSOFT) {
          userDetails = {
            user_code: data.access_token,
            provider: provider,
            redirect_url: appConfig.APP_RE_REDIRECT_URL || ""
          };
        }

        verifySocialUser(userDetails)
            .then(response => {
              let { access_token, token_type } = response.data;
              setTokenCookies(access_token, token_type);
              // disableLoading();
              dispatch(props.requestUser(undefined));
            })
            .catch(err => {
              if (err.response.data.detail === "Inactive user") {
                successToast("ask admin to enable the user");
              } else {
                if (err.response.data.detail) {
                  warningToast(err.response.data.detail);
                } else {
                  warningToast("Something went Wrong");
                }
              }
            });
      }
    },
    [provider, profile]
  );

  const setTokenCookies = (token, tokenType) => {
    const cookies = new Cookies();
    cookies.set(ACCESS_TOKEN, token, { httpOnly: false, path: "/" });
    cookies.set(TOKEN_TYPE, tokenType, { httpOnly: false, path: "/" });
  };
  return (
    <>
      <div className="text-center">
        <p className="text-muted font-weight-bold">or you can sign in with</p>
      </div>
      <div
        className={`App ${
          provider && profile
            ? "hide d-flex justify-content-center"
            : "d-flex justify-content-center"
        }`}
      >
        <div className={"d-flex"}>
        <LoginSocialGoogle
          ref={googleRef}
          client_id={appConfig.APP_GG_APP_ID || ""}
          onLogoutFailure={onLogoutFailure}
          scope={"https://www.googleapis.com/auth/userinfo.email"}
          fetch_basic_profile={true}
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          onResolve={({ provider, data }) => {
            onProviderResolve({ provider, data });
          }}
          onReject={err => {
            console.log("onReject", err);
          }}
          isOnlyGetToken={true}
        >
          <a className="googleplusBtn smGlobalBtn" href="#"></a>
        </LoginSocialGoogle>

        <LoginSocialMicrosoft
          client_id={appConfig.APP_MICROSOFT_APP_ID || ""}
          redirect_uri={appConfig.APP_RE_REDIRECT_URL || ""}
          onLoginStart={onLoginStart}
          scope={"profile openid email offline_access user.Read"}
          isOnlyGetToken={true}
          onResolve={({ provider, data }) => {
            onProviderResolve({ provider, data });
          }}
          onReject={err => {
            console.log("onReject", err);
          }}
        >
          <a className="microsoftBtn smGlobalBtn" href="#"></a>
        </LoginSocialMicrosoft>

        <LoginSocialGithub
          client_id={appConfig.APP_GITHUB_CLIENT_ID || ""}
          client_secret={appConfig.APP_GITHUB_APP_SECRET || ""}
          redirect_uri={appConfig.APP_RE_REDIRECT_URL || ""}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            onProviderResolve({ provider, data });
          }}
          onReject={err => {
            console.log("error", err);
          }}
          scope={"read:user, user:email"}
          isOnlyGetCode={true}
        >

          <a className="githubBtn smGlobalBtn" href="#"></a>
        </LoginSocialGithub>

        <LoginSocialLinkedin
          client_id={appConfig.APP_LINKEDIN_APP_ID || ""}
          client_secret={appConfig.APP_LINKEDIN_APP_SECRET || ""}
          redirect_uri={appConfig.APP_RE_REDIRECT_URL || ""}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            onProviderResolve({ provider, data });
          }}
          onReject={err => {
            console.log("error", err);
          }}
          scope={"r_emailaddress,r_liteprofile"}
          isOnlyGetCode={true}
        >
          <a className="linkedinBtn smGlobalBtn" href="#"></a>
        </LoginSocialLinkedin>
        </div>

      </div>
    </>
  );
};

export default injectIntl(connect(null, auth.AuthAction)(LoginWithSocialMedia));
