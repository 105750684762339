import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { DatasetStorageTypeTable } from "./dataset-storage-type-table/DatasetStorageTypeTable";
import { useDatasetStorageTypeUIContext } from "./DatasetStorageTypeUIContext";

export function DatasetStorageTypeCard() {
  const datasetStorageTypeUIContext = useDatasetStorageTypeUIContext();
  const datasetStorageTypeUIProps = useMemo(() => {
    return {
      newDatasetStorageTypeButtonClick:
        datasetStorageTypeUIContext.newDatasetStorageTypeButtonClick
    };
  }, [datasetStorageTypeUIContext]);

  return (
    <Card>
      <CardHeader title="Dataset Storage Type Details">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={datasetStorageTypeUIProps.newDatasetStorageTypeButtonClick}
          >
            Add Dataset Storage Type
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DatasetStorageTypeTable />
      </CardBody>
    </Card>
  );
}
