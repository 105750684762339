/* eslint-disable */
import { request } from "../../../../shared/helper/RequestWrapper";

const GET_ALL_EXPERIMENT = "/get_all_user_experiment";
const GET_ALL_FILTER_USER_EXPERIMENT = "/get_all_filter_user_experiment";
const GET_USER_EXPERIMENT_BY_ID = "/get_user_experiment_by_id";
const UPDATE_USER_EXPERIMENT = "/update_user_experiment";
const UPDATE_CREDITS = "/update_model_test_credit";
const GET_CREDITS = "/get_model_test_credit_for_current_user";
const START_TESTING = "/add_infer_job";
const LOAD_INFER_JOB = "/load_infer_job";
const LOAD_VIDEO_INFER_JOB = "/load_video_infer_job";
const UNLOAD_INFER_JOB = "/unload_infer_job";
const GET_ONE_MODEL = "/get_user_experiment_by_id";
const UPDATE_USER_EXPERIMENT_BY_STATUS_ACTIVE_USER =
  "/update_user_experiment_by_status_active_user";

export async function getExperimentById(id) {
  return await request({
    endpoint: GET_USER_EXPERIMENT_BY_ID + "?user_experiment_id=" + id,
    method: "GET"
  });
}

export async function updateExperiment(data) {
  return await request({
    endpoint: UPDATE_USER_EXPERIMENT,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function getAllExperiment(pageNo,pageSize,search,data) {
  return await request({
    endpoint: GET_ALL_FILTER_USER_EXPERIMENT+`?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: "POST",
    body: data,
  });
}

export async function updateCredits(body) {
  return await request({
    endpoint: UPDATE_CREDITS,
    method: "POST",
    body: body
  });
}
export async function getCredits() {
  return await request({
    endpoint: GET_CREDITS,
    method: "GET"
  });
}
export async function startTesting(
  modelId,
  status = true,
  created_date,
  updated_date,
  userId
) {
  const data = {
    image_size: 640,
    confidence_threshold: 0.5,
    iou_threshold: 0.3,
    experiment_id: modelId,
    status: status,
    created_date: created_date,
    updated_date: updated_date,
    user_id: userId
  };
  return await request({
    endpoint: START_TESTING,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: data,
    method: "POST"
  });
}

export async function loadInferJob(jobId, file) {
  let fileData = new FormData();
  fileData.append("image", file);
  return await request({
    endpoint:
      LOAD_INFER_JOB +
      `?experiment_id=${jobId}` +
      `&confidence_threshold=0.5` +
      `&iou_threshold=0.3`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    body: fileData
  });
}
export async function loadVideoInferJob(jobId, file) {
  let fileData = new FormData();
  fileData.append("video", file);
  return await request({
    endpoint:
      LOAD_VIDEO_INFER_JOB +
      `?experiment_id=${jobId}` +
      `&confidence_threshold=0.5` +
      `&iou_threshold=0.3`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    body: fileData
  });
}

export async function unloadInferJob() {
  return await request({
    endpoint: UNLOAD_INFER_JOB,
    method: "POST"
  });
}
export async function getOneModel(modelId) {
  return await request({
    endpoint: GET_ONE_MODEL + `?user_experiment_id=${modelId}`,
    method: "GET"
  });
}
export async function trainModel(id) {
  return await request({
    endpoint:
      UPDATE_USER_EXPERIMENT_BY_STATUS_ACTIVE_USER +
      "?user_experiment_id=" +
      id +
      `&user_experiment_status=processing`,
    method: "POST"
  });
}
