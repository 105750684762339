import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {FrameworkManagementEditDialogHeader} from "./FrameworkManagementEditDialogHeader";
import {FrameworkManagementEditForm} from "./FrameworkManagementEditForm";
import {FrameworkManagementSlice} from "../../_redux/FrameworkManagementSlice";
import * as action from "../../_redux/FrameworkManagementAction";
import {SavingDetailsModal} from "../../../../../shared/components/SavingDetailsModal";
import {successToast} from "../../../../../shared/components/ToastMessage";

const {actions} = FrameworkManagementSlice;

export function FrameworkManagementEditDialog({id, show, onHide}) {
  const {actionsLoading, frameworkFetchedById,currentState} = useSelector(
      (state) => ({
        actionsLoading: state.frameworkManagement.actionsLoading,
        frameworkFetchedById: state.frameworkManagement.frameworkFetchedById,
        currentState: state.frameworkManagement

      }),
      shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== null && id !== undefined) {
      dispatch(action.fetchFrameworkById(id));
    } else {
      dispatch(actions.clearFrameworkById());
    }
  }, [id, dispatch]);

  const [loading, setLoading] = useState(false);
  const saveFramework = (framework) => {
    setLoading(true);
    if (!id) {
      // server request for creating framework
      dispatch(action.createFramework(framework)).then(() =>
      { dispatch(action.fetchFrameworks(currentState.page,currentState.size,""));
        onHide()
      });
    } else {
      // server request for updating framework
      dispatch(action.frameworkUpdate(framework)).then(() => {
        successToast("Framework Details updated successfully")
        onHide()
      });
    }
  };

  return (
      <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
      >
        <SavingDetailsModal show={loading} top={'start'}/>
        <FrameworkManagementEditDialogHeader id={id}/>
        <FrameworkManagementEditForm
            saveFramework={saveFramework}
            actionsLoading={actionsLoading}
            frameworkData={frameworkFetchedById}
            onHide={onHide}
        />
      </Modal>
  );
}
