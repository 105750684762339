import { createSlice } from "@reduxjs/toolkit";

const initialDatasetState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  lastError: null,
  filteredEntities: "",
  datasetFetchedById: "",
  uploadedDataset: [],
  uploadDatasetLoader: true,
  uploadedImagesLoader: true,
  uploadedDatasetFetchById: "",
  uploadedImagesArr: [],
  datasetTotalSize:0,
  page:1,
  size:10
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const DatasetManagementSlice = createSlice({
  name: "dl-Dataset",
  initialState: initialDatasetState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.entities = [];
        state.uploadedImagesArr = [];
        state.uploadedDataset = [];
      } else {
        state.actionsLoading = false;
        state.entities = [];
        state.uploadedImagesArr = [];
        state.uploadedDataset = [];
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    datasetFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload.items;
      state.datasetTotalSize = action.payload.total
      state.totalCount = action.payload.length;
      state.page=action.payload.page;
      state.size=action.payload.size;

    },datasetFetchedForDropDown: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload;
      state.datasetTotalSize = action.payload.total
      state.totalCount = action.payload.length;

    },
    datasetUploadedFetched: (state, action) => {
      state.uploadDatasetLoader = false;
      state.error = null;
      state.uploadedDataset = action.payload;
      state.totalCount = action.payload.length;
    },
    uploadedImages: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.uploadedImagesLoader = false;
      state.uploadedImagesArr = action.payload;
      state.totalCount = action.payload.length;
    },
    datasetFetchedById: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.datasetFetchedById = action.payload;
    },
    datasetUploadedFetchedById: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.uploadedDatasetFetchById = action.payload;
    },

    datasetCreated: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.entities.push(action.payload);
      state.error = null;
    },
    datasetUploadedCreated: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.uploadedDataset.push(action.payload);
      state.error = null;
    },

    datasetUpdated: (state, action) => {
      let data = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map(entity => {
        if (entity.id === data.id) {
          return data;
        }
        return entity;
      });
    },
    datasetUploadedUpdated: (state, action) => {
      let data = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.uploadedDataset = state.uploadedDataset.map(entity => {
        if (entity.id === data.id) {
          return data;
        }
        return entity;
      });
    },

    datasetUploaded: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.entities.push(action.payload);
      state.error = null;
    },

    clearDatasetById: (state, action) => {
      state.datasetFetchedById = "";
    }
  }
});
