import { Route } from "react-router-dom";
import React from "react";
import { ModelUIProvider } from "./ModelUIContext";
import { ModelCard } from "./ModelCard";
import { ModelEditDialog } from "./model-edit-dialog/ModelEditDialog";
import { ModelStatusDialog } from "./model-status-dialog/ModelStatusDialog";
import { ModelDetailsDialog } from "./model-details-dialog/ModelDetailsDialog";

export function ModelPage({ history }) {
  const modelPageBaseUrl = "/models/modelPage";

  const modelUIEvents = {
    newModelButtonClick: () => {
      history.push(`${modelPageBaseUrl}/new`);
    },
    openChangeStatusDialog: (id, status) => {
      history.push(`${modelPageBaseUrl}/${id}/${status}/changeStatus`);
    },
    openEditModelDialog: id => {
      history.push(`${modelPageBaseUrl}/${id}/edit`);
    },
    openViewModelDialog: id => {
      history.push(`${modelPageBaseUrl}/${id}/view`);
    }
  };

  return (
    <ModelUIProvider modelUIEvents={modelUIEvents}>
      <Route path={`${modelPageBaseUrl}/new`}>
        {({ history, match }) => (
          <ModelEditDialog
            id={null}
            show={match != null}
            onHide={() => {
              history.push(modelPageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${modelPageBaseUrl}/:id/edit`}>
        {({ history, match }) => (
          <ModelEditDialog
            show={match != null}
            id={match?.params?.id}
            onHide={() => {
              history.push(modelPageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${modelPageBaseUrl}/:id/view`}>
        {({ history, match }) => (
          <ModelDetailsDialog
            show={match != null}
            id={match?.params?.id}
            onHide={() => {
              history.push(modelPageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${modelPageBaseUrl}/:id/:status/changeStatus`}>
        {({ history, match }) => (
          <ModelStatusDialog
            show={match != null}
            id={match?.params?.id}
            status={match?.params?.status}
            onHide={() => {
              history.push(modelPageBaseUrl);
            }}
          />
        )}
      </Route>
      <ModelCard />
    </ModelUIProvider>
  );
}
