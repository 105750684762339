import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import Card from "@material-ui/core/Card";
import {CardContent, CardMedia, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: "flex"
  // },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width: 151
  }
}));

// const projectType = [
//   { value: "team-managed", label: "Team-Managed" },
//   { value: "student", label: "student" }
// ];

export function ProjectCreate() {
  const classes = useStyles();

  const [formData, setFormData] = useState({});

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2> Create Project</h2>
        </div>
        <div>
          <h6>You can these details anytime in your project setting.</h6>
        </div>
      </div>

      <div className={"centered-element"}>
        <Form>
          <Row>
            <Col xl={6} xs={12} md={6} lg={6} sm={12}>
              <Form.Group controlId="projectName">
                <Form.Label column sm={12}>
                  Project Name
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="text"
                    name="projectName"
                    placeholder="Project Name"
                    // value={formData.locationName}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xl={6} xs={12} md={6} lg={6} sm={12}>
              <Form.Group controlId="projectDecryption">
                <Form.Label sm={12}>Project Decryption</Form.Label>
                <Col sm={12}>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Form>

        <div className="scrollbar-project-create" id="style-2">
          <div className="force-overflow-project-create">
            <Row className={"mb-5"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className={"mb-2"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className={"mb-2"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className={"mb-2"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className={"mb-5"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className={"mb-2"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className={"mb-2"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className={"mb-2"}>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
              <Col xl={6} lg={6} md={6} xs={12} sm={12}>
                <Card className={"d-flex"}>
                  <CardMedia
                    className={classes.cover}
                    image="https://tusker-ui.s3.ap-south-1.amazonaws.com/image/hh1.png"
                    title="Live from space album cover"
                  />
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Typography component="h5" variant="h5">
                        Live From Space
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Mac Miller
                      </Typography>
                    </CardContent>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
