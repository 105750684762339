import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import ModalFooterCommon from "../../../../shared/components/ModalFooterCommon";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import DropDownMenuCommon from "../../../../shared/components/DropDownMenuCommon";
import * as modelAction from "../../../SuperAdmin/ModelManagement/_redux/ModelAction";
import * as projectAction from "../../../../redux/actions/projectManagementAction";
import * as hyperParamsAction from "../../../../redux/actions/hyperParamsManagementAction";
import * as annotationTypeAction from "../../../SuperAdmin/AnnotationType/_redux/AnnotationTypeAction";

import {shallowEqual, useDispatch, useSelector} from "react-redux";
import InfoIcon from "@material-ui/icons/Info";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import {omit} from "lodash";
import * as datasetAction from "../../../../redux/actions/DatasetManagementAction";

function AddEditExperimentModal({
                                    saveExperiment,
                                    show,
                                    handleClose,
                                    editModalData,
                                    setEditModalData
                                }) {
    const [addExperiment, setExperiment] = useState({
        experiment_name: "",
        experiment_description: "",
        experiment_status: "Pending",
        model_id: "",
        project_id: "",
        hyper_params_id: "",
        annotation_type_id: "",
        dataset_id: "",
        status: true,
        id: ""
    });
    const [error, setErrors] = useState({
        experiment_name: "",
        experiment_description: "",
        experiment_status: "",
        model_id: "",
        project_id: "",
        hyper_params_id: "",
        annotation_type_id: "",
        dataset_id: "",
        status: ""
    });
    const [flag, setFlag] = useState(true);
    const [modelsOptions, setModelsOptions] = useState([]);
    const [projectsOptions, setProjectsOptions] = useState([]);
    const [hyperParamsOptions, setHyperParamsOptions] = useState([]);
    const [annotationTypesOptions, setAnnotationTypesOptions] = useState([]);
    const [datasetsOptions, setDatasetsOptions] = useState([]);

    const dispatch = useDispatch();

    const {
        models,
        projects,
        hyperParams,
        annotationTypes,
        datasets
    } = useSelector(
        state => ({
            models: state?.model?.entities,
            projects: state?.projectManagement?.entities,
            projectsList: state?.projectManagement,
            hyperParams: state?.hyperParamsManagement?.entities,
            annotationTypes: state?.annotationType?.entities,
            datasets: state?.datasetManagement?.entities
        }),
        shallowEqual
    );

    useEffect(() => {
        if (!show) {
            setFlag(true);

            setExperiment({
                experiment_name: "",
                experiment_description: "",
                experiment_status: "Pending",
                model_id: "",
                project_id: "",
                hyper_params_id: "",
                annotation_type_id: "",
                dataset_id: "",
                status: true,
                id: ""
            });
            setEditModalData({
                experiment_name: "",
                experiment_description: "",
                experiment_status: "Pending",
                model_id: "",
                project_id: "",
                hyper_params_id: "",
                annotation_type_id: "",
                dataset_id: "",
                status: true,
                id: ""
            });
            setErrors({
                experiment_name: "",
                experiment_description: "",
                model_id: "",
                project_id: "",
                hyper_params_id: "",
                annotation_type_id: "",
                dataset_id: ""
            });
        }
    }, [show]);

    const validation = (name, e) => {
        switch (name) {
            case "experiment_name":
                if (e.target.value === "") {
                    setErrors({
                        ...error, [name]: "Experiment Name must be required.",
                    })
                } else if (new RegExp(/^\s+|\s+$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Experiment Name cannot contain space.",
                    })
                } else if (!new RegExp(/^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()_-]+$/).test(e.target.value)) {
                    setErrors({
                        ...error, [name]: "Special characters are not allowed.",
                    })
                } else if (e.target.value.length < 3) {
                    setErrors({
                        ...error, [name]: "Minimum 3 characters are allowed.",
                    })
                } else if (e.target.value.length > 50) {
                    setErrors({
                        ...error, [name]: "Maximum 50 characters are allowed.",
                    })
                } else {
                    let newObj = omit(error, "experiment_name");
                    setErrors(newObj);
                }

                break;
            case "experiment_description":
                if (e.target.value === "") {
                    setErrors({
                        ...error,
                        [name]: " Experiment Description must be required."
                    });
                } else if (
                    !new RegExp(/(.*[a-z]){3}/i).test(e.target.value)
                ) {
                    setErrors({
                        ...error,
                        [name]: "Only normal characters are allowed."
                    });
                } else {
                    let newObj = omit(error, "experiment_description");
                    setErrors(newObj);
                }
                break;
        }
    };

    useEffect(() => {
        if (
            !error?.experiment_name &&
            !error?.experiment_description &&
            !error?.model_id &&
            !error?.project_id &&
            !error?.hyper_params_id &&
            !error?.annotation_type_id &&
            !error?.dataset_id
        ) {
            if (
                addExperiment?.experiment_name &&
                addExperiment?.experiment_description &&
                addExperiment?.model_id &&
                addExperiment?.project_id &&
                addExperiment?.hyper_params_id &&
                addExperiment?.annotation_type_id &&
                addExperiment?.dataset_id
            ) {
                setFlag(false);
            } else if (addExperiment?.id) {
                if (
                    !addExperiment?.experiment_name ||
                    !addExperiment?.experiment_description ||
                    !addExperiment?.model_id ||
                    !addExperiment?.project_id ||
                    !addExperiment?.hyper_params_id ||
                    !addExperiment?.annotation_type_id ||
                    !addExperiment?.dataset_id
                ) {
                    setFlag(true);
                } else if (
                    addExperiment?.experiment_name ||
                    addExperiment?.experiment_description ||
                    addExperiment?.model_id ||
                    addExperiment?.project_id ||
                    addExperiment?.hyper_params_id ||
                    addExperiment?.annotation_type_id ||
                    addExperiment?.dataset_id
                ) {
                    setFlag(false);
                }
            }
        } else {
            setFlag(true);
        }
    }, [addExperiment]);

    useEffect(() => {
        let modelsOptions = [];
        models &&
        models.map(obj =>
            modelsOptions.push({label: obj?.model_name, value: obj?.id})
        );
        setModelsOptions(modelsOptions);
    }, [models]);

    useEffect(() => {
        let projectsOptions = [];
        projects &&
        projects.map(obj =>
            projectsOptions.push({label: obj?.project_name, value: obj?.id})
        );
        setProjectsOptions(projectsOptions);
    }, [projects]);

    useEffect(() => {
        let hyperParamsOptions = [];
        hyperParams &&
        hyperParams.map(obj =>
            hyperParamsOptions.push({
                label: obj?.hyper_parameter_name,
                value: obj?.id
            })
        );
        setHyperParamsOptions(hyperParamsOptions);
    }, [hyperParams]);

    useEffect(() => {
        let annotationTypesOptions = [];
        annotationTypes &&
        annotationTypes.map(obj =>
            annotationTypesOptions.push({
                label: obj?.annotation_type_name,
                value: obj?.id
            })
        );
        setAnnotationTypesOptions(annotationTypesOptions);
    }, [annotationTypes]);

    useEffect(() => {
        let DatasetsOptions = [];
        datasets &&
        datasets.map(obj =>
            DatasetsOptions.push({label: obj?.dataset_name, value: obj?.id})
        );
        setDatasetsOptions(DatasetsOptions);
    }, [datasets]);
    useEffect(() => {
        if (
            show &&
            editModalData?.id &&
            modelsOptions &&
            projectsOptions &&
            hyperParamsOptions &&
            annotationTypesOptions &&
            datasetsOptions
        ) {
            let selectedModel = {};
            if (modelsOptions && modelsOptions.length > 0) {
                for (let i = 0; i < modelsOptions.length; i++) {
                    let option = modelsOptions[i];
                    if (
                        editModalData?.model_details?.model_name?.includes(option.label)
                    ) {
                        selectedModel = option;
                    }
                }
            }

            let selectedProject = {};

            if (projectsOptions && projectsOptions.length > 0) {
                for (let i = 0; i < projectsOptions.length; i++) {
                    let option = projectsOptions[i];
                    if (editModalData?.project_details?.project_name === option.label) {
                        selectedProject = option;
                    }
                }
            }

            let selectedHyperParameter = {};
            if (hyperParamsOptions && hyperParamsOptions.length > 0) {
                for (let i = 0; i < hyperParamsOptions.length; i++) {
                    let option = hyperParamsOptions[i];
                    if (
                        editModalData?.hyper_params_details?.hyper_parameter_name?.includes(
                            option.label
                        )
                    ) {
                        selectedHyperParameter = option;
                    }
                }
            }

            let selectedAnnotationType = {};
            if (annotationTypesOptions && annotationTypesOptions.length > 0) {
                for (let i = 0; i < annotationTypesOptions.length; i++) {
                    let option = annotationTypesOptions[i];
                    if (
                        editModalData?.annotation_type_details?.annotation_type_name?.includes(
                            option.label
                        )
                    ) {
                        selectedAnnotationType = option;
                    }
                }
            }

            let selectedDatasetDetails = {};
            if (datasetsOptions && datasetsOptions.length > 0) {
                for (let i = 0; i < datasetsOptions.length; i++) {
                    let option = datasetsOptions[i];
                    if (
                        editModalData?.dataset_details?.dataset_name?.includes(option.label)
                    ) {
                        selectedDatasetDetails = option;
                    }
                }
            }

            let data = {
                experiment_name: editModalData?.experiment_name,
                experiment_description: editModalData?.experiment_description,
                experiment_status: editModalData?.experiment_status || "Pending",
                model_id: selectedModel,
                project_id: selectedProject,
                hyper_params_id: selectedHyperParameter,
                annotation_type_id: selectedAnnotationType,
                dataset_id: selectedDatasetDetails,
                status: true,
                id: editModalData.id || null
            };

            setExperiment(data);
        }
    }, [
        editModalData,
        modelsOptions,
        projectsOptions,
        hyperParamsOptions,
        annotationTypesOptions,
        datasetsOptions
    ]);

    useEffect(() => {
        if (show) {
            dispatch(modelAction.fetchEnabledModels());
            dispatch(projectAction.fetchProjectsForDropDown());
            dispatch(hyperParamsAction.fetchHyperParamsForDropDowns());
            dispatch(annotationTypeAction.fetchEnabledAnnotationTypes());
            dispatch(datasetAction.fetchDatasetsForDropDown());
        }
    }, [show]);

    const changeExperimentData = e => {
        let value = e.target.value;
        let name = e.target.name;
        let formData = {...addExperiment};
        formData[name] = value;
        setExperiment(formData);
        validation(name, e);
    };

    const handleModelsChange = model => {
        let data = {...addExperiment};
        data["model_id"] = model;
        setExperiment(data);
    };
    const handleProjecetChange = project => {
        let data = {...addExperiment};
        data["project_id"] = project;
        setExperiment(data);
    };

    const handleHyperParameterChange = hyperParameter => {
        let data = {...addExperiment};
        data["hyper_params_id"] = hyperParameter;
        setExperiment(data);
    };

    const handleTargetAnnotationChange = targetAnnotation => {
        let data = {...addExperiment};
        data["annotation_type_id"] = targetAnnotation;
        setExperiment(data);
    };

    const handleDatasetChange = dataset => {
        let data = {...addExperiment};
        data["dataset_id"] = dataset;
        setExperiment(data);
    };

    const submitExperiment = () => {
        const data = {
            experiment_name: addExperiment.experiment_name.trim() || "",
            experiment_description: addExperiment.experiment_description.trim(),
            experiment_status: addExperiment.experiment_status.trim() || "Pending",
            model_id: addExperiment.model_id.value || "",
            project_id: addExperiment.project_id.value || "",
            hyper_params_id: addExperiment.hyper_params_id.value || "",
            annotation_type_id: addExperiment.annotation_type_id.value || "",
            dataset_id: addExperiment.dataset_id.value || "",
            status: addExperiment.status || true,
            id: addExperiment?.id || null
        };

        saveExperiment(data);
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                scrollable={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalHeaderCommon
                    title={`${
                        addExperiment && addExperiment?.id ? "Edit " : "Add New"
                    } Experiment`}
                />
                <Modal.Body>
                    <div className={"mb-5 theme-color"}>
                        <Form.Label>
                            Experiment Name <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={
                                    <div className="tools">
                                        The Name Of Your Deep Learning Model Ex. Person Detection.
                                    </div>
                                }
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={`form-control`}
                            name="experiment_name"
                            placeholder="Experiment Name"
                            value={addExperiment["experiment_name"]}
                            onChange={changeExperimentData}
                            maxLength={"51"}
                            onBlur={e => validation("experiment_name", e)}
                        />
                        <div className={"input-validation-star"}>
                            {error["experiment_name"]}
                        </div>
                    </div>

                    <div className={"mb-5 theme-color"}>
                        <Form.Label>
                            Experiment Description{" "}
                            <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={
                                    <div className="tools">
                                        Model's General Information Ex. Person Detection Model Will
                                        Detect Person From Input Image Or Video{" "}
                                    </div>
                                }
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <textarea
                            type="text"
                            className={`form-control`}
                            name="experiment_description"
                            placeholder="Experiment Description"
                            value={addExperiment["experiment_description"]}
                            onChange={changeExperimentData}
                            maxLength={"250"}
                            onBlur={e => validation("experiment_description", e)}
                            rows="6"
                        />
                        <div>
                            <div className={"float-left input-validation-star"}>
                                {error["experiment_description"]}
                            </div>
                            <span
                                className={`float-right ${
                                    addExperiment?.experiment_description?.length === 250
                                        ? "input-validation-star"
                                        : ""
                                }`}
                            >
                {250 - addExperiment?.experiment_description?.length} characters
                left
              </span>
                        </div>
                    </div>
                    <div className={"mb-5 mt-9   theme-color"}>
                        <Form.Group className="">
                            <Form.Label className="">
                                Model<span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            A “Model” In Machine Learning Is The Output Of A Machine
                                            Learning Algorithm Run On Data.a Model Represents What Was
                                            Learned By A Machine Learning Algorithm Using Historical
                                            Data.
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Form.Label>
                            <DropDownMenuCommon
                                isMulti={false}
                                isLoading={false}
                                placeholder="Select Model"
                                value={addExperiment["model_id"]}
                                onChange={handleModelsChange}
                                options={modelsOptions}
                                maxMenuHeight={95}
                            />
                        </Form.Group>
                    </div>

                    <div className={"mb-5 theme-color"}>
                        <Form.Group className="">
                            <Form.Label className="">
                                Project<span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            Project Is Detection System Which May Use More Than One
                                            Model For Detection From Image And Video Input.
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Form.Label>
                            <DropDownMenuCommon
                                isMulti={false}
                                isLoading={false}
                                placeholder="Select Project"
                                value={addExperiment["project_id"]}
                                onChange={handleProjecetChange}
                                options={projectsOptions}
                                maxMenuHeight={95}
                            />
                        </Form.Group>
                    </div>

                    <div className={"mb-5 theme-color"}>
                        <Form.Group className="">
                            <Form.Label className="">
                                Hyper Parameter
                                <span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            A Hyper parameter Is A Parameter Whose Value Is Used To
                                            Control The Learning Process Of A Model.such Examples Of
                                            Hyperparameters Are Learning Rate And Mini-batch Size.
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Form.Label>
                            <DropDownMenuCommon
                                isMulti={false}
                                isLoading={false}
                                placeholder="Select Hyper Parameter"
                                value={addExperiment["hyper_params_id"]}
                                onChange={handleHyperParameterChange}
                                options={hyperParamsOptions}
                                maxMenuHeight={95}
                            />
                        </Form.Group>
                    </div>

                    <div className={"mb-5 theme-color"}>
                        <Form.Group className="">
                            <Form.Label className="">
                                Target Annotation Type
                                <span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            Annotation Type Of Your Dataset Like Pascal (xml) Or Yolo
                                            (txt)
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Form.Label>
                            <DropDownMenuCommon
                                isMulti={false}
                                isLoading={false}
                                placeholder="Select Target Annotation Type"
                                value={addExperiment["annotation_type_id"]}
                                onChange={handleTargetAnnotationChange}
                                options={annotationTypesOptions}
                                maxMenuHeight={95}
                            />
                        </Form.Group>
                    </div>

                    <div className={"mb-5 theme-color"}>
                        <Form.Group className="">
                            <Form.Label className="">
                                Dataset<span className={"input-validation-star"}>*</span>
                                <Tooltip
                                    title={
                                        <div className="tools">
                                            A Dataset (or Data Set) Is A Collection Of Data In Various
                                            Forms Like Text,image,video,voice,etc.
                                        </div>
                                    }
                                    placement={"right"}
                                >
                                    <InfoIcon/>
                                </Tooltip>
                            </Form.Label>
                            <DropDownMenuCommon
                                isMulti={false}
                                isLoading={false}
                                placeholder="Select Dataset"
                                value={addExperiment["dataset_id"]}
                                onChange={handleDatasetChange}
                                options={datasetsOptions}
                                maxMenuHeight={95}
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <ModalFooterCommon
                    handleClose={handleClose}
                    submitEmployee={e => submitExperiment(e)}
                    id={addExperiment ? addExperiment?.id : ""}
                    flag={flag}
                />
            </Modal>
        </>
    );
}

export default AddEditExperimentModal;
