import React from "react";

export function ProjectStatusColumnFormatter(
  cellContent,
  row,
  rowIndex
) {
  return (
    <>
      {row.project_status.toLowerCase() === "pending" ? (
        <span className="label label-lg label-light-danger label-inline">
          pending
        </span>
      ) : row.project_status.toLowerCase() === "in-progress" ? (
        <span className="label label-lg label-light-warning label-inline font-weight-bold py-4">
          processing
        </span>
      ) : row.project_status.toLowerCase() === "completed" ? (
        <span className="label label-lg label-light-success label-inline">
          completed
        </span>
      ) : (
        <span className="label label-lg label-light-success label-inline">
          {row.project_status.toLowerCase()}
        </span>
      )}
    </>
  );
}
