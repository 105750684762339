import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ProjectManagementEditDialogHeader } from "./ProjectManagementEditDialogHeader";
import { ProjectManagementEditForm } from "./ProjectManagementEditForm";
import { ProjectManagementAdminSlice } from "../../_redux/ProjectManagementAdminSlice";
import * as action from "../../_redux/ProjectManagementAdminAction";
import { SavingDetailsModal } from "../../../../../shared/components/SavingDetailsModal";
import { successToast } from "../../../../../shared/components/ToastMessage";

const { actions } = ProjectManagementAdminSlice;

export function ProjectManagementEditDialog({ id, show, onHide }) {
  const { actionsLoading, projectFetchedById } = useSelector(
    state => ({
      actionsLoading: state.projectManagementAdmin.actionsLoading,
      projectFetchedById: state.projectManagementAdmin.projectFetchedById
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== null && id !== undefined) {
      dispatch(action.fetchProjectById(id));
    } else {
      dispatch(actions.clearProjectById());
    }
  }, [id, dispatch]);

  const [loading, setLoading] = useState(false);
  const saveProject = project => {
    setLoading(true);
    if (id) {
      dispatch(action.projectUpdate(project)).then(() => {
        successToast("Project Details updated successfully");
        dispatch(action.fetchProjects());
        onHide();
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <SavingDetailsModal show={loading} top={"start"} />
      <ProjectManagementEditDialogHeader id={id} />
      <ProjectManagementEditForm
        saveProject={saveProject}
        actionsLoading={actionsLoading}
        projectData={projectFetchedById}
        onHide={onHide}
      />
    </Modal>
  );
}
