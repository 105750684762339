import React from "react";
import Select from "react-select";

const customStyles = {
    option: styles => ({
        ...styles,
        cursor: "pointer"
    }),
    control: styles => ({
        ...styles,
        cursor: "pointer"
    })
};

function DropDownMenuCommon({
                                isMulti,
                                isLoading,
                                placeholder,
                                value,
                                onChange,
                                options,
                                maxMenuHeight
                            }) {
    return (
        <>
            <Select
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                    }
                })}
                styles={customStyles}
                isMulti={isMulti}
                isLoading={isLoading}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                options={options}
                maxMenuHeight={maxMenuHeight}
            />
        </>
    );
}

export default DropDownMenuCommon;
