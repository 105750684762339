import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import DropDownMenuCommon from "../../../../shared/components/DropDownMenuCommon";
import {UploadDatasetType} from "../../../../shared/enums/dropDown.enum";
import ImageUploader from "react-images-upload";
import {successToast, warningToast} from "../../../../shared/components/ToastMessage";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import BlockUi from "react-block-ui";
import RTSPDataSetUpload from "./RTSPDataSetUpload";
import {useDispatch} from "react-redux";
import * as action from "../../../../redux/actions/DatasetManagementAction";

const UPLOAD_STATUS_UPLOADING = "uploading";

function UploadDatasetModal({show, handleClose}) {
    const dispatch = useDispatch();
    const [addDataset, setAddDataset] = useState({
        dataset_name: null,
        dataset_description: null,
        fps: null
    });
    const [error, setErrors] = useState({
        dataset_name: null,
        dataset_description: null,
        fps: null
    });
    const [datasetType, setDatasetType] = useState("");
    const [showPreviewImage, setShowPreviewImage] = useState(false);
    const [filePicture, setFilePicture] = useState([]);
    const [videoFiles, setvideoFiles] = useState([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [imagePrivewLoader, setImagePrivewLoader] = useState(false);
    const [ShowRTSPModal, setShowRTSPModal] = useState(false);
    const [remove, setRemove] = useState(false);
    const [rtspList, setRtspList] = useState(false);
    const [flag, setFlag] = useState(true);

    const handleUploadDatasetType = type => {
        if (type?.value === 1) {
            setDatasetType(type);
            setFilePicture([]);
            setvideoFiles([]);
        } else if (type?.value === 2) {
            setDatasetType(type);
            setFilePicture([]);
            setvideoFiles([]);
        } else if (type?.value === 3) {
            setDatasetType(type);
            setFilePicture([]);
            setvideoFiles([]);
        }
    };
    useEffect(() => {
        let is_valid = validate({...addDataset});
        is_valid ? setFlag(false) : setFlag(true);
    }, [filePicture, datasetType, rtspList, addDataset.fps]);
    const onDropImages = (picture, _) => {
        setImagePrivewLoader(true);
        if (picture.length < 500) {
            setFilePicture([...picture]);
            setShowPreviewImage(true);
        } else {
            warningToast("You can upload maximum 500 images");
        }
        setImagePrivewLoader(false);
    };

    const handleFile = e => {
        const files = [...e.target.files].filter(x => x.type.includes("video"));
        const length = filePicture.length;
        const newFile = files.map((file, ind) => {
            const newFile = {
                id: length + ind,
                type: getPreviewType(file.type, file.name),
                mime: file.type,
                name: file.name,
                size: file.size,
                url: window.URL.createObjectURL(file),
                progress: 0,
                status: UPLOAD_STATUS_UPLOADING,
                file
            };
            return newFile;
        });

        setvideoFiles([...videoFiles, ...newFile]);
        setFilePicture([...filePicture, ...files]);
    };

    const handleRemoveFile = id => {
        const index = videoFiles.findIndex(f => f.id === id);
        const file_name = videoFiles.find(f => f.id === id).name;
        const file_index = filePicture.findIndex(f => f.name === file_name);
        window.URL.revokeObjectURL(videoFiles[index].url);

        setFilePicture([
            ...filePicture.slice(0, file_index),
            ...filePicture.slice(file_index + 1)
        ]);
        setvideoFiles([
            ...videoFiles.slice(0, index),
            ...videoFiles.slice(index + 1)
        ]);
    };

    const handleRtspModal = () => {
        setShowRTSPModal(true);
    };
    const handleCloseRTSPModal = () => {
        setShowRTSPModal(false);
    };

    const validate = formData => {
        let addUploadDataset = {...formData};

        let errors = {};
        let isValid = true;
        if (
            addUploadDataset.dataset_name === null ||
            addUploadDataset.dataset_description === null ||
            addUploadDataset.dataset_name === undefined ||
            addUploadDataset.dataset_description === undefined
        ) {
            isValid = false;
        }
        if (addUploadDataset["dataset_name"] === "" && addUploadDataset.project_name !== null) {
            isValid = false;
            errors["dataset_name"] = "Dataset Name must be required.";
        } else if (addUploadDataset["dataset_name"] && addUploadDataset["dataset_name"].match(/^\s+|\s+$/)) {
            isValid = false;
            errors["dataset_name"] = "Dataset Name cannot contain space.";
        } else if (addUploadDataset["dataset_name"] && !addUploadDataset["dataset_name"].match(/^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9\s()_-]+$/)) {
            isValid = false;
            errors["dataset_name"] = "Special characters are not allowed.";
        } else if (addUploadDataset["dataset_name"] && addUploadDataset["dataset_name"].length < 3) {
            isValid = false;
            errors["dataset_name"] = "Minimum 3 characters are allowed.";
        } else if (addUploadDataset["dataset_name"] && addUploadDataset["dataset_name"].length > 50) {
            isValid = false;
            errors["dataset_name"] = "Maximum 50 characters are allowed.";
        }

        if (
            addUploadDataset["dataset_description"] === "" &&
            addUploadDataset.dataset_description !== null
        ) {
            errors["dataset_description"] = "Dataset Description must be required.";
            isValid = false;
        } else if (
            addUploadDataset["dataset_description"] &&
            !addUploadDataset["dataset_description"].match(/(.*[a-z]){3}/i)
        ) {
            errors["dataset_description"] = "Dataset Description should at least contain 3 characters.";
            isValid = false;
        }
        if (datasetType.label !== "rtsp") {
            if (filePicture.length === 0) {
                isValid = false;
            }
        } else {
            if (rtspList.length === 0 || rtspList.length === undefined) {
                isValid = false;
            }
        }
        if (datasetType.label === "video" && !addUploadDataset.fps) {

            isValid = false;
        }
        if (addUploadDataset.fps === "" && addUploadDataset.fps !== null) {
            errors["fps"] = "FPS must be required.";
        }
        if (addUploadDataset.fps && !addUploadDataset.fps.match(/^([0-9]*)$/)) {
            errors["fps"] = "Please enter only digit.";
        }
        setErrors(errors);

        return isValid;
    };

    const handleUpload = () => {
        if (!validate()) {

            let file_type = datasetType?.label;
            let id, dataset_name;
            let newdata = {
                dataset_name: addDataset?.dataset_name.trim(),
                dataset_description: addDataset?.dataset_description.trim()
            };
            if (file_type !== "rtsp") {
                if (
                    (addDataset?.dataset_name && addDataset?.dataset_description,
                    filePicture.length > 0)
                ) {
                    try {
                        successToast("Upload process started");
                        dispatch(action.createUploadedDataset(newdata)).then(res => {
                            if (res?.payload) {
                                id = res.payload.id;
                                dataset_name = res.payload.dataset_name;
                            }
                            dispatch(
                                action.uploadDatasetFiles(
                                    id,
                                    dataset_name,
                                    filePicture,
                                    file_type,
                                    addDataset?.fps
                                )
                            );
                            dispatch(action.fetchUploadedDatasets());
                        });
                        handleClose();
                    } catch (e) {
                        warningToast("Can not create upload dataset");
                        setFilePicture([]);
                    }
                }
            } else {
                let id;
                if (
                    addDataset?.dataset_name &&
                    addDataset?.dataset_description &&
                    rtspList.length > 0
                ) {
                    try {
                        successToast("Upload process started");
                        dispatch(action.createUploadedDataset(newdata)).then(res => {
                            if (res?.payload) {
                                id = res.payload.id;
                                dataset_name = res.payload.dataset_name;
                            }
                            dispatch(action.createAddImagesRtsp(rtspList, id, dataset_name));
                            dispatch(action.fetchUploadedDatasets());
                            handleClose();
                        });
                    } catch (e) {
                        warningToast("Can not create upload dataset");
                    }
                }
            }
        }
    };
    useEffect(() => {
        if (!show) {
            setFlag(true);
            setAddDataset({
                dataset_name: null,
                dataset_description: null,
                fps: null
            });
            setFilePicture([]);
            setvideoFiles([]);
            setDatasetType("");
        }
    }, [show]);

    const changeUploadDatasetData = e => {
        let value = e.target.value;
        let name = e.target.name;
        let formData = {...addDataset};
        formData[name] = value;
        setAddDataset(formData);
        let is_valid = validate(formData);
        is_valid ? setFlag(false) : setFlag(true);
    };

    const handleRemove = () => {
        setAddDataset({dataset_name: null, dataset_description: null, fps: null});
        setImageLoader(true);
        setFilePicture([]);
        setvideoFiles([]);
        // setAddDataset({});
        setRemove(!remove);
        setTimeout(() => {
            setImageLoader(false);
        }, 100);

    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                scrollable={true}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalHeaderCommon title={"Add Upload Dataset"}/>
                <Modal.Body style={{height: "330px"}}>
                    <Row>
                        <Col xl={3} lg={3}>
                            <div className={"mb-5 theme-color"}>
                                <Form.Label>
                                    Dataset Name{" "}
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        className={"theme-color"}
                                        title={
                                            <div className="tools">The Name Of The Dataset Name</div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`form-control`}
                                    name="dataset_name"
                                    placeholder="Dataset Name"
                                    value={
                                        addDataset["dataset_name"] ? addDataset["dataset_name"] : ""
                                    }
                                    maxLength={"50"}
                                    onChange={e => changeUploadDatasetData(e)}
                                    onBlur={e => changeUploadDatasetData(e)}
                                />
                                <div className={"input-validation-star"}>
                                    {error["dataset_name"]}
                                </div>
                            </div>
                        </Col>

                        <Col xl={3} lg={3}>
                            <div className={"mb-5 theme-color"}>
                                <Form.Label>
                                    Dataset Description{" "}
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Model's General Information Ex. Person Detection Model
                                                Will Detect Person From Input Image Or Video{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </Form.Label>
                                <textarea
                                    type="text"
                                    className={`form-control`}
                                    name="dataset_description"
                                    placeholder="Dataset Description"
                                    value={
                                        addDataset["dataset_description"]
                                            ? addDataset["dataset_description"]
                                            : ""
                                    }
                                    onChange={e => changeUploadDatasetData(e)}
                                    maxLength={"250"}
                                    onBlur={e => changeUploadDatasetData(e)}
                                    rows="1"
                                />
                                <div>
                                    <div className={"float-left input-validation-star"}>
                                        {error["dataset_description"]}
                                    </div>
                                    <span
                                        className={`float-right ${
                                            addDataset?.dataset_description?.length === 250
                                                ? "input-validation-star"
                                                : ""
                                        }`}
                                    >
                    {250 - (addDataset?.dataset_description?.length ? addDataset?.dataset_description?.length : 0)} characters left
                  </span>
                                </div>
                            </div>
                        </Col>

                        <Col xl={3} lg={3}>
                            <div className={"mb-5 theme-color"}>
                                <Form.Group className="">
                                    <Form.Label className="">
                                        Upload Type
                                        <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            title={
                                                <div className="tools">
                                                    An Optimizer Is A Method Or Algorithm To Update The
                                                    Various Parameters That Can Reduce The Loss In Much
                                                    Less Effort.
                                                </div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Form.Label>
                                    <DropDownMenuCommon
                                        isMulti={false}
                                        isLoading={false}
                                        placeholder="Select  Upload Type"
                                        value={datasetType}
                                        onChange={handleUploadDatasetType}
                                        options={UploadDatasetType}
                                    />
                                </Form.Group>
                            </div>
                        </Col>

                        <Col xl={3} lg={3}>
                            {datasetType?.value === 2 ? (
                                <div className={"mb-5 theme-color"}>
                                    <Form.Label>
                                        FPS <span className={"input-validation-star"}>*</span>
                                        <Tooltip
                                            className={"theme-color"}
                                            title={
                                                <div className="tools">
                                                    The Name Of The Dataset Name
                                                </div>
                                            }
                                            placement={"right"}
                                        >
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Form.Label>

                                    <Form.Control
                                        type="text"
                                        className={`form-control`}
                                        name="fps"
                                        placeholder="FPS"
                                        value={addDataset["fps"] ? addDataset["fps"] : ""}
                                        maxLength={"50"}
                                        onChange={e => changeUploadDatasetData(e)}
                                        onBlur={e => changeUploadDatasetData(e)}
                                    />
                                    <div className={"input-validation-star"}>{error["fps"]}</div>
                                </div>
                            ) : (
                                datasetType?.value === 3 && (
                                    <div className={"mt-4"}>
                                        <Button
                                            variant="primary"
                                            className={"mt-5 col-6"}
                                            onClick={() => handleRtspModal()}
                                        >
                                            Add RTSP
                                        </Button>
                                    </div>
                                )
                            )}
                        </Col>
                    </Row>

                    <BlockUi tag="div" blocking={imagePrivewLoader} color="#147b82">
                        <Row>
                            {datasetType?.value === 1 && !imageLoader ? (
                                <ImageUploader
                                    className="fileContainer uploadPictureContainer deleteImage chooseFileButton"
                                    withIcon={true}
                                    withLabel={true}
                                    withPreview={showPreviewImage}
                                    label="Max file size: 2MB, Supported: .jpg .gif .png .svg"
                                    buttonText="Select Images"
                                    onChange={(e, p) => {
                                        onDropImages(e, p);
                                    }}
                                    imgExtension={[
                                        ".jpg",
                                        ".gif",
                                        ".png",
                                        ".gif",
                                        ".svg",
                                        ".jpeg"
                                    ]}
                                    maxFileSize={204857655555555}
                                    fileSizeError=" file size is too big"
                                />
                            ) : datasetType?.value === 2 ? (
                                <div
                                    className={"d-flex flex-column w-100 align-items-center mt-5"}
                                >
                                    <div className={"mt-5"}>
                                        <SVG
                                            title="upload icon"
                                            className={"text-center uploadDataset-image-width"}
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/General/uploadIcon.svg"
                                            )}
                                        />
                                    </div>
                                    <div className={"Upload-dataset-video-font"}>
                                        Supported Format : mp4 , AVI
                                    </div>

                                    <div className={""}>
                                        <FileUploadButton onChange={handleFile} accept={".mp4"}>
                                            Add Video File{" "}
                                        </FileUploadButton>
                                    </div>

                                    <FileUploadPreview
                                        files={videoFiles}
                                        fileTypePreviews={previewers}
                                        onRemoveFile={handleRemoveFile}
                                    />
                                </div>
                            ) : (
                                datasetType?.value === 3 && (
                                    <RTSPDataSetUpload
                                        showModal={ShowRTSPModal}
                                        setShow={setShowRTSPModal}
                                        handleClose={handleCloseRTSPModal}
                                        rtspList={rtspList}
                                        setRtspList={setRtspList}
                                        remove={remove}
                                    />
                                )
                            )}
                        </Row>
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleUpload();
                        }}
                        disabled={flag}
                    >
                        Upload
                    </Button>
                    <Button variant="danger" onClick={handleRemove}>
                        Reset
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadDatasetModal;

const FileUploadPreviewImage = ({name, url}) => (
    <img
        className="Upload-dataset-preview__item__image"
        src={url}
        alt={name}
        title={name}
    />
);

const FileUploadPreviewVideo = ({name, url}) => (
    <video
        className="Upload-dataset-preview__item__video"
        src={url}
        title={name}
        controls
    />
);

const FileUploadButton = ({children, ...props}) => (
    <div className="upload-dataset-btn">
        <button className="btn_of_upload">{children}</button>
        <input
            className="upload-dataset-btn__input"
            name="file"
            type="file"
            {...props}
            multiple
        />
    </div>
);

const previewers = {
    image: FileUploadPreviewImage,
    video: FileUploadPreviewVideo
};

const FileUploadPreview = ({
                               files = [],
                               fileTypePreviews = {},
                               onRemoveFile
                           }) =>
    files.length ? (
        <div className="Upload-dataset-preview">
            {files.map(file => {
                const Previewer =
                    fileTypePreviews[file.type] || FileUploadPreviewDefault;

                return (
                    <div
                        key={file.id}
                        className={`Upload-dataset-preview__item Upload-dataset-preview__item--${file.type}`}
                        onClick={() => onRemoveFile(file.id)}
                    >
                        <FileUploadStatus progress={file.progress} status={file.status}/>
                        {<Previewer {...file} />}
                    </div>
                );
            })}
        </div>
    ) : null;

const FileUploadPreviewDefault = ({name}) => name;
const FileUploadStatus = ({progress, status}) => (
    <div
        className={`Upload-dataset-preview__item__state Upload-dataset-preview__item__state--${status}`}
        style={{width: `${progress}%`}}
    />
);

const getPreviewType = (mime, name) => {
    const mimeParts = mime.toLowerCase().split("/");
    let type = mimeParts[0];
    if (type === "application") {
        type = mimeParts[1];
    }
    if (type.startsWith("x-") || type.includes("vnd")) {
        type = name.split(".").pop();
    }
    return type;
};
