import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import ModalFooterCommon from "../../../../shared/components/ModalFooterCommon";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import InfoIcon from "@material-ui/icons/Info";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import {omit} from "lodash";


function AddEditProjectModal({
                                 saveProject,
                                 show,
                                 handleClose,
                                 editModalData

                             }) {

    const [addProject, setAddProject] = useState({
        project_name: "",
        project_description: "",
        project_status: "Pending"
    });
    const [errors, setErrors] = useState({
        project_name: "",
        project_description: "",
        project_status: ""
    });
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        if (!show) {
            setFlag(true)

            setAddProject({
                project_name: "",
                project_description: "",
                project_status: "Pending"
            })
            setErrors({
                project_name: "",
                project_description: "",
                project_status: ""
            });
        }
    }, [show])

    const validate = (name, e) => {
        switch (name) {
            case "project_name":
                if (e.target.value === "") {

                    setErrors({
                        ...errors, [name]: "Project Name must be required.",
                    })
                } else if (new RegExp(/^\s+|\s+$/).test(e.target.value)) {
                    setErrors({
                        ...errors, [name]: "Project Name cannot contain space.",
                    })
                } else if (!new RegExp(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u).test(e.target.value)) {

                    setErrors({
                        ...errors, [name]: "Special characters and digits are not allowed.",
                    })
                } else if (e.target.value.length < 3) {
                    setErrors({
                        ...errors, [name]: "Minimum 3 characters are allowed.",
                    })
                } else if (e.target.value.length > 50) {
                    setErrors({
                        ...errors, [name]: "Maximum 50 characters are allowed.",
                    })
                } else {
                    let newObj = omit(errors, "project_name");
                    setErrors(newObj);
                }


                break;
            case "project_description":
                if (e.target.value === "") {

                    setErrors({
                        ...errors, [name]: "Project Description must be required.",
                    })
                } else {
                    let newObj = omit(errors, "project_description");
                    setErrors(newObj);
                }

                break;

        }
    };

    useEffect(() => {
        if (
            !errors?.project_name &&
            !errors?.project_description

        ) {
            if (
                addProject?.project_name &&
                addProject?.project_description
            ) {
                setFlag(false);
            } else if (addProject?.id) {
                if (
                    !addProject?.project_name ||
                    !addProject?.project_description
                ) {
                    setFlag(true);
                } else if (
                    addProject?.project_name ||
                    addProject?.project_description
                ) {
                    setFlag(false);
                }
            }

        } else {
            setFlag(true)
        }

    }, [addProject]);

    const changeProjectData = e => {
        let value = e.target.value;
        let name = e.target.name;
        let formData = {...addProject};
        formData[name] = value;
        setAddProject(formData);
        validate(name, e);

    };
    const submitEmployee = () => {
        const data = {
            project_name: addProject?.project_name.trim() || "",
            project_description: addProject?.project_description.trim() || "",
            project_status: addProject?.project_status || "Pending",
            status: addProject?.status === undefined ? true : addProject?.status,
            id: addProject?.id || null
        };
        saveProject(data);
    };


    useEffect(() => {
        if (show && editModalData?.id) {

            if (editModalData) {
                setFlag(false)
                setErrors({
                    project_name: "",
                    project_description: "",
                    project_status: ""
                })
            }
            setAddProject(editModalData);
        }

    }, [editModalData]);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                scrollable={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalHeaderCommon
                    title={`${addProject && addProject?.id ? "Edit " : "Add New"} Project`}
                />
                <Modal.Body>
                    <div className={"mb-5 theme-color"}>
                        <Form.Label>
                            Project Name <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={
                                    <div className="tools">
                                        Project Name Is Detection System Which May Use More Than One
                                        Model For Detection From Image And Video Input.ex:-object
                                        Detection.
                                    </div>
                                }
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={`form-control`}
                            name="project_name"
                            placeholder="Project Name"
                            value={addProject["project_name"]}
                            maxLength={"51"}
                            onChange={e => changeProjectData(e)}
                            onBlur={e => validate("project_name", e)}
                        />
                        <div className={"input-validation-star"}>
                            {errors["project_name"]}
                        </div>
                    </div>

                    <div className={"mb-5 theme-color"}>
                        <Form.Label>
                            Project Description{" "}
                            <span className={"input-validation-star"}>*</span>
                            <Tooltip
                                title={
                                    <div className="tools">
                                        Project's General Information Ex. Person Detection Model
                                        Will Detect Person From Input Image Or Video{" "}
                                    </div>
                                }
                                placement={"right"}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Form.Label>
                        <textarea
                            type="text"
                            className={`form-control`}
                            name="project_description"
                            placeholder="Project Description"
                            value={addProject["project_description"]}
                            onChange={e => changeProjectData(e)}
                            onBlur={e => validate("project_description", e)}
                            maxLength={"250"}
                            rows="6"
                        />
                        <div>
                            <spam className={"float-left input-validation-star"}>
                                {errors["project_description"]}
                            </spam>
                            <span
                                className={`float-right ${
                                    addProject?.project_description?.length === 250
                                        ? "input-validation-star"
                                        : ""
                                }`}
                            >
                {250 - (addProject?.project_description?.length ? addProject?.project_description?.length : 0)} characters left
              </span>
                        </div>
                    </div>
                </Modal.Body>

                <ModalFooterCommon
                    handleClose={handleClose}
                    submitEmployee={e => submitEmployee(e)}
                    id={addProject ? addProject?.id : ""}
                    flag={flag}
                />
            </Modal>
        </>
    );
}

export default AddEditProjectModal;
