import React, { useEffect } from "react";
import { HyperParamsManagementCard } from "./HyperParamsManagementCard";
import * as actions from "../../../../redux/actions/hyperParamsManagementAction";
import { useDispatch } from "react-redux";
export function HyperParamsManagementPageNew() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchHyperParams(1, 10, ""));
  }, []);

  return <HyperParamsManagementCard />;
}
