/* eslint-disable */
import { request } from "../shared/helper/RequestWrapper";
import { HttpRequest } from "../shared/enums/http.methods.enums";
export const REGISTER_COMPANY_URL = "/add_company";
export const GET_USER_COMPANY_INFO="/get_user_company_info"
export const UPDATE_USER_COMPANY_INFO="/update_user_company_info"


export function getUserCompanyInfo() {
  return request({
    endpoint: GET_USER_COMPANY_INFO,
    method: HttpRequest.GET,
  });
}
export function updateUserCompanyInfo(data) {
  return request({
    endpoint: UPDATE_USER_COMPANY_INFO,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}


export function registerCompany(data) {
  return request({
    headers: {
      project: "autoServing",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    endpoint: REGISTER_COMPANY_URL,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}
