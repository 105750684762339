import {Route} from "react-router-dom";
import React from "react";
import {FrameworkManagementUIProvider} from "./FrameworkManagementUIContext";
import {FrameworkManagementCard} from "./FrameworkManagementCard"
import {FrameworkManagementEditDialog} from "./framework-management-edit-dialog/FrameworkManagementEditDialog";
import {FrameworkManagementStatusDialog} from "./framework-management-status-dialog/FrameworkManagementStatusDialog";

export function FrameworkManagementPage({history}) {

    const frameworkManagementPageBaseUrl = "/frameworkManagement/frameworkManagementPage"

    const frameworkManagementUIEvents = {
        newFrameworkManagementButtonClick: () => {
            history.push(`${frameworkManagementPageBaseUrl}/new`);
        },
        openChangeFrameworkManagementStatusDialog: (id, status) => {
            history.push(`${frameworkManagementPageBaseUrl}/${id}/${status}/changeStatus`);
        },
        openEditFrameworkManagementDialog: (id) => {
            history.push(`${frameworkManagementPageBaseUrl}/${id}/edit`);
        }
    };

    return (
        <FrameworkManagementUIProvider frameworkManagementUIEvents={frameworkManagementUIEvents}>
            <Route path={`${frameworkManagementPageBaseUrl}/new`}>
                {({history, match}) => (
                    <FrameworkManagementEditDialog
                        show={match != null}
                        onHide={() => {
                            history.push(frameworkManagementPageBaseUrl);
                        }}
                    />
                )}
            </Route>
            <Route path={`${frameworkManagementPageBaseUrl}/:id/edit`}>
                {({history, match}) => (
                    <FrameworkManagementEditDialog
                        show={match != null}
                        id={match?.params?.id}
                        onHide={() => {
                            history.push(frameworkManagementPageBaseUrl);
                        }}
                    />
                )}
            </Route>
            <Route path={`${frameworkManagementPageBaseUrl}/:id/:status/changeStatus`}>
                {({history, match}) => (
                    <FrameworkManagementStatusDialog
                        show={match != null}
                        id={match?.params?.id}
                        status={match?.params?.status}
                        onHide={() => {
                            history.push(frameworkManagementPageBaseUrl);
                        }}
                    />
                )}
            </Route>
            <FrameworkManagementCard/>
        </FrameworkManagementUIProvider>
    );
}
