import React, { Suspense } from "react";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";
import {ProjectManagementPage} from "./component/ProjectManagementPage";

function ProjectManagement() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact={true}
            from="/user/projects"
            to="/user/projects/projectManagementPage"
          />
        }
        <ContentRoute
          path="/user/projects/projectManagementPage"
          component={ProjectManagementPage}
        />
      </Switch>
    </Suspense>
  );
}

export default ProjectManagement;
