import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { Checkbox, FormControlLabel } from "@material-ui/core";
const roleTags = [
  { value: "free", label: "Free", id: 1 },
  { value: "premium", label: "Premium", id: 2 },
  { value: "onprem", label: "On Premise", id: 3 }
];
export function CompanyMainPage() {
  const [formData, setFormData] = useState({})

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2>Registration Corporate</h2>
        </div>
        <div>
          <h6>* is.</h6>
        </div>
      </div>
      <div className={"centered-element"}>
        <div className="scrollbar" id="style-2">
          <div className="force-overflow">
            <Form>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyName">
                    <Form.Label column sm={12}>
                      Company Name
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="companyName"
                        placeholder="Company Name"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyEmail">
                    <Form.Label column sm={12}>
                      Company Email
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="companyEmail"
                        placeholder="Company Email"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyWebsite">
                    <Form.Label column sm={12}>
                      Company Website
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="companyWebsite"
                        placeholder="Company Website"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyContact">
                    <Form.Label column sm={12}>
                      Company Contact
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="companyContact"
                        placeholder="Company Contact"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyPoc">
                    <Form.Label column sm={12}>
                      Company Poc
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="companyPoc"
                        placeholder="Company Poc"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col>Your Company Poc entr for this (e.g. *********). </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyPocContact">
                    <Form.Label column sm={12}>
                      Company Poc Contact
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="companyPocContact"
                        placeholder="Company Poc Contact"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>Your Company Poc Contact (e.g. *********). </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyPinCode">
                    <Form.Label column sm={12}>
                      Company Pin Code
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="companyPinCode"
                        placeholder="Company Pin Code"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="userEmail">
                    <Form.Label column sm={12}>
                      User Email
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="userEmail"
                        placeholder="User Email"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyDescription">
                    <Form.Label sm={12}>Company Description</Form.Label>
                    <Col sm={12}>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="companyAddress">
                    <Form.Label sm={12}>Company Address</Form.Label>
                    <Col sm={12}>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="password">
                    <Form.Label column sm={12}>
                      Password
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="confirmPassword">
                    <Form.Label column sm={12}>
                      Confirm Password
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="roleTags">
                    <Form.Label column sm={12}>
                      Role Tag
                    </Form.Label>
                    <Col sm={12}>
                      <Select
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#5DBFC4",
                            primary: "#147b82"
                          }
                        })}
                        name="roleTags"
                        className="select-react-dropdown"
                        options={roleTags}
                        placeholder={"select Role Tag"}
                        // onChange={(e) => this.changeLocationOptions(e)}
                        // defaultValue={this.state.locationDropDownDetails}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label column sm={12}></Form.Label>
                    <Col sm={12} className={"pt-5"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={state.checkedB}
                            onChange={() =>
                              handleChange("I agree the Terms & Conditions")
                            }
                            value="checkedB"
                            color="primary"
                          />
                        }
                        label="I agree the Terms & Conditions"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
