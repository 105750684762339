import React, {useEffect, useState} from "react";
import BlockUi from "react-block-ui";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import * as moment from "moment/moment";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import {BoootstrapTable} from "../../../../shared/components/BoootstrapTable";
import {useDispatch} from "react-redux";
import {Switch} from "@material-ui/core";
import * as action from "../../../../redux/actions/DatasetManagementAction";
import InfoImportDatasetModal from "./InfoImportDatasetModal";
import {SwitchAlertModal} from "../../../../shared/components/SwitchAlertModal";
import {warningToast} from "../../../../shared/components/ToastMessage";
import {UpdateDatasetStatus} from "../../../../service/datasetManagement.api";

function ImportDatasetTable({
  currentItems,
  showTable,
  totalCount,
  pageNo,
  pageSize,
  pageChange,
  sizePerPageChangeApiCall,
                              editImportDatasetModal
}) {
  const [filterEntities, setFilterEntities] = useState([]);
  const [infoModalShow, setInfoModalShow] = useState(false);
  const [infoModalData, setInfoModalData] = useState([]);

  const [datasetStatusData, setDatasetStatusData] = useState([]);
  const [datasetStatus, setDatasetStatus] = useState(false);
  const [successIcon, setSuccessIcon] = useState(false);
  const [userStatusLoader, setUserStatusLoader] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      dataField: "#",
      text: "Index",
      align: "center",
      formatter: (cell, row, rowIndex) => {
        return <span>{(pageNo - 1) * pageSize + (rowIndex + 1)}</span>;
      }
    },
    {
      dataField: "dataset_name",
      text: "Dataset Name",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (cell, _) => {
        return (
          <>
            <Tooltip
              title={<div className="tools"> {cell}</div>}
              placement={"top-end"}
            >
              <span className={"project-table-name-length"}>{cell}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      dataField: "dataset_description",
      text: "Dataset Description",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (cell, _) => {
        return (
          <>
            <Tooltip
              title={<div className="tools"> {cell}</div>}
              placement={"top-end"}
            >
              <span className={"project-table-name-length"}>{cell}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      dataField: "created_date",
      text: "Created Date",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (_, row) =>
        moment
          .utc(row?.created_date)
          .local()
          .format("DD MMMM YYYY, HH:mm:ss")
    },
    {
      dataField: "updated_date",
      text: "Updated Date",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (_, row) =>
        moment
          .utc(row?.updated_date)
          .local()
          .format("DD MMMM YYYY, HH:mm:ss")
    },
    {
      dataField: "is_verified",
      text: "verified",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (cellContent, row) => {
        return (
            <>
              <Switch
                  checked={row?.is_verified}
                  onChange={() => handleDatasetStatus(row)}
                  color="primary"
              />
            </>
        );
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
            <>
              <UncontrolledDropdown direction="left">
                <DropdownToggle className="dropdown-toggle-btn">
                   <span className="svg-icon svg-icon-md svg-icon-primary icon-hover " style={{color:"#B5B5C3"}}>
                    <SVG

                        src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/three-dots-vertical.svg"
                        )}
                    />
                   </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="text-hover-btn dropdown-menu-color">
                    <a  className="text-dark-75 text-hover-primary"
                        onClick={() => editImportDatasetModal(cellContent, row)}
                    >
                      <a className="svg-icon svg-icon-md svg-icon-primary mr-2  "
                      >
                        <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                            )}

                        />
                      </a> Edit Dataset
                    </a>


                  </DropdownItem>
                  <DropdownItem className="text-hover-btn dropdown-menu-color">
                    <a  className="text-dark-75 text-hover-primary"
                        onClick={() => handleImportDatasetInfo(cellContent, row)}
                    >
                      <a className="svg-icon svg-icon-md svg-icon-primary mr-2"

                      >
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}

                        />
                      </a>Dataset Info
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
        );
      },
      style: {
        minWidth: "55px"
      }
    }
  ];

  useEffect(() => {
    setFilterEntities(currentItems);
  }, [currentItems]);

  const onPageChange = (page, sizePerPage) => {
    pageChange(page, sizePerPage);
  };
  const sizePerPageChange = (page, sizePerPage) => {
    sizePerPageChangeApiCall(sizePerPage, page);
  };

  const handleImportDatasetInfo = (cellContent, row) => {
    setInfoModalData(row);
    setInfoModalShow(true);
  };

  const handleInfoClose = () => {
    setInfoModalData([])
    setInfoModalShow(false);
  };


  const handleDatasetStatus = row => {
    setDatasetStatusData(row);
    setDatasetStatus(true);
  };


  const handleServiceAccessStatus = (id, status) => {
    UpdateUserDatasetStatus(id, status);
  };

  const UpdateUserDatasetStatus = (id, status) => {
    setUserStatusLoader(true);
    // setToggleTitle("Updating Status!");
    UpdateDatasetStatus(!status, id)
        .then(response => {
          if (response && response.isSuccess) {
            setUserStatusLoader(false);
            // setToggleTitle("Status Updated Successfully!");
            setSuccessIcon(true);
            setTimeout(function() {
              handleServiceAccessClose();
              dispatch(action.fetchDatasets(1, 10, ""));
            }, 1000);
          }
        })
        .catch(e => {
          setUserStatusLoader(false);
          warningToast("Something went wrong");
        });
  };

  const handleServiceAccessClose = () => {
    setDatasetStatus(false);
    setSuccessIcon(false);
    setDatasetStatusData([]);
  };

  return (
    <>
      <BlockUi tag="div" blocking={showTable} color="#147b82">
        {filterEntities.length > 0 && (
          <BoootstrapTable
            sizePerPageList={[
              { text: "10", value: 10 },
              { text: "5", value: 5 },
              { text: "3", value: 3 }
            ]}
            hideSizePerPage={false}
            showTotal={true}
            alwaysShowAllBtns={true}
            hidePageListOnlyOnePage={true}
            columns={columns}
            data={filterEntities}
            sizePerPage={pageSize}
            page={pageNo}
            totalSize={totalCount}
            onTableChange={onPageChange}
            sizePerPageChange={sizePerPageChange}
          />
        )}
        {filterEntities.length === 0 && (
          <h3 className={"mt-5 d-flex justify-content-center"}>
            No Data Found
          </h3>
        )}
      </BlockUi>

      <SwitchAlertModal
          showAlert={datasetStatus}
          handleAccessUserClose={handleServiceAccessClose}
          handleUserStatus={(id, status) => handleServiceAccessStatus(id, status)}
          id={datasetStatusData.id}
          status={datasetStatusData?.is_verified}
          userStatusLoader={userStatusLoader}
          successIcon={successIcon}
      />

      <InfoImportDatasetModal
          show={infoModalShow}
          infoModalData={infoModalData}
          handleClose={handleInfoClose}
      />
    </>
  );
}

export default ImportDatasetTable;
