import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getOneModel } from "../../../_redux/ExperimentManagementAPI";
import { warningToast } from "../../../../../../shared/components/ToastMessage";
import { TestNowTab } from "./TestNowTab";

export function ExperimentModal({ id }) {
  // eslint-disable-next-line
  const [loaderState, setLoaderState] = useState(false);
  const [model, setModel] = useState({});
  useEffect(() => {
    (async () => {
      try {
        setLoaderState(true);
        const res = await getOneModel(id);
        if (res.isSuccess) {
          setLoaderState(false);
          setModel(res.data);
        } else {
          setLoaderState(false);
          warningToast("Error getting model details");
          throw new Error("error getting model id");
        }
      } catch (e) {
        setLoaderState(false);
        warningToast("Something went wrong");
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <div class="col-xl-3"></div>
        </Col>

        <Col>
          <div class="col-xl-3"></div>
        </Col>
      </Row>
      <TestNowTab model={model} />
    </>
  );
}
