/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  entityFilter,
  getFilteredAndPaginatedEntities,
  headerSortingClasses,
  sortCaret
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { useExperimentManagementUIContext } from "../ExperimentManagementUIContext";
import * as actions from "../../_redux/RequestedManagementAdminAction";
import { SearchText } from "../../../../../shared/components/SearchText";
import { trainModel } from "../../_redux/ExperimentManagementAPI";
import { warningToast } from "../../../../../shared/components/ToastMessage";
import SweetAlert from "react-bootstrap-sweetalert";
import BlockUi from "react-block-ui";
import {BoootstrapTable} from "../../../../../shared/components/BoootstrapTable";
import {dateTimeFormatter} from "../../../../../shared/helper/timeZone";
export function RequestedManagementTable({ myKey }) {
  const experimentManagementUIContext = useExperimentManagementUIContext();
  const experimentManagementUIProps = useMemo(
    () => experimentManagementUIContext,
    [experimentManagementUIContext]
  );

  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [loaderState, setLoaderState] = useState(false);
  const [id, setId] = useState();
  const openTrainExperimentManagementDialog = async idx => {
    setAlertData({ ...alertData, show: true, id: idx });
  };
  const trainAPI = async id => {
    try {
      setLoaderState(true);
      const res = await trainModel(id, "processing");
      if (res.isSuccess) {
        setLoaderState(false);
        dispatch(actions.fetchReqExperiments(reqPage, reqSize, search)).then();
        dispatch(actions.fetchExperiments(reqPage, reqSize, search)).then();
      } else {
        setLoaderState(false);
        warningToast("Error getting model details");
        throw new Error("error getting model id");
      }
    } catch (e) {
      setLoaderState(false);
      warningToast("Something went wrong");
    }
  };

  const [show, setShow] = React.useState(false);
  const [alertData, setAlertData] = React.useState({
    show: false,
    id,
    title: "Are You Sure You Want To Start Training?"
  });

  const columns = [
    {
      dataField: "#",
      text: "Index",
      formatter: (cell, row, rowIndex) => {
        return <span>{(reqPage - 1) * reqSize + (rowIndex + 1)}</span>;
      }
    },
    {
      dataField: "experiment_name",
      text: "Experiment Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "165px"
      }
    },
    {
      dataField: "experiment_description",
      text: "Experiment Description",
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "experiment_status",
      text: "Experiment Status",
      sort: true,
      formatter: columnFormatters.ExperimentStatusFormatter,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "created_date",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: dateTimeFormatter,
      style: {
        minWidth: 180
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        openChangeStatusDialog:
          experimentManagementUIProps.openChangeExperimentManagementStatusDialog
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditExperimentDialog:
          experimentManagementUIProps.openEditExperimentManagementDialog,
        openViewExperimentDialog:
          experimentManagementUIProps.openViewExperimentManagementDialog
      },
      style: {
        minWidth: "125px"
      }
    },
    {
      dataField: "train",
      text: "Train",
      sort: true,
      formatter: columnFormatters.ReqTrainColumnFormatter,
      formatExtraData: {
        openTrainExperimentDialog: openTrainExperimentManagementDialog
      }
    }
  ];

  const { currentState } = useSelector(
    state => ({ currentState: state.ExperimentManagementAdminSlice }),
    shallowEqual
  );

  const {
    entities2,
    listLoading,
    totalRequestedCount,
    reqPage,
    reqSize
  } = currentState;

  const [filterEntities, setFilterEntities] = useState(entities2);
  const searchInput = useRef("");
  let currentItems = getFilteredAndPaginatedEntities(
    filterEntities || entities2,
    experimentManagementUIProps.queryParams
  );

  //Filter data for model type
  const filterExperiment = e => {
    // const searchStr = e?.target?.value || searchInput?.current?.value;
    const keys = [
      "experiment_name",
      "experiment_description",
      "experiment_status",
      "created_date",
      "updated_date"
    ];
    currentItems = entityFilter(
      entities2 || filterEntities,
      null,
      keys,
      experimentManagementUIProps.queryParams,
      setFilterEntities
    );
  };
  //State Change Handlers
  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    if(myKey==="results") {
      dispatch(actions.fetchReqExperiments(1, 10, search)).then();
    }setTimeout(() => {
      setLoader(false);
    }, 50);
  }, [experimentManagementUIProps.queryParams, dispatch, myKey]);

  useEffect(() => {
    filterExperiment();
  }, [entities2]);

  useEffect(() => {
    let queryparams = experimentManagementUIProps.queryParams;
    queryparams.pageNumber = 1;
    experimentManagementUIProps.setQueryParams(queryparams);
  }, [myKey]);
  const onPageChange = (page, sizePerPage) => {
    dispatch(actions.fetchReqExperiments(page, sizePerPage, search));
  };

  const onSizePerPageChange = sizePerPage => {
    dispatch(actions.fetchReqExperiments(1, sizePerPage, search));
  };
  const onKeyPressHandler = e => {
    if (e.key === "Enter") {
      dispatch(actions.fetchReqExperiments(1, reqSize, search));
    }
  };

  const onChangeSearch = e => {
    const searchStr = e?.target?.value.trim() || searchInput.current.value.trim();
    setSearch(searchStr);
  };

  return (
    <>
      <div className=" mb-5 pagination-tab" />
      <BlockUi tag="div" blocking={loader} color="#147b82">
        <SearchText
          reference={searchInput}
          onChangeHandler={onChangeSearch}
          onKeyDown={onKeyPressHandler}
          searchField={"on EXPERIMENT NAME or EXPERIMENT STATUS"}
        />
        {currentItems && currentItems.length > 0 ? (
          <BoootstrapTable
            sizePerPageList={[
              { text: "10", value: 10 },
              { text: "5", value: 5 },
              { text: "3", value: 3 }
            ]}
            hideSizePerPage={false}
            showTotal={true}
            alwaysShowAllBtns={true}
            hidePageListOnlyOnePage={true}
            columns={columns}
            data={currentItems}
            sizePerPage={reqSize}
            page={reqPage}
            totalSize={totalRequestedCount}
            onTableChange={onPageChange}
            sizePerPageChange={onSizePerPageChange}
          />
        ) : (
          <h3 className={"table-center"}>No Data Available</h3>
        )}
      </BlockUi>
      <SweetAlert
        info
        showConfirm
        showCancel
        closeOnClickOutside
        confirmBtnText="Train"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        cancelBtnStyle={{ color: "black" }}
        title={alertData.title}
        body
        show={alertData.show}
        focusCancelBtn
        onConfirm={() => {
          setAlertData({ ...alertData, show: false });
          setShow(true);
          setId(alertData.id);
          trainAPI(alertData.id);
        }}
        onCancel={() => {
          setAlertData({ ...alertData, show: false });
        }}
      ></SweetAlert>
    </>
  );
}
