import React, {useState} from "react";
import {Card, CardMedia} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {Viewer} from "tusk-annotate-tool";

function UploadedDatasetImagePreview({
                                         imageListForDataset,
                                         data,
                                         dataIndex
                                     }) {
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [count, setCount] = useState(dataIndex);
    const [previewIndex, setPreviewIndex] = useState(
        dataIndex ? dataIndex : null
    );
    const [nextButton, setNextButton] = useState(false);

    const previewImg = (event, index) => {
        event.stopPropagation();
        const nextData = imageListForDataset[index];
        setPreviewData(nextData);
        setPreviewIndex(index);
        setIsViewerOpen(true);
        setNextButton(imageListForDataset.length - 1 === index ? true : false);
    };


    const closePreview = () => {
        setIsViewerOpen(false);
    };

    const nextPreview = dataIndex => {
        setCount(count + 1);

        const nextIndexVal = dataIndex + 1;
        const nextIndex = imageListForDataset[nextIndexVal];
        setPreviewData(nextIndex);
        setPreviewIndex(nextIndexVal);
        setNextButton(
            imageListForDataset.length - 1 === nextIndexVal ? true : false
        );
    };
    const previousPreview = dataIndex => {
        setCount(count - 1);
        const prevIndexVal = dataIndex - 1;
        const nextIndex = imageListForDataset[prevIndexVal];
        setPreviewData(nextIndex);
        setPreviewIndex(prevIndexVal);
        setNextButton(
            imageListForDataset.length - 1 === prevIndexVal ? true : false
        );
    };
    const handleKeyPress = event => {
        if (
            event.key === "ArrowRight" &&
            !(previewIndex > imageListForDataset.length - 2)
        ) {
            nextPreview(previewIndex);
        } else if (event.key === "ArrowLeft" && previewIndex !== 0) {
            previousPreview(previewIndex);
        }
    };
    return (
        <>
            {
                <Card style={{width: "160px", height: "160px"}} contentEditable="true"
                      onKeyDown={e => handleKeyPress(e)}>
                    <div className={"position-absolute"} style={{cursor: "pointer"}}>
                        <CardMedia alt={"Image Here"}>
                            <img draggable={"false"} src={data?.src} style={{height: "160px", width: "160px"}}/>
                        </CardMedia>
                        <a title="Information"
                           style={{zIndex: "75"}}
                           className="d-flex justify-content-end mx-2 my-1 image-eye cursor-pointer"
                           onClick={event => previewImg(event, dataIndex)}>
                              <span className="svg-icon svg-icon-md svg-icon-light-inverse">
                                <VisibilityIcon
                                    color={"action"}
                                    style={{fontSize: "2rem", color: "#147b82"}}
                                />
                              </span>
                        </a>
                        {isViewerOpen && (
                            <>
                                <div>
                                    <div className="styles-module_wrapper__1I_qj react-simple-image-viewer__modal">
                                        <div className={"d-flex justify-content-around"}>
                                            <div
                                                className="styles-module_images_count react-simple-image-viewer__close">
                                                <div className={"xl-6"}>
                                                    {" "}
                                                    {count + 1} / {imageListForDataset.length}{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <span
                                            className="styles-module_close__2I1sI react-simple-image-viewer__close"
                                            onClick={closePreview}>×
                                        </span>

                                        {previewIndex && (
                                            <span
                                                className="styles-module_navigation__1pqAE styles-module_prev__KqFRp react-simple-image-viewer__previous"
                                                onClick={() => previousPreview(previewIndex)}>❮
                                            </span>
                                        )}
                                        {!nextButton && (
                                            <span
                                                className="styles-module_navigation__1pqAE styles-module_next__1uQwZ 	react-simple-image-viewer__next"
                                                onClick={() => nextPreview(previewIndex)}>❯
                                            </span>
                                        )}
                                        <div
                                            className="styles-module_content__2jwZj react-simple-image-viewer__modal-content dataset-viewver-class">
                                            <div
                                                className="styles-module_slide__1zrfk react-simple-image-viewer__slide dataset-Viewvwe">
                                                <Viewer
                                                    imageSrc={previewData?.src}
                                                    regions={previewData?.regions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Card>
            }
        </>
    );
}

export default UploadedDatasetImagePreview;
