import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import {getCurrentUser} from "../../service/authCrud.api";
import Cookies from "universal-cookie";
import {ACCESS_TOKEN, TOKEN_TYPE} from "../../shared/enums/auth.enums";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request user] Action",
  UserLoaded: "[Load user] Auth API",
  SetUser: "[Set user] Action",
  SetProfileDetails: "[Set Profile Details For Social Login] Action",
  SetIsLoginInitiated : "[Login Initiated] Action"
};

const initialAuthState = {
  user: null,
  authToken: undefined,
  profile: null,

  isLoginInitiated : false
};

const cookies = new Cookies();
export const reducer = persistReducer(
    {storage, key: "auto-dl-admin-auth", whitelist: ["user", "authToken", "isLoginInitiated"]},
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          const {authToken} = action.payload;

          return {authToken, user: undefined};
        }

        case actionTypes.Register: {
          const {authToken} = action.payload;

          return {authToken, user: undefined};
        }

        case actionTypes.Logout: {
          cookies.remove(ACCESS_TOKEN,  {httpOnly: false,path: "/"});
          cookies.remove(TOKEN_TYPE, {httpOnly: false,path: "/"});
          return initialAuthState;
        }

        case actionTypes.UserLoaded: {
          const {user} = action.payload;
          return {...state, user};
        }

        case actionTypes.SetUser: {
          const {user} = action.payload;
          // const {user} = {...action.payload, user: {...action.payload.user, roles: [{role: 'L1'}]}};
          return {...state, user};
        }

        case actionTypes.SetProfileDetails: {
          const {profile}= action.payload;
          return {...state, profile};
        }

        case actionTypes.SetIsLoginInitiated : {
          const {isLoginInitiated = false} = action.payload
          return {...state, isLoginInitiated}
        }

        default:
          return state;
      }
    }
);

export const AuthAction = {
  login: (authToken) => ({type: actionTypes.Login, payload: {authToken}}),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: {authToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: {user},
  }),
  fulfillUser: (user) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user) => ({type: actionTypes.SetUser, payload: {user}}),
  setLoginInitiated : (isLoginInitiated) => ({type : actionTypes.SetIsLoginInitiated , payload : {isLoginInitiated}}),
  SetProfileDetails: (profile) => ({type: actionTypes.SetProfileDetails, payload: {profile}}),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(AuthAction.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(AuthAction.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getCurrentUser();

    yield put(AuthAction.fulfillUser(user));
  });
}
