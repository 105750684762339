import { request } from "../../../../shared/helper/RequestWrapper";

const GET_ALL_MODEL = "/get_all_training_models";
const GET_ENABLED_MODEL = "/get_all_enabled_training_model";
const GET_MODEL_BY_ID = "/get_training_model_by_id";
const ADD_MODEL = "/add_training_model";
const UPDATE_MODEL = "/update_training_model";

export async function getModelById(id) {
  return await request({
    endpoint: GET_MODEL_BY_ID + "?training_model_id=" + id,
    method: "GET"
  });
}

export async function addNewModel(data) {
  return await request({
    endpoint: ADD_MODEL,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function updateModel(data) {
  return await request({
    endpoint: UPDATE_MODEL,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function getAllModel(pageNo,pageSize,search) {
  return await request({
    endpoint: GET_ALL_MODEL+`?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: "GET"
  });
}

export async function getEnabledModel() {
  return await request({
    endpoint: GET_ENABLED_MODEL,
    method: "GET"
  });
}
