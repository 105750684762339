import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { shallowEqual, useSelector } from "react-redux";

function ExprimentChartModal({ show, handleClose }) {
  const [epochs, setEpochs] = useState([]);
  const [giouLoss, setGiouLoss] = useState([]);
  const [objectLoss, setObjectLoss] = useState([]);
  const [precision, setPrecision] = useState([]);
  const [recall, setRecall] = useState([]);
  const [map, setMap] = useState([]);
  const [f1, setF1] = useState([]);

  const { experimentDetails } = useSelector(
    state => ({
      // actionsLoading: state.experimentManagement,
      experimentDetails: state.experimentManagement.experimentChartById
    }),
    shallowEqual
  );

  useEffect(() => {
    let epochs = [];
    let giouLoss = [];
    let objectLoss = [];
    let precision = [];
    let recall = [];
    let map = [];
    let f1 = [];

    Object.keys(experimentDetails).map(item => {
      epochs.push(parseInt(item));
      giouLoss.push(parseFloat(experimentDetails[item]["giou_loss"]));
      objectLoss.push(parseFloat(experimentDetails[item]["object_loss"]));
      precision.push(parseFloat(experimentDetails[item]["precision"]));
      recall.push(parseFloat(experimentDetails[item]["recall"]));
      map.push(parseFloat(experimentDetails[item]["map"]));
      f1.push(parseFloat(experimentDetails[item]["f1"]));
    });
    setEpochs(epochs);
    setF1(f1);
    setMap(map);
    setGiouLoss(giouLoss);
    setPrecision(precision);
    setObjectLoss(objectLoss);
    setRecall(recall);
  }, [experimentDetails]);

  const options = {
    title: {
      text: "Chart Details"
    },
    // xAxis: {
    //     categories: epochs,
    // },
    credits: {
      enabled: false
    },
    series: [
      {
        name: "Giou_Loss",
        data: giouLoss
      },
      {
        name: "Object_Loss",
        data: objectLoss
      },
      {
        name: "Precision",
        data: precision
      },
      {
        name: "Recall",
        data: recall
      },
      {
        name: "Map",
        data: map
      },
      {
        name: "F1",
        data: f1
      }
    ]
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderCommon title={`Modal Details`} />
        <Modal.Body>
          {experimentDetails ? (
              <>
                <HighchartsReact highcharts={Highcharts} options={options} />

                <div className="onk">
                  <table
                      align={"center"}
                      className="table"
                      style={{ width: "100%", marginBottom: "20px", marginTop: "50px" }}
                  >
                    <thead className="table-dark">
                    <tr>
                      <td>Epoch</td>
                      <td>Precision</td>
                      <td>Recall</td>
                      <td>F1 Score</td>
                      <td>Loss</td>
                      <td>GIOU Loss</td>
                      <td>Map</td>
                    </tr>
                    </thead>
                    <tbody style={{ minHeight: "500px" }}>
                    {epochs.map((item, index) => (
                        <tr key={index}>
                          <td>{item}</td>
                          <td>{precision[index]}</td>
                          <td>{recall[index]}</td>
                          <td>{f1[index]}</td>
                          <td>{objectLoss[index]}</td>
                          <td>{giouLoss[index]}</td>
                          <td>{map[index]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </>
          ) : (
              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                    fontSize: "18px",
                    color: "#666",
                  }}
              >No data found</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExprimentChartModal;
