import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { UsersDetailsForm } from "./UsersDetailsForm";

import * as actions from "../../_redux/UserAction";

export function UsersDetailsDialog({ id, show, onHide }) {
  const dispatch = useDispatch();
  const { actionsLoading, userDetails } = useSelector(
    state => ({
      actionsLoading: state.users.actionsLoading,
      entities: state.users.entities,
      userDetails: state.users.userDetails
    }),
    shallowEqual
  );

  const getUserDetails = id => {
    // eslint-disable-next-line
    dispatch(actions.fetchUserById(id));
  };

  useEffect(() => {
    if(show==true) {
      getUserDetails(id);
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          User Details
        </Modal.Title>
      </Modal.Header>
      <UsersDetailsForm
        actionsLoading={actionsLoading}
        userDetails={userDetails}
        onHide={onHide}
      />
    </Modal>
  );
}
