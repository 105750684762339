import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { warningToast } from "../../../../../shared/components/ToastMessage";

export function ExperimentManagementEditForm({
  saveExperiment,
  experimentData,
  models,
  projects,
  hyperParams,
  annotationTypes,
  datasets,
  onHide
}) {
  const [formData, setFormData] = useState({
    experimentName: "",
    experimentDescription: "",
    experimentStatus: "pending",
    modelId: "",
    modelName: "",
    projectId: "",
    projectName: "",
    hyperParamsId: "",
    optimizer: "",
    annotationTypeId: "",
    annotationTypeName: "",
    datasetId: "",
    datasetName: "",
    status: true,
    id: ""
  });

  const isValidate = () => {
    if (!formData.experimentName)
      warningToast("Please Enter An Experiment Name");
    else if (!formData.experimentDescription)
      warningToast("Please Enter An Experiment Description");
    else if (!formData.modelId || formData.modelId === -1)
      warningToast("Please Select A Model");
    else if (!formData.projectId || formData.projectId === -1)
      warningToast("Please Select A Project");
    else if (!formData.hyperParamsId || formData.hyperParamsId === -1)
      warningToast("Please Select A Hyper Parameter");
    else if (!formData.annotationTypeId || formData.annotationTypeId === -1)
      warningToast("Please Select A Annotation Type");
    else if (!formData.datasetId || formData.datasetId === -1)
      warningToast("Please Select A Dataset");
    else return true;

    return false;
  };

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const handleSubmit = () => {
    if (isValidate()) saveExperiment(formData);
  };

  useEffect(() => {
    setFormData({
      experimentName: experimentData.experiment_name || "",
      experimentDescription: experimentData.experiment_description || "",
      experimentStatus: experimentData.experiment_status || "Pending",
      modelId: experimentData.model_details?.id,
      modelName: experimentData.model_details?.model_name,
      projectId: experimentData.project_details?.id,
      projectName: experimentData?.project_details?.project_name,
      hyperParamsId: experimentData.hyper_params_details?.id,
      optimizer: experimentData?.hyper_params_details?.optimizer,
      annotationTypeId: experimentData.annotation_type_details?.id,
      annotationTypeName:
        experimentData?.dataset_details?.annotation_details
          ?.annotation_type_name,
      datasetId: experimentData.dataset_details?.id,
      datasetName: experimentData?.dataset_details?.dataset_name,
      status:
        experimentData.status === undefined ? true : experimentData.status,
      id: experimentData.id || null
    });
  }, [experimentData]);

  return (
    <>
      <Modal.Body>
        <Form>
          <Form.Group controlId="ExperimentName" as={Row}>
            <Form.Label column sm={4}>
              Experiment Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                type="text"
                name="experimentName"
                placeholder="Experiment name"
                value={formData.experimentName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="ExperimentDescription" as={Row}>
            <Form.Label column sm={4}>
              Experiment Description
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                as="textarea"
                rows="3"
                placeholder="Experiment description"
                name="experimentDescription"
                value={formData.experimentDescription}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="modelId" as={Row}>
            <Form.Label column sm={4}>
              Model
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                type="text"
                name="modelId"
                placeholder="Model Name"
                value={formData.modelName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="projectId" as={Row}>
            <Form.Label column sm={4}>
              Project
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                type="text"
                name="projectId"
                placeholder="Project Name"
                value={formData.projectName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="hyperParamsId" as={Row}>
            <Form.Label column sm={4}>
              Hyper Parameter Optimizer
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                type="text"
                name="hyperParamsId"
                placeholder="Hyper Param Optimizer"
                value={formData.optimizer}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="annotationType" as={Row}>
            <Form.Label column sm={4}>
              Target Annotation Type
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                type="text"
                name="annotationType"
                placeholder="Annotation Type"
                value={formData.annotationTypeName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="datasetId" as={Row}>
            <Form.Label column sm={4}>
              Dataset
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                type="text"
                name="datasetId"
                placeholder="Dataset Name"
                value={formData.datasetName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="experimentStatus" as={Row}>
            <Form.Label column sm={4}>
              Project Status
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="experimentStatus"
                value={formData.experimentStatus.toLowerCase()}
                onChange={handleChange}
              >
                {["Pending", "processing", "Completed"]?.map((opt, idx) => (
                  <option key={idx} value={opt.toLowerCase()}>
                    {opt}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </Button>
        <> </>
        <Button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary btn-elevate"
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}
