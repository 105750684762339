import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "../../../../shared/helper/UIHelpers";

const ExperimentManagementUIContext = createContext();

export function useExperimentManagementUIContext() {
  return useContext(ExperimentManagementUIContext);
}

export function ExperimentManagementUIProvider({
  experimentManagementUIEvents,
  children
}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParams,
    newExperimentManagementButtonClick:
      experimentManagementUIEvents.newExperimentManagementButtonClick,
    openChangeExperimentManagementStatusDialog:
      experimentManagementUIEvents.openChangeExperimentManagementStatusDialog,
    openEditExperimentManagementDialog:
      experimentManagementUIEvents.openEditExperimentManagementDialog,
    openViewExperimentManagementDialog:
      experimentManagementUIEvents.openViewExperimentManagementDialog
  };

  return (
    <ExperimentManagementUIContext.Provider value={value}>
      {children}
    </ExperimentManagementUIContext.Provider>
  );
}
