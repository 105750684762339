/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
/* eslint-disable */
import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import {Row} from "react-bootstrap";

export function UserProfileDropdown() {
  const {user} = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
          objectPath.get(uiService.config, "extras.user.dropdown.style") ===
          "light",
    };
  }, [uiService]);

  return (
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-user-profile"
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
            <span className=" font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.first_name}
          </span>
              <div
                  className={
                      "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  }
              >
            <span className="symbol symbol-35">
            <span className="symbol-label font-size-h5 font-weight-bold" style={{backgroundColor : "#147b82" ,color : "white"}}>
                {user.first_name && user.first_name[0]}
            </span>
          </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
            className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            style={{width: 'auto', minWidth: '300px'}}>
          <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'*/}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center p-8 rounded-top" style={{height : '100px'}}
            >
                {user?.profile_image_url?(<div >
                    <img  style={{width : '55px', height : '55px' , borderRadius : '10px' }} src={toAbsoluteUrl(user.profile_image_url)} />
                </div>):(<div >
                    <img  style={{width : '55px', height : '55px' , borderRadius : '10px' }} src={toAbsoluteUrl("/media/users/blank.png")} />
                </div>)}



              <div className="symbol mr-3 user-profile-name" style={{padding:"15px"}}>
                <div className=" m-0 flex-grow-1 mr-3 font-size-sm font-weight-bolder">
                  {user.first_name}
                </div>
                <div className=" m-0 flex-grow-1 mr-3 font-size-sm">
                  {user.user_email}
                </div>
              </div>

            </div>
          )}
        </>

          <div className="navi navi-spacer-x-0 ">
            <div className="navi-separator mt-2"></div>
            <Link to="/user/profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-primary" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                </div>
              </div>
            </div>
          </Link>

              <div className="navi-footer px-8 py-5 text-right d-block">
                  <Link
                      to="/logout"
                      className="btn btn-light-primary font-weight-bold"
                  >
                      Sign Out
                  </Link>
              </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
  );
}
