import React from "react";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import {withRouter} from "react-router";
import "../../../../../_metronic/_assets/sass/components/custom/_forms-wizard.scss";
import {
    successToast,
    warningToast
} from "../../../../../shared/components/ToastMessage";
import {ToastContainer} from "react-toastify";
import {saveCompanyData} from "../../../../../redux/actions/CompanyRegistrationAction";
import {
    checkUserData,
    saveUserData
} from "../../_wizard_redux/userReg/UserRegistrationAction";
import {saveModelData} from "../../_wizard_redux/modelDetails/ModelDetailsAction";

const getNavStates = (indx, length) => {
    let styles = [];
    for (let i = 0; i < length; i++) {
        if (i < indx) {
            styles.push("done");
        } else if (i === indx) {
            styles.push("doing");
        } else {
            styles.push("todo");
        }
    }
    return {current: indx, styles: styles};
};

const checkNavState = (currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 1) {
        return {
            showPreviousBtn: false,
            showNextBtn: true,
            showSaveBtn: false
        };
    } else if (currentStep === 0) {
        return {
            showPreviousBtn: false,
            showNextBtn: true,
            showSaveBtn: false
        };
    } else if (currentStep === stepsLength - 1) {
        return {
            showPreviousBtn: false,
            showNextBtn: false,
            showSaveBtn: true
        };
    } else {
        return {
            showPreviousBtn: true,
            showNextBtn: false,
            showSaveBtn: false
        };
    }
};

class AIModelMultiStepWizard extends React.Component {
    state = {
        showPreviousBtn: false,
        showNextBtn: true,
        compState: 0,
        navState: getNavStates(0, this.props.steps.length),
        showSaveBtn: false,
        temp: true,
        alert: {
            success: false,
            danger: false,
            warning: false,
            show: false,
            showConfirm: true,
            showCancel: true,
            cancelText: "Cancel"
        },
        onHide: this.props.onHide,
        onStepsCompleted: this.props.onStepsCompleted,
        userDetails: this.props.userDetails,
        setUserDetails: this.props.setUserDetails,
        id: this.props.id,
        accountType: this.props.accountType,
        deploymentType: this.props.deploymentType,
        isUserVerified: false
    };

    setNavState = next => {
        this.setState({
            navState: getNavStates(next, this.props.steps.length)
        });
        if (next < this.props.steps.length) {
            this.setState({compState: next});
        }
        this.setState(checkNavState(next, this.props.steps.length));
    };

    isNextPageRequest = (curPage, newPage) => {
        return curPage < newPage;
    };
    isValid1Data = () => {
        let companyRegistrationData = this.props.companyRegistration
            .companyRegistrationData;

        if (
            companyRegistrationData === "" ||
            companyRegistrationData === undefined
        ) {
            warningToast("Please Fill the Form");
            return false;
        }
        if (companyRegistrationData["company_name"] === "") {
            warningToast("Please Enter Company Name");
            return false;
        }
        if (companyRegistrationData["company_email"] === "") {
            warningToast("Please Enter Company Email");
            return false;
        }
        if (companyRegistrationData["company_description"] === "") {
            warningToast("Please Enter Company Description");
            return false;
        }
        if (companyRegistrationData["company_address"] === "") {
            warningToast("Please Enter Company Address");
            return false;
        }
        if (companyRegistrationData["company_pin_code"] === "") {
            warningToast("Please Enter Company Pincode");
            return false;
        }
        if (companyRegistrationData["company_website"] === "") {
            warningToast("Please Enter Company Website");
            return false;
        }
        if (companyRegistrationData["company_contact"] === "") {
            warningToast("Please Enter Company Contact");
            return false;
        }
        if (companyRegistrationData["company_poc"] === "") {
            warningToast("Please Enter Company POC");
            return false;
        }
        if (companyRegistrationData["company_poc_contact"] === "") {
            warningToast("Please Enter Company POC Contact");
            return false;
        }
        if (companyRegistrationData["deployment_region"] === "") {
            warningToast("Please Enter Deployment Region");
            return false;
        }
        return true;
    };
    isValid2Data = () => {
        let userRegistrationData = this.props.userRegistration.userRegistrationData;

        if (userRegistrationData === "") {
            warningToast("Please Fill the Form");
            return false;
        }
        if (userRegistrationData["user_password"] === "") {
            warningToast("Please Enter user Password");
            return false;
        }
        return true;
    };
    isValid3Data = () => {
        let userRegistrationData = this.props.userRegistration.userRegistrationData;
        let modelDetailsData = this.props.modelDetails.modelDetailsData;
        if (userRegistrationData === "" && this.state.accountType === "no") {
            warningToast("Please check your user Details");
            return false;
        }
        if (modelDetailsData === "") {
            warningToast("Please Fill the Form");
            return false;
        }
        if (modelDetailsData["experiment_name"] === "") {
            warningToast("Please Enter Model Name");
            return false;
        }
        if (modelDetailsData["experiment_description"] === "") {
            warningToast("Please Enter Model Description");
            return false;
        }
        if (modelDetailsData["model_infer_cpu_speed"] === "") {
            warningToast("Please Enter CPU speed");
            return false;
        }
        if (modelDetailsData["model_infer_gpu_speed"] === "") {
            warningToast("Please Enter GPU speed");
            return false;
        }
        if (modelDetailsData["framework_id"] === "") {
            warningToast("Please Enter Model FrameWork ID");
            return false;
        }
        if (modelDetailsData["model_size"] === "") {
            warningToast("Please Enter Model Size");
            return false;
        }
        if (modelDetailsData["model_category_id"] === "") {
            warningToast("Please Select Model Category");
            return false;
        }
        if (modelDetailsData["batch_size"] === "") {
            warningToast("Please Enter Model Batch Size");
            return false;
        }
        if (modelDetailsData["num_epochs"] === "") {
            warningToast("Please Enter Epochs Number");
            return false;
        }
        if (modelDetailsData["img_size"] === "") {
            warningToast("Please Enter Model Image Size");
            return false;
        }
        if (modelDetailsData["model_labels_list"] === "") {
            warningToast("Please Enter Model Labels List");
            return false;
        }

        return true;
    };
    isValidData = (curPage, newPage) => {
        if (this.isNextPageRequest(curPage, newPage)) {
            if (curPage === 0 && this.state.accountType === "no") {

                if (this.isValid1Data()) {
                    this.props.saveCompanyData({
                        ...this.props.companyRegistration.companyRegistrationData
                    });
                    return true;
                }
            } else if (curPage === 1 && this.state.accountType === "no") {
                if (this.isValid2Data()) {
                    let companyRegistrationData = this.props.companyRegistration
                        .companyRegistrationData;
                    this.props.saveUserData({
                        ...this.props.userRegistration.userRegistrationData,
                        companyRegistrationData
                    });
                    return true;
                }
            } else if (curPage === 2 && this.state.accountType === "no") {
                if (this.isValid3Data()) {
                    let userRegistrationData = this.props.userRegistration
                        .userRegistrationData;
                    this.props.saveModelData(
                        {...this.props.modelDetails.modelCreated, userRegistrationData},
                        this.state.id,
                        true
                    );
                    return true;
                }
            } else if (curPage === 0 && this.state.accountType === "yes") {
                if (this.isValid2Data()) {
                    this.props.checkUserData({
                        ...this.props.userRegistration.userRegistrationData
                    });
                    if (this.state.user_id !== undefined) {
                        return true;
                    }
                }
            } else if (curPage === 1 && this.state.accountType === "yes") {
                if (this.isValid3Data()) {
                    let userRegistrationData = this.props.userRegistration
                        .userRegistrationData;
                    this.props.saveModelData(
                        userRegistrationData,
                        this.state.id,
                        this.props.auth.user.id,
                        false,
                        this.state.deploymentType
                    );
                    return true;
                }
            }
            // else if (newPage > curPage) {
            //   // warningToast("Can not Jump without selecting previous Selection");
            //   return false;
            // }
            // return false;
        }
        return true;
    };

    handleOnClick = evt => {
        if (this.isValidData(this.state.compState, evt.currentTarget.value)) {
            if (
                evt.currentTarget.value === this.props.steps.length - 1 &&
                this.state.compState === this.props.steps.length - 1
            ) {
                this.setNavState(this.props.steps.length);
            } else {
                this.setNavState(evt.currentTarget.value);
            }
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (
            nextProps.companyRegistration.companyRegistrationData &&
            this.state.temp
        ) {
            this.setState({
                id: nextProps.id,
                temp: false
            });
        }
        this.setState(
            {
                user_id: nextProps.userRegistration.userRegistrationData["id"]
            },
            () => {
                if (this.state.user_id && this.state.accountType === "yes") {
                    this.setNavState(1);
                }
            }
        );
    }


    save = evt => {
        if (this.isValid3Data()) {
            let userRegistrationData = this.props.userRegistration
                .userRegistrationData;
            let modelDetailsData = this.props.modelDetails.modelDetailsData;
            this.props.saveModelData(
                {modelDetailsData, userRegistrationData},
                this.state.id,
                this.props.auth.user.id,
                true,
                this.state.deploymentType
            );
            successToast("Model deployed");
            this.state.onHide();
            this.state.onStepsCompleted();
        }
    };

    next = () => {
        if (this.isValidData(this.state.compState, this.state.compState + 1)) {
            this.setNavState(this.state.compState + 1);

        }
    };

    previous = () => {
        if (this.state.compState > 0) {
            this.setNavState(this.state.compState - 1);
        }
    };

    getClassName = (className, i) => {
        return className + "-" + this.state.navState.styles[i];
    };

    renderSteps = () => {
        return this.props.steps.map((s, i) => (
            <li
                className={this.getClassName("form-wizard-step", i)}
                onClick={this.handleOnClick}
                key={i}
                value={i}
            >
                <em>{i + 1}</em>
                <span style={{fontSize: "1.2rem"}}>{this.props.steps[i].name}</span>
                {/*<span>{this.props.steps[i].name}</span>*/}
            </li>
        ));
    };

    render() {
        return (
            <div>
                <ol className="forms-wizard">{this.renderSteps()}</ol>
                {this.props.steps[this.state.compState].component}
                <div className="divider"/>
                <div className="clearfix">
                    <div style={this.props.showNavigation ? {} : {display: "none"}}>
                        <Button
                            variant="primary"
                            className="btn-shadow float-left ml-2 mb-2"
                            outline
                            style={this.state.showPreviousBtn ? {} : {display: "none"}}
                            onClick={this.previous}
                        >
                            Previous
                        </Button>
                        {this.state.showNextBtn ? (
                            <Button
                                onClick={this.next}
                                variant="primary"
                                className="btn-shadow float-right mb-2 mr-2"
                                disabled={!this.props.userRegistration.userRegistrationData.user_password}

                            >

                                Next
                            </Button>
                        ) : (
                            <div></div>
                        )}
                        {this.state.showSaveBtn ? (
                            <Button
                                onClick={this.save}
                                variant="success"
                                className="btn-shadow float-right mb-2 mr-2"
                                disabled={!this.props.modelDetails.modelDetailsData.model_name ||
                                    !this.props.modelDetails.modelDetailsData.model_labels_list ||
                                    !this.props.modelDetails.modelDetailsData.model_description}
                            >
                                Save
                            </Button>
                        ) : (
                            <div></div>
                        )}
                        <Button
                            variant="primary"
                            className="btn-shadow float-left ml-2 mb-2"
                            outline
                            onClick={this.state.onHide}
                        >
                            Close
                        </Button>
                    </div>
                </div>
                <ToastContainer/>
            </div>
        );
    }
}

/*MultiImageAnalyzerWizard.defaultProps = {
    showNavigation: true
};*/


const mapStateToProps = state => ({
    companyRegistration: state.companyRegistration,
    userRegistration: state.userRegistration,
    modelDetails: state.modelDetails,
    auth: state.auth
});
const mapDispatchToProps = () => dispatch => {
    return {
        saveCompanyData: companyData => {
            dispatch(saveCompanyData(companyData));
        },
        saveUserData: userData => {
            dispatch(saveUserData(userData));
        },
        saveModelData: modelData => {
            dispatch(saveModelData(modelData));
        },
        checkUserData: userData => {
            dispatch(checkUserData(userData));
        }
    };
};

//export default connect(mapStateToProp)(MultiImageAnalyzerWizard);
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AIModelMultiStepWizard)
);
