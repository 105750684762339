import {callTypes, modelDetails} from "./ModelDetailsSlice";
import {warningToast} from "../../../../../shared/components/ToastMessage";
import {saveAIModel, saveS3DataHandler, saveTrainingSettings, updateUserStatus} from "./ModelDetailsAPI";

const { actions } = modelDetails;

export const saveModelData = (
  regData,
  exp_id,
  user_id,
  isCalled,
  deploymentType
) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  if (isCalled || regData?.userRegistrationData?.id) {
    const DATA = {
      exp_id: exp_id ? exp_id : regData.modelDetailsData.exp_id,
      model_name: regData?.experiment_name
        ? regData.experiment_name
        : regData.modelDetailsData.model_name,
      model_description: regData?.experiment_description
        ? regData.experiment_description
        : regData.modelDetailsData.model_description,
      model_cpu_infer_speed: regData?.model_details?.model_infer_cpu_speed
        ? regData?.model_details?.model_infer_cpu_speed
        : regData?.modelDetailsData?.model_cpu_infer_speed
        ? regData.modelDetailsData.model_cpu_infer_speed
        : "",
      model_gpu_infer_speed: regData?.model_details?.model_infer_gpu_speed
        ? regData?.model_details?.model_infer_gpu_speed
        : regData?.modelDetailsData?.model_gpu_infer_speed
        ? regData?.modelDetailsData?.model_gpu_infer_speed
        : "",
      model_version_id: 1,
      model_accuracy: 90,
      model_size: regData?.model_details?.model_size
        ? regData?.model_details?.model_size
        : regData.modelDetailsData.model_size,
      model_framework_id: regData?.model_details?.framework_id
        ? regData?.model_details?.framework_id
        : regData.modelDetailsData.model_framework_id,
      framework_version_number: "v1",
      model_type_id: 1,
      model_device_id: 1,
      model_category_id: 1,
      model_depth: "x",
      batch_size: regData?.hyper_params_details?.batch_size
        ? regData?.hyper_params_details?.batch_size
        : regData.modelDetailsData.batch_size,
      num_epochs: regData?.hyper_params_details?.num_epochs
        ? regData?.hyper_params_details?.num_epochs
        : regData.modelDetailsData.num_epochs,
      img_size: regData?.hyper_params_details?.img_size
        ? regData?.hyper_params_details?.img_size
        : regData.modelDetailsData.img_size,
      status: true,
      user_id:
        regData?.modelDetailsData?.deploymentType === "private"
          ? regData?.userRegistrationData?.id
          : null,
      model_labels_list: regData?.model_labels_list
        ? regData?.model_labels_list
        : regData.modelDetailsData.model_labels_list
    };
    const userLoginData = {
      user_id: regData ? regData?.userRegistrationData?.id : "",
      user_status: true
    };
    if (userLoginData?.user_id) {
      updateUserStatus(userLoginData.user_status, userLoginData.user_id)
        .then(response => {
          if (response && response.isSuccess) {
            saveAIModel(DATA)
              .then(res => {
                if (res && res.isSuccess) {
                  dispatch(actions.modelCreated(res.data));
                  saveTrainingSettings(res.data, DATA)
                    .then(re => {
                      if (re && re.isSuccess) {
                        saveS3DataHandler(res.data, DATA)
                          .then(r => {
                            if (r && r.isSuccess) {
                            } else {
                              throw new Error("something went wrong");
                            }
                          })
                          .catch(error => {
                            warningToast(
                              error?.response?.data?.detail ||
                                "something went wrong"
                            );
                            dispatch(
                              actions.catchError({
                                error,
                                callType: callTypes.action
                              })
                            );
                          });
                      } else {
                        throw new Error("something went wrong");
                      }
                    })
                    .catch(error => {
                      warningToast(
                        error?.response?.data?.detail || "something went wrong"
                      );
                      dispatch(
                        actions.catchError({
                          error,
                          callType: callTypes.action
                        })
                      );
                    });
                } else {
                  throw new Error("something went wrong");
                }
              })
              .catch(error => {
                warningToast(
                  error?.response?.data?.detail || "something went wrong"
                );
                dispatch(
                  actions.catchError({ error, callType: callTypes.action })
                );
              });
          } else {
            throw new Error("something went wrong");
          }
        })
        .catch(error => {
          warningToast(error?.response?.data?.detail || "something went wrong");
          dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
    } else {
      dispatch(actions.modelCreated(DATA));
    }
  } else {
    dispatch(
      actions.modelCreated({
        exp_id: exp_id
          ? exp_id
          : regData?.modelDetailsData?.exp_id
          ? regData?.modelDetailsData?.exp_id
          : 0,
        model_name: regData?.experiment_name
          ? regData.experiment_name
          : regData?.modelDetailsData?.model_name
          ? regData.modelDetailsData.model_name
          : "",
        model_description: regData?.experiment_description
          ? regData.experiment_description
          : regData?.modelDetailsData?.model_description
          ? regData?.modelDetailsData?.model_description
          : "",
        model_cpu_infer_speed: regData?.model_infer_cpu_speed
          ? regData?.model_infer_cpu_speed
          : regData?.model_details?.model_cpu_infer_speed
          ? regData.modelDetailsData.model_cpu_infer_speed
          : "",
        model_gpu_infer_speed: regData?.model_infer_gpu_speed
          ? regData?.model_infer_gpu_speed
          : regData?.model_details?.model_gpu_infer_speed
          ? regData?.modelDetailsData?.model_gpu_infer_speed
          : "",
        model_version_id: 1,
        model_accuracy: 90,
        model_size: regData?.model_size
          ? regData?.model_size
          : regData?.model_details?.model_size
          ? regData?.model_details?.model_size
          : regData?.modelDetailsData?.model_size
          ? regData?.modelDetailsData?.model_size
          : "",
        model_framework_id: regData?.framework_id
          ? regData?.framework_id
          : regData?.model_details?.framework_id
          ? regData?.model_details?.framework_id
          : regData?.modelDetailsData?.framework_id
          ? regData?.modelDetailsData?.framework_id
          : 0,
        framework_version_number: "v1",
        model_type_id: 1,
        model_device_id: 1,
        model_category_id: 1,
        model_depth: "x",
        batch_size: regData?.batch_size
          ? regData?.batch_size
          : regData?.hyper_params_details?.batch_size
          ? regData?.hyper_params_details?.batch_size
          : regData?.modelDetailsData?.batch_size
          ? regData?.modelDetailsData?.batch_size
          : "",
        num_epochs: regData?.num_epochs
          ? regData?.num_epochs
          : regData?.hyper_params_details?.num_epochs
          ? regData?.hyper_params_details?.num_epochs
          : regData?.modelDetailsData?.num_epochs
          ? regData?.modelDetailsData?.num_epochs
          : "",
        img_size: regData?.img_size
          ? regData?.img_size
          : regData?.hyper_params_details?.img_size
          ? regData?.hyper_params_details?.img_size
          : regData?.modelDetailsData?.img_size
          ? regData?.modelDetailsData?.img_size
          : "",
        status: true,
        user_id: deploymentType === "private" ? user_id : null,
        model_labels_list: regData?.model_labels_list
          ? regData?.model_labels_list
          : "",
        deploymentType: deploymentType
      })
    );
  }
};
