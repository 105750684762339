import React, { useState } from "react";
import { useFormik } from "formik";
import {connect, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../../../redux/reducers/authRedux";
import {useHistory} from "react-router-dom";
import LoginWithSocialMedia from "../Pages/LoginWithSocialMedia";
import {RegisterAndLoginSlice} from "../../../redux/reducers/registerAndLoginSlice";
import { checkEmailExist} from "../../../service/authCrud.api";
import { warningToast} from "../../../shared/components/ToastMessage";
import {appConfigs} from "../../../configs";

// const initialValues = {
//   company_name: "",
//   company_email: "",
//   company_description: "",
//   company_address: "",
//   company_pin_code: "",
//   company_website: "",
//   company_contact: "",
//   company_poc: "",
//   company_poc_contact: "",
//   user_email: "",
//   user_password: "",
//   change_password: "",
//   role_tag: "",
//   acceptTerms: false
// };





const { actions } = RegisterAndLoginSlice;
function Registration(props) {
  const dispatch = useDispatch();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
    let history = useHistory();


    const initialValues = useSelector(state => {
        const providerProfile = state?.auth?.profile || {};
        return {
            email: providerProfile?.email,
        };
    });


    const RegistrationSchema = Yup.object().shape({
      email: Yup.string().trim()
          .matches(  /^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim,"Incorrect email format.")
          .max(50, "Maximum 50 characters")
          .required(
              "Email must be required"
          ),

    // company_name: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_email: Yup.string()
    //   .matches(
    //     /^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim,
    //     "Please Enter Valid Email"
    //   )
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_description: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(500, "Maximum 500 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_address: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(500, "Maximum 500 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_pin_code: Yup.string()
    //   .matches(/^[0-9]{5,6}$/, "Please enter valid company pin code")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_website: Yup.string()
    //   // .url("Enter valid company website")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_contact: Yup.string()
    //   .matches(/^[0-9]{10,11}$/, "Please enter valid contact number")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_poc: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(25, "Maximum 20 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // company_poc_contact: Yup.string()
    //   .matches(/^[0-9]{10,11}$/, "Please enter valid contact number")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // user_email: Yup.string()
    //   .email("Wrong email format")
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // user_password: Yup.string()
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //     "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number and one special character required"
    //   )
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   ),
    // change_password: Yup.string()
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //     })
    //   )
    //   .when("user_password", {
    //     is: val => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf(
    //       [Yup.ref("user_password")],
    //       "Password and Confirm Password didn't match"
    //     )
    //   }),
    // role_tag: Yup.string().required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
    //   })
    // ),
    // acceptTerms: Yup.bool().required("You must accept the terms and conditions")
  });
  // const [activeKey, setActiveKey] = useState("company");

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
        let provider = "email"
        let userDetails ={
            user_code: values?.email,
            provider : provider,
            redirect_url:  appConfigs.APP_IP ?  appConfigs.APP_IP  + `/auth/registration-information` : `https://avf.tusker.ai/auth/registration-information`
        }

        dispatch(actions.UserProfileDetails(userDetails));
        checkEmail(values?.email)


    }
  });
    const checkEmail = (email) => {
        checkEmailExist(email)
            .then(response => {
               if(response.data){
                   warningToast("user already exist")
                   setLoading(false)
                   disableLoading()
               }
               else {
                   history.push("/auth/registration-information");
               }
            })
            .catch(error => {
                if (error) {
                    console.log("error" , error)
                    warningToast(error.detail);
                    setLoading(false)
                    disableLoading()
                } else {
                    warningToast("Something went Wrong");
                }

            });
    };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Sign up to register account</h3>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}


          {/* begin: Email */}
          <div className="form-group fv-plugins-icon-container">
              <input
                  placeholder="Email"
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                  // disabled={initialValues.email || false}
              />
              {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik?.errors?.email}</div>
                  </div>
              ) : null}
          </div>
          {/* end: Email */}



          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            size={"sm"}
            id="kt_login_signin_submit"
            type="submit"
            disabled={!formik.values.email|| loading }
            className={`btn btn-primary font-weight-bold px-9 py-3`}
          >
            <span>Sign Up</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <LoginWithSocialMedia
            enableLoading={enableLoading}
            disableLoading={disableLoading}
            />

      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.AuthAction)(Registration));
