import {
  callTypes,
  ProjectManagementAdminSlice
} from "./ProjectManagementAdminSlice";
import { warningToast } from "../../../../shared/components/ToastMessage";
import {
  getAllProject,
  getProjectById,
  updateProject
} from "./ProjectManagementAPI";

const { actions } = ProjectManagementAdminSlice;

export const fetchProjects = (pageNo ,pageSize,search) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllProject(pageNo ,pageSize,search)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.projectFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find projects";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProjectById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getProjectById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.projectFetchedById(response.data));
      } else {
        throw new Error("Error getting Project details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const projectUpdate = projectData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    project_name: projectData.projectName || projectData.project_name,
    project_description:
      projectData.projectDescription || projectData.project_description,
    project_status: projectData.projectStatus || projectData.project_status,
    status: projectData.status,
    id: projectData.id
  };

  return updateProject(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.projectUpdated(response.data));
      } else {
        throw new Error("Error updating Project details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
