// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState} from "react";
import {Col, Modal, Row} from "react-bootstrap";
import {useFormik} from "formik";
import {FormattedMessage} from "react-intl";
import * as Yup from "yup";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {userRegistration} from "../../../ExperimentManagement/_wizard_redux/userReg/UserRegistrationSlice";

const { actions } = userRegistration;

const initialCompanyValues = {
  company_name: "",
  company_email: "",
  company_description: "",
  company_address: "",
  company_pin_code: "",
  company_website: "",
  company_contact: "",
  company_poc: "",
  deployment_region: "",
  company_poc_contact: ""
};

export default function LoginPage({
  accountType
}) {
  const dispatch = useDispatch();
  const {
    userRegistrationData,
  } = useSelector(
    state => ({
      userRegistrationData: state.userRegistration.userRegistrationData
      // companyRegistrationData: state.companyRegistration.companyRegistrationData
    }),
    shallowEqual
  );

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user
    }),
    shallowEqual
  );
  const [formData, setFormData] = useState({
    user_email: userRegistrationData.user_email
      ? userRegistrationData.user_email
      : user.user_email,
    user_password: userRegistrationData.user_password
      ? userRegistrationData.user_password
      : user.user_password,


    // company: companyRegistrationData?.company_name
    //   ? companyRegistrationData.company_name
    //   : companyDetails.company,
    // company_id: companyRegistrationData?.company_id
    //   ? companyRegistrationData.company_id
    //   : companyDetails.company_id
  });


  const RegistrationCompanySchema = Yup.object().shape({
    user_email: Yup.string()
      .matches(
        /^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim,
        "Please Enter Valid Email"
      )
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required"),
    user_password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
        "Minimum eight characters, at least one uppercase letter, at least one special character, one lowercase letter and one number required"
      )
      .required(
        "Minimum eight characters, at least one uppercase letter, at least one special character, one lowercase letter and one number required"
      )
  });

  const handleChange = (key, event) => {
    let value = event.target.value;
    let data = { ...formData };
    data[key] = value;
    setFormData(data);
    dispatch(actions.userCreated(data));
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: initialCompanyValues,
    validationSchema: RegistrationCompanySchema
  });

  return (
    <>
      <Modal.Body>
        <Row>
          <Col xl={3}></Col>
          <Col xl={6}>
            <div
              className="login-form login-signin remove-scroll"
              style={{
                display: "block",
                maxHeight: "539px",
                overflowY: "auto",
                overflowX: "hidden"
              }}
            >
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                  {accountType === "no" ? (
                    <FormattedMessage id="AUTH.REGISTER.TITLE" />
                  ) : (
                    <>Login Details</>
                  )}
                </h3>
                {accountType === "no" ? (
                  <p className="text-muted font-weight-bold">
                    Enter your user details to complete Step 2/3
                  </p>
                ) : (
                  <p className="text-muted font-weight-bold">
                    Enter your user details to complete Step 1/2
                  </p>
                )}
              </div>

              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                {/* begin: Alert */}
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                {/* end: Alert */}

                {/* begin: user Email */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    User Email
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="User Email"
                        type="user_email"
                        disabled={true}
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "user_email"
                        )}`}
                        name="user_email"
                        defaultValue={formData.user_email}
                        onChange={event => handleChange("user_email", event)}
                      />
                      {formik.touched.user_email && formik.errors.user_email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.user_email}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: user Email */}

                {/* begin: Password */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    User Password
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "user_password"
                        )}`}
                        name="user_password"
                        defaultValue={formData.user_password}
                        onChange={event => handleChange("user_password", event)}
                      />
                      {formik.touched.user_password &&
                      formik.errors.user_password ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.user_password}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col xl={3}></Col>
        </Row>
      </Modal.Body>
    </>
  );
}
