import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  userDetails: "",
  totalUsers:0,
  page:1,
  size:10,
  data:""
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const UserSlice = createSlice({
  name: "dl-users",
  initialState: initialUserState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    userFetched: (state, action) => {
      const userData = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = userData.items;
      state.totalUsers = userData.total;
      state.page=action.payload.page;
      state.size=action.payload.size;
      state.totalCount = userData && userData.length;
      state.data=action.payload.data
    },
    userCreated: (state, action) => {
      let userData = action.payload;
      state.actionsLoading = false;
      state.listLoading = false;
      state.entities.push(userData);
      state.error = null;
    },
    viewUserDetails: (state, actions) => {
      let userDetails = actions.payload;
      state.actionsLoading = false;
      state.userDetails = userDetails;
    },
    userStatusChanged: (state, actions) => {
      let { status, id } = actions.payload;
      state.entities = state.entities.map(entity => {
        if (entity.id === id) {
          return { ...entity, company_status: status === "true" };
        }
        return entity;
      });
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    }
  }
});
