import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Radio, RadioGroup } from "react-radio-group";
import { Col, Form } from "react-bootstrap";

function TrainConformationModal({
  show,
  successIcon,
  handleTrainOnChange,
  openTrainExperimentDialogClose,
  openTrainExperimentDialogSubmit
}) {
  return (
    <div>
      {/*{temp ? (*/}
      <SweetAlert
        info={!successIcon}
        success={successIcon}
        showCancel={!successIcon}
        showConfirm={!successIcon}
        closeOnClickOutside={""}
        confirmBtnText="Confirm"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        // disabled={btn}
        title={"Select Training Medium"}
        body
        // show={alertTrainData.temp}
        show={show}
        focusCancelBtn
        onConfirm={() => {
          openTrainExperimentDialogSubmit();
          // setTemp(false);
          // setId(alertTrainData.id);
          // if (storage === "cloud") {
          //     trainAPI(alertTrainData.id);
          //     setURL(false);
          //     setValue();
          //     setBtn(true);
          // } else if (storage === "localmachine") {
          //     setURL(false);
          //     setValue();
          //     setBtn(true);
          //     checkStatus(alertTrainData.id);
          // }
        }}
        onCancel={() => {
          openTrainExperimentDialogClose();
          // setTemp(false);
          // setBtn(true);
          // setURL(false);
          // setValue();
        }}
      >
        <div className={"d-flex justify-content-center"}>
          <Form.Group controlId="gender">
            <Col sm={12}>
              <RadioGroup
                name="storage"
                className="d-flex radio-color"
                onChange={e => handleTrainOnChange(e)}
              >
                <div className="radio-button-background mr-3">
                  <Radio value="localmachine" className="radio-button mr-2" />
                  Local Machine
                </div>
                <div className="radio-button-background">
                  <Radio value="cloud" className="radio-button mr-2" />
                  Cloud GPU
                </div>
              </RadioGroup>
            </Col>
          </Form.Group>
        </div>
        {/*{url ? (*/}
        {/*<Form.Group className="mt-4 mb-2" controlId="URL" as={Row}>*/}
        {/*  <Form.Label column sm={4}>*/}
        {/*    api Address*/}
        {/*  </Form.Label>*/}
        {/*  <Col sm={8}>*/}
        {/*    <Form.Control*/}
        {/*      type="text"*/}
        {/*      name="URL"*/}
        {/*      placeholder="Enter api Address"*/}
        {/*      // value={urlvalue}*/}
        {/*      // onChange={e => handleOnChange(e)}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Form.Group>*/}
        {/*) : null}*/}
      </SweetAlert>
      {/*) : (*/}
      {/*    <SweetAlert*/}
      {/*        info*/}
      {/*        showConfirm*/}
      {/*        showCancel*/}
      {/*        closeOnClickOutside*/}
      {/*        confirmBtnText="Yes"*/}
      {/*        confirmBtnBsStyle="primary"*/}
      {/*        cancelBtnBsStyle="light"*/}
      {/*        cancelBtnStyle={{ color: "black" }}*/}
      {/*        // title={alertData.title}*/}
      {/*        // show={alertData.show}*/}
      {/*        focusCancelBtn*/}
      {/*        onConfirm={() => {*/}
      {/*            // setAlertData({ ...alertData, show: false });*/}
      {/*            // setShow(true);*/}
      {/*        }}*/}
      {/*        onCancel={() => {*/}
      {/*            // setAlertData({ ...alertData, show: false });*/}
      {/*        }}*/}
      {/*    />*/}
      {/*)}*/}
    </div>
  );
}

export default TrainConformationModal;
