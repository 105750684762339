import { request } from "../../../../shared/helper/RequestWrapper";

const GET_ALL_ANNOTATION_TYPE = "/get_all_annotation_types";
const GET_ENABLED_ANNOTATION_TYPE = "/get_all_enabled_annotation_types";
const GET_ANNOTATION_TYPE_BY_ID = "/get_annotation_type_by_id";
const ADD_ANNOTATION_TYPE = "/add_annotation_type";
const UPDATE_ANNOTATION_TYPE = "/update_annotation_type";

export async function getAnnotationTypeById(id) {
  return await request({
    endpoint: GET_ANNOTATION_TYPE_BY_ID + "?annotation_type_by_id=" + id,
    method: "GET"
  });
}

export async function addNewAnnotationType(data) {
  return await request({
    endpoint: ADD_ANNOTATION_TYPE,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function updateAnnotationType(data) {
  return await request({
    endpoint: UPDATE_ANNOTATION_TYPE,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function getAllAnnotationType(pageNo,pageSize,search) {
  return await request({
    endpoint: GET_ALL_ANNOTATION_TYPE+ `?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: "GET"
  });
}

export async function getAllEnabledAnnotationType() {
  return await request({
    endpoint: GET_ENABLED_ANNOTATION_TYPE,
    method: "GET"
  });
}
