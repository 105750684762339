/* eslint-disable */
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { LoadingDialog } from "../../../../../_metronic/_partials/controls";
import * as moment from "moment";

export function ExperimentDetailsForm({
  actionsLoading,
  experimentDetails,
  onHide
}) {
  return (
    <>
      <Modal.Body className="pt-0">
        <LoadingDialog isLoading={actionsLoading} text="Loading ..." />

        {/**
                 Experiment Details
                 */}
        <div
          className="row col-12 text-center modal-contain-title-part"
        >
          <span className="w-100 font-weight-bold">Experiment Details</span>
        </div>

        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Experiment Name</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.experiment_name}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Experiment Description</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.experiment_description}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Experiment Status</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.experiment_status}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Model Details
                 */}
        <div
          className="row col-12 text-center modal-contain-title-part"
        >
          <span className="w-100 font-weight-bold">Model Details</span>
        </div>

        <div className="row">
          <div className="col col-md-6">
            <span>
              <b>Model Name</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.model_details?.model_name}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Description</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.model_details?.model_description}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Infer CPU Speed</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.model_details?.model_infer_cpu_speed}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Infer GPU Speed</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.model_details?.model_infer_gpu_speed}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Size</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.model_details?.model_size}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.model_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.model_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Project Details
                 */}
        <div
          className="row col-12 text-center modal-contain-title-part"
        >
          <span className="w-100 font-weight-bold">Project Details</span>
        </div>

        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Experiment Name</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.project_details?.project_name}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Experiment Description</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.project_details?.project_description}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Experiment Status</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.project_details?.project_status}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.project_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.project_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Hyper Params Details
                 */}
        <div
          className="row col-12 text-center modal-contain-title-part"
        >
          <span className="w-100 font-weight-bold">
            Experiment Details
          </span>
        </div>

        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Learning Rate</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.learning_rate}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Momentum</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.momentum}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Weight Decay</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.weight_decay}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Optimizer</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.optimizer}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Batch Size</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.batch_size}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Image Size</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.img_size}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Split Ratio</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.split_ratio}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Is Transfer Learning ?</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.is_transfer_learning
              ? "Yes"
              : "No"}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Number Epochs</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.num_epochs}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Is Rectangle ?</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.is_rect ? "Yes" : "No"}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Is Multi Scale ?</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.hyper_params_details?.is_multi_scale
              ? "Yes"
              : "No"}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.hyper_params_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.hyper_params_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Dataset Details
                 */}
        <div
          className="row col-12 text-center modal-contain-title-part"
        >
          <span className="w-100 font-weight-bold">Dataset Details</span>
        </div>

        <div className="row">
          <div className="col col-md-6">
            <span>
              <b>Dataset Name</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.dataset_details?.dataset_name}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Zip URL</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.dataset_details?.zip_url}
          </div>
        </div>
        {//TODO: replace hardcoded value with dynamic value (currently '1' denotes data storage type selected as AWS S3)
        experimentDetails?.dataset_details?.dataset_storage_details?.id ===
          "1" && (
          <>
            <div className="row mt-4">
              <div className="col col-md-6">
                <span>
                  <b>S3 Bucket</b>
                </span>
              </div>
              <div className="col col-md-6">
                {experimentDetails?.dataset_details?.s3_bucket}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col col-md-6">
                <span>
                  <b>S3 Access Key</b>
                </span>
              </div>
              <div className="col col-md-6">
                {experimentDetails?.dataset_details?.s3_access_key}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col col-md-6">
                <span>
                  <b>S3 Secret Key</b>
                </span>
              </div>
              <div className="col col-md-6">
                {experimentDetails?.dataset_details?.s3_secret_key}
              </div>
            </div>
          </>
        )}
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Is Verified</b>
            </span>
          </div>
          <div className="col col-md-6">
            {experimentDetails?.dataset_details?.is_verified ? "Yes" : "No"}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.dataset_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.dataset_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Dataset Storage Details
                 */}
        <div
          className="row col-12 text-center modal-contain-title-part"
        >
          <span className="w-100 font-weight-bold">
            Dataset Storage Details
          </span>
        </div>

        <div className="row mt-5">
          <div className="col col-md-6">
            <span>
              <b>Dataset Type Name</b>
            </span>
          </div>
          <div className="col col-md-6">
            {
              experimentDetails?.dataset_details?.dataset_storage_details
                ?.dataset_storage_name
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Dataset Type Description</b>
            </span>
          </div>
          <div className="col col-md-6">
            {
              experimentDetails?.dataset_details?.dataset_storage_details
                ?.dataset_storage_description
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.dataset_details?.dataset_storage_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.dataset_details?.dataset_storage_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Annotation Details
                 */}
        <div
          className="row col-12 text-center modal-contain-title-part"
        >
          <span className="w-100 font-weight-bold">
            Annotation Type Details
          </span>
        </div>

        <div className="row mt-5">
          <div className="col col-md-6">
            <span>
              <b>Target Annotation Type Name</b>
            </span>
          </div>
          <div className="col col-md-6">
            {
              experimentDetails?.dataset_details?.annotation_details
                ?.annotation_type_name
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Target Annotation Type Description</b>
            </span>
          </div>
          <div className="col col-md-6">
            {
              experimentDetails?.dataset_details?.annotation_details
                ?.annotation_type_name_description
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.dataset_details?.annotation_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}


          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
                .utc(experimentDetails?.dataset_details?.annotation_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}
