import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Images from "./Images";
import { downloadDataSetImage } from "../../../../service/annotationTools.api";
import { warningToast } from "../../../../shared/components/ToastMessage";
import * as action from "../../../../redux/actions/DatasetManagementAction";

function MainImageFile({
  activeKey,
  setActiveKey,
  annotationToolShow,
  dataSetId
}) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [assignButton, setAssignButton] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  useEffect(()=>{
    setSelectedImages([]);
  },[activeKey])


  const selecteCheckbox = (event, data) => {
    let selected = { ...selectedImages };
    if (Object.keys(selected).includes(data._id.$oid)) {
      delete selected[data._id.$oid];
    } else {
      selected[data._id.$oid] = data;
    }
    setSelectedImages({ ...selected });
  };

  useEffect(() => {
    if (Object.keys(selectedImages).length > 0) {
      if (!assignButton) {
          setAssignButton(true);
      }
    } else {
      setAssignButton(false);
    }
  }, [selectedImages]);

  const annotationImageMove = () => {
    annotationToolShow(selectedImages);
  };

  const handleChange = (event, imageListForDataset) => {
    let selected = {};
    if (event.target.checked) {
      imageListForDataset.map(data => {
        selected[data._id.$oid] = data;
      });
      setSelectedImages(selected);
    } else {
      setSelectedImages([]);
    }
  };

  const downloadButtonHandle = data => {
    if (data && data.length > 0) {
      setShowDownloadButton(true);
    } else {
      setShowDownloadButton(false);
    }
  };

  const downloadDataSetImages = dataSetId => {
    downloadDataSetImage(dataSetId)
      .then(function(response) {
        window.open(response?.data, "_blank");
      })
      .catch(e => {
        warningToast("Something went wrong");
      });
  };

  return (
    <div>
      <div style={{ textAlign: "end" }}>
        {activeKey && activeKey === "annotated" && showDownloadButton ? (
          <button
            type="button"
            className="btn btn-primary mb-3  p-2"
            onClick={() => downloadDataSetImages(dataSetId)}
          >
            Download
          </button>
        ) : (
          <></>
        )}
      </div>

      <Tabs
        id="controlled-tab-example"
        activeKey={activeKey}
        onSelect={e => setActiveKey(e)}
        style={{ fontSize: "1.275rem", fontWeight: "500" }}
        className={"round-tab"}
      >
        <Tab eventKey="raw" title="Raw">
          {activeKey && activeKey === "raw" ? (
            <Images
              assignButton={assignButton}
              annotationImageMove={annotationImageMove}
              selecteCheckbox={selecteCheckbox}
              handleChange={handleChange}
              downloadButtonHandle={downloadButtonHandle}
              selectedImages={selectedImages}
              activeKey={activeKey}
              dataSetId={dataSetId}
            />
          ) : (
            <></>
          )}
        </Tab>
        <Tab eventKey="annotated" title="Annotated">
          {activeKey && activeKey === "annotated" ? (
            <Images
              assignButton={assignButton}
              annotationImageMove={annotationImageMove}
              selecteCheckbox={selecteCheckbox}
              handleChange={handleChange}
              downloadButtonHandle={downloadButtonHandle}
              selectedImages={selectedImages}
              activeKey={activeKey}
              dataSetId={dataSetId}
            />
          ) : (
            <></>
          )}
        </Tab>
        <Tab eventKey="all" title="All">
          {activeKey && activeKey === "all" ? (
            <Images
              assignButton={assignButton}
              annotationImageMove={annotationImageMove}
              selecteCheckbox={selecteCheckbox}
              handleChange={handleChange}
              downloadButtonHandle={downloadButtonHandle}
              selectedImages={selectedImages}
              activeKey={activeKey}
              dataSetId={dataSetId}
            />
          ) : (
            <></>
          )}
        </Tab>
      </Tabs>
    </div>
  );
}

export default MainImageFile;
