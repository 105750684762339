import React, { Suspense } from "react";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";
import { ExperimentManagementPage } from "./component/ExperimentManagementPage";

function ExperimentManagementNew() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact={true}
            from="/user/experiments"
            to="/user/experiments/experimentManagementPage"
          />
        }
        <ContentRoute
          path="/user/experiments/experimentManagementPage"
          component={ExperimentManagementPage}
        />
      </Switch>
    </Suspense>
  );
}
export default ExperimentManagementNew;
