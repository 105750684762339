import React from "react";
export function TrainColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openTestExperimentDialog }
) {
  return (
    <>
      <button
        type="button"
        className="btn btn-primary col-xl-12"
        disabled={
          row.experiment_status.toLowerCase() !== "pending" &&
          row.experiment_status.toLowerCase() !== "error"
        }
        onClick={() => openTestExperimentDialog(row.id)}
      >
        Train
      </button>
    </>
  );
}
