import React from "react";
import { Button, Modal } from "react-bootstrap";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import * as moment from "moment/moment";

function InfoHyperParamsModal({ show, handleClose, infoModalData }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderCommon title={`Hyper Parameters Details`} />
        <Modal.Body>
          <div className="text-center modal-contain-title-part">
            <span className="w-100 font-weight-bold">
              Hyper Parameters Details
            </span>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Hyper Parameter Name</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_parameter_name}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Learning Rate</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.learning_rate}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Momentum</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.momentum}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Weight Decay</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.weight_decay}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Optimizer</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.optimizer}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Batch Size</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.batch_size}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Image Size</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.img_size}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Split Ratio</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.split_ratio}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Transfer Learning</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.is_transfer_learning ? "Yes" : "No"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Number Epochs</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.num_epochs}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Rectangle</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.is_rect ? "Yes" : "No"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Multi Scale</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.is_multi_scale ? "Yes" : "No"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InfoHyperParamsModal;
