import React from 'react';
import {Modal} from "react-bootstrap";

function ModalHeaderCommon({title}) {
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
            </Modal.Header>
        </>
    );
}

export default ModalHeaderCommon;