import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import HyperParamsTable from "./HyperParamsTable";
import AddEditHyperParamsModal from "./AddEditHyperParamsModal";
import * as actions from "../../../../redux/actions/hyperParamsManagementAction";
import { SearchText } from "../../../../shared/components/SearchText";
import * as action from "../../../../redux/actions/hyperParamsManagementAction";
import {useHistory} from "react-router-dom";

export function HyperParamsManagementCard() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [search, setSearch] = useState("")
  const {user} = useSelector((state) => state.auth, shallowEqual);
  const searchInput = useRef("");
  const dispatch = useDispatch();

  const { hyperParamsData, listLoading } = useSelector(
    state => ({
      hyperParamsData: state.hyperParamsManagement,
      listLoading: state.hyperParamsManagement?.listLoading
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentItems(hyperParamsData?.entities);
    setTotalCount(hyperParamsData?.hyperParamsTotalSize);
    setPageNo(hyperParamsData?.page);
    setPageSize(hyperParamsData?.size);
    setShowTable(listLoading);
  }, [hyperParamsData]);

  const handleClose = () => {
    setShow(false);
    setEditModalData([]);

  };
  const handleShow = () => {
    if(!user?.user_email){
      history.push(`/user/profile/profile-information`);
    }else {
      setShow(true);
      setEditModalData([]);
    }
  };

  const saveHyperParams = hyperParams => {
    if(hyperParams.is_transfer_learning==="no"){
      hyperParams.is_transfer_learning=false
    }if(hyperParams.is_multi_scale==="no"){
      hyperParams.is_multi_scale=false
    }if(hyperParams.is_transfer_learning==="yes"){
      hyperParams.is_transfer_learning=true
    }if(hyperParams.is_multi_scale==="yes"){
      hyperParams.is_multi_scale=true
    }
    if (!hyperParams.id) {
      dispatch(action.createHyperParams(hyperParams)).then(() => {
        dispatch(action.fetchHyperParams(1, 10, search));
        handleClose();
      });
    } else {
      dispatch(action.hyperParamsUpdate(hyperParams)).then(() => {
        dispatch(action.fetchHyperParams(pageNo, pageSize, search));
        handleClose();
      });
    }
  };

  const pageChange = (page, sizePerPage) => {
    dispatch(actions.fetchHyperParams(page, sizePerPage, search));
  };

  const sizePerPageChangeApiCall = (page, sizePerPage) => {
    dispatch(actions.fetchHyperParams(1, sizePerPage, search));
  };

  const onChangeSearch = e => {
    const searchStr =
      e?.target?.value.trim() || searchInput.current.value.trim();
    setSearch(searchStr)
    dispatch(actions.fetchHyperParams(1, 10, searchStr));
  };

  const editModalDataTable = (_, row) => {
    setEditModalData(row);
    setShow(true);
  };

  return (
    <>
      <Card style={{ marginBottom: "0px" }}>
        <CardHeader title="Hyper Parameters Details">
          <CardHeaderToolbar>
            <div className={"d-flex"}>
              <SearchText
                reference={searchInput}
                onChangeHandler={onChangeSearch}
                searchField={"Search on Name or Batch Size"}
              />
              <Button
                color={"primary"}
                className={"ml-2 col-lg-4"}
                onClick={handleShow}
              >
                Add Hyper Param
              </Button>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody style={{ padding: "5px" }}>
          <HyperParamsTable
            currentItems={currentItems}
            showTable={showTable}
            pageNo={pageNo}
            pageSize={pageSize}
            totalCount={totalCount}
            pageChange={(page, sizePerPage) => pageChange(page, sizePerPage)}
            sizePerPageChangeApiCall={(page, sizePerPage) =>
              sizePerPageChangeApiCall(page, sizePerPage)
            }
            editModalDataTable={editModalDataTable}
          />
        </CardBody>
      </Card>
      <AddEditHyperParamsModal
        show={show}
        saveHyperParams={saveHyperParams}
        handleClose={handleClose}
        editModalData={editModalData}
      />
    </>
  );
}
