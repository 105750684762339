import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "./reducers/authRedux";
import {UserSlice} from "../containers/SuperAdmin/Users/_redux/UserSlice";
import {FrameworkManagementSlice} from "../containers/SuperAdmin/FrameworkManagement/_redux/FrameworkManagementSlice";
import {ModelTypeSlice} from "../containers/SuperAdmin/ModelType/_redux/ModelTypeSlice";
import {AnnotationTypeSlice} from "../containers/SuperAdmin/AnnotationType/_redux/AnnotationTypeSlice";
import {DatasetStorageTypeSlice} from "../containers/SuperAdmin/DatasetStorageType/_redux/DatasetStorageTypeSlice";
import {ModelSlice} from "../containers/SuperAdmin/ModelManagement/_redux/ModelSlice";
import {DatasetManagementSlice} from "./reducers/DatasetManagementSlice";
import {ExperimentManagementAdminSlice} from "../containers/SuperAdmin/ExperimentManagement/_redux/ExperimentManagementAdminSlice"
import {ProjectManagementAdminSlice} from "../containers/SuperAdmin/ProjectManagement/_redux/ProjectManagementAdminSlice";
import {companyRegistration} from "./reducers/CompanyRegistrationSlice"
import {userRegistration} from "../containers/User/ExperimentManagement/_wizard_redux/userReg/UserRegistrationSlice"
import {modelDetails} from "../containers/User/ExperimentManagement/_wizard_redux/modelDetails/ModelDetailsSlice"
import {RegisterAndLoginSlice} from "./reducers/registerAndLoginSlice";
import {ProjectManagementSlice} from "./reducers/projectManagementSlice";
import {HyperParamsManagementSlice} from "./reducers/hyperParamsManagementSlice";
import {ExperimentManagementSlice} from "./reducers/experimentManagementSlice";
export const rootReducer = combineReducers({
    auth: auth.reducer,
    users: UserSlice.reducer,
    frameworkManagement: FrameworkManagementSlice.reducer,
    modelType: ModelTypeSlice.reducer,
    annotationType: AnnotationTypeSlice.reducer,
    datasetStorageType: DatasetStorageTypeSlice.reducer,
    model: ModelSlice.reducer,
    projectManagement: ProjectManagementSlice.reducer,
    datasetManagement: DatasetManagementSlice.reducer,
    hyperParamsManagement: HyperParamsManagementSlice.reducer,
    experimentManagement: ExperimentManagementSlice.reducer,
    ExperimentManagementAdminSlice : ExperimentManagementAdminSlice.reducer,
    projectManagementAdmin: ProjectManagementAdminSlice.reducer,
    // AnnotationManagement: AnnotationManagementSlice.reducer,
    companyRegistration: companyRegistration.reducer,
    userRegistration: userRegistration.reducer,
    modelDetails: modelDetails.reducer,
    registerAndLogin : RegisterAndLoginSlice.reducer,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
