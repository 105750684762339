/* eslint-disable */
import {Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useMemo, useRef} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {successToast} from "../../shared/components/ToastMessage"
function PreData() {
    const textAreaRef = useRef(null);
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            navigator.clipboard.writeText(row.dataset)
        },
    }
    const columns = [
        {
            dataField: 'id',
            text: 'ID' ,
        },
        {
            dataField: 'dataset',
            text: 'Dataset',
        },
    ]
    const [data,setData] = React.useState([]);
    const [searchData , setSearch] = React.useState('');
    const dataset = [
        "Power plugs and sockets",
        "Baked goods",
        "Mug",
        "Submarine",
        "Serving tray",
        "Umbrella",
        "Binoculars",
        "Ant",
        "Chopsticks",
        "Red panda",
        "Crutch",
        "Waffle iron",
        "Motorcycle",
        "Guitar",
        "Snake",
        "Pizza",
        "Drum",
        "Tiger",
        "Swan",
        "Winter melon",
        "Invertebrate",
        "Hot dog",
        "Tool",
        "Can opener",
        "Kite",
        "Ski",
        "Washing machine",
        "Pitcher (Container)",
        "Accordion",
        "Ladle",
        "Calculator",
        "Shirt",
        "Raccoon",
        "Teapot",
        "Footwear",
        "Armadillo",
        "Parrot",
        "Boot",
        "Training bench",
        "Radish",
        "Curtain",
        "Bat (Animal)",
        "Toothbrush",
        "Carnivore",
        "Treadmill",
        "Cocktail shaker",
        "Coat",
        "Girl",
        "Scorpion",
        "Eagle",
        "Tin can",
        "Airplane",
        "Willow",
        "Tree house",
        "Sea lion",
        "Table tennis racket",
        "Swim cap",
        "Bench",
        "Segway",
        "Pillow",
        "Blender",
        "Reptile",
        "Convenience store",
        "Violin",
        "Coffee",
        "Cheetah",
        "Suit",
        "Lipstick",
        "Bed",
        "Scale",
        "Vehicle",
        "Container",
        "Helicopter",
        "Penguin",
        "Beer",
        "Flashlight",
        "Poster",
        "House",
        "Crocodile",
        "Rays and skates",
        "Cookie",
        "Doll",
        "Digital clock",
        "Leopard",
        "Artichoke",
        "Sombrero",
        "Apple",
        "Miniskirt",
        "Heater",
        "Plate",
        "Piano",
        "Nightstand",
        "Coin",
        "Baseball glove",
        "Cattle",
        "Pear",
        "Picnic basket",
        "Human foot",
        "Jug",
        "Light bulb",
        "Bust",
        "Blue jay",
        "Plant",
        "Truck",
        "Human leg",
        "Zucchini",
        "Polar bear",
        "Food",
        "Train",
        "Filing cabinet",
        "Kitchen appliance",
        "Hiking equipment",
        "Lemon",
        "Waste container",
        "Person",
        "Indoor rower",
        "Lizard",
        "Jacuzzi",
        "Fireplace",
        "Spoon",
        "High heels",
        "Ladybug",
        "Sun hat",
        "Stairs",
        "Panda",
        "Building",
        "Dairy Product",
        "Human ear",
        "Wheel",
        "Sewing machine",
        "Laptop",
        "Christmas tree",
        "Handgun",
        "Toy",
        "Syringe",
        "Tower",
        "Table",
        "Seafood",
        "Bicycle",
        "Moths and butterflies",
        "Office building",
        "Duck",
        "Bookcase",
        "Bus",
        "Pancake",
        "Band-aid",
        "Falcon",
        "Tap",
        "Tea",
        "Sushi",
        "Medical equipment",
        "Mobile phone",
        "Cricket ball",
        "Cat",
        "Bomb",
        "Maple",
        "Lighthouse",
        "Vegetable",
        "Tank",
        "Parachute",
        "Loveseat",
        "Surfboard",
        "Racket",
        "Cart",
        "Computer keyboard",
        "Oboe",
        "Toilet paper",
        "Ball",
        "Mouse",
        "Sunglasses",
        "Mixer",
        "Starfish",
        "Briefcase",
        "Ratchet (Device)",
        "Jellyfish",
        "Rhinoceros",
        "Crown",
        "Tennis racket",
        "Butterfly",
        "Chisel",
        "Spatula",
        "Shelf",
        "Cream",
        "Chainsaw",
        "Camel",
        "Paddle",
        "Personal care",
        "Beetle",
        "Owl",
        "Drawer",
        "Box",
        "Marine invertebrates",
        "Billiard table",
        "Tortoise",
        "Organ (Musical Instrument)",
        "Axe",
        "Wardrobe",
        "Lantern",
        "Coffee cup",
        "Tablet computer",
        "Hippopotamus",
        "Swimwear",
        "Hair spray",
        "Stapler",
        "Ostrich",
        "Wine glass",
        "Bathtub",
        "Screwdriver",
        "Bear",
        "Flying disc",
        "Pizza cutter",
        "Banjo",
        "Necklace",
        "Cutting board",
        "Headphones",
        "Weapon",
        "Candle",
        "Drinking straw",
        "Teddy bear",
        "Saxophone",
        "Personal flotation device",
        "Drill (Tool)",
        "Sword",
        "Microwave oven",
        "Canoe",
        "Trumpet",
        "Gondola",
        "Ruler",
        "Shower",
        "Horizontal bar",
        "Tart",
        "Coffeemaker",
        "Pen",
        "Soap dispenser",
        "Rugby ball",
        "Missile",
        "Ambulance",
        "Turkey",
        "Ladder",
        "Tennis ball",
        "Car",
        "Wine",
        "Food processor",
        "Taxi",
        "Flag",
        "Scoreboard",
        "Football",
        "Bathroom cabinet",
        "Tire",
        "Human mouth",
        "Strawberry",
        "Nail (Construction)",
        "Watch",
        "Studio couch",
        "Couch",
        "French horn",
        "Squid",
        "Cello",
        "Bowl",
        "Alarm clock",
        "Oven",
        "Bicycle wheel",
        "Deer",
        "Jacket",
        "Beehive",
        "Turtle",
        "Porcupine",
        "Barge",
        "Human head",
        "Golf cart",
        "Palm tree",
        "Snowboard",
        "Skyscraper",
        "Auto part",
        "Window",
        "Tableware",
        "Otter",
        "Stationary bicycle",
        "Cassette deck",
        "Infant bed",
        "Billboard",
        "Human eye",
        "Peach",
        "Sparrow",
        "Dumbbell",
        "Grapefruit",
        "Stool",
        "Doughnut",
        "Scarf",
        "Fashion accessory",
        "Door handle",
        "Bread",
        "Human face",
        "Rocket",
        "Envelope",
        "Human beard",
        "Snowman",
        "Refrigerator",
        "Crab",
        "Window blind",
        "Lobster",
        "Shellfish",
        "Maracas",
        "Skull",
        "Eraser",
        "Toilet",
        "Porch",
        "Bee",
        "Slow cooker",
        "Stop sign",
        "Beaker",
        "Fountain",
        "Pressure cooker",
        "Remote control",
        "Musical instrument",
        "Spice rack",
        "Sculpture",
        "Furniture",
        "Elephant",
        "Jet ski",
        "Fax",
        "Wheelchair",
        "Dolphin",
        "Ceiling fan",
        "Snowmobile",
        "Bell pepper",
        "Hair dryer",
        "Kangaroo",
        "Shotgun",
        "Jaguar (Animal)",
        "Countertop",
        "Microphone",
        "Frog",
        "Mirror",
        "Wrench",
        "Lavender (Plant)",
        "Woman",
        "Cabbage",
        "Human body",
        "Jeans",
        "Torch",
        "Trousers",
        "Marine mammal",
        "Frying pan",
        "Trombone",
        "Bottle",
        "Bidet",
        "Bagel",
        "Animal",
        "Snail",
        "Telephone",
        "Cannon",
        "Glove",
        "Fast food",
        "Cosmetics",
        "Harmonica",
        "Bicycle helmet",
        "Measuring cup",
        "Gas stove",
        "Suitcase",
        "Limousine",
        "Bronze sculpture",
        "Ipod",
        "Popcorn",
        "Sports uniform",
        "Saucer",
        "Dress",
        "Adhesive tape",
        "Hedgehog",
        "Dragonfly",
        "Plastic bag",
        "Egg (Food)",
        "Human nose",
        "Grinder",
        "Rifle",
        "Land vehicle",
        "Television",
        "Milk",
        "Pasta",
        "Pig",
        "Mushroom",
        "Balloon",
        "Salt and pepper shakers",
        "Sports equipment",
        "Fedora",
        "Face powder",
        "Cake",
        "Harbor seal",
        "Centipede",
        "Grape",
        "Worm",
        "Mango",
        "Tripod",
        "Chicken",
        "Street light",
        "Handbag",
        "Paper towel",
        "Harp",
        "Skirt",
        "Light switch",
        "Closet",
        "Magpie",
        "Bathroom accessory",
        "Croissant",
        "Tent",
        "Common sunflower",
        "Antelope",
        "Cheese",
        "Punching bag",
        "Chair",
        "Caterpillar",
        "Cabinetry",
        "Chime",
        "Whisk",
        "Shrimp",
        "Dog bed",
        "Oyster",
        "Kitchenware",
        "Picture frame",
        "Stethoscope",
        "Cooking spray",
        "Juice",
        "Pastry",
        "Fox",
        "Squash (Plant)",
        "Houseplant",
        "Bird",
        "Whiteboard",
        "Plumbing fixture",
        "Honeycomb",
        "Dishwasher",
        "Candy",
        "Cake stand",
        "Lion",
        "Tick",
        "Sea turtle",
        "Toaster",
        "Shark",
        "Drink",
        "Whale",
        "Traffic sign",
        "Rabbit",
        "Harpsichord",
        "Helmet",
        "Belt",
        "Book",
        "Burrito",
        "Squirrel",
        "Towel",
        "Orange",
        "Stretcher",
        "Wok",
        "Garden Asparagus",
        "Human hand",
        "Knife",
        "Bowling equipment",
        "Taco",
        "Sheep",
        "Broccoli",
        "Vehicle Registration plate",
        "Musical keyboard",
        "Hat",
        "Ring binder",
        "Sock",
        "Dessert",
        "Shorts",
        "Corded phone",
        "Man",
        "Human arm",
        "Cupboard",
        "Spider",
        "Brassiere",
        "Dice",
        "Cucumber",
        "Goose",
        "Ice cream",
        "Flute",
        "Snack",
        "Kitchen utensil",
        "Pretzel",
        "Seat belt",
        "Pumpkin",
        "Wall clock",
        "Zebra",
        "Hamster",
        "Dinosaur",
        "Cantaloupe",
        "Bull",
        "Fire hydrant",
        "Waffle",
        "Dog",
        "Kitchen knife",
        "Watercraft",
        "Common fig",
        "Vase",
        "Tree",
        "Aircraft",
        "Backpack",
        "Mammal",
        "Monkey",
        "Rose",
        "Submarine sandwich",
        "Guacamole",
        "Horse",
        "Sink",
        "Lamp",
        "Insect",
        "Fruit",
        "Boy",
        "Goat",
        "Flowerpot",
        "Muffin",
        "Sofa bed",
        "Scissors",
        "Human hair",
        "Kettle",
        "Sandwich",
        "Fish",
        "Earrings",
        "Facial tissue holder",
        "Chest of drawers",
        "Bow and arrow",
        "Parking meter",
        "Door",
        "Pineapple",
        "Coconut",
        "Lynx",
        "Carrot",
        "Cowboy hat",
        "Cat furniture",
        "Sandal",
        "Watermelon",
        "Clock",
        "Clothing",
        "Tomato",
        "Volleyball (Ball)",
        "Hamburger",
        "Wine rack",
        "Kitchen & dining room table",
        "Swimming pool",
        "Tiara",
        "Diaper",
        "Canary",
        "Skunk",
        "Goggles",
        "Koala",
        "Camera",
        "Wood-burning stove",
        "Brown bear",
        "Banana",
        "Mule",
        "Humidifier",
        "Flower",
        "Balance beam",
        "Hand dryer",
        "Roller skates",
        "Lily",
        "Hammer",
        "Printer",
        "Woodpecker",
        "Seahorse",
        "Alpaca",
        "Luggage and bags",
        "Isopod",
        "Coffee table",
        "Fork",
        "Baseball bat",
        "Tie",
        "Pencil sharpener",
        "Platter",
        "Bottle opener",
        "Computer monitor",
        "Van",
        "Unicycle",
        "Cocktail",
        "Football helmet",
        "Pomegranate",
        "Mechanical fan",
        "Glasses",
        "Castle",
        "Goldfish",
        "Dagger",
        "Raven",
        "Salad",
        "Golf ball",
        "Giraffe",
        "Snowplow",
        "Barrel",
        "Boat",
        "Home appliance",
        "Desk",
        "Traffic light",
        "Potato",
        "Perfume",
        "Computer mouse",
        "French fries",
        "Paper cutter",
        "Pencil case",
        "Mixing bowl",
        "Office supplies",
        "Skateboard"
    ]
    const handleChange = (e) => {
        setSearch(e.target.value)
        let data = []
        dataset.filter((x,index) => {
            if(x.toLowerCase().includes((e.target.value).toLowerCase())) {
                data.push({"id": index+1, "dataset": x})
            }
        })
        setData(data);
    }

    useEffect(()=>{
        let data = []
        dataset.map((x,index) => {
            data.push({"id" : index + 1 , "dataset" : x})
        })
        setData(data);
        successToast("Click on Name To Copy")
    },[])
    return (
        <>
            <Card>
                <CardHeader title=" Pre-available Dataset">
                    <CardHeaderToolbar>
                            <Form.Label>
                                <b>
                                    Search data
                                </b>
                                </Form.Label>
                            <Form.Control
                                className="mb-2"
                                        type="text"
                                        name="search"
                                        value={searchData}
                                        onChange={(e)=>{handleChange(e)}}
                                    />

                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="mt-2">
                    <BootstrapTable
                        classes="table table-hover-cells table-head-custom table-vertical-center table-horizontal-center overflow-hidden"
                        bootstrap4
                        wrapperClasses="table-responsive"
                        keyField="id"
                        bordered={false}
                        data={data}
                        columns={ columns } rowEvents={ rowEvents }
                        ref={textAreaRef}
                        pagination={ paginationFactory() }
                    >
                    </BootstrapTable>
                </CardBody>
            </Card>
                                            </>

    );
}
export default PreData;