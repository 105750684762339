import { Route } from "react-router-dom";
import React from "react";
import { ProjectManagementUIProvider } from "./ProjectManagementUIContext";
import { ProjectManagementCard } from "./ProjectManagementCard";
import { ProjectManagementEditDialog } from "./project-management-edit-dialog/ProjectManagementEditDialog";

export function ProjectManagementPage({ history }) {
  const projectManagementPageBaseUrl = "/projects/projectManagementPage";

  const projectManagementUIEvents = {
    openEditProjectManagementDialog: id => {
      history.push(`${projectManagementPageBaseUrl}/${id}/edit`);
    }
  };

  return (
    <ProjectManagementUIProvider
      projectManagementUIEvents={projectManagementUIEvents}
    >
      <Route path={`${projectManagementPageBaseUrl}/:id/edit`}>
        {({ history, match }) => (
          <ProjectManagementEditDialog
            show={match != null}
            id={match?.params?.id}
            onHide={() => {
              history.push(projectManagementPageBaseUrl);
            }}
          />
        )}
      </Route>
      <ProjectManagementCard />
    </ProjectManagementUIProvider>
  );
}
