// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect, useState} from "react";
import {Col, Modal, Row} from "react-bootstrap";
import {useFormik} from "formik";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../ExperimentManagement/_wizard_redux/modelDetails/ModelDetailsAction";
// Validation schema

const initialCompanyValues = {
  model_name: "",
  model_description: "",
  model_infer_cpu_speed: "",
  model_infer_gpu_speed: "",
  model_size: "",
  model_category_id: 0,
  company_contact: "",
  company_poc: "",
  batch_size: 0,
  num_epochs: 0
};

export default function ModelDetails({
  experimentDetails,
  onHide,
  id,
  accountType,
  deploymentType
}) {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user
    }),
    shallowEqual
  );
  const [alert, setAlert] = React.useState({
    success: false,
    danger: false,
    warning: false,
    show: false,
    showConfirm: true,
    showCancel: true,
    cancelText: "Cancel"
  });

  const showAlert = () => {
    setAlert({
      ...alert,
      show: true,
      danger: true,
      success: false,
      showConfirm: true,
      showCancel: true,
      cancelText: "Cancel"
    });
  };

  const handleChange = (key, event) => {
    let value = event.target.value;
    let data = { ...formData };
    data[key] = value;
    setFormData(data);
    dispatch(actions.saveModelData(data, id, user.id, false, deploymentType));
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: initialCompanyValues
  });

  useEffect(() => {
    dispatch(actions.saveModelData(experimentDetails, id, user.id, false));
  }, []);

  const [formData, setFormData] = useState({
    experiment_name: experimentDetails?.experiment_name
      ? experimentDetails?.experiment_name
      : "",
    experiment_description: experimentDetails?.experiment_description
      ? experimentDetails?.experiment_description
      : "",
    model_infer_cpu_speed: experimentDetails?.model_details
      ?.model_infer_cpu_speed
      ? experimentDetails.model_details?.model_infer_cpu_speed
      : "",
    model_infer_gpu_speed: experimentDetails?.model_details
      ?.model_infer_gpu_speed
      ? experimentDetails.model_details?.model_infer_gpu_speed
      : "",
    framework_id: experimentDetails?.model_details?.framework_id
      ? experimentDetails.model_details?.framework_id
      : "",
    model_size: experimentDetails?.model_details?.model_size
      ? experimentDetails.model_details?.model_size
      : "",
    batch_size: experimentDetails?.hyper_params_details?.batch_size
      ? experimentDetails?.hyper_params_details?.batch_size
      : "",
    num_epochs: experimentDetails?.hyper_params_details?.num_epochs
      ? experimentDetails?.hyper_params_details?.batch_size
      : "",
    img_size: experimentDetails?.hyper_params_details?.img_size
      ? experimentDetails?.hyper_params_details?.batch_size
      : "",
    model_labels_list: "",
    deploymentType: deploymentType
  });

  return (
    <>
      <Modal.Body>
        <Row>
          <Col xl={2}></Col>
          <Col xl={8}>
            <div
              className="login-form login-signin remove-scroll"
              style={{
                display: "block",
                maxHeight: "539px",
                overflowY: "auto",
                overflowX: "hidden"
              }}
            >
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">Model Details</h3>
                {accountType === "no" ? (
                  <p className="text-muted font-weight-bold">
                    Review your Modal details steps 3/3
                  </p>
                ) : (
                  <p className="text-muted font-weight-bold">
                    Review your Modal details 2/2
                  </p>
                )}
              </div>

              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                {/* begin: Alert */}
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                {/* end: Alert */}

                {/* begin: Company Name */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Model Name
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Model Name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "experiment_name"
                        )}`}
                        name="experiment_name"
                        defaultValue={formData.experiment_name}
                        onChange={event =>
                          handleChange("experiment_name", event)
                        }
                      />
                      {formik.touched.company_name &&
                      formik.errors.experiment_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.experiment_name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Name */}

                {/* begin: Company Email */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Model Description
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Model Description"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "experiment_description"
                        )}`}
                        name="experiment_description"
                        defaultValue={formData.experiment_description}
                        onChange={event =>
                          handleChange("experiment_description", event)
                        }
                      />
                      {formik.touched.company_email &&
                      formik.errors.experiment_description ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.experiment_description}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Email */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Model Label List
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Model Label List"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "model_labels_list"
                        )}`}
                        name="model_labels_list"
                        defaultValue={formData.model_labels_list}
                        onChange={event =>
                          handleChange("model_labels_list", event)
                        }
                      />
                      {formik.touched.company_pin_code &&
                      formik.errors.model_labels_list ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.model_labels_list}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* begin: Company Description */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    CPU speed
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        type="text"
                        placeholder="model_infer_cpu_speed"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "model_infer_cpu_speed"
                        )}`}
                        disabled={true}
                        name="company_description"
                        defaultValue={formData.model_infer_cpu_speed}
                        onChange={event =>
                          handleChange("model_infer_cpu_speed", event)
                        }
                      />
                      {formik.touched.company_description &&
                      formik.errors.model_infer_cpu_speed ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.model_infer_cpu_speed}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Description */}

                {/* begin: Company Address */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    GPU speed
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        type="text"
                        placeholder="model_infer_gpu_speed"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "model_infer_gpu_speed"
                        )}`}
                        disabled={true}
                        name="model_infer_gpu_speed"
                        defaultValue={formData.model_infer_gpu_speed}
                        onChange={event =>
                          handleChange("model_infer_gpu_speed", event)
                        }
                      />
                      {formik.touched.company_address &&
                      formik.errors.model_infer_gpu_speed ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.model_infer_gpu_speed}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Address */}

                {/* begin: Company Pin Code */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Model size
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Model Size"
                        type="text"
                        disabled={true}
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "model_size"
                        )}`}
                        name="model_size"
                        defaultValue={formData.model_size}
                        onChange={event => handleChange("model_size", event)}
                      />
                      {formik.touched.company_pin_code &&
                      formik.errors.model_size ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.model_size}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Pin Code */}

                {/* begin: Company Website */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Batch Size
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Batch Size"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "batch_size"
                        )}`}
                        disabled={true}
                        name="batch_size"
                        defaultValue={formData.batch_size}
                        onChange={event => handleChange("batch_size", event)}
                      />
                      {formik.touched.company_website &&
                      formik.errors.batch_size ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.batch_size}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Website */}

                {/* begin: Company Contact */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Epoch No
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Epoch Number"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "num_epochs"
                        )}`}
                        disabled={true}
                        name="num_epochs"
                        defaultValue={formData.num_epochs}
                        onChange={event => handleChange("num_epochs", event)}
                      />
                      {formik.touched.company_contact &&
                      formik.errors.num_epochs ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.num_epochs}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Contact */}

                {/* begin: Company POC */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    FrameWork ID
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="FrameWork ID"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "framework_id"
                        )}`}
                        disabled={true}
                        name="framework_id"
                        defaultValue={formData.framework_id}
                        onChange={event => handleChange("framework_id", event)}
                      />
                      {formik.touched.company_poc &&
                      formik.errors.framework_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.framework_id}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company POC */}

                {/* begin: Company POC Contact */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Image Size
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Image Size"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "img_size"
                        )}`}
                        name="img_size"
                        disabled={true}
                        defaultValue={formData.img_size}
                        onChange={event => handleChange("img_size", event)}
                      />
                      {formik.touched.company_poc_contact &&
                      formik.errors.img_size ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.img_size}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col xl={2}></Col>
        </Row>
      </Modal.Body>
    </>
  );
}
