import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as moment from "moment/moment";
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip";
import {BoootstrapTable} from "../../../../shared/components/BoootstrapTable";

function ProjectTable({
  currentItems,
  showTable,
  totalCount,
  pageNo,
  pageSize,
  pageChange,
  sizePerPageChangeApiCall,
  editModalDataTable
}) {
  const [filterEntities, setFilterEntities] = useState([]);

  const columns = [
    {
      dataField: "#",
      text: "Index",
      align: "center",
      formatter: (cell, row, rowIndex) => {
        return <span>{(pageNo - 1) * pageSize + (rowIndex + 1)}</span>;
      }
    },
    {
      dataField: "project_name",
      text: "Project Name",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (cell, _) => {
        return (
          <>
            <Tooltip
              title={<div className="tools"> {cell}</div>}
              placement={"top-end"}
            >
              <span className={"project-table-name-length"}>{cell}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      dataField: "project_description",
      text: "Project Description",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (cell, _) => {
        return (
          <>
            <Tooltip
              title={<div className="tools"> {cell}</div>}
              placement={"top-end"}
            >
              <span className={"project-table-name-length"}>{cell}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      dataField: "created_date",
      text: "Created Date",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (_, row) =>
        moment
          .utc(row?.created_date)
          .local()
          .format("DD MMMM YYYY, HH:mm:ss")
    },
    {
      dataField: "updated_date",
      text: "Updated Date",
      align: "center",
      style: {
        minWidth: "55px"
      },
      formatter: (_, row) =>
        moment
          .utc(row?.updated_date)
          .local()
          .format("DD MMMM YYYY, HH:mm:ss")
    },
    {
      dataField: "action",
      text: "Actions",
      align: "center",
      formatter: (cellContent, row) => {
        return (
          <>
            <UncontrolledDropdown direction="left">
              <DropdownToggle className="dropdown-toggle-btn">
                   <span className="svg-icon svg-icon-md svg-icon-primary icon-hover" style={{color:"#B5B5C3"}}>
                    <SVG

                        src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/three-dots-vertical.svg"
                        )}
                    />
                   </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="text-hover-btn dropdown-menu-color">

                    <span className=" text-dark-75 text-hover-primary "
                     onClick={() => editModalDataTable(cellContent, row)}>
                     <span className="svg-icon svg-icon-md svg-icon-primary mr-2">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                          )}
                      />
                    </span>Edit Project
                    </span>


                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        );
      },
      style: {
        minWidth: "55px"
      }
    }
  ];

  useEffect(() => {
    setFilterEntities(currentItems);
  }, [currentItems]);

  const onPageChange = (page, sizePerPage) => {
    pageChange(page, sizePerPage);
  };
  const sizePerPageChange = (page, sizePerPage) => {
    sizePerPageChangeApiCall(sizePerPage, page);
  };

  return (
    <>
      <BlockUi tag="div" blocking={showTable} color="#147b82">
        {filterEntities.length > 0 && (
          <BoootstrapTable
            sizePerPageList={[
              { text: "10", value: 10 },
              { text: "5", value: 5 },
              { text: "3", value: 3 }
            ]}
            hideSizePerPage={false}
            showTotal={true}
            alwaysShowAllBtns={true}
            hidePageListOnlyOnePage={true}
            columns={columns}
            data={filterEntities}
            sizePerPage={pageSize}
            page={pageNo}
            totalSize={totalCount}
            onTableChange={onPageChange}
            sizePerPageChange={sizePerPageChange}
          />
        )}
        {filterEntities.length === 0 && (
          <h3 className={"mt-5 d-flex justify-content-center"}>
            No Data Found
          </h3>
        )}
      </BlockUi>
    </>
  );
}

export default ProjectTable;
