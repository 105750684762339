import React from "react";
import {Button, Modal} from "react-bootstrap";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import * as moment from "moment/moment";

function InfoImportDatasetModal({ show, handleClose, infoModalData }) {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                scrollable={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalHeaderCommon title={`Dataset Details`} />
                <Modal.Body>
                    <div className="row col-12 text-center modal-contain-title-part">
                        <span className="w-100 font-weight-bold">Dataset Details</span>
                    </div>
                    <div className="row">
                        <div className="col col-md-6">
                            <span>
                                <b>Dataset Name</b>
                            </span>
                        </div>
                        <div className="col col-md-6">{infoModalData?.dataset_name}</div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Zip URL</b>
                            </span>
                        </div>
                        <div className="col col-md-6">{infoModalData?.zip_url}</div>
                    </div>
                    {infoModalData?.dataset_storage_details?.id === 1 && (<>
                            <div className="row mt-4">
                                <div className="col col-md-6">
                                    <span>
                                        <b>S3 Bucket</b>
                                    </span>
                                </div>
                                <div className="col col-md-6">{infoModalData?.s3_bucket}</div>
                            </div>
                            <div className="row mt-4">
                                <div className="col col-md-6">
                                    <span>
                                        <b>S3 Access Key</b>
                                    </span>
                                </div>
                                <div className="col col-md-6">{infoModalData?.s3_access_key}</div>
                            </div>
                            <div className="row mt-4">
                                <div className="col col-md-6">
                                    <span>
                                        <b>S3 Secret Key</b>
                                    </span>
                                    </div>
                                    <div className="col col-md-6">{infoModalData?.s3_secret_key}</div>
                                </div>
                            </>
                        )}
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Is Verified</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {infoModalData?.is_verified ? "Yes" : "No"}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Created Date</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {moment
                                .utc(infoModalData?.created_date)
                                .local()
                                .format("DD MMMM YYYY, HH:mm:ss")}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Updated Date</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {moment
                                .utc(infoModalData?.updated_date)
                                .local()
                                .format("DD MMMM YYYY, HH:mm:ss")}
                        </div>
                    </div>

                    <div className="row col-12 text-center modal-contain-title-part">
                        <span className="w-100 font-weight-bold">
                            Dataset Storage Details
                        </span>
                    </div>

                    <div className="row mt-5">
                        <div className="col col-md-6">
                            <span>
                                <b>Dataset Type Name</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {infoModalData?.dataset_storage_details?.dataset_storage_name}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Dataset Type Description</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {
                                infoModalData?.dataset_storage_details
                                    ?.dataset_storage_description
                            }
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Created Date</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {moment
                                .utc(infoModalData?.dataset_storage_details?.created_date)
                                .local()
                                .format("DD MMMM YYYY, HH:mm:ss")}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Updated Date</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {moment
                                .utc(infoModalData?.dataset_storage_details?.updated_date)
                                .local()
                                .format("DD MMMM YYYY, HH:mm:ss")}
                        </div>
                    </div>

                    <div className="row col-12 text-center modal-contain-title-part">
                        <span className="w-100 font-weight-bold">
                            Annotation Type Details
                        </span>
                    </div>

                    <div className="row mt-5">
                        <div className="col col-md-6">
                            <span>
                                <b>Annotation Type Name</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {infoModalData?.annotation_details?.annotation_type_name}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Annotation Type Description</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {
                                infoModalData?.annotation_details
                                    ?.annotation_type_name_description
                            }
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Created Date</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {moment
                                .utc(infoModalData?.annotation_details?.created_date)
                                .local()
                                .format("DD MMMM YYYY, HH:mm:ss")}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col col-md-6">
                            <span>
                                <b>Updated Date</b>
                            </span>
                        </div>
                        <div className="col col-md-6">
                            {moment
                                .utc(infoModalData?.annotation_details?.updated_date)
                                .local()
                                .format("DD MMMM YYYY, HH:mm:ss")}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InfoImportDatasetModal;
