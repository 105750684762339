import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {ContentRoute, LayoutSplashScreen} from "../../../../_metronic/layout";
import DashboardPage from "../../../Dashboards/DashboardPage";
import PreData from "../../../User/PreData"
import HelpSection from "../../../User/HelpSection";
import BlankPage from "../../../Account/RegistrationWithChoice/choiseStep/BlankPage";
import AnnotationTools from "../../../User/AnnotationTools";
import ProjectManagement from "../../../User/ProjectManagement";
import HyperParamsManagement from "../../../User/HyperParamsManagement";
import ExperimentManagement from "../../../User/ExperimentManagement";
import ProfileDetailsPage from "../../../User/Profile";
import DatasetManagement from "../../../User/DatasetManagement";

// const DashboardPage = lazy(() => import('./Pages/DashboardPage'));

function UserBasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/user/dashboard"/>
                }

                <ContentRoute path={"/conformationStep"} component={BlankPage}/>
                <ContentRoute path="/user/dashboard" component={DashboardPage}/>


                <ContentRoute path="/user/projects" component={ProjectManagement}/>


                <ContentRoute path="/user/datasets" component={DatasetManagement}/>

                <ContentRoute path="/user/hyperParams" component={HyperParamsManagement}/>

                <ContentRoute path="/user/experiments" component={ExperimentManagement}/>

                <ContentRoute path="/user/annotation" component={AnnotationTools}/>


                <ContentRoute path="/user/help" component={HelpSection}/>

                <ContentRoute path="/user/profile" component={ProfileDetailsPage}/>
                {/*<ContentRoute path="/user/RegistrationChoicePage" component={RegistrationWithChoiceManagement}/>*/}

                <ContentRoute path="/user/predata" component={PreData}/>
                <Redirect to="/"/>
            </Switch>
        </Suspense>
    );
}

export default UserBasePage;
