import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export function ExperimentCreate({ handleHyperChange }) {
  const [formData, setFormData] = useState({
    hyperParamName: "",
    learningRate: "",
    momentum: "",
    weightDecay: "",
    optimizer: "sgd",
    batchSize: "",
    imgSize: "",
    splitRatio: "",
    isTransferLearning: false,
    numEpochs: "",
    isRect: false,
    isMultiScale: false,
    status: true,
    id: ""
  });
  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2>Train Modal</h2>
        </div>
        <div>
          <h6>* is.</h6>
        </div>
      </div>

      <div className={"centered-element"}>
        <h2 className={"text-center"} style={{ marginBottom: "30px" }}>
          Add Experiment
        </h2>
        <Form className={"m-5"}>
          <Row>
            <Col xl={6} md={6} lg={6} xs={12} sm={12}>
              <Form.Group controlId="ExperimentName" as={Row}>
                <Form.Label sm={12}>
                  Experiment Name
                  {/*<Tooltip*/}
                  {/*    title="The Name of your deep learning model ex. Person Detection."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        The Name of your deep learning model ex. Person
                        Detection.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12} className={"pl-0"}>
                  <Form.Control
                    type="text"
                    name="experimentName"
                    placeholder="Experiment name"
                    value={formData.experimentName}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xl={6} md={6} lg={6} xs={12} sm={12}>
              <Form.Group controlId="datasetId" as={Row}>
                <Form.Label sm={12}>
                  Dataset
                  {/*<Tooltip*/}
                  {/*    title= "A Dataset (or data set) is a collection of data in various forms like text,image,video,voice,etc."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        A dataset (or data set) is a collection of data in
                        various forms like text,image,video,voice,etc.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12} className={"pl-0"}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Dataset"}
                    name="datasetId"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xl={6} md={6} lg={6} xs={12} sm={12}>
              <Form.Group controlId="modelId" as={Row}>
                <Form.Label sm={12}>
                  Model
                  {/*<Tooltip*/}
                  {/*    title="A “model” in machine learning is the output of a machine learning algorithm run on data.A model represents what was learned by a machine learning algorithm using historical data."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        A “model” in machine learning is the output of a machine
                        learning algorithm run on data.A model represents what
                        was learned by a machine learning algorithm using
                        historical data.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12} className={"pl-0"}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select modelId"}
                    name="modelId"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xl={6} md={6} lg={6} xs={12} sm={12}>
              <Form.Group controlId="projectId" as={Row}>
                <Form.Label sm={12}>
                  Project
                  {/*<Tooltip*/}
                  {/*    title="Project is detection system which may use more than one model for detection from image and video input."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        Project is detection system which may use more than one
                        model for detection from image and video input.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12} className={"pl-0"}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Project"}
                    name="projectId"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xl={6} md={6} lg={6} xs={12} sm={12}>
              <Form.Group controlId="hyperParamsId" as={Row}>
                <Form.Label sm={12}>
                  Hyper Parameter
                  {/*<Tooltip*/}
                  {/*    title="A hyperparameter is a parameter whose value is used to control the learning process of a model.Such examples of hyperparameters are learning rate and mini-batch size.."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        A hyperparameter is a parameter whose value is used to
                        control the learning process of a model.Such examples of
                        hyperparameters are learning rate and mini-batch size..
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12} className={"pl-0"}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select hyperParamsId"}
                    name="hyperParamsId"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xl={6} md={6} lg={6} xs={12} sm={12}>
              <Form.Group controlId="annotationType" as={Row}>
                <Form.Label sm={12}>
                  Target Annotation Type
                  {/*<Tooltip*/}
                  {/*    title= "Annotation type of your Dataset like pascal (XML) or yolo (TXT)"*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        Annotation type of your dataset like pascal (XML) or
                        yolo (TXT)
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12} className={"pl-0"}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Target Annotation Type"}
                    name="annotationTypeId"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Group controlId="ExperimentDescription" as={Row}>
              <Form.Label sm={12}>
                Experiment Description
                {/*<Tooltip*/}
                {/*    title="Model's General Information ex. Person Detection Model will detect person from input image or video "*/}
                {/*    placement="right"*/}
                {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                <Tooltip
                  title={
                    <div className="tools">
                      Model's General Information ex. Person Detection Model
                      will detect person from input image or video{" "}
                    </div>
                  }
                  placement={"right"}
                >
                  <InfoIcon className={"icon-color"} />
                </Tooltip>
              </Form.Label>
              <Col sm={12} className={"pl-0"}>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Experiment description"
                  name="experimentDescription"
                  value={formData.experimentDescription}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <FormControlLabel
              control={
                <Checkbox
                  // checked={state.checkedB}
                  // onChange={()=>handleChange("Project planning and coordination")}
                  onChange={handleHyperChange}
                  value="hyper-Params"
                  color="primary"
                />
              }
              label="Hyper Params"
            />
          </Row>
        </Form>
      </div>
    </>
  );
}
