/* eslint-disable */
import { request } from "../../../../../shared/helper/RequestWrapper";
import { HttpRequest } from "../../../../../shared/enums/http.methods.enums";
export const REGISTER_USER_URL = "/add_user";
export const CHECK_USER_FROM_AUTO_DL = "/check_user_from_autoDL";

export function registerUser(data) {
  return request({
    headers: {
      project: "autoServing",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    endpoint: REGISTER_USER_URL,
    method: HttpRequest.POST,
    body: data
  });
}
export function checkUser(data) {
  return request({
    headers: {
      project: "autoServing",
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    endpoint: CHECK_USER_FROM_AUTO_DL,
    method: HttpRequest.POST,
    body: data
  });
}
