import React, { Suspense } from "react";
import { ModelPage } from "./ModelTable/ModelPage";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";

 function Model() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from models root URL to /models/modelPage */
          <Redirect exact={true} from="/models" to="/models/modelPage" />
        }
        <ContentRoute path="/models/modelPage" component={ModelPage} />
      </Switch>
    </Suspense>
  );
}

export default Model;
