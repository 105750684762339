import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { warningToast } from "../../../../../shared/components/ToastMessage";

export function ModelEditForm({
  saveModel,
  modelData,
  onHide,
  frameworks,
  modelTypes
}) {
  const [formData, setFormData] = useState({
    modelName: "",
    modelDescription: "",
    modelICS: "",
    modelIGS: "",
    modelSize: "",
    frameworkId: "",
    modelTypeId: "",
    status: false,
    id: ""
  });

  const isValidate = () => {
    if (!formData.modelName) warningToast("Please Enter Model Name");
    else if (!formData.modelDescription)
      warningToast("Please Enter Model Description");
    else if (!formData.modelICS) warningToast("Please Enter CPU Speed");
    else if (!formData.modelIGS) warningToast("Please Enter GPU Speed");
    else if (!formData.modelSize) warningToast("Please Enter Model Size");
    else if (!formData.frameworkId) warningToast("Please Select Framework");
    else if (!formData.modelTypeId) warningToast("Please Select Model Type");
    else return true;

    return false;
  };

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const handleSubmit = () => {
    if (isValidate()) saveModel(formData);
  };

  useEffect(() => {
    setFormData({
      modelName: modelData.model_name || "",
      modelDescription: modelData.model_description || "",
      modelICS: modelData.model_infer_cpu_speed || "",
      modelIGS: modelData.model_infer_gpu_speed || "",
      modelSize: modelData.model_size || "",
      frameworkId: modelData.framework_id || "",
      modelTypeId: modelData.model_type_id || "",
      status: modelData.status || false,
      id: modelData.id || null
    });
  }, [modelData]);

  return (
    <>
      <Modal.Body>
        <Form>
          <Form.Group controlId="ModelName" as={Row}>
            <Form.Label column sm={4}>
              Model Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="modelName"
                placeholder="Model name"
                value={formData.modelName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="ModelDescription" as={Row}>
            <Form.Label column sm={4}>
              Model Description
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Model description"
                name="modelDescription"
                value={formData.modelDescription}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="modelICS" as={Row}>
            <Form.Label column sm={4}>
              Model Infer CPU Speed
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                rows="3"
                placeholder="Model Infer CPU Speed"
                name="modelICS"
                value={formData.modelICS}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="modelIGS" as={Row}>
            <Form.Label column sm={4}>
              Model Infer GPU Speed
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                rows="3"
                placeholder="Model Infer GPU Speed"
                name="modelIGS"
                value={formData.modelIGS}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="modelSize" as={Row}>
            <Form.Label column sm={4}>
              Model Size
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                rows="3"
                placeholder="Model Size"
                name="modelSize"
                value={formData.modelSize}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="framework" as={Row}>
            <Form.Label column sm={4}>
              Framework
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="frameworkId"
                value={formData.frameworkId}
                onChange={handleChange}
              >
                {!formData.frameworkId && (
                  <option value={-1}>Select Framework</option>
                )}
                {frameworks?.map(f => (
                  <option value={f.id}>{f.framework_name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="modelType">
            <Form.Label column sm={4}>
              Model Type
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="modelTypeId"
                value={formData.modelTypeId}
                onChange={handleChange}
              >
                {!formData.modelTypeId && (
                  <option value={-1}>Select Model Type</option>
                )}
                {modelTypes?.map(mtype => (
                  <option value={mtype.id}>{mtype.model_type_name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </Button>
        <> </>
        <Button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary btn-elevate"
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}
