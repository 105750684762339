import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "../../../../shared/helper/UIHelpers";

const AnnotationTypeUIContext = createContext();

export function useAnnotationTypeUIContext() {
  return useContext(AnnotationTypeUIContext);
}

export function AnnotationTypeUIProvider({ annotationTypeUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParams,
    newAnnotationTypeButtonClick:
      annotationTypeUIEvents.newAnnotationTypeButtonClick,
    openChangeStatusDialog: annotationTypeUIEvents.openChangeStatusDialog,
    openEditAnnotationTypeDialog:
      annotationTypeUIEvents.openEditAnnotationTypeDialog
  };

  return (
    <AnnotationTypeUIContext.Provider value={value}>
      {children}
    </AnnotationTypeUIContext.Provider>
  );
}
