import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { Button } from "reactstrap";
import AddEditProjectModal from "./AddEditProjectModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as action from "../../../../redux/actions/projectManagementAction";
import ProjectTable from "./ProjectTable";
import { SearchText } from "../../../../shared/components/SearchText";
import {useHistory} from "react-router-dom";

export function ProjectManagementCard() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [search, setSearch] = useState("");

  const searchInput = useRef("");
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth, shallowEqual);
  const { projectManagement, listLoading } = useSelector(
    state => ({
      projectManagement: state.projectManagement,
      listLoading: state.projectManagement?.listLoading
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentItems(projectManagement?.entities);
    setTotalCount(projectManagement?.totalProject);
    setPageNo(projectManagement?.page);
    setPageSize(projectManagement?.size);
    setShowTable(listLoading);
  }, [projectManagement]);

  const handleClose = () =>{ setShow(false);
  setEditModalData([])
  }
  const handleShow = () => setShow(true);

  const saveProject = project => {
    if (!project?.id) {
    //     // server request for creating Project
    dispatch(action.createProject(project)).then(() => {
      dispatch(action.fetchProjects(1, 10, search));
      handleClose();
          });
      }
      else {
      // server request for updating Project
      dispatch(action.projectUpdate(project)).then(() => {
        dispatch(action.fetchProjects(pageNo, pageSize, search));
          handleClose()
    });
    }
  };

  const pageChange = (page, sizePerPage) => {
    dispatch(action.fetchProjects(page, sizePerPage, search));
  };

  const sizePerPageChangeApiCall = (page, sizePerPage) => {
    dispatch(action.fetchProjects(1, sizePerPage, search));
  };

  const onChangeSearch = e => {
    const searchStr =
      e?.target?.value.trim() || searchInput.current.value.trim();
    setSearch(searchStr)
    dispatch(action.fetchProjects(1, 10, searchStr));
  };

  const editModalDataTable = (_ , row) =>{
    setEditModalData(row)
    setShow(true)
  }
  const handleAddProject = () =>{
    setEditModalData([])
    if(!user?.user_email){
      history.push(`/user/profile/profile-information`);
    }else {
    handleShow()
    }
  }
  return (
    <>
      <Card>
        <CardHeader title="Project Details">
          <CardHeaderToolbar>
            <div className={'d-flex'}>
            <SearchText
              reference={searchInput}
              onChangeHandler={onChangeSearch}
              searchField={"Search on Name"}
              className={'mr-2'}
            />
            <Button
              color={"primary"}
              className={'ml-2 col-lg-4'}
              onClick={handleAddProject}
            >
              Add Project
            </Button>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody style={{ padding: "5px" }}>
          <ProjectTable
            currentItems={currentItems}
            showTable={showTable}
            pageNo={pageNo}
            pageSize={pageSize}
            totalCount={totalCount}
            pageChange={(page, sizePerPage) => pageChange(page, sizePerPage)}
            sizePerPageChangeApiCall={(page, sizePerPage) =>
              sizePerPageChangeApiCall(page, sizePerPage)
            }
            editModalDataTable={editModalDataTable}
          />
        </CardBody>
      </Card>

        <AddEditProjectModal
          show={show}
          saveProject={saveProject}
          handleClose={handleClose}
          editModalData={editModalData}
        />
    </>
  );
}
