import React, {useMemo} from "react";
import {Card, CardBody, CardHeader, CardHeaderToolbar,} from "../../../../_metronic/_partials/controls";
import {FrameworkManagementTable} from "./framework-management-table/FrameworkManagementTable";
import {useFrameworkManagementUIContext} from "./FrameworkManagementUIContext";

export function FrameworkManagementCard() {
    const frameworkUIContext = useFrameworkManagementUIContext();
    const frameworkUIProps = useMemo(() => {
        return {
            newFrameworkManagementButtonClick: frameworkUIContext.newFrameworkManagementButtonClick,
        };
    }, [frameworkUIContext]);

    return (
        <Card>
            <CardHeader title="Framework Details">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={frameworkUIProps.newFrameworkManagementButtonClick}
                    >
                        Add Framework
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <FrameworkManagementTable/>
            </CardBody>
        </Card>
    );
}
