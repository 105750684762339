/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";

function UserAsideMenuList({layoutProps}) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/user/dashboard", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user/dashboard">
            <span className="svg-icon menu-icon">
              <SVG title="View Dashboard" src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*custom start*/}
                <li
                    className={`menu-item ${getMenuItemActive("/user/projects", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user/projects">
            <span className="svg-icon menu-icon">
              <SVG title="View Project" src={toAbsoluteUrl("/media/svg/icons/Devices/Server.svg")}/>
            </span>
                        <span className="menu-text">Projects</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/user/datasets", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user/datasets">
            <span className="svg-icon menu-icon">
              <SVG title="Add Dataset" src={toAbsoluteUrl("/media/svg/icons/Weather/Cloud1.svg")}/>
            </span>
                        <span className="menu-text">Datasets</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/user/annotation", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user/annotation">
            <span className="svg-icon menu-icon">
              <SVG title="Annotation" src={toAbsoluteUrl("/media/svg/icons/Design/Select.svg")}/>
            </span>
                        <span className="menu-text">Annotation</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/user/hyperParams", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user/hyperParams">
            <span className="svg-icon menu-icon">
              <SVG title="Add Hyper Parameter" src={toAbsoluteUrl("/media/svg/icons/Design/Join-1.svg")}/>
            </span>
                        <span className="menu-text">Hyper Parameters</span>
                    </NavLink>
                </li>


                {/*<li*/}
                {/*    className={`menu-item ${getMenuItemActive("/user/experimentsOld", false)}`}*/}
                {/*    aria-haspopup="true"*/}
                {/*>*/}
                {/*  <NavLink className="menu-link" to="/user/experimentsOld">*/}
                {/*  <span className="svg-icon menu-icon">*/}
                {/*    <SVG  title="Test Model" src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>*/}
                {/*  </span>*/}
                {/*    <span className="menu-text">Experiments Old</span>*/}
                {/*  </NavLink>*/}
                {/*</li>*/}

                <li
                    className={`menu-item ${getMenuItemActive("/user/experiments", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user/experiments">
            <span className="svg-icon menu-icon">
              <SVG title="Test Model" src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
                        <span className="menu-text">Experiments</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/user/help", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user/help">
            <span className="svg-icon menu-icon">
              <SVG title="Help" src={toAbsoluteUrl("/media/svg/icons/General/hell.svg")}/>
            </span>
                        <span className="menu-text">Help</span>
                    </NavLink>
                </li>


                {/*custom end*/}
            </ul>
        </>
    );
}

export default UserAsideMenuList;
