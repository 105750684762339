import { request } from "../shared/helper/RequestWrapper";
import {HttpRequest} from "../shared/enums/http.methods.enums";

const GET_ALL_HYPER_PARAMS_FOR_CURRENT_USER =
  "/get_hyper_params_for_current_user";
const GET_ALL_HYPER_PARAMS_FOR_DROPDOWN_CURRENT_USER = "/get_hyper_params_for_dropdown_current_user";
const GET_HYPER_PARAMS_BY_ID = "/get_user_hyper_params_by_id";
const ADD_HYPER_PARAMS = "/add_user_hyper_params";
const UPDATE_HYPER_PARAMS = "/update_user_hyper_params";
const UPDATE_USER_HYPER_PARAMS_STATUS = "/update_user_hyper_params_status";

export async function getHyperParamsById(id) {
  return await request({
    endpoint: GET_HYPER_PARAMS_BY_ID + "?user_hyper_params_id=" + id,
    method: HttpRequest.GET,
  });
}

export async function addNewHyperParams(data) {
  return await request({
    endpoint: ADD_HYPER_PARAMS,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}

export async function updateHyperParams(data) {
  return await request({
    endpoint: UPDATE_HYPER_PARAMS,
    method: HttpRequest.POST,
    body: JSON.stringify(data)
  });
}

export async function getAllHyperParams(pageNo,pageSize,search) {
  return await request({
    endpoint: GET_ALL_HYPER_PARAMS_FOR_CURRENT_USER +`?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: HttpRequest.GET,
  });
}export async function getAllHyperParamsForDropDown() {
  return await request({
    endpoint: GET_ALL_HYPER_PARAMS_FOR_DROPDOWN_CURRENT_USER,
    method: HttpRequest.GET,
  });
}

export async function UpdateUserHyperParamsStatus(status , id) {
  return await request({
    endpoint: UPDATE_USER_HYPER_PARAMS_STATUS +`?update_status=${status}&update_id=${id}`,
    method: HttpRequest.POST,
  });
}

