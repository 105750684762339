import { appConfigs } from "../../../../configs";

const { request } = require("../../../../shared/helper/RequestWrapper");
export const BASE_URL = appConfigs.API_HOST;
export const ADD_USER = "/add_user";
export const UPDATE_USER_STATUS = "/update_user_status";
export const GET_USER_BY_ID = "/get_user_by_id";
export const GET_ALL_FILTER_USERS = "/get_all_filter_users";
const GET_ALL_ROLES = "/get_all_roles";

export async function saveUser(data) {
  return await request({
    url: BASE_URL + ADD_USER,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function getAllFilterUsers(pageNo, pageSize, search, obj) {
  if (!obj.user_role && !obj.status) {
    var obj = {};
  }
  else if(obj.user_role && !obj.status){
    var obj = { user_role: obj.user_role };
  }
 else if(!obj.user_role && obj.status) {
    var obj = {status: obj.status };
  }
  return await request({
    url:
      BASE_URL +
      GET_ALL_FILTER_USERS +
      `?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: "POST",
    body: obj
  });
}

export async function getUserById(userId) {
  return await request({
    url: BASE_URL + GET_USER_BY_ID + "?user_id=" + userId,
    method: "POST"
  });
}

export async function updateUserStatus(userStatus, userId) {
  return await request({
    url:
      BASE_URL +
      UPDATE_USER_STATUS +
      "?user_status=" +
      userStatus +
      "&user_id=" +
      userId,
    method: "POST",
    body: JSON.stringify(userStatus, userId)
  });
}

export async function getAllRoles() {
  return await request({
    endpoint: GET_ALL_ROLES,
    method: "GET"
  });
}
