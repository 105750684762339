import { request } from "../../../../shared/helper/RequestWrapper";

const GET_ALL_DATASET_STORAGE_TYPE = "/get_all_dataset_storage";
const GET_ENABLED_DATASET_STORAGE_TYPE = "/get_all_enabled_dataset_storage";
const GET_DATASET_STORAGE_TYPE_BY_ID = "/get_dataset_storage_by_id";
const ADD_DATASET_STORAGE_TYPE = "/add_dataset_storage";
const UPDATE_DATASET_STORAGE_TYPE = "/update_dataset_storage";

export async function getDatasetStorageTypeById(id) {
  return await request({
    endpoint: GET_DATASET_STORAGE_TYPE_BY_ID + "?dataset_storage_id=" + id,
    method: "GET"
  });
}

export async function addNewDatasetStorageType(data) {
  return await request({
    endpoint: ADD_DATASET_STORAGE_TYPE,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function updateDatasetStorageType(data) {
  return await request({
    endpoint: UPDATE_DATASET_STORAGE_TYPE,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function getAllDatasetStorageType(pageNo,pageSize,search) {
  return await request({
    endpoint: GET_ALL_DATASET_STORAGE_TYPE  + `?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: "GET"
  });
}

export async function getAllEnabledDatasetStorageType() {
  return await request({
    endpoint: GET_ENABLED_DATASET_STORAGE_TYPE,
    method: "GET"
  });
}
