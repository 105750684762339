import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { warningToast } from "../../../../../shared/components/ToastMessage";

export function AnnotationTypeEditForm({
  saveAnnotationType,
  annotationTypeData,
  onHide
}) {
  const [formData, setFormData] = useState({
    annotationTypeName: "",
    annotationTypeDescription: "",
    status: false,
    id: ""
  });

  const isValidate = () => {
    if (!formData.annotationTypeName)
      warningToast("Please Enter Annotation Type Name");
    else if (!formData.annotationTypeDescription)
      warningToast("Please Enter Annotation Type Description");
    else return true;

    return false;
  };

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const handleSubmit = () => {
    if (isValidate()) saveAnnotationType(formData);
  };

  useEffect(() => {
    setFormData({
      annotationTypeName: annotationTypeData.annotation_type_name || "",
      annotationTypeDescription:
        annotationTypeData.annotation_type_name_description || "",
      status: annotationTypeData.status || false,
      id: annotationTypeData.id || null
    });
  }, [annotationTypeData]);

  return (
    <>
      <Modal.Body>
        <Form>
          <Form.Group controlId="AnnotationTypeName" as={Row}>
            <Form.Label column sm={4}>
              Annotation Type Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="annotationTypeName"
                placeholder="Annotation Type name"
                value={formData.annotationTypeName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="AnnotationTypeDescription" as={Row}>
            <Form.Label column sm={4}>
              Annotation Type Description
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Annotation Type description"
                name="annotationTypeDescription"
                value={formData.annotationTypeDescription}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </Button>
        <> </>
        <Button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary btn-elevate"
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}
