import React from 'react';
import {useSubheader} from "../../../../_metronic/layout";
import PersonInformation from "./PersonInformation";
import {Redirect, Route, Switch} from "react-router-dom";
import ProfileCard from "./ProfileCard";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import companyInformation from "./CompanyInformation";

export default function ProfileDetails() {
    const suhbeader = useSubheader();
    suhbeader.setTitle("user profile");

    return (
            <div className="d-flex flex-row">
                {window?.location?.pathname !== "/user/profile/forgot-password" ?
                    <ProfileCard />  :<></>
                }
            <div className="flex-row-fluid ml-lg-8">
                <Switch>
                    <Redirect
                        from="/user/profile"
                        exact={true}
                        to="/user/profile/profile-information"
                    />

                    <Route
                        path="/user/profile/change-password"
                        component={ChangePassword}
                    />
                    <Route
                        path="/user/profile/profile-information"
                        component={PersonInformation}
                    />
                    <Route
                        path="/user/profile/company-information"
                        component={companyInformation}
                    />
                    <Route
                        path="/user/profile/forgot-password"
                        component={ForgotPassword}
                    />

                </Switch>
            </div>
        </div>
    );
}
