import {
  callTypes,
  HyperParamsManagementSlice
} from "../reducers/hyperParamsManagementSlice";
import { successToast, warningToast } from "../../shared/components/ToastMessage";
import {
  addNewHyperParams,
  getAllHyperParams, getAllHyperParamsForDropDown,
  getHyperParamsById,
  updateHyperParams
} from "../../service/hyperParamsManagement.api";

const { actions } = HyperParamsManagementSlice;

export const fetchHyperParams = (pageNo,pageSize,search) => dispatch => {
  // let loader=false
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllHyperParams(pageNo,pageSize,search)
    .then(response => {
      if (response && response.isSuccess) {
        // loader=true
        dispatch(actions.hyperParamsFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find hyperParams";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchHyperParamsForDropDowns = () => dispatch => {
  let loader=false
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllHyperParamsForDropDown()
    .then(response => {
      if (response && response.isSuccess) {
        loader=true
        dispatch(actions.hyperParamsFetchedForData(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find hyperParams";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchHyperParamsById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getHyperParamsById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.hyperParamsFetchedById(response.data));
      } else {
        throw new Error("Error getting hyperParams details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createHyperParams = hyperParamsData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // const data = {
  //   hyper_parameter_name: hyperParamsData.hyperParamName,
  //   learning_rate: hyperParamsData.learningRate,
  //   momentum: hyperParamsData.momentum,
  //   weight_decay: hyperParamsData.weightDecay,
  //   optimizer: hyperParamsData.optimizer,
  //   batch_size: hyperParamsData.batchSize,
  //   img_size: hyperParamsData.imgSize,
  //   split_ratio: hyperParamsData.splitRatio,
  //   is_transfer_learning: hyperParamsData.isTransferLearning,
  //   num_epochs: hyperParamsData.numEpochs,
  //   is_rect: hyperParamsData.isRect,
  //   is_multi_scale: hyperParamsData.isMultiScale,
  //   status: hyperParamsData.status
  // };
  return addNewHyperParams(hyperParamsData)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.hyperParamsCreated(response.data));
        successToast("Hyper Params Added Successfully");
      } else {
        throw new Error("something went wrong");
      }
    })
    .catch(error => {
      warningToast(error?.response?.data?.detail || "something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const hyperParamsUpdate = hyperParamsData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    hyper_parameter_name:
      hyperParamsData.hyperParamName || hyperParamsData.hyper_parameter_name,
    learning_rate:
      hyperParamsData.learningRate || hyperParamsData.learning_rate,
    momentum: hyperParamsData.momentum || hyperParamsData.momentum,
    weight_decay: hyperParamsData.weightDecay || hyperParamsData.weight_decay,
    optimizer: hyperParamsData.optimizer || hyperParamsData.optimizer,
    batch_size: hyperParamsData.batchSize || hyperParamsData.batch_size,
    img_size: hyperParamsData.imgSize || hyperParamsData.img_size,
    split_ratio: hyperParamsData.splitRatio || hyperParamsData.split_ratio,
    is_transfer_learning:
      hyperParamsData.isTransferLearning !== undefined
        ? hyperParamsData.isTransferLearning
        : hyperParamsData.is_transfer_learning,
    num_epochs: hyperParamsData.numEpochs || hyperParamsData.num_epochs,
    is_rect:
      hyperParamsData.isRect !== undefined
        ? hyperParamsData.isRect
        : hyperParamsData.is_rect,
    is_multi_scale:
      hyperParamsData.isMultiScale !== undefined
        ? hyperParamsData.isMultiScale
        : hyperParamsData.is_multi_scale,
    status: hyperParamsData.status,
    id: hyperParamsData.id
  };

  return updateHyperParams(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.hyperParamsUpdated(response.data));
        successToast("Hyper Params Updated Successfully");
      } else {
        throw new Error("Error updating hyperParams details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
