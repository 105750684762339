import React from "react";
import { Modal } from "react-bootstrap";
import StartWizardPage from "./StartWizardPage";

function DeployeUserMainModal({ openSignUpWizard,openSignUpWizardClose,experimentDetails ,accountType ,deploymentType ,onStepsCompleted}) {
  return (
    <>
      <Modal
        size="xl"
        show={openSignUpWizard}
        onHide={openSignUpWizardClose}
        aria-labelledby="example-modal-sizes-title-xl"
        backdrop="static"
      >
        <StartWizardPage
          // companyDetails={companyDetails}
          // setCompanyDetails={setCompanyDetails}
          // userDetails={userDetails}
          // setUserDetails={setUserDetails}
          // modelDetails={modelDetails}
          // setModelDetails={setModelDetails}
          onStepsCompleted={onStepsCompleted}
          experimentDetails={experimentDetails}
          onHide={openSignUpWizardClose}
          accountType={accountType}
          deploymentType={deploymentType}
          // id={id}
        />
      </Modal>
    </>
  );
}

export default DeployeUserMainModal;
