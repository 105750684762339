import React from "react";
import { Button, Modal } from "react-bootstrap";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import * as moment from "moment/moment";

function ExprimentInfoModal({ show, handleClose, infoModalData }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        scrollable={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderCommon title={`Experiment Details`} />
        <Modal.Body>
          {/**
             Experiment Details
             */}
          <div className="row col-12 text-center modal-contain-title-part">
            <span className="w-100 font-weight-bold">Experiment Details</span>
          </div>

          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Experiment Name</b>
              </span>
            </div>
            <div className="col col-md-6">{infoModalData?.experiment_name}</div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Experiment Description</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.experiment_description}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Experiment Status</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.experiment_status}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4 mb-3">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>

          {/**
             Model Details
             */}
          <div className="row col-12 text-center modal-contain-title-part mb-4">
            <span className="w-100 font-weight-bold">Model Details</span>
          </div>

          <div className="row">
            <div className="col col-md-6">
              <span>
                <b>Model Name</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.model_details?.model_name}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Model Description</b>
              </span>
            </div>
            <div className="col col-md-6">
                {infoModalData?.model_details?.model_description}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Model Infer CPU Speed</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.model_details?.model_infer_cpu_speed}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Model Infer GPU Speed</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.model_details?.model_infer_gpu_speed}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Model Size</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.model_details?.model_size}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.model_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4 mb-3">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.model_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>

          {/**
             Project Details
             */}
          <div className="row col-12 text-center modal-contain-title-part">
            <span className="w-100 font-weight-bold">Project Details</span>
          </div>

          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Experiment Name</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.project_details?.project_name}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Experiment Description</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.project_details?.project_description}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Experiment Status</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.project_details?.project_status}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.project_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4 mb-3">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.project_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>

          {/**
             Hyper Params Details
             */}
          <div className="row col-12 text-center modal-contain-title-part">
            <span className="w-100 font-weight-bold">
              Hyper Parameters Details
            </span>
          </div>

          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Learning Rate</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.learning_rate}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Momentum</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.momentum}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Weight Decay</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.weight_decay}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Optimizer</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.optimizer}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Batch Size</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.batch_size}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Image Size</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.img_size}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Split Ratio</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.split_ratio}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Is Transfer Learning ?</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.is_transfer_learning
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Number Epochs</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.num_epochs}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Is Rectangle ?</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.is_rect ? "Yes" : "No"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Is Multi Scale ?</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.hyper_params_details?.is_multi_scale
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.hyper_params_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4 mb-6">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.hyper_params_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>

          {/**
             Dataset Details
             */}
          <div className="row col-12 text-center modal-contain-title-part ">
            <span className="w-100 font-weight-bold">Dataset Details</span>
          </div>

          <div className="row mt-5">
            <div className="col col-md-6">
              <span>
                <b>Dataset Name</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.dataset_details?.dataset_name}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Zip URL</b>
              </span>
            </div>
            {/* <a style={{wordBreak: 'break-all'}} href={infoModalData?.dataset_details?.zip_url}
                           target={'_blank'}> {infoModalData?.dataset_details?.zip_url}</a>*/}
            <div className="col col-md-6">
              {infoModalData?.dataset_details?.zip_url}
            </div>
          </div>
          {//TODO: replace hardcoded value with dynamic value (currently '1' denotes data storage type selected as AWS S3)
          infoModalData?.dataset_details?.dataset_storage_details?.id ==
            "1" && (
            <>
              <div className="row mt-4">
                <div className="col col-md-6">
                  <span>
                    <b>S3 Bucket</b>
                  </span>
                </div>
                <div className="col col-md-6">
                  {infoModalData?.dataset_details?.s3_bucket}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col col-md-6">
                  <span>
                    <b>S3 Access Key</b>
                  </span>
                </div>
                <div className="col col-md-6">
                  {infoModalData?.dataset_details?.s3_access_key}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col col-md-6">
                  <span>
                    <b>S3 Secret Key</b>
                  </span>
                </div>
                <div className="col col-md-6">
                  {infoModalData?.dataset_details?.s3_secret_key}
                </div>
              </div>
            </>
          )}
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Is Verified</b>
              </span>
            </div>
            <div className="col col-md-6">
              {infoModalData?.dataset_details?.is_verified ? "Yes" : "No"}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.dataset_details?.created_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4 mv mb-6">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(infoModalData?.dataset_details?.updated_date)
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>

          {/**
             Dataset Storage Details
             */}
          <div className="row col-12 text-center modal-contain-title-part">
            <span className="w-100 font-weight-bold">
              Dataset Storage Details
            </span>
          </div>

          <div className="row mt-5">
            <div className="col col-md-6">
              <span>
                <b>Dataset Type Name</b>
              </span>
            </div>
            <div className="col col-md-6">
              {
                infoModalData?.dataset_details?.dataset_storage_details
                  ?.dataset_storage_name
              }
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Dataset Type Description</b>
              </span>
            </div>
            <div className="col col-md-6">
              {
                infoModalData?.dataset_details?.dataset_storage_details
                  ?.dataset_storage_description
              }
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(
                  infoModalData?.dataset_details?.dataset_storage_details
                    ?.created_date
                )
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4 mb-6">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(
                  infoModalData?.dataset_details?.dataset_storage_details
                    ?.updated_date
                )
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>

          {/**
             Annotation Details
             */}
          <div className="row col-12 text-center modal-contain-title-part">
            <span className="w-100 font-weight-bold">
              Annotation Type Details
            </span>
          </div>

          <div className="row mt-5">
            <div className="col col-md-6">
              <span>
                <b>Target Annotation Type Name</b>
              </span>
            </div>
            <div className="col col-md-6">
              {
                infoModalData?.dataset_details?.annotation_details
                  ?.annotation_type_name
              }
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Target Annotation Type Description</b>
              </span>
            </div>
            <div className="col col-md-6">
              {
                infoModalData?.dataset_details?.annotation_details
                  ?.annotation_type_name_description
              }
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Created Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(
                  infoModalData?.dataset_details?.annotation_details
                    ?.created_date
                )
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col col-md-6">
              <span>
                <b>Updated Date</b>
              </span>
            </div>
            <div className="col col-md-6">
              {moment
                .utc(
                  infoModalData?.dataset_details?.annotation_details
                    ?.updated_date
                )
                .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExprimentInfoModal;
