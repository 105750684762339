import { callTypes, UserSlice } from "./UserSlice";
import { successToast } from "../../../../shared/components/ToastMessage";
import {
  saveUser,
  updateUserStatus,
  getUserById,
  getAllFilterUsers
} from "./UserAPI";

const { actions } = UserSlice;

export const fetchFilterUsers = (
  pageNo,
  pageSize,
  search,
  data
) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllFilterUsers(pageNo, pageSize, search, data)
    .then(response => {
      let object = data;
      if (response && response.isSuccess) {
        let data = response.data;
        data.data = object;
        dispatch(actions.userFetched(data));
      }
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getUserById(id)
    .then(response => {
      if (response && response.isSuccess) {
        let data = response.data;
        dispatch(actions.viewUserDetails(data));
      }
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createUser = userData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  const data = {
    user_email: userData.userEmail,
    company_email: userData.companyEmail,
    company_name: userData.companyName,
    company_description: userData.companyDescription,
    company_address: userData.companyAddress,
    company_pin_code: userData.companyPinCode,
    company_website: userData.companyWebsite,
    company_contact: userData.companyContact,
    company_poc: userData.companyPoc,
    company_poc_contact: userData.companyPocContact,
    user_password: userData.userPassword,
    role_tag: userData.roleTag,
    company_status: userData.status
  };

  return saveUser(data)
    .then(response => {
      if (response && response.isSuccess) {
        let data = response.data;

        dispatch(actions.userCreated(data));
        successToast("user Added Successfully");
      }
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const changeUserStatus = (id, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return updateUserStatus(status, id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.userStatusChanged({ status, id }));
      }
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
