import React from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {Card, Col, Row} from "react-bootstrap";

export function ChoicePage3() {

  function handleChange(e) {
    alert("You clicked " + e);
  }

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <h2>Tell us a bit about yourself</h2>
        <h5>This helps us personalise your experience.</h5>
      </div>
      <div className={"centered-element"}>
        <Card
          style={{
            width: "50rem",
            border: "2px dashed #147b82",
            height: "30rem",
            overflow: "auto"
          }}
          className={"custome-chip"}
        >
          <Card.Body>
            <Card.Title className={"text-center"}>
              What are your main tasks? (Multiple choise)
            </Card.Title>
            {/*<Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>*/}
            <Card.Text>
              <div className={"mt-5"}>
                <Row>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Design and/pr diagramming")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Design and/pr diagramming"
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Testing and quality assurance (QA)")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Testing and quality assurance (QA)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Security and data management")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Security and data management"
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Project planning and coordination")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Project planning and coordination"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Documentation and file management")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Documentation and file management"
                    />
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Productivity tracking and reporting")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Productivity tracking and reporting"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Improving workflows and processes")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Improving workflows and processes"
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={state.checkedB}
                          onChange={() =>
                            handleChange("Providing support and help desk")
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Providing support and help desk"
                    />
                  </Col>
                </Row>

                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={state.checkedB}
                      onChange={() => handleChange("Code Writing and reviews")}
                      value="checkedB"
                      color="primary"
                    />
                  }
                  label="Code Writing and reviews"
                />
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
