import React, {useEffect} from 'react';
import Cookies from "universal-cookie";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {LayoutSplashScreen} from "../../../_metronic/layout";
import {Redirect} from "react-router-dom";
import {RegisterAndLoginSlice} from "../../../redux/reducers/registerAndLoginSlice";
const { actions } = RegisterAndLoginSlice;
function Logout() {
    const dispatch = useDispatch();
  useEffect(() => {
    const cookies = new Cookies();
    dispatch(actions.clearUserProfileDetails());
    cookies.set('access_token', '', {httpOnly: false,path: "/"});
    cookies.set('token_type', '', {httpOnly: false,path: "/"});

  }, []);

    const {hasAuthToken} = useSelector(
        ({auth}) => ({
            hasAuthToken: Boolean(auth.authToken)
        }),
        shallowEqual
    );

  return (
      hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login"  />
  );
}

export default Logout;


