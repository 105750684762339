import {createSlice} from "@reduxjs/toolkit";

const initialProjectState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    lastError: null,
    filteredEntities: '',
    projectFetchedById: "",
    totalProject:0,
    page:1,
    size:10
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const ProjectManagementSlice = createSlice({
    name: "dl-Project",
    initialState: initialProjectState,
    reducers: {

        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
                state.entities = []
            } else {
                state.actionsLoading = false;
                state.entities = []
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        projectFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.entities = action.payload.items;
            state.totalProject = action.payload.total;
            state.totalCount = action.payload.length;
            state.page=action.payload.page;
            state.size=action.payload.size;
        },
        projectFetchedForData: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.entities = action.payload;
            state.totalProject = action.payload.total;
            state.totalCount = action.payload.length;

        },

        projectFetchedById: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
            state.projectFetchedById = action.payload;
        },

        projectCreated: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.entities.push(action.payload);
            state.error = null;
        },

        projectUpdated: (state, action) => {
            let data = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = state.entities.map(entity => {
                if (entity.id === data.id) {
                    return data;
                }
                return entity;
            });
        },

        clearProjectById: (state, action) => {
            state.projectFetchedById = "";
        }
    }
});
