import React from "react";
import { Button, Modal } from "react-bootstrap";
import { LoadingDialog } from "../../../../../_metronic/_partials/controls";
import * as moment from "moment";

export function ModelDetailsForm({
  actionsLoading,
  modelDetails,
  frameworkDetails,
  modelTypeDetails,
  onHide
}) {
  return (
    <>
      <Modal.Body className="pt-0">
        <LoadingDialog isLoading={actionsLoading} text="Loading ..." />

        {/**
                 Model Details
                 */}
        <div className="row col-12 text-center modal-contain-title-part">
          <span className="w-100 font-weight-bold">Model Details</span>
        </div>

        <div className="row">
          <div className="col col-md-6">
            <span>
              <b>Model Name</b>
            </span>
          </div>
          <div className="col col-md-6">{modelDetails.model_name}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Description</b>
            </span>
          </div>
          <div className="col col-md-6">{modelDetails.model_description}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Infer CPU Speed</b>
            </span>
          </div>
          <div className="col col-md-6">
            {modelDetails.model_infer_cpu_speed}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Infer GPU Speed</b>
            </span>
          </div>
          <div className="col col-md-6">
            {modelDetails.model_infer_gpu_speed}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Size</b>
            </span>
          </div>
          <div className="col col-md-6">{modelDetails.model_size}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(modelDetails.created_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(modelDetails.updated_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Model Type Details
                 */}
        <div className="row col-12 text-center modal-contain-title-part">
          <span className="w-100 font-weight-bold">Model Type Details</span>
        </div>

        <div className="row mt-5">
          <div className="col col-md-6">
            <span>
              <b>Model Type Name</b>
            </span>
          </div>
          <div className="col col-md-6">{modelTypeDetails.model_type_name}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Model Type Description</b>
            </span>
          </div>
          <div className="col col-md-6">{modelTypeDetails.model_type_desc}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(modelTypeDetails.created_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(modelTypeDetails.updated_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>

        {/**
                 Framework Details
                 */}
        <div className="row col-12 text-center modal-contain-title-part">
          <span className="w-100 font-weight-bold">Framework Details</span>
        </div>

        <div className="row mt-5">
          <div className="col col-md-6">
            <span>
              <b>Framework Name</b>
            </span>
          </div>
          <div className="col col-md-6">{frameworkDetails.framework_name}</div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Framework Description</b>
            </span>
          </div>
          <div className="col col-md-6">
            {frameworkDetails.framework_description}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Framework Version</b>
            </span>
          </div>
          <div className="col col-md-6">
            {frameworkDetails.framework_version}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Created Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(frameworkDetails.created_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <span>
              <b>Updated Date</b>
            </span>
          </div>
          <div className="col col-md-6">
            {moment
              .utc(frameworkDetails.updated_date)
              .local()
                .format("DD MMMM YYYY, HH:mm:ss")}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}
