import React, {Suspense } from "react";
import {Redirect, Switch} from "react-router-dom";
import {ContentRoute, LayoutSplashScreen} from "../../../../_metronic/layout";

import DashboardPage from "../../../Dashboards/DashboardPage";
import Users from "../../../SuperAdmin/Users";
import FrameworkManagement from "../../../SuperAdmin/FrameworkManagement";
import ModelType from "../../../SuperAdmin/ModelType";
import AnnotationType from "../../../SuperAdmin/AnnotationType";
import DatasetStorageType from "../../../SuperAdmin/DatasetStorageType";
import Model from "../../../SuperAdmin/ModelManagement";
import ProjectManagement from "../../../SuperAdmin/ProjectManagement";
import ExperimentManagement from "../../../SuperAdmin/ExperimentManagement";



function BasePage() {

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                {/*<ContentRoute path={"/conformationStep"} component={BlankPage} />*/}
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/users" component={Users}/>
                <ContentRoute path="/frameworkManagement" component={FrameworkManagement}/>
                <ContentRoute path="/modelType" component={ModelType}/>
                <ContentRoute path="/annotationType" component={AnnotationType}/>
                <ContentRoute path="/datasetStorageType" component={DatasetStorageType}/>
                <ContentRoute path="/models" component={Model}/>
                <ContentRoute path="/projects" component={ProjectManagement}/>
                <ContentRoute path="/experiments" component={ExperimentManagement}/>

                <Redirect to="/"/>
            </Switch>
        </Suspense>
    );
}
export default BasePage;