import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { warningToast } from "../../../../../shared/components/ToastMessage";

export function ProjectManagementEditForm({
  saveProject,
  projectData,
  onHide
}) {
  const [formData, setFormData] = useState({
    projectName: "",
    projectDescription: "",
    projectStatus: "pending",
    status: true,
    id: ""
  });

  const isValidate = () => {
    if (!formData.projectName) warningToast("Please Enter Project Name");
    else if (!formData.projectDescription)
      warningToast("Please Enter Project Description");
    else return true;

    return false;
  };

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const handleSubmit = () => {
    if (isValidate()) saveProject(formData);
  };

  useEffect(() => {
    setFormData({
      projectName: projectData.project_name || "",
      projectDescription: projectData.project_description || "",
      projectStatus: projectData.project_status || "Pending",
      status: projectData.status === undefined ? true : projectData.status,
      id: projectData.id || null
    });
  }, [projectData]);

  return (
    <>
      <Modal.Body>
        <Form>
          <Form.Group controlId="ProjectName" as={Row}>
            <Form.Label column sm={4}>
              Project Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                type="text"
                name="projectName"
                placeholder="Project name"
                value={formData.projectName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="ProjectDescription" as={Row}>
            <Form.Label column sm={4}>
              Project Description
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                disabled
                as="textarea"
                rows="3"
                placeholder="Project description"
                name="projectDescription"
                value={formData.projectDescription}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="projectStatus" as={Row}>
            <Form.Label column sm={4}>
              Project Status
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="projectStatus"
                value={formData.projectStatus.toLowerCase()}
                onChange={handleChange}
              >
                {["Pending", "In-Progress", "Completed"]?.map((opt, idx) => (
                  <option key={idx} value={opt.toLowerCase()}>
                    {opt}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </Button>
        <> </>
        <Button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary btn-elevate"
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}
