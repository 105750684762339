import {request} from "../shared/helper/RequestWrapper";
import {HttpRequest} from "../shared/enums/http.methods.enums";

const GET_PROJECTS_FOR_CURRENT_USER = "/get_project_for_current_user";
const GET_PROJECTS_FOR_DROPDOWN_CURRENT_USER = "/get_project_for_dropdown_current_user";
const GET_PROJECT_BY_ID = "/get_project_by_id";
const ADD_PROJECT = "/add_project";
const UPDATE_PROJECT = "/update_project";

export async function getProjectById(id) {
    return await request({
        endpoint: GET_PROJECT_BY_ID + "?project_id=" + id,
        method: HttpRequest.GET,
    });
}

export async function addNewProject(data) {
    return await request({
        endpoint: ADD_PROJECT,
        method: HttpRequest.POST,
        body: JSON.stringify(data)
    })
}

export async function updateProject(data) {
    return await request({
        endpoint: UPDATE_PROJECT,
        method: HttpRequest.POST,
        body: JSON.stringify(data)
    })
}

export async function getAllProject(pageNo,pageSize,search) {
    return await request({
        endpoint: GET_PROJECTS_FOR_CURRENT_USER +`?page=${pageNo}&size=${pageSize}&search=${search}`,
        method: HttpRequest.GET,
    });
}
export async function getAllProjectDropDown() {
    return await request({
        endpoint: GET_PROJECTS_FOR_DROPDOWN_CURRENT_USER ,
        method: HttpRequest.GET,
    });
}