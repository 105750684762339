import React, { useState } from "react";
import { Chip, makeStyles } from "@material-ui/core";
import {Card, Col, Form} from "react-bootstrap";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1),
    backgroundColor: "#147b82",
    color: "#fff"
  }
}));

export function ChoicePage2() {
  const [textBoxShow, setTextBoxShow] = useState(false);
  const [text, setText] = useState("");
  const [submitted, setSubmitted] = useState("");

  const classes = useStyles();

  const handleClick = roleName => {
    alert("You clicked " + roleName);
  };

  function handleChange(e) {
    alert("You clicked" + e.target.value);
    setText(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(text);
    setText("");
    setTextBoxShow(false);
  }

  const handleClickOther = () => {
    setTextBoxShow(!textBoxShow);
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <h2>Tell us a bit about yourself</h2>
        <h5>This helps us personalise your experience.</h5>
      </div>
      <div className={"centered-element"}>
        <Card
          style={{
            width: "40rem",
            border: "2px dashed #147b82",
            height: "30rem",
            overflow: "auto"
          }}
          className={"custome-chip"}
        >
          <Card.Body>
            <Card.Title className={"text-center"}>What's your role?</Card.Title>
            {/*<Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>*/}
            <Card.Text>
              <div className={classes.root}>
                <Chip
                  label="Product Owner"
                  className={classes.chip}
                  onClick={() => handleClick("Product Owner")}
                />

                <Chip
                  label="Founder"
                  className={classes.chip}
                  onClick={() => handleClick("Founder")}
                />

                <Chip
                  label="Scrum Master"
                  className={classes.chip}
                  onClick={() => handleClick("Scrum Master")}
                />

                <Chip
                  label="Student"
                  className={classes.chip}
                  onClick={() => handleClick("Student")}
                />

                <Chip
                  label="Program Manager CTO"
                  className={classes.chip}
                  onClick={() => handleClick("Program Manager CTO")}
                />

                <Chip
                  label="Business Analyst"
                  className={classes.chip}
                  onClick={() => handleClick("Business Analyst")}
                />

                <Chip
                  label="Other"
                  className={classes.chip}
                  onClick={handleClickOther}
                />
              </div>
            </Card.Text>

            <div className={""}>
              {textBoxShow ? (
                <>
                  <form onSubmit={handleSubmit}>
                    {/*<input type="text" value={text} onChange={handleChange} />*/}
                    <Form.Group controlId="other">
                      <Col sm={12}>
                        <Form.Control
                          type="text"
                          name="other"
                          placeholder="Other"
                          value={text}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </form>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card.Body>
          {submitted ? <div>submitted: {submitted} </div> : <></>}
        </Card>
      </div>
    </>
  );
}
