import {
  callTypes,
  ExperimentManagementSlice
} from "../reducers/experimentManagementSlice";
import { successToast, warningToast } from "../../shared/components/ToastMessage";
import {
  addNewExperiment,
  getAllExperiment,
  getExperimentById,
  getExperimentChartById,
  updateExperiment
} from "../../service/experimentManagement.api";

const { actions } = ExperimentManagementSlice;

export const fetchExperiments = (pageNo ,pageSize,search) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllExperiment(pageNo ,pageSize,search)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.experimentFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find experiments";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchExperimentById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getExperimentById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.experimentFetchedById(response.data));
      } else {
        throw new Error("Error getting Experiment details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createExperiment = experimentData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return addNewExperiment(experimentData)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.experimentCreated(response.data));
        successToast("Experiment Added Successfully");
      } else {
        throw new Error("something went wrong");
      }
    })
    .catch(error => {
      warningToast(error?.response?.data?.detail || "something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const experimentUpdate = experimentData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return updateExperiment(experimentData)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.experimentUpdated(response.data));
        successToast("Experiment Updated Successfully");
      } else {
        throw new Error("Error updating Experiment details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchExperimentChartById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getExperimentChartById(id)
    .then(response => {
        if (response.data.length !== 0){
            dispatch(actions.experimentChartById(response.data[0].data));
        }
        else {
            dispatch(actions.experimentChartById(""));
        }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
