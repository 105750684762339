// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { companyRegistration } from "../../../../../redux/reducers/CompanyRegistrationSlice";

const { actions } = companyRegistration;
// Validation schema

const initialCompanyValues = {
  company_name: "",
  company_email: "",
  company_description: "",
  company_address: "",
  company_pin_code: "",
  company_website: "",
  company_contact: "",
  company_poc: "",
  deployment_region: "",
  company_poc_contact: ""
};

const deploymentRegions = [
  {
    id: 1,
    name: "Ohio",
    value: "us-east-2"
  },
  {
    id: 2,
    name: "N. Virginia",
    value: "us-east-1"
  },
  {
    id: 3,
    name: "N. California",
    value: "us-west-1"
  },
  {
    id: 4,
    name: "Oregon",
    value: "us-west-2"
  },
  {
    id: 5,
    name: "Cape Town",
    value: "af-south-1"
  },
  {
    id: 6,
    name: "Hong Kong",
    value: "ap-east-1"
  },
  {
    id: 7,
    name: "Mumbai",
    value: "ap-south-1"
  },
  {
    id: 8,
    name: "Osaka",
    value: "ap-northeast-3"
  },
  {
    id: 9,
    name: "Seoul",
    value: "ap-northeast-2"
  },
  {
    id: 10,
    name: "Singapore",
    value: "ap-southeast-1"
  },
  {
    id: 11,
    name: "Sydney",
    value: "ap-southeast-2"
  },
  {
    id: 12,
    name: "Tokyo",
    value: "ap-northeast-1"
  },
  {
    id: 13,
    name: "Central",
    value: "ca-central-1"
  },
  {
    id: 14,
    name: "Frankfurt",
    value: "eu-central-1"
  },
  {
    id: 15,
    name: "Ireland",
    value: "eu-west-1"
  },
  {
    id: 16,
    name: "London",
    value: "eu-west-2"
  },
  {
    id: 17,
    name: "Milan",
    value: "eu-south-1"
  },
  {
    id: 18,
    name: "Paris",
    value: "eu-west-3"
  },
  {
    id: 19,
    name: "Stockholm",
    value: "eu-north-1"
  },
  {
    id: 20,
    name: "Bahrain",
    value: "me-south-1"
  },
  {
    id: 21,
    name: "São Paulo",
    value: "sa-east-1"
  }
];

export default function SignUpPage({  setCompanyDetails, accountType }) {
  const dispatch = useDispatch();
  const { companyRegistrationData } = useSelector(
    state => ({
      companyRegistrationData: state.companyRegistration.companyRegistrationData
    }),
    shallowEqual
  );
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user
    }),
    shallowEqual
  );
  const [formData, setFormData] = useState({
    company_name: companyRegistrationData.company_name
      ? companyRegistrationData.company_name
      : "",
    company_email: companyRegistrationData.company_email
      ? companyRegistrationData.company_email
      : user.company_email,
    company_description: companyRegistrationData.company_description
      ? companyRegistrationData.company_description
      : "",
    company_address: companyRegistrationData.company_address
      ? companyRegistrationData.company_address
      : "",
    company_pin_code: companyRegistrationData.company_pin_code
      ? companyRegistrationData.company_pin_code
      : "",
    company_website: companyRegistrationData.company_website
      ? companyRegistrationData.company_website
      : "",
    company_contact: companyRegistrationData.company_contact
      ? companyRegistrationData.company_contact
      : "",
    company_poc: companyRegistrationData.company_poc
      ? companyRegistrationData.company_poc
      : "",
    company_poc_contact: companyRegistrationData.company_poc_contact
      ? companyRegistrationData.company_poc_contact
      : "",
    deployment_region: companyRegistrationData.deployment_region
      ? companyRegistrationData.deployment_region
      : ""
  });
  const RegistrationCompanySchema = Yup.object().shape({
    company_name: Yup.string()
      .matches(/[^0-9]/, "Please Enter Valid Company Name")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required"),
    company_email: Yup.string()
      .matches(
        /^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim,
        "Please Enter Valid Email"
      )
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required"),
    company_description: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required"),
    company_address: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required"),
    company_pin_code: Yup.string()
      .matches(/^[0-9]{5,6}$/, "Please enter valid company pin code")
      .required("Required"),
    company_website: Yup.string()
      // .url("Enter valid company website")
      .required("Required"),
    company_contact: Yup.string()
      .matches(/^[0-9]{10,11}$/, "Please enter valid contact number")
      .required("Required"),
    company_poc: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(25, "Maximum 20 symbols")
      .required("Required"),
    company_poc_contact: Yup.string()
      .matches(/^[0-9]{10,11}$/, "Please enter valid contact number")
      .required("Required"),
    deployment_region: Yup.string().required("Required")
  });

  // eslint-disable-next-line


  const handleChange = (key, event) => {
    let value = event.target.value;
    let data = { ...formData };
    data[key] = value;
    setFormData(data);
    setCompanyDetails(data);
    dispatch(actions.companyCreated(data));
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: initialCompanyValues,
    validationSchema: RegistrationCompanySchema
  });

  return (
    <>
      <Modal.Body>
        <Row>
          <Col xl={2}></Col>
          <Col xl={8}>
            <div
              className="login-form login-signin remove-scroll"
              style={{
                display: "block",
                maxHeight: "539px",
                overflowY: "auto",
                overflowX: "hidden"
              }}
            >
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                  <FormattedMessage id="AUTH.REGISTER.TITLE" />
                </h3>
                {accountType === "no" ? (
                  <p className="text-muted font-weight-bold">
                    Enter your company details to complete Step 1/3
                  </p>
                ) : (
                  <p className="text-muted font-weight-bold">
                    Enter your company details to complete Step
                  </p>
                )}
              </div>

              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                {/* begin: Alert */}
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                {/* end: Alert */}

                {/* begin: Company Name */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company Name
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Company Name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_name"
                        )}`}
                        name="company_name"
                        defaultValue={formData.company_name}
                        onChange={event => handleChange("company_name", event)}
                      />
                      {formik.touched.company_name &&
                      formik.errors.company_name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_name}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Name */}

                {/* begin: Company Email */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company Email
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Company Email"
                        type="company_email"
                        disabled={true}
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_email"
                        )}`}
                        name="company_email"
                        defaultValue={formData.company_email}
                        onChange={event => handleChange("company_email", event)}
                      />
                      {formik.touched.company_email &&
                      formik.errors.company_email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_email}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Email */}

                {/* begin: Company Description */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company Description
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <textarea
                        placeholder="Company Description"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_description"
                        )}`}
                        name="company_description"
                        defaultValue={formData.company_description}
                        onChange={event =>
                          handleChange("company_description", event)
                        }
                      />
                      {formik.touched.company_description &&
                      formik.errors.company_description ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_description}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Description */}

                {/* begin: Company Address */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company Address
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <textarea
                        placeholder="Company Address"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_address"
                        )}`}
                        name="company_address"
                        defaultValue={formData.company_address}
                        onChange={event =>
                          handleChange("company_address", event)
                        }
                      />
                      {formik.touched.company_address &&
                      formik.errors.company_address ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_address}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Address */}

                {/* begin: Company Pin Code */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company Pincode
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Company Pin Code"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_pin_code"
                        )}`}
                        name="company_pin_code"
                        defaultValue={formData.company_pin_code}
                        onChange={event =>
                          handleChange("company_pin_code", event)
                        }
                      />
                      {formik.touched.company_pin_code &&
                      formik.errors.company_pin_code ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_pin_code}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Pin Code */}

                {/* begin: Company Website */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company Website
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Company Website"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_website"
                        )}`}
                        name="company_website"
                        defaultValue={formData.company_website}
                        onChange={event =>
                          handleChange("company_website", event)
                        }
                      />
                      {formik.touched.company_website &&
                      formik.errors.company_website ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_website}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Website */}

                {/* begin: Company Contact */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company Contact
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Company Contact"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_contact"
                        )}`}
                        name="company_contact"
                        defaultValue={formData.company_contact}
                        onChange={event =>
                          handleChange("company_contact", event)
                        }
                      />
                      {formik.touched.company_contact &&
                      formik.errors.company_contact ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_contact}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company Contact */}

                {/* begin: Company POC */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company POC
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Company POC"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_poc"
                        )}`}
                        name="company_poc"
                        defaultValue={formData.company_poc}
                        onChange={event => handleChange("company_poc", event)}
                      />
                      {formik.touched.company_poc &&
                      formik.errors.company_poc ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_poc}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Company POC */}

                {/* begin: Company POC Contact */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Company POC Contact
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        placeholder="Company POC Contact"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                          "company_poc_contact"
                        )}`}
                        name="company_poc_contact"
                        defaultValue={formData.company_poc_contact}
                        onChange={event =>
                          handleChange("company_poc_contact", event)
                        }
                      />
                      {formik.touched.company_poc_contact &&
                      formik.errors.company_poc_contact ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.company_poc_contact}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                {/* begin: Deployment Region */}
                <Row>
                  <Col xl={3} className="mt-4 mb-2">
                    Deploy Region
                  </Col>
                  <Col xl={9}>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Control
                        as="select"
                        name="deployment_region"
                        className={`form-control form-control-solid h-auto py-5 px-6${getInputClasses(
                          "deployment_region"
                        )}`}
                        defaultValue={formData.deployment_region}
                        onChange={event =>
                          handleChange("deployment_region", event)
                        }
                      >
                        {!formik.values.deployment_region && (
                          <option value={-1}>Select Deployment Region</option>
                        )}
                        {deploymentRegions?.map(d => (
                          <option value={d.value} key={d.id}>
                            {d.name}
                          </option>
                        ))}
                      </Form.Control>
                      {formik.touched.deployment_region &&
                      formik.errors.deployment_region ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.deployment_region}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                {/* end: Deployment Region*/}
              </form>
            </div>
          </Col>
          <Col xl={2}></Col>
        </Row>
      </Modal.Body>
    </>
  );
}
