import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

export function ImportDataMainPage() {
  const [formData, setFormData] = useState();

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2>Train Modal</h2>
        </div>
        <div>
          <h6>* is.</h6>
        </div>
      </div>

      <div className={"centered-element"}>
        <h2 className={"text-center"} style={{ marginBottom: "30px" }}>
          Add New Dataset
        </h2>
        <Form className={"m-5"}>
          <Row>
            <Col xl={6} md={6} lg={6} sm={12} xs={12}>
              <Form.Group controlId="ExperimentName">
                <Form.Label sm={12}>
                  Experiment Name
                  {/*<Tooltip*/}
                  {/*    title="The Name of your deep learning model ex. Person Detection."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        The Name of your deep learning model ex. Person
                        Detection.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="text"
                    name="experimentName"
                    placeholder="Experiment name"
                    // value={formData.experimentName}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              {/*<Form.Group controlId="firstName">*/}
              {/*    <Form.Label column sm={12}>*/}
              {/*        First Name*/}
              {/*    </Form.Label>*/}
              {/*    <Col sm={12}>*/}
              {/*        <Form.Control*/}
              {/*            type="text"*/}
              {/*            name="firstName"*/}
              {/*            placeholder="First Name"*/}
              {/*            // value={formData.locationName}*/}
              {/*            onChange={handleChange}*/}
              {/*        />*/}
              {/*    </Col>*/}
              {/*    <div className={"ml-5"}>*/}
              {/*        Your <b>real name</b> as it appears in your passport, driver's*/}
              {/*        license, or other legal documents.*/}
              {/*    </div>*/}
              {/*</Form.Group>*/}
            </Col>
            <Col xl={6} md={6} lg={6} sm={12} xs={12}>
              <Form.Group controlId="ExperimentDescription">
                <Form.Label sm={12}>
                  Experiment Description
                  {/*<Tooltip*/}
                  {/*    title="Model's General Information ex. Person Detection Model will detect person from input image or video "*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        Model's General Information ex. Person Detection Model
                        will detect person from input image or video{" "}
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Experiment description"
                    name="experimentDescription"
                    // value={formData.experimentDescription}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xl={6} md={6} lg={6} sm={12} xs={12}>
              <Form.Group controlId="modelId">
                <Form.Label sm={12}>
                  Model
                  <Tooltip
                    title={
                      <div className="tools">
                        A “model” in machine learning is the output of a machine
                        learning algorithm run on data.A model represents what
                        was learned by a machine learning algorithm using
                        historical data.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Model"}
                    name="model"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xl={6} md={6} lg={6} sm={12} xs={12}>
              <Form.Group controlId="projectId">
                <Form.Label sm={12}>
                  Project
                  {/*<Tooltip*/}
                  {/*    title="Project is detection system which may use more than one model for detection from image and video input."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        Project is detection system which may use more than one
                        model for detection from image and video input.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Project"}
                    name="project"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xl={6} md={6} lg={6} sm={12} xs={12}>
              <Form.Group controlId="hyperParamsId">
                <Form.Label sm={12}>
                  Hyper Parameter
                  {/*<Tooltip*/}
                  {/*    title="A hyperparameter is a parameter whose value is used to control the learning process of a model.Such examples of hyperparameters are learning rate and mini-batch size.."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        A hyperparameter is a parameter whose value is used to
                        control the learning process of a model.Such examples of
                        hyperparameters are learning rate and mini-batch size..
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Hyper Parameter"}
                    name="hyperParameter"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xl={6} md={6} lg={6} sm={12} xs={12}>
              <Form.Group controlId="annotationType">
                <Form.Label sm={12}>
                  Target Annotation Type
                  {/*<Tooltip*/}
                  {/*    title= "Annotation type of your Dataset like pascal (XML) or yolo (TXT)"*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        Annotation type of your dataset like pascal (XML) or
                        yolo (TXT)
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Target Annotation Type"}
                    name="targetAnnotationType"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xl={6} md={6} lg={6} sm={12} xs={12}>
              <Form.Group controlId="datasetId">
                <Form.Label sm={12}>
                  Dataset
                  {/*<Tooltip*/}
                  {/*    title= "A Dataset (or data set) is a collection of data in various forms like text,image,video,voice,etc."*/}
                  {/*    placement="right"*/}
                  {/*><InfoIcon className="ml-2"></InfoIcon></Tooltip>*/}
                  <Tooltip
                    title={
                      <div className="tools">
                        A dataset (or data set) is a collection of data in
                        various forms like text,image,video,voice,etc.
                      </div>
                    }
                    placement={"right"}
                  >
                    <InfoIcon className={"icon-color"} />
                  </Tooltip>
                </Form.Label>
                <Col sm={12}>
                  <Select
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#5DBFC4",
                        primary: "#147b82"
                      }
                    })}
                    placeholder={"Select Dataset"}
                    name="dataset"
                    className="select-react-dropdown"
                    // options={levelOfStudyOptions}
                    // onChange={(e) => this.changeLocationOptions(e)}
                    // defaultValue={this.state.locationDropDownDetails}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xl={6} md={6} lg={6} sm={12} xs={12} />
          </Row>
        </Form>
      </div>
    </>
  );
}
