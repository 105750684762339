import React, {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import ModalHeaderCommon from "../../../../shared/components/ModalHeaderCommon";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../../redux/actions/DatasetManagementAction";
import UploadedDatasetImagePreview from "./UploadedDatasetImagePreview";
import BlockUi from "react-block-ui";

function UploadedImageModal({
                                show, handleClose, dataSetId
                            }) {
    const dispatch = useDispatch();

    const {uploadedImagesArr, uploadedImagesLoader} = useSelector(state => ({
        uploadedImagesArr: state.datasetManagement?.uploadedImagesArr,
        uploadedImagesLoader: state.datasetManagement?.uploadedImagesLoader
    }), shallowEqual);

    useEffect(() => {
        if (dataSetId) {
            dispatch(actions.fetchUploadedImages(dataSetId));
        }
    }, [dataSetId]);

    return (<>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                scrollable={true}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalHeaderCommon
                    title={`Uploaded Images`}
                />
                <Modal.Body>

                    <div className={"annotatioon-image-card-raw"} style={{position: "relative"}}>
                        <BlockUi tag="div" blocking={uploadedImagesLoader} color="#147b82">
                            {uploadedImagesArr && uploadedImagesArr.length > 0 ? (<div className={"upload-image-row"}>
                                    {uploadedImagesArr.length > 0 ? (uploadedImagesArr.map((item, index) => {
                                            return (<>
                                                    <div className={'dataset-upload-card'}>
                                                        <UploadedDatasetImagePreview
                                                            data={item}
                                                            dataIndex={index}
                                                            imageListForDataset={uploadedImagesArr}
                                                        />
                                                    </div>
                                                </>);
                                        })) : (<h3 style={{paddingTop: "40px"}} className="text-center">
                                            No Data Found
                                        </h3>)}
                                </div>) : (<h3 style={{paddingTop: "40px"}} className="text-center">
                                    No Data Found
                                </h3>)}
                        </BlockUi>
                    </div>
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-between'}>
                    <div>
                    <span className="label label-lg  label-inline font-weight-bold py-4"
                          style={{backgroundColor: "#147b82", color: "#fff", height: '36px'}}>
                    Count : {uploadedImagesArr?.length ? uploadedImagesArr.length : 0}
                  </span>
                    </div>

                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>);
}

export default UploadedImageModal;
