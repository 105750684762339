import { createSlice } from "@reduxjs/toolkit";

const initialAnnotationTypeState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  filteredEntities: "",
  annotationTypeFetchedById: "",
  totalAnnotation: 0,
  page: 1,
  size: 10
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const AnnotationTypeSlice = createSlice({
  name: "dl-annotationType",
  initialState: initialAnnotationTypeState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    annotationTypeFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload.items;
      state.totalAnnotation = action.payload.total;
      state.page = action.payload.page;
      state.size = action.payload.size;
      state.totalCount = action.payload.length;
    },

    annotationTypeFetchedForData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload;
      state.totalAnnotation = action.payload.total;
      state.totalCount = action.payload.length;
    },

    annotationTypeFetchedById: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.annotationTypeFetchedById = action?.payload;
    },

    annotationTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.entities.push(action.payload);
      state.error = null;
    },

    annotationTypeUpdated: (state, action) => {
      let data = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map(entity => {
        if (entity.id === data.id) {
          return data;
        }
        return entity;
      });
    },

    clearAnnotationTypeById: (state, action) => {
      state.annotationTypeFetchedById = "";
    }
  }
});
