import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ExperimentDetailsForm } from "./ExperimentDetailsForm";
import { ExperimentManagementAdminSlice } from "../../_redux/ExperimentManagementAdminSlice";
import * as action from "../../_redux/ExperimentManagementAdminAction";

const { actions } = ExperimentManagementAdminSlice;

export function ExperimentDetailsDialog({ id, show, onHide }) {
  const dispatch = useDispatch();
  const { actionsLoading, experimentDetails, experiments } = useSelector(
    state => ({
      actionsLoading: state.experimentManagement.actionsLoading,
      experiments: state.experimentManagement.entities,
      experimentDetails: state.experimentManagement.experimentFetchedById
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch(action.fetchExperimentById(id));
    } else {
      dispatch(actions.clearExperimentById());
    }
    // eslint-disable-next-line
  }, [id, experiments]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Experiment Details
        </Modal.Title>
      </Modal.Header>
      <ExperimentDetailsForm
        actionsLoading={actionsLoading}
        experimentDetails={experimentDetails}
        onHide={onHide}
      />
    </Modal>
  );
}
