import React, { useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { ExperimentManagementTable } from "./experiment-management-table/ExperimentManagementTable";
import { RequestedManagementTable } from "./experiment-management-table/RequestedManagementTable";
import { Tab, Tabs } from "react-bootstrap";

export function ExperimentManagementCard() {
  const [activeKey, setActiveKey] = useState("Experiment-details");
  return (
    <Card>
      <CardBody>
        <Tabs
          id="controlled-tab-example"
          activeKey={activeKey}
          onSelect={e => setActiveKey(e)}
          style={{ fontSize: "1.275rem", fontWeight: "500" }}
        >
          <Tab eventKey="experiment-details" title="Experiment Details">
            <ExperimentManagementTable myKey={activeKey} />
          </Tab>
          <Tab eventKey="results" title="Requested Experiments">
            <RequestedManagementTable myKey={activeKey} />
          </Tab>
        </Tabs>
      </CardBody>
    </Card>
  );
}
