import { createSlice } from "@reduxjs/toolkit";

const initialExperimentState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  lastError: null,
  filteredEntities: "",
  experimentFetchedById: "",
  experimentChartById: "",
  experimentTotalSize:0,
  page:1,
  size:10
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ExperimentManagementSlice = createSlice({
  name: "dl-Experiment",
  initialState: initialExperimentState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.entities = [];
      } else {
        state.actionsLoading = false;
        state.entities = [];
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    experimentFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload.items;
      state.experimentTotalSize = action.payload.total;
      state.totalCount = action.payload.total;
      state.page=action.payload.page;
      state.size=action.payload.size;
    },

    experimentFetchedById: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.experimentFetchedById = action.payload;
    },

    experimentCreated: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.entities.push(action.payload);
      state.error = null;
    },

    experimentUpdated: (state, action) => {
      let data = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map(entity => {
        if (entity.id === data.id) {
          return data;
        }
        return entity;
      });
    },

    clearExperimentById: (state, action) => {
      state.experimentFetchedById = "";
    },
    experimentChartById: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.experimentChartById = action.payload;
    }
  }
});
