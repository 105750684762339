import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function HelpSectionCard({ image, description, heading }) {
  return (
    <div
      // className="card help my-widget popup"
        className="card help popup"
      style={{
        width: "auto",
        height: "auto",
        borderStyle: "solid"
      }}
    >
      <div className="row no-gutters">
        <div className="col-sm-5">
          <div>
            <Zoom>
              {" "}
              <img
                className="card-img image-screenshot"
                style={{ borderStyle: "outset", borderColor: "black" }}
                src={image}
                alt={image + "-image"}
              />
            </Zoom>
          </div>
        </div>
        <div className="col-sm-7">
          <div className="card-body">
            <h5 className="card-title">{heading}</h5>
            <p className="card-text">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
