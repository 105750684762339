import React, { Suspense } from "react";
import { AnnotationTypePage } from "./AnnotationTypeTable/AnnotationTypePage";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";

function AnnotationType() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact={true}
            from="/annotationType"
            to="/annotationType/annotationTypePage"
          />
        }
        <ContentRoute
          path="/annotationType/annotationTypePage"
          component={AnnotationTypePage}
        />
      </Switch>
    </Suspense>
  );
}
export default AnnotationType;