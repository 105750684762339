import { request } from "../../../../shared/helper/RequestWrapper";

const GET_ALL_PROJECTS = "/get_all_projects";
const GET_PROJECT_BY_ID = "/get_project_by_id";
const UPDATE_PROJECT = "/update_project";

export async function getProjectById(id) {
  return await request({
    endpoint: GET_PROJECT_BY_ID + "?project_id=" + id,
    method: "GET"
  });
}

export async function updateProject(data) {
  return await request({
    endpoint: UPDATE_PROJECT,
    method: "POST",
    body: JSON.stringify(data)
  });
}

export async function getAllProject(pageNo,pageSize,search) {
  return await request({
    endpoint: GET_ALL_PROJECTS  + `?page=${pageNo}&size=${pageSize}&search=${search}`,
    method: "GET"
  });
}
