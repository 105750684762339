import { Route } from "react-router-dom";
import React from "react";
import { AnnotationTypeUIProvider } from "./AnnotationTypeUIContext";
import { AnnotationTypeCard } from "./AnnotationTypeCard";
import { AnnotationTypeEditDialog } from "./annotation-type-edit-dialog/AnnotationTypeEditDialog";
import { AnnotationTypeStatusDialog } from "./annotation-type-status-dialog/AnnotationTypeStatusDialog";

export function AnnotationTypePage({ history }) {
  const annotationTypePageBaseUrl = "/annotationType/annotationTypePage";

  const annotationTypeUIEvents = {
    newAnnotationTypeButtonClick: () => {
      history.push(`${annotationTypePageBaseUrl}/new`);
    },
    openChangeStatusDialog: (id, status) => {
      history.push(`${annotationTypePageBaseUrl}/${id}/${status}/changeStatus`);
    },
    openEditAnnotationTypeDialog: id => {
      history.push(`${annotationTypePageBaseUrl}/${id}/edit`);
    }
  };

  return (
    <AnnotationTypeUIProvider annotationTypeUIEvents={annotationTypeUIEvents}>
      <Route path={`${annotationTypePageBaseUrl}/new`}>
        {({ history, match }) => (
          <AnnotationTypeEditDialog
            show={match != null}
            onHide={() => {
              history.push(annotationTypePageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${annotationTypePageBaseUrl}/:id/edit`}>
        {({ history, match }) => (
          <AnnotationTypeEditDialog
            show={match != null}
            id={match?.params?.id}
            onHide={() => {
              history.push(annotationTypePageBaseUrl);
            }}
          />
        )}
      </Route>
      <Route path={`${annotationTypePageBaseUrl}/:id/:status/changeStatus`}>
        {({ history, match }) => (
          <AnnotationTypeStatusDialog
            show={match != null}
            id={match?.params?.id}
            status={match?.params?.status}
            onHide={() => {
              history.push(annotationTypePageBaseUrl);
            }}
          />
        )}
      </Route>
      <AnnotationTypeCard />
    </AnnotationTypeUIProvider>
  );
}
