import React, { useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import { warningToast } from "../../../../../shared/components/ToastMessage";
import BlockUi from "react-block-ui";
import { Col, Form, Row } from "react-bootstrap";
import "../../../../../_metronic/_assets/sass/components/custom/upload-dataset.scss";

export default function UploadDataMainPage({ onHide }) {
  const [pictures, setPictures] = useState([]);
  const [showPreview, setShowPreview] = useState(true);
  const [datasetName, setDatasetName] = useState("");
  const [datasetDesc, setDatasetDesc] = useState("");
  const [loader, setLoader] = useState(false);

  const handleNameChange = e => {
    setDatasetName(e.target.value);
  };

  const handleDescChange = e => {
    setDatasetDesc(e.target.value);
  };

  const onDrop = (picture, pictureDataURLs) => {
    setLoader(true);
    if (picture.length < 500) {
      setPictures([...pictures, picture]);
      setShowPreview(true);
    } else {
      warningToast("You can upload maximum 500 images");
      setPictures([...pictures, picture.slice(0, 500)]);
    }
    setLoader(false);
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2>Train Modal</h2>
        </div>
        <div>
          <h6>* is.</h6>
        </div>
      </div>

      <BlockUi tag="div" blocking={loader} color="#147b82">
        <div className={"centered-element"}>
          <h2 className={"text-center"} style={{ marginBottom: "30px" }}>
            Upload Dataset
          </h2>
          <Form className={"m-5"}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group controlId="email">
                  <Form.Label column sm={12}>
                    Dataset Name
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      name="datasetName"
                      placeholder="Dataset Name"
                      value={datasetName}
                      onChange={handleNameChange}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group controlId="lastName">
                  <Form.Label column sm={12}>
                    Dataset Description
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      name="datasetDesc"
                      placeholder="Dataset Description"
                      value={datasetDesc}
                      onChange={handleDescChange}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <div>
              <Form.Group>
                <Col sm={12}>
                  <button
                    className="btn btn-primary mr-2"
                    // onClick={()=>{handleUpload()}}
                  >
                    Upload
                  </button>
                  <button
                    className="btn btn-danger"
                    // onClick={handleRemove}
                  >
                    Reset
                  </button>
                </Col>
              </Form.Group>
            </div>
            <Row>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  maxHeight: "434px",
                  overflowY: "auto"
                }}
                className={"m-5"}
              >
                <ImageUploader
                  className="fileContainer uploadPictureContainer deleteImage chooseFileButton"
                  withIcon={true}
                  withLabel={true}
                  withPreview={showPreview}
                  label="Max file size: 2MB, accepted: .jpg .gif .png .svg"
                  buttonText="Select Images"
                  onChange={(e, p) => {
                    setLoader(true);
                    onDrop(e, p);
                  }}
                  imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                  maxFileSize={2048576}
                  fileSizeError=" file size is too big"
                />
              </Col>
            </Row>
          </Form>
        </div>
      </BlockUi>
    </>
  );
}
