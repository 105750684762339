import React, { useEffect, useState } from "react";
import { Card, Container, Tab, Tabs } from "react-bootstrap";
import ChoiceForCompunyAndStudent from "./ChoiceForCompunyAndStudent";
import { StudentMainPage } from "./student/StudentMainPage";
import { CompanyMainPage } from "./company/CompanyMainPage";
import { ChoicePage1 } from "./ChoicePage1";
import { ChoicePage2 } from "./ChoicePage2";
import { ChoicePage3 } from "./ChoicePage3";
import { ChoicePage4 } from "./ChoicePage4";
import { ProjectCreate } from "./ProjectCreate";
import DataSetCreate from "./DataSetCreate";
import { ImportDataMainPage } from "./importDataSet/ImportDataMainPage";
import UploadDataMainPage from "./uploadDataSet/UploadDataMainPage";
import { HyperParamsCreate } from "./HyperParamsCreate";
import { ExperimentCreate } from "./ExperimentCreate";

const BlankPage = () => {
  const [activeKey, setActiveKey] = useState(0);
  const [showFinish, setShowFinish] = useState(1);
  const [accountName, setAccountName] = useState("");
  const [datasetName, setDatasetName] = useState("");

  const [button, setButton] = useState(false);

  const nextPageChange = () => {
    setActiveKey(activeKey + 1);
    if (activeKey === 8) {
      setShowFinish(0);
    }
  };

  const modalClose = () => {
    window.location.href = "/#/user/experiments/experimentManagementPage";
  };

  const choiceAccount = accountNameNew => {
    if (accountNameNew === accountName) {
      setAccountName("");
      setButton(false);
    } else {
      setAccountName(accountNameNew);
      setButton(true);
    }
  };

  const choiceDataset = DatasetNameNew => {
    if (DatasetNameNew === datasetName) {
      setDatasetName("");
    } else {
      setDatasetName(DatasetNameNew);
    }
  };

  let handleInsertStudent = () => {
  };

  let handleInsertExpriment = () => {};

  const handleHyperChange = e => {
    setShowFinish(e.target.checked);
  };

  const buttonConfig = {
    //ActiveStep: ButtonText
    0: { buttonText: "Next", insertApi: handleInsertStudent },
    1: { buttonText: "Next", insertApi: handleInsertStudent },
    2: { buttonText: "Skip", insertApi: handleInsertStudent },
    3: { buttonText: "Skip", insertApi: handleInsertStudent },
    4: { buttonText: "Skip", insertApi: handleInsertStudent },
    5: { buttonText: "Skip", insertApi: handleInsertStudent },
    6: { buttonText: "Next", insertApi: handleInsertStudent },
    7: { buttonText: "Next", insertApi: handleInsertStudent },
    8: { buttonText: "Next", insertApi: handleInsertStudent },
    9: { buttonText: "Next", insertApi: handleInsertStudent },
    10: { buttonText: "Train", insertApi: handleInsertExpriment }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Card
        style={{
          height: "100vh"
        }}
      >
        <Card.Body className={"custom-tabs"}>
          {/*<DatasetManagementTable/>*/}
          <Container>
            <Tabs
              id="controlled-tab-example"
              activeKey={activeKey}
              style={{
                fontSize: "1.275rem",
                fontWeight: "500",
                border: "none"
              }}
              className={"new-nav"}
            >
              <Tab eventKey="0">
                <ChoiceForCompunyAndStudent
                  // nextPageChange={nextPageChange}
                  choiceAccount={choiceAccount}
                  accountName={accountName}
                />
              </Tab>
              <Tab eventKey="1">
                {accountName === "student" ? (
                  <StudentMainPage />
                ) : (
                  <CompanyMainPage />
                )}
              </Tab>
              <Tab eventKey="2">
                <ChoicePage1
                // nextPageChange={nextPageChange}
                />
              </Tab>
              <Tab eventKey="3">
                <ChoicePage2
                // nextPageChange={nextPageChange}
                />
              </Tab>
              <Tab eventKey="4">
                <ChoicePage3
                // nextPageChange={nextPageChange}
                />
              </Tab>
              <Tab eventKey="5">
                <ChoicePage4
                // nextPageChange={nextPageChange}
                />
              </Tab>

              <Tab eventKey="6">
                <ProjectCreate
                // nextPageChange={nextPageChange}
                />
              </Tab>
              <Tab eventKey="7">
                <DataSetCreate
                  choiceDataset={choiceDataset}
                  datasetName={datasetName}
                />
              </Tab>
              <Tab eventKey="8">
                {datasetName === "importDataSet" ? (
                  <ImportDataMainPage />
                ) : (
                  <UploadDataMainPage />
                )}
              </Tab>
              <Tab eventKey="9">
                <ExperimentCreate
                  handleHyperChange={handleHyperChange}
                  modalClose={modalClose}
                />
              </Tab>

              <Tab eventKey="10">
                <HyperParamsCreate modalClose={modalClose} />
              </Tab>
            </Tabs>

            <div className={"button-centered-element "}>
              <button
                disabled={!button}
                type="button"
                className="btn btn-primary px-5 py-3"
                onClick={
                  buttonConfig[activeKey]?.buttonText === "Train" || !showFinish
                    ? modalClose
                    : nextPageChange
                }
              >
                {!showFinish ? "Train" : buttonConfig[activeKey]?.buttonText}
              </button>
            </div>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default BlankPage;
