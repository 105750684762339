import { AnnotationTypeSlice, callTypes } from "./AnnotationTypeSlice";
import { successToast, warningToast } from "../../../../shared/components/ToastMessage";
import {
  addNewAnnotationType,
  getAllAnnotationType,
  getAllEnabledAnnotationType,
  getAnnotationTypeById,
  updateAnnotationType
} from "./AnnotationTypeAPI";

const { actions } = AnnotationTypeSlice;

export const fetchAnnotationTypes = (pageNo ,pageSize,search) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllAnnotationType(pageNo ,pageSize,search)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.annotationTypeFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find annotation types";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEnabledAnnotationTypes = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllEnabledAnnotationType()
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.annotationTypeFetchedForData(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find annotation types";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createAnnotationType = annotationTypeData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  const data = {
    annotation_type_name: annotationTypeData.annotationTypeName,
    annotation_type_name_description:
      annotationTypeData.annotationTypeDescription,
    status: annotationTypeData.status
  };
  return addNewAnnotationType(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.annotationTypeCreated(response.data));
        successToast("Annotation Type Added Successfully");
      } else {
        throw new Error("something went wrong");
      }
    })
    .catch(error => {
      warningToast("something went wrong");
      console.error("error:", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAnnotationTypeById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getAnnotationTypeById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.annotationTypeFetchedById(response.data));
      } else {
        throw new Error("Error getting annotation type details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const annotationTypeUpdate = annotationTypeData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    annotation_type_name:
      annotationTypeData.annotationTypeName ||
      annotationTypeData.annotation_type_name,
    annotation_type_name_description:
      annotationTypeData.annotationTypeDescription ||
      annotationTypeData.annotation_type_name_description,
    status: annotationTypeData.status,
    id: annotationTypeData.id
  };

  return updateAnnotationType(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.annotationTypeUpdated(response.data));
      } else {
        throw new Error("Error updating annotation type details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
