import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { ModelTable } from "./model-table/ModelTable";
import { useModelUIContext } from "./ModelUIContext";

export function ModelCard() {
  const modelUIContext = useModelUIContext();
  const modelUIProps = useMemo(() => {
    return {
      newModelButtonClick: modelUIContext.newModelButtonClick
    };
  }, [modelUIContext]);

  return (
    <Card>
      <CardHeader title="Model Details">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={modelUIProps.newModelButtonClick}
          >
            Add Model
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ModelTable />
      </CardBody>
    </Card>
  );
}
