import React, { Suspense } from "react";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";
import { ExperimentManagementPage } from "./ExperimentManagementTable/ExperimentManagementPage";
import { ExperimentModal } from "./ExperimentManagementTable/experiment-management-table/Model/ExperimentModal";

 function ExperimentManagement() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact={true}
            from="/experiments"
            to="/experiments/experimentManagementPage"
          />
        }
        <ContentRoute
          path="/experiments/experimentManagementPage"
          component={ExperimentManagementPage}
        />
        <ContentRoute
          exact
          path="/experiments/experiment-management-table/model/experimentModal/:id"
        >
          {({ match }) => <ExperimentModal id={match?.params?.id} />}
        </ContentRoute>
      </Switch>
    </Suspense>
  );
}
export default ExperimentManagement;