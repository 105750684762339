import { createSlice } from "@reduxjs/toolkit";

const initialExperimentState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  lastError: null,
  filteredEntities: "",
  experimentFetchedById: "",
  experimentChartById: "",
  userRegistrationData: ""
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const userRegistration = createSlice({
  name: "userRegistration",
  initialState: initialExperimentState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.userRegistrationData = "";
      } else {
        state.actionsLoading = false;
        state.userRegistrationData = "";
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    userCreated: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.userRegistrationData = action.payload;
      state.error = null;
    }
  }
});
