/* eslint-disable */
import React from "react";
import Cookies from 'universal-cookie';
import {ACCESS_TOKEN, TOKEN_TYPE} from "../enums/auth.enums";
import {warningToast} from "../components/ToastMessage";
import {appConfigs} from "../../configs";

const axios = require('axios');

export function request(options) {

    let isStatus = false;
    let isSuccess = false;
    let failureStatus = false;
    let unAthorizedStatus = false;
    let setConfig = {}
    if(options['headers']?.project){
        setConfig = {
            headers: options['headers'] ? options['headers'] : {'Content-Type': 'application/json'},
            url: 'https://adapi.tusker.ai/api/v1' + options['endpoint'],
            method: options['method'],
            data: options['body'],
        };
    }else {
        setConfig = {
            headers: options['headers'] ? options['headers'] : {'Content-Type': 'application/json'},
            url: 'https://adapi.tusker.ai/api/v1' + options['endpoint'],
            method: options['method'],
            data: options['body'],
        };
    }
    console.log("setConfig:::::",options,options['headers'] ,options['url'] , options['headers']?.project,setConfig ,appConfigs.API_HOST ,appConfigs.APP_AUTO_SERVING_API_HOST)
    const config = setConfig
    const cookies = new Cookies();
    if (cookies.get(ACCESS_TOKEN) && cookies.get(TOKEN_TYPE)) {
        config['headers']['Authorization'] = cookies.get(TOKEN_TYPE, {httpOnly: false ,path: "/"}) + " " + cookies.get(ACCESS_TOKEN, {httpOnly: false ,path: "/"});
    }
    return axios.request(config)
        .then(response => {
            let data;
            if (response.request.status === 200) {
                isSuccess = true;
                data = response.data;

            } else {
                isSuccess = false;
                data = null;
            }
            return {data, isStatus, isSuccess, failureStatus}

        })
        .catch((error) => {
            const {status} = error.response;
            if (status === 401) {
                warningToast('Access Denied!');
                cookies.remove(ACCESS_TOKEN, {httpOnly: false,path: "/"});
                cookies.remove(TOKEN_TYPE, {httpOnly: false,path: "/"});
                unAthorizedStatus = true;
                window.location.href = "logout"
            } else if (status === 404) {
                isStatus = true;
                // warningToast(error.response?.data?.detail || "No Data Found!");
            } else if (status === 500) {
                failureStatus = true;
            }
            throw error;
        })
};

export const extractErrorFromError =(error) =>{

    let responceBody = error?.response?.data || {} ;
     let errorResponce = responceBody?.detail?.message || responceBody?.message || responceBody?.detail ;
        return errorResponce || "Something Went wrong";
}
