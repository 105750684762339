import { callTypes, DatasetManagementSlice } from "../reducers/DatasetManagementSlice";
import { successToast, warningToast } from "../../shared/components/ToastMessage";
import {
    addNewDataset,
    getAllDataset,
    getDatasetById,
    updateDataset,
    getUploadedFilesFromUser,
    addUploadedDataset,
    updateUploadedDataset,
    getUploadedDatasetById,
    getAllUploadedDataset,
    getUserUploadedImages, addImagesFromUserRtsp, getAllDatasetForDropDown
} from "../../service/datasetManagement.api";

const { actions } = DatasetManagementSlice;

export const fetchDatasets = (pageNo ,pageSize,search) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllDataset(pageNo,pageSize,search)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find datasets";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchDatasetsForDropDown = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllDatasetForDropDown()
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetFetchedForDropDown(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find datasets";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDatasetById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getDatasetById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetFetchedById(response.data));
      } else {
        throw new Error("Error getting Dataset details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDataset = datasetData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return addNewDataset(datasetData)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetCreated(response.data));
        successToast("Dataset Added Successfully");
      } else {
        throw new Error("something went wrong");
      }
    })
    .catch(error => {
      warningToast(error?.response?.data?.detail || "something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const datasetUpdate = datasetData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return updateDataset(datasetData)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetUpdated(response.data));
          successToast("Impoer Datset details updated successfully");

      } else {
        throw new Error("Error updating Dataset details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const uploadDatasetFiles = (i, name, body,file_type,fps) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getUploadedFilesFromUser(i, name, body,file_type,fps)
    .then(response => {
      if (response && response.isSuccess) {
      } else {
        throw new Error("Error getting Dataset details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createUploadedDataset = datasetData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return addUploadedDataset(datasetData)
    .then(response => {
      if (response && response.isSuccess) {
        return dispatch(actions.datasetUploadedCreated(response.data));
        successToast("Dataset Added Successfully");
      } else {
        throw new Error("something went wrong");
      }
    })
    .catch(error => {
      warningToast(error?.response?.data?.detail || "something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const uploadDatasetUpdate = datasetData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    dataset_name: datasetData.datasetName || datasetData.dataset_name,
    dataset_description:
      datasetData.datasetDescription || datasetData.dataset_description,
    status: datasetData.status,
    id: datasetData.id
  };
  return updateUploadedDataset(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetUpdated(response.data));
      } else {
        throw new Error("Error updating Dataset details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchUploadedDatasetById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getUploadedDatasetById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetFetchedById(response.data));
      } else {
        throw new Error("Error getting Dataset details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      console.error("Error: ", error);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchUploadedDatasets = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllUploadedDataset()
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.datasetUploadedFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find datasets";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchUploadedImages = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getUserUploadedImages(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.uploadedImages(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find datasets";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
// FOR RTSP API
export const createAddImagesRtsp = (datasetData,id,dataset_name) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return addImagesFromUserRtsp(datasetData,id,dataset_name)
      .then(response => {
        if (response && response.isSuccess) {
          return dispatch(actions.datasetUploadedCreated(response.data));
          successToast("Dataset Added Successfully");
        } else {
          throw new Error("something went wrong");
        }
      })
      .catch(error => {
        warningToast(error?.response?.data?.detail || "something went wrong");
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
