import {Container, Modal} from "react-bootstrap";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import React from "react";

function SendEmailNotificationModal({modalClose, email, show}) {
    return(
        <>
            <Modal
                style={{marginTop: "0vh"}}
                show={show}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Container>
                        <div className={"text-center"}>
                            <h3> check your inbox to log in</h3>
                            <img
                                src={toAbsoluteUrl("/media/gif/mail-send.gif")}
                                alt="Metronic logo"
                            />
                        </div>
                        <div>
                            To complete signup process please validate your email by otp
                            verification. &nbsp;
                            <b>{email}</b>
                        </div>
                        <div className={"text-center"}>
                            <button
                                type="submit"
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                onClick={modalClose}
                            >
                                <span>Ok</span>
                            </button>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )

}
export default SendEmailNotificationModal;