import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Radio, RadioGroup } from "react-radio-group";
import { contryNameOptions } from "../../../../../shared/enums/contryName.enums";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {
  getCurrentDayEndDateWithTimeInUtc,
  getCurrentDayStartDateWithTimeInUtc,
  getUtcDateAndTimeFromCalendar
} from "../../../../../shared/helper/timeZone";

const levelOfStudyOptions = [
  { value: "secondary", label: "Secondary (middle or high school)" },
  { value: "vocational", label: "Vocational education" },
  { value: "undergraduate", label: "Undergraduate" },
  { value: "graduate-postgraduate", label: "Graduate / Postgraduate" }
];

export function StudentMainPage() {
  const [formData, setFormData] = useState({
    // message: '',
    // image: null,
    // imageBase64: '',
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [show, setShow] = useState(true);
  const [values, setValues] = useState("");

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const handleStartDateChange = e => {
    if (e[0] && !e[1]) {
      let selected_day = e[0].day;
      let date_GMT =
        e[0].year +
        "-" +
        e[0].month.number +
        "-" +
        selected_day +
        " " +
        e[0].hour.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) +
        ":" +
        e[0].minute.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
      setStartDate(getUtcDateAndTimeFromCalendar(date_GMT));
      setEndDate(getCurrentDayEndDateWithTimeInUtc());
    } else if (e[1]) {
      //toggle startDate and endDate if startDate > endDate
      if (e[0]) {
        let selected_day = e[0].day;
        let date_GMT =
          e[0].year +
          "-" +
          e[0].month.number +
          "-" +
          selected_day +
          " " +
          e[0].hour.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) +
          ":" +
          e[0].minute.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false
          });
        setStartDate(getUtcDateAndTimeFromCalendar(date_GMT));
      }
      let selected_day = e[1].day;
      let date_GMT =
        e[1].year +
        "-" +
        e[1].month.number +
        "-" +
        selected_day +
        " " +
        e[1].hour.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) +
        ":" +
        e[1].minute.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
      setEndDate(getUtcDateAndTimeFromCalendar(date_GMT));
      setShow(true);
    } else {
      setStartDate(getCurrentDayStartDateWithTimeInUtc());
      setEndDate(getCurrentDayEndDateWithTimeInUtc());
    }
  };

  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <div className={""}>
          <h2>Registration Student</h2>
        </div>
        <div>
          <h6>* is.</h6>
        </div>
      </div>
      <div className={"centered-element"}>
        <div className="scrollbar" id="style-2">
          <div className="force-overflow">
            <Form>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="firstName">
                    <Form.Label column sm={12}>
                      First Name
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                    <div className={"ml-5"}>
                      Your <b>real name</b> as it appears in your passport,
                      driver's license, or other legal documents.
                    </div>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="lastName">
                    <Form.Label column sm={12}>
                      Last Name
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="email">
                    <Form.Label column sm={12}>
                      Email Address
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="country">
                    <Form.Label column sm={12}>
                      Country / region
                    </Form.Label>
                    <Col sm={12}>
                      <Select
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#5DBFC4",
                            primary: "#147b82"
                          }
                        })}
                        name="country"
                        className="select-react-dropdown"
                        options={contryNameOptions}
                        // onChange={(e) => this.changeLocationOptions(e)}
                        // defaultValue={this.state.locationDropDownDetails}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="status">
                    <Form.Label column sm={12}>
                      Status
                    </Form.Label>
                    <Col sm={12}>
                      <RadioGroup
                        name="gender"
                        className="d-flex"
                        // onChange={e => handleTrainOnChange(e)}
                      >
                        <div className="radio-button-background mr-3">
                          <Radio
                            value="student"
                            className="radio-button mr-2"
                          />
                          I'm a student
                        </div>
                        <div className="radio-button-background">
                          <Radio
                            value="teacher"
                            className="radio-button mr-2"
                          />
                          I'm a teacher
                        </div>
                      </RadioGroup>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="gender">
                    <Form.Label column sm={12}>
                      Gender
                    </Form.Label>
                    <Col sm={12}>
                      <RadioGroup
                        name="gender"
                        className="d-flex"
                        // onChange={e => handleTrainOnChange(e)}
                      >
                        <div className="radio-button-background mr-3">
                          <Radio value="male" className="radio-button mr-2" />
                          Male
                        </div>
                        <div className="radio-button-background">
                          <Radio value="female" className="radio-button mr-2" />
                          Female
                        </div>
                      </RadioGroup>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={12} md={12} lg={12} sm={12} xs={12}>
                  <Form.Group controlId="it-study">
                    <Form.Label column sm={12}>
                      Is Computer Science or Engineering your major field of
                      study?
                    </Form.Label>
                    <Col sm={12}>
                      <RadioGroup
                        name="gender"
                        className="d-flex"
                        // onChange={e => handleTrainOnChange(e)}
                      >
                        <div className="radio-button-background mr-3">
                          <Radio value="yes" className="radio-button mr-2" />
                          Yes
                        </div>
                        <div className="radio-button-background">
                          <Radio value="no" className="radio-button mr-2" />
                          No
                        </div>
                      </RadioGroup>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group>
                    <Form.Label column sm={12}>
                      Select Date
                    </Form.Label>
                    <Col sm={12}>
                      <DatePicker
                        style={{
                          border: "1px solid hsl(0,0%,80%)",
                          minHeight: "40px"
                        }}
                        placeholder="Select Date"
                        className="teal filterDateWidth"
                        format="MM/DD/YYYY"
                        value={values}
                        onChange={date => handleStartDateChange(date)}
                        plugins={[<DatePanel markFocused />]}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="level-of-study">
                    <Form.Label column sm={12}>
                      Level of study
                    </Form.Label>
                    <Col sm={12}>
                      <Select
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#5DBFC4",
                            primary: "#147b82"
                          }
                        })}
                        name="country"
                        className="select-react-dropdown"
                        options={levelOfStudyOptions}
                        // onChange={(e) => this.changeLocationOptions(e)}
                        // defaultValue={this.state.locationDropDownDetails}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="isic-itic-name">
                    <Form.Label column sm={12}>
                      ISIC/ITIC name
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="isic-itic-name"
                        placeholder="ISIC/ITIC name"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      Your name as it appears on the card (e.g. B. Smart).
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="isic-itic-id">
                    <Form.Label column sm={12}>
                      ISIC/ITIC ID
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        name="isic-itic-id"
                        placeholder="ISIC/ITIC ID"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col>
                      Your ISIC/ITIC serial number (e.g. S123456789012X).
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="password">
                    <Form.Label column sm={12}>
                      Password
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12} xs={12}>
                  <Form.Group controlId="confirm-password">
                    <Form.Label column sm={12}>
                      Confirm Password
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="password"
                        name="confirm-password"
                        placeholder="Confirm Password"
                        // value={formData.locationName}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
