import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  entityFilter,
  getFilteredAndPaginatedEntities,
  headerSortingClasses,
  sortCaret
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { useUsersUIContext } from "../UsersUIContext";
import * as actions from "../../_redux/UserAction";
import { SearchText } from "../../../../../shared/components/SearchText";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-block-ui/style.css";
import {BoootstrapTable} from "../../../../../shared/components/BoootstrapTable";

export function UsersTable() {
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => usersUIContext, [usersUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "#",
      text: "Index",
      formatter: (cell, row, rowIndex) => {
        return <span>{(page - 1) * size + (rowIndex + 1)}</span>;
      }
    },
    {
      dataField: "user_email",
      text: "user Email Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "provider",
      text: "Provider",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses
    },

    {
      dataField: "status",
      text: "status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,

      formatExtraData: {
        openChangeStatusDialog: usersUIContext.openChangeStatusDialog
      },
      headerSortingClasses
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openViewUsersDetailsDialog: usersUIContext.openViewUsersDetailsDialog
      },
      style: {
        minWidth: "100px"
      }
    }
  ];

  const { currentState } = useSelector(
    state => ({ currentState: state.users }),
    shallowEqual
  );

  const { entities, totalUsers, page, size, data } = currentState;
  const [filterEntities, setFilterEntities] = useState(entities);
  const searchInput = useRef("");
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  let currentItems = getFilteredAndPaginatedEntities(
    filterEntities || entities,
    usersUIProps.queryParams
  );

  const filterUser = e => {
    // const searchStr = e?.target?.value || searchInput.current.value;
    const keys = ["id", "user_email", "first_name", "last_name", "provider"];
    currentItems = entityFilter(
      entities || filterEntities,
      null,
      keys,
      usersUIProps.queryParams,
      setFilterEntities
    );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    dispatch(actions.fetchFilterUsers(1, 10, search, data)).then();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    filterUser();
    // eslint-disable-next-line
  }, [entities]);
  const onPageChange = (page, sizePerPage) => {
    dispatch(actions.fetchFilterUsers(page, sizePerPage, search, data));
  };

  const onSizePerPageChange = sizePerPage => {
    dispatch(actions.fetchFilterUsers(1, sizePerPage, search, data));
  };
  const onKeyPressHandler = e => {
    if (e.key === "Enter") {
      dispatch(actions.fetchFilterUsers(1, size, search, data));
    }
  };

  const onChangeSearch = e => {
    const searchStr = e?.target?.value.trim() || searchInput.current.value.trim();
    setSearch(searchStr);
  };

  return (
    <>
      <div>
        <div className=" mb-5 pagination-tab" />
        <BlockUi tag="div" blocking={loader} color="#147b82">
          <SearchText
            reference={searchInput}
            onChangeHandler={onChangeSearch}
            onKeyDown={onKeyPressHandler}
            searchField={"on USER EMAIL or COMPANY NAME"}
          />
          {currentItems && currentItems.length > 0 ? (
            <BoootstrapTable
              sizePerPageList={[
                { text: "10", value: 10 },
                { text: "5", value: 5 },
                { text: "3", value: 3 }
              ]}
              hideSizePerPage={false}
              showTotal={true}
              alwaysShowAllBtns={true}
              hidePageListOnlyOnePage={true}
              columns={columns}
              data={currentItems}
              sizePerPage={size}
              page={page}
              totalSize={totalUsers}
              onTableChange={onPageChange}
              sizePerPageChange={onSizePerPageChange}
            />
          ) : (
            <h3 className={"table-center"}>No Data Available</h3>
          )}
        </BlockUi>
      </div>
    </>
  );
}
