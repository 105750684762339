import { callTypes, ModelSlice } from "./ModelSlice";
import { successToast, warningToast } from "../../../../shared/components/ToastMessage";
import {
  addNewModel,
  getAllModel,
  getEnabledModel,
  getModelById,
  updateModel
} from "./modelAPI";

const { actions } = ModelSlice;

export const fetchModels = (pageNo ,pageSize,search) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getAllModel(pageNo ,pageSize,search)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.modelFetched(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find model";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEnabledModels = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getEnabledModel()
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.modelFetchedForData(response.data));
      } else {
        warningToast("something went wrong");
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find model";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createModel = modelData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  const data = {
    model_name: modelData.modelName,
    model_description: modelData.modelDescription,
    model_infer_cpu_speed: modelData.modelICS,
    model_infer_gpu_speed: modelData.modelIGS,
    model_size: modelData.modelSize,
    framework_id: modelData.frameworkId,
    model_type_id: modelData.modelTypeId,
    status: modelData.status
  };
  return addNewModel(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.modelCreated(response.data));
        successToast("Model Type Added Successfully");
      } else {
        throw new Error("something went wrong");
      }
    })
    .catch(error => {
      warningToast("something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchModelById = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getModelById(id)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.modelFetchedById(response.data));
      } else {
        throw new Error("Error getting model details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const modelUpdate = modelData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    model_name: modelData.modelName || modelData.model_name,
    model_description:
      modelData.modelDescription || modelData.model_description,
    model_infer_cpu_speed:
      modelData.modelICS || modelData.model_infer_cpu_speed,
    model_infer_gpu_speed:
      modelData.modelIGS || modelData.model_infer_gpu_speed,
    model_size: modelData.modelSize || modelData.model_size,
    framework_id: modelData.frameworkId || modelData.framework_id,
    model_type_id: modelData.modelTypeId || modelData.model_type_id,
    status: modelData.status,
    id: modelData.id
  };

  return updateModel(data)
    .then(response => {
      if (response && response.isSuccess) {
        dispatch(actions.modelUpdated(response.data));
      } else {
        throw new Error("Error updating model details");
      }
    })
    .catch(error => {
      warningToast("Something went wrong");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
