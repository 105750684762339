import React from "react";

export function ExperimentStatusFormatter(
  cellContent, // display the proper content
  row,
  rowIndex //formatter call
) {
  return (
    <>
      {row.experiment_status.toLowerCase() === "pending" ? (
        <span className="label label-lg label-light-danger label-inline">
          Pending
        </span>
      ) : row.experiment_status.toLowerCase() === "completed" ? (
        <span className="label label-lg label-light-success label-inline">
          Completed
        </span>
      ) : row.experiment_status.toLowerCase() === "requested" ? (
        <span className="label label-lg label-light-primary label-inline font-weight-bold py-4">
          Requested
        </span>
      ) : row.experiment_status.toLowerCase() === "processing" ? (
        <span className="label label-lg label-light-warning label-inline font-weight-bold py-4">
          Processing
        </span>
      ) : row.experiment_status.toLowerCase() === "error" ? (
        <span className="label label-lg label-light-warning label-inline font-weight-bold py-4">
          Error
        </span>
      ) : (
        <span>{row.experiment_status.toLowerCase()}</span>
      )}
    </>
  );
}
