import React, {useEffect, useMemo, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  entityFilter,
  getFilteredAndPaginatedEntities,
  headerSortingClasses,
  sortCaret
} from "../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import {useExperimentManagementUIContext} from "../ExperimentManagementUIContext";
import * as actions from "../../_redux/ExperimentManagementAdminAction";
import {SearchText} from "../../../../../shared/components/SearchText";
import {useHistory} from "react-router-dom";
import {getOneModel, trainModel} from "../../_redux/ExperimentManagementAPI";
import {successToast, warningToast} from "../../../../../shared/components/ToastMessage";
import {StartTestingModal} from "./Model/StartTestingModal";
import SweetAlert from "react-bootstrap-sweetalert";
import * as moment from "moment";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import {BoootstrapTable} from "../../../../../shared/components/BoootstrapTable";

export function ExperimentManagementTable({ myKey }) {
  const experimentManagementUIContext = useExperimentManagementUIContext();
  const experimentManagementUIProps = useMemo(
    () => experimentManagementUIContext,
    [experimentManagementUIContext]
  );
  // eslint-disable-next-line
  const history = useHistory();
  // eslint-disable-next-line
  const [loaderState, setLoaderState] = useState(false);
  const [model, setModel] = useState({});
  const openTestExperimentManagementDialog = async id => {
    try {
      setLoaderState(true);
      const res = await getOneModel(id);
      if (res.isSuccess) {
        setLoaderState(false);
        setModel(res.data);
        setAlertData({ ...alertData, show: true });
      } else {
        setLoaderState(false);
        warningToast("Error getting model details");
        throw new Error("error getting model id");
      }
    } catch (e) {
      setLoaderState(false);
      warningToast("Something went wrong");
    }
  };
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");

  const [show, setShow] = React.useState(false);
  const [alertData, setAlertData] = React.useState({
    show: false,
    title: "Are You Sure You Want To Start Testing?"
  });
  const openTrainExperimentManagementDialog = async id => {
    try {
      setLoaderState(true);
      const res = await trainModel(id, "processing");
      if (res.isSuccess) {
        setLoaderState(false);
        successToast(
          "Experiment training requested successfully, training time could be 3 to 48 hours depends on Dataset size and number of iterations."
        );
        dispatch(actions.fetchExperiments(page, size, search)).then();
      } else {
        setLoaderState(false);
        warningToast("Error getting model details");
        throw new Error("error getting model id");
      }
    } catch (e) {
      setLoaderState(false);
      warningToast("Something went wrong");
    }
  };

  const columns = [
    {
      dataField: "#",
      text: "Index",
      formatter: (cell, row, rowIndex) => {
        return <span>{(page - 1) * size + (rowIndex + 1)}</span>;
      }
    },
    {
      dataField: "experiment_name",
      text: "Experiment Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "165px"
      }
    },
    {
      dataField: "experiment_description",
      text: "Experiment Description",
      sort: true,

      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "experiment_status",
      text: "Experiment Status",
      sort: true,
      formatter: columnFormatters.ExperimentStatusFormatter,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: "created_date",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (_, row) =>
        moment
          .utc(row?.created_date)
          .local()
            .format("DD MMMM YYYY, HH:mm:ss"),
      style: {
        minWidth: 180
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: columnFormatters.StatusColumnFormatter,
      formatExtraData: {
        openChangeStatusDialog:
          experimentManagementUIProps.openChangeExperimentManagementStatusDialog
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditExperimentDialog:
          experimentManagementUIProps.openEditExperimentManagementDialog,
        openViewExperimentDialog:
          experimentManagementUIProps.openViewExperimentManagementDialog
      },
      style: {
        minWidth: "125px"
      }
    },
    {
      dataField: "test",
      text: "Test",
      sort: true,
      formatter: columnFormatters.TestColumnFormatter,
      formatExtraData: {
        openTestExperimentDialog: openTestExperimentManagementDialog
      }
    },
    {
      dataField: "train",
      text: "Train",
      sort: true,
      formatter: columnFormatters.TrainColumnFormatter,
      formatExtraData: {
        openTestExperimentDialog: openTrainExperimentManagementDialog
      }
    },
    {
      dataField: "deploy",
      text: "Deploy",
      sort: true,
      formatter: columnFormatters.DeployColumnFormatter,
      formatExtraData: {
        openTestExperimentDialog: openTestExperimentManagementDialog
      }
    }
  ];

  const { currentState } = useSelector(
    state => ({ currentState: state.ExperimentManagementAdminSlice }),
    shallowEqual
  );
  const {
    entities,
    //listLoading,
    totalexperimentFetched,
    page,
    size
  } = currentState;
  const [filterEntities, setFilterEntities] = useState(entities);

  const searchInput = useRef("");
  let currentItems = getFilteredAndPaginatedEntities(
    filterEntities || entities,
    experimentManagementUIProps.queryParams
  );

  //Filter data for model type
  const filterExperiment = e => {
    // const searchStr = e?.target?.value || searchInput?.current?.value;
    const keys = [
      "experiment_name",
      "experiment_description",
      "experiment_status",
      "created_date",
      "updated_date"
    ];
    currentItems = entityFilter(
      entities || filterEntities,
      null,
      keys,
      experimentManagementUIProps.queryParams,
      setFilterEntities
    );
  };

  //State Change Handlers
  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    if(myKey==='Experiment-details') {
      dispatch(actions.fetchExperiments(1, 10, search));
    }
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [experimentManagementUIProps.queryParams, dispatch, myKey, search]);

  useEffect(() => {
    filterExperiment();
    // eslint-disable-next-line
  }, [entities]);

  useEffect(() => {
    let queryparams = experimentManagementUIProps.queryParams;
    queryparams.pageNumber = 1;
    experimentManagementUIProps.setQueryParams(queryparams);
  }, [myKey]);
  const onPageChange = (page, sizePerPage) => {
    dispatch(actions.fetchExperiments(page, sizePerPage, search));
  };

  const onSizePerPageChange = sizePerPage => {
    dispatch(actions.fetchExperiments(1, sizePerPage, search));
  };
  const onKeyPressHandler = e => {
    if (e.key === "Enter") {
      dispatch(actions.fetchExperiments(1, size, search));
    }
  };

  const onChangeSearch = e => {
    const searchStr = e?.target?.value.trim() || searchInput?.current?.value.trim();
    setSearch(searchStr);
  };

  return (
    <>
      {show && (
        <StartTestingModal model={model} show={show} setShow={setShow} />
      )}

      <div>
        <div className=" mb-5 pagination-tab" />
        <BlockUi tag="div" blocking={loader} color="#147b82">
          <SearchText
            reference={searchInput}
            onChangeHandler={onChangeSearch}
            onKeyDown={onKeyPressHandler}
            searchField={"on EXPERIMENT NAME or EXPERIMENT STATUS"}
          />
          {currentItems && currentItems.length > 0 ? (
            <BoootstrapTable
              sizePerPageList={[
                { text: "10", value: 10 },
                { text: "5", value: 5 },
                { text: "3", value: 3 }
              ]}
              hideSizePerPage={false}
              showTotal={true}
              alwaysShowAllBtns={true}
              hidePageListOnlyOnePage={true}
              columns={columns}
              data={currentItems}
              sizePerPage={size}
              page={page}
              totalSize={totalexperimentFetched}
              onTableChange={onPageChange}
              sizePerPageChange={onSizePerPageChange}
            />
          ) : (
            <h3 className={"table-center"}>No Data Available</h3>
          )}
        </BlockUi>
      </div>
      <SweetAlert
        info
        showConfirm
        showCancel
        closeOnClickOutside
        confirmBtnText="Yes"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        cancelBtnStyle={{ color: "black" }}
        title={alertData.title}
        show={alertData.show}
        focusCancelBtn
        onConfirm={() => {
          setAlertData({ ...alertData, show: false });
          setShow(true);
        }}
        onCancel={() => {
          setAlertData({ ...alertData, show: false });
        }}
      />
    </>
  );
}
