import React from "react";
import {CustomInput} from "reactstrap";

export function StatusColumnFormatter(
    cellContent,
    row,
    rowIndex,
) {

    return (
        <>
            <div style={{textAlign: "center"}} className={"mt-2"}>

                <CustomInput
                    disabled
                    type="switch"
                    id={row.id}
                    checked={row.status}
                    className="mb-2 mr-2 custom-pointer"
                />
            </div>
        </>
    );
}
