import {callTypes, userRegistration} from "./UserRegistrationSlice";
import {successToast, warningToast} from "../../../../../shared/components/ToastMessage";
import {checkUser, registerUser} from "./UserRegistrationAPI";

const { actions } = userRegistration;
// const { action } = companyRegistration;

export const saveUserData = regData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    user_email: regData ? regData.user_email : "",
    user_password: regData ? regData.user_password : "",
    user_status: 1,
    company_id: regData?.companyRegistrationData?.id
      ? regData?.companyRegistrationData?.id
      : regData?.company_id
      ? regData?.company_id
      : "",
    company: regData?.companyRegistrationData?.comapny_name
      ? regData?.companyRegistrationData?.comapny_name
      : regData?.company
      ? regData?.company
      : ""
  };
  if (
    regData === "" ||
    regData.user_email === "" ||
    regData.user_password === "" ||
    regData.companyRegistrationData.id === "" ||
    regData.companyRegistrationData.comapny_name === ""
  ) {
    dispatch(actions.userCreated(data));
  } else {
    return registerUser(data)
      .then(response => {
        if (response && response.isSuccess) {
          dispatch(actions.userCreated(response.data));
        } else {
          throw new Error("something went wrong");
        }
      })
      .catch(error => {
        warningToast(error?.response?.data?.detail || "something went wrong");
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  }
};
export const checkUserData = regData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  const data = {
    user_email: regData ? regData.user_email : "",
    user_password: regData ? regData.user_password : "",
    user_status: 1
  };
  if (
    regData === "" ||
    regData.user_email === "" ||
    regData.user_password === ""
  ) {
    dispatch(actions.userCreated(data));
  } else {
    return checkUser(data)
      .then(response => {
        if (response && response.isSuccess) {
          dispatch(actions.userCreated(response.data));
          successToast("user Verified");
        } else {
          throw new Error("something went wrong");
        }
      })
      .catch(error => {
        warningToast(error?.response?.data?.detail || "something went wrong");
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  }
};
