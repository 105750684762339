import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import InfoHyperParamsModal from "./InfoHyperParamsModal";
import { Switch } from "@material-ui/core";
import { SwitchAlertModal } from "../../../../shared/components/SwitchAlertModal";
import { UpdateUserHyperParamsStatus } from "../../../../service/hyperParamsManagement.api";
import * as actions from "../../../../redux/actions/hyperParamsManagementAction";
import { useDispatch } from "react-redux";
import { warningToast } from "../../../../shared/components/ToastMessage";
import { BoootstrapTable } from "../../../../shared/components/BoootstrapTable";

function HyperParamsTable({
  currentItems,
  showTable,
  totalCount,
  pageNo,
  pageSize,
  pageChange,
  sizePerPageChangeApiCall,
  editModalDataTable
}) {
  const [filterEntities, setFilterEntities] = useState([]);
  const [infoModalShow, setInfoModalShow] = useState(false);
  const [infoModalData, setInfoModalData] = useState([]);
  const [hyperParamsStatusData, setHyperParamsStatusData] = useState([]);
  const [hyperParamsStatus, setHyperParamsStatus] = React.useState(false);
  const [successIcon, setSuccessIcon] = useState(false);
  const [userStatusLoader, setUserStatusLoader] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      dataField: "#",
      text: "Index",
      formatter: (cell, row, rowIndex) => {
        return <span>{(pageNo - 1) * pageSize + (rowIndex + 1)}</span>;
      }
    },
    {
      dataField: "hyper_parameter_name",
      text: "Hyper Name",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "learning_rate",
      text: "Learning Rate",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "momentum",
      text: "Momentum",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "weight_decay",
      text: "Weight Decay",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "optimizer",
      text: "Optimizer",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "batch_size",
      text: "Batch Size",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "img_size",
      text: "Image Size",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "split_ratio",
      text: "Split Ratio",
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <>
            <Switch
              checked={row.status}
              onChange={() => handleHyperParamsStatus(row)}
              color="primary"
            />
          </>
        );
      },
      style: {
        minWidth: "55px"
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cellContent, row) => {
        return (
          <>
            <UncontrolledDropdown direction="left">
              <DropdownToggle className="dropdown-toggle-btn">
                <span
                  className="svg-icon svg-icon-md svg-icon-primary icon-hover "
                  style={{ color: "#B5B5C3" }}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/three-dots-vertical.svg"
                    )}
                  />
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="text-hover-btn dropdown-menu-color">
                  <a
                    className="text-dark-75 text-hover-primary"
                    onClick={() => editModalDataTable(cellContent, row)}
                  >
                    <a className="svg-icon svg-icon-md svg-icon-primary mr-2  ">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      />
                    </a>{" "}
                    Edit Hyper Param
                  </a>
                </DropdownItem>
                <DropdownItem className="text-hover-btn dropdown-menu-color">
                  <a
                    className="text-dark-75 text-hover-primary"
                    onClick={() => handleHyperParameterInfo(cellContent, row)}
                  >
                    <a className="svg-icon svg-icon-md svg-icon-primary mr-2">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Info-circle.svg"
                        )}
                      />
                    </a>{" "}
                     Hyper Param Info
                  </a>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        );
      },
      style: {
        minWidth: "55px"
      }
    }
  ];

  useEffect(() => {
    setFilterEntities(currentItems);
  }, [currentItems]);

  const onPageChange = (page, sizePerPage) => {
    pageChange(page, sizePerPage);
  };
  const sizePerPageChange = (page, sizePerPage) => {
    sizePerPageChangeApiCall(sizePerPage, page);
  };

  const handleHyperParameterInfo = (cellContent, row) => {
    setInfoModalShow(true);
    setInfoModalData(row);
  };

  const handleInfoClose = () => {
    setInfoModalShow(false);
  };

  const handleServiceAccessStatus = (id, status) => {
    UpdateUserHyperParamsStatusa(id, status);
  };

  const UpdateUserHyperParamsStatusa = (id, status) => {
    setUserStatusLoader(true);
    UpdateUserHyperParamsStatus(!status, id)
      .then(response => {
        if (response && response.isSuccess) {
          setUserStatusLoader(false);
          setSuccessIcon(true);
          setTimeout(function() {
            handleServiceAccessClose();
            dispatch(actions.fetchHyperParams(1, 10, ""));
          }, 1000);
        }
      })
      .catch(error => {
        console.log("error::", error);
        setUserStatusLoader(false);
        warningToast("Something went wrong");
      });
  };

  const handleHyperParamsStatus = row => {
    setHyperParamsStatus(true);
    setHyperParamsStatusData(row);
  };

  const handleServiceAccessClose = () => {
    setHyperParamsStatus(false);
    setSuccessIcon(false);
    setHyperParamsStatusData("");
  };

  return (
    <>
      <BlockUi tag="div" blocking={showTable} color="#147b82">
        {filterEntities.length > 0 && (
          <BoootstrapTable
            sizePerPageList={[
              { text: "10", value: 10 },
              { text: "5", value: 5 },
              { text: "3", value: 3 }
            ]}
            hideSizePerPage={false}
            showTotal={true}
            alwaysShowAllBtns={true}
            hidePageListOnlyOnePage={true}
            columns={columns}
            data={filterEntities}
            sizePerPage={pageSize}
            page={pageNo}
            totalSize={totalCount}
            onTableChange={onPageChange}
            sizePerPageChange={sizePerPageChange}
          />
        )}
        {filterEntities.length === 0 && (
          <h3 className={"mt-5 d-flex justify-content-center"}>
            No Data Found
          </h3>
        )}
      </BlockUi>

      <InfoHyperParamsModal
        show={infoModalShow}
        infoModalData={infoModalData}
        handleClose={handleInfoClose}
      />

      <SwitchAlertModal
        showAlert={hyperParamsStatus}
        handleAccessUserClose={handleServiceAccessClose}
        handleUserStatus={(id, status) => handleServiceAccessStatus(id, status)}
        id={hyperParamsStatusData.id}
        status={hyperParamsStatusData?.status}
        userStatusLoader={userStatusLoader}
        successIcon={successIcon}
      />
    </>
  );
}

export default HyperParamsTable;
