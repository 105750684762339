import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {Tooltip} from "@material-ui/core";
import BlockUi from "react-block-ui";
import {fetchUploadedFolder} from "../../../../service/annotationTools.api";

function Folder({datasetFolderOpen}) {
    const [datasetManagement, setDatasetManagement] = useState([]);
    const [datasetManagementLoading, setDatasetManagementLoading] = useState(false);
    useEffect(() => {
        fetchUploadedAnnotation()
    }, []);

    const fetchUploadedAnnotation = () => {
        setDatasetManagementLoading(true)
        fetchUploadedFolder()
            .then(response => {
                if (response && response.isSuccess) {
                    setDatasetManagement(response?.data)
                    setDatasetManagementLoading(false)
                }
            })
            .catch(error => {
                console.log("error::", error);
                setDatasetManagementLoading(false)
            });
    };

    return (
        <>
            <BlockUi tag="div" blocking={datasetManagementLoading} color="#147b82">
                {datasetManagement && datasetManagement.length > 0 ? (
                        <Row>
                            {datasetManagement && datasetManagement.map((item, index) => (
                                <Col xl={1} md={2} xs={6} sm={4}>
                                    <div className={'text-center'}>
                                        <div className="icon d-flex justify-content-center" id={index}>
                                            <div>
                    <span
                        title={index + 1}
                        style={{position: "relative"}}
                        className="
                      svg-icon
                      svg-icon-xxl
                      svg-icon-2x
                      svg-icon-primary
                      cursor-pointer folderBox"
                        onClick={() => {
                            datasetFolderOpen(item?.id);
                        }}
                    >
                      <SVG
                          title={item.dataset_name}
                          className="upload-dataset-folder showHover"
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Files/Group-folders.svg"
                          )}
                      />
                      <SVG
                          title={item.dataset_name}
                          className="upload-dataset-folder showN"
                          src={toAbsoluteUrl(
                              "/media/svg/icons/General/Folder.svg"
                          )}
                      />
                    </span>
                                            </div>
                                        </div>
                                        <Tooltip
                                            title={<div>{item.dataset_name}</div>}
                                            placement={"top"}
                                        >
                  <span className="annotation-tool-dataset-name">
                    {item.dataset_name}
                  </span>
                                        </Tooltip>
                                    </div>
                                </Col>
                            ))}


                        </Row>)
                    : <> <h3 className={" mt-5 d-flex justify-content-center"}>
                        No Data Found
                    </h3>
                    </>}
            </BlockUi>
        </>
    );
}

export default Folder;
