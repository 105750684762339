export const Optimizer = [
    {label: "SGD", value: 'sgd'},
    {label: "ADAM", value: 'adam'}
];

export const YesOrNo = [
    {label: "YES", value: true},
    {label: "NO", value: false}
];
export const ImageSize = [
    {label: "640", value: 640},
    {label: "1280", value: 1280}
];

export const UploadDatasetType = [
    { label: "image", value: 1 },
    { label: "video", value: 2 },
    { label: "rtsp", value: 3 }
];

export const weekdays = [
    {
        id: 0,
        value: "S"
    },
    {
        id: 1,
        value: "M"
    },
    {
        id: 2,
        value: "T"
    },
    {
        id: 3,
        value: "W"
    },
    {
        id: 4,
        value: "T"
    },
    {
        id: 5,
        value: "F"
    },
    {
        id: 6,
        value: "S"
    }
];