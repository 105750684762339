import {createSlice} from "@reduxjs/toolkit";

const initialFrameworkState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    lastError: null,
    filteredEntities: '',
    frameworkFetchedById: "",
    totalFramework:0,
    page:1,
    size:10
};
export const callTypes = {
    list: "list",
    action: "action"
};

export const FrameworkManagementSlice = createSlice({
    name: "dl-framework",
    initialState: initialFrameworkState,
    reducers: {

        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        frameworkFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.entities = action.payload.items;
            state.totalFramework = action.payload.total;
            state.page=action.payload.page;
            state.size=action.payload.size;
            state.totalCount = action.payload.length;
        },

        frameworkFetchedById: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
            state.frameworkFetchedById = action.payload;
        },

        frameworkCreated: (state, action) => {
            state.actionsLoading = false;
            state.entities.push(action.payload);
            state.error = null;
        },

        frameworkUpdated: (state, action) => {
            let data = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = state.entities.map(entity => {
                if (entity.id === data.id) {
                    return data;
                }
                return entity;
            });
        },

        clearFrameworkById: (state, action) => {
            state.frameworkFetchedById = "";
        }
    }
});
