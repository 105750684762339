import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Button} from "react-bootstrap";
import {
    addOtp,
    deleteProfileImage,
    updateUserInfo,
    updateUserProfileImage,
    validateOtpAPI
} from "../../../../service/authCrud.api";
import {extractErrorFromError} from "../../../../shared/helper/RequestWrapper";
import {infoToastShort, successToast, warningToast} from "../../../../shared/components/ToastMessage";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {AuthAction as action} from "../../../../redux/reducers/authRedux";
import SendEmailNotificationModal from "../../../../shared/components/SendEmailNotificationModal";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

const otpRules = /^\d{6}$/;

function PersonInformation() {
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState(false);
    let history = useHistory();
    const {user} = useSelector((state) => state.auth, shallowEqual);
    const [isOtpInitiated, setOtpInitiated] = useState(false);
    const [email, setEmail] = useState("");
    const [show, setShow] = useState(false);
    const [textShowOtp, setTextShowOtp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validateOtp, setValidateOtp] = useState(false);
    const [imagePath, setImagePath] = useState('');
    const [imageSrc, setImageSrc] = useState([]);
    const [deleteImage, setDeleteImage] = useState(false);

    useEffect(() => {
        if (!user?.user_email) {
            infoToastShort('Enter your email address to access Tusker AI.')
        }
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        const formData = new FormData();

        formData.append('profile_image', file);
        reader.onload = () => {
            const binaryString = reader.result;
            setImageSrc(binaryString);
        };

        setImagePath(file)
        if (file) {
            reader.readAsDataURL(file);
        }


    }

    const initialValues = useSelector(state => {
        return {
            firstname: user?.first_name || "",
            lastname: user?.last_name || "",
            email: user?.user_email || "",
            id: user?.id || "",
            phone: user.contact || "",
            profile_image: "" || "",
        };
    });


    const emailSchema = Yup.object().shape({
        firstname: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "First name should atleast contain 3 characters")
            .max(50, "Maximum 50 characters").required("First name must be required").nullable("First name must be required"),
        lastname: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "Last name should atleast contain 3 characters")
            .max(50, "Maximum 50 characters")
            .required("Last name must be required"),
        email: Yup.string()
            .matches(/^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Incorrect Email Format")
            .max(50, "Maximum 50 characters")
            .required("Email must be required"),
        phone: Yup.string()
            .matches(/^([0-9]*)$/, "please enter only digits")
            .min(10, "Invalid Phone digits")
            .max(10, "Maximum 10 digits")
            .required("Phone Number must be required"),
        otp: Yup.string().required("Otp must be required").when({
            is: value => value && value.length > 0, then: Yup.string()
                .matches(otpRules, {
                    message: "kindly enter a valid OTP."
                })
        }),
    });
    const Schema = Yup.object().shape({
        firstname: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "First name should atleast contain 3 characters")
            .max(50, "Maximum 50 characters").required("First name must be required"),
        lastname: Yup.string().trim().matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters and digits are not allowed.')
            .min(3, "Last name should atleast contain 3 characters")
            .max(50, "Maximum 50 characters")
            .required("Last name must be required"),
        email: Yup.string()
            .matches(/^[\w\.]+@([\w-]+\.)+[\w-]{2,4}$/i, "Incorrect Email Format")
            .max(50, "Maximum 50 characters")
            .required("Email must be required"),
        phone: Yup.string()
            .matches(/^([0-9]*)$/, "please enter only digits")
            .min(10, "Invalid Phone digits")
            .max(10, "Maximum 10 digits")
            .required("Phone Number must be required"),

    });
    const modalOpen = () => {
        setShow(true);
    };

    const modalClose = () => {
        setShow(false);
    };

    const sendOtpOnEmail = (email, forceResend = false) => {
        if (isOtpInitiated && !forceResend) {
            return;
        }
        setLoading(true);
        setOtpInitiated(true);
        // setOtpVerified(false);
        let body = {
            email: email, check_email: true, otp_type: "update_profile"
        };
        setEmail(email);
        addOtp(body)
            .then(response => {
                modalOpen();
                setTextShowOtp(true);
                setLoading(false);
            })
            .catch(error => {
                console.log("error::", error);
                const warningMsg = extractErrorFromError(error);
                warningToast(warningMsg);
                modalClose();
                setLoading(false);
                setTextShowOtp(false);
            });
    };

    const handleValidateOtp = (email, otp) => {
        setLoading(true);
        if (!email || !otp) {
            setLoading(false);
            formik.setSubmitting(false);
            formik.setStatus("Invalid Otp Please try Again");
            warningToast("Invalid Otp");
            return;
        }
        validateOtpAPI(email, otp)
            .then(res => {
                setValidateOtp(false);
                const isSuccess = res?.data?.success;
                // setOtpVerified(isSuccess || false);
                if (isSuccess) {
                    successToast("Otp Validated Successfully");
                    setValidateOtp(true);
                    setLoading(false);
                } else {
                    setLoading(false);
                    formik.setSubmitting(false);
                    formik.setStatus("Invalid Otp Please try Again");
                    warningToast("Invalid Otp");
                }
            })
            .catch(error => {
                console.log("error::", error);
                warningToast(error.request.statusText);
                if (error) {
                    warningToast(error.detail);
                } else {
                    warningToast("Something went Wrong");
                }
                modalClose();
                setLoading(false);
                formik.setSubmitting(false);
                formik.setStatus("Invalid Otp Please try Again");
                warningToast("Invalid Otp");
            });
    };

    const handleOTPFocus = () => {
        formik.setStatus("");
    };

    const formik = useFormik({
        initialValues,
        validationSchema: initialValues?.email ? Schema : emailSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: (values, {setSubmitting}) => {
            if (editUser) {
                if (initialValues?.email || validateOtp) {
                    setLoading(true);
                    let body = {
                        first_name: values?.firstname.trim(),
                        last_name: values?.lastname.trim(),
                        user_email: values?.email.trim(),
                        contact: values?.phone.trim(),
                        id: user?.id,
                    };
                    const formData = new FormData();
                    formData.append('profile_image', imagePath);
                    updateUserInfo(body)
                        .then(res => {

                            if (deleteImage) {
                                deleteUserProfileImage();
                                setDeleteImage(false)
                            } else if (imagePath && !deleteImage) {
                                updateUserProfileImage(formData)
                                    .then(response => {
                                        if (response && response.isSuccess) {
                                            dispatch(action.setUser(response.data));
                                            setEditUser(false);
                                            setLoading(false);
                                            setTextShowOtp(false);
                                        }
                                    })
                            }
                            if (res && res.isSuccess) {
                                dispatch(action.setUser(res.data));
                                setEditUser(false);
                                setLoading(false);
                                setTextShowOtp(false);
                                successToast("Updated user Details Successfully")
                            } else {
                                throw new Error('Error updating user details');
                            }
                        })
                        .catch(error => {
                            if (error) {
                                console.log("error::", error);
                                setLoading(false);
                                warningToast(error.request.statusText)
                                warningToast(error.detail);
                            } else {
                                setLoading(false);
                                warningToast("Something went Wrong");
                            }

                        });
                } else if (!isOtpInitiated && // !providerEmailExists &&
                    textShowOtp && !validateOtp) {
                    sendOtpOnEmail(values?.email);
                } else if (textShowOtp && !validateOtp) {
                    if (values.otp) {
                        setSubmitting(false);
                    }
                    handleValidateOtp(values?.email, values.otp);
                }
            }
        },
    });
    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const handleClose = () => {
        history.push("/user/dashboard")
    }
    const handelChangeEditData = () => {
        setEditUser(true);
        if (imagePath) {
            setImageSrc([])
            setImagePath(user.profile_image_url)
        }


        return formik.resetForm()

    }
    const handleChangeClose = () => {
        setEditUser(false)
    }

    const removeImage = () => {
        setImageSrc([])
        setDeleteImage(true)

    }
    const deleteUserProfileImage = () => {
        deleteProfileImage()
            .then(response => {
                if (response && response.isSuccess) {
                    dispatch(action.setUser(response.data));
                    successToast("Profile Image deleted Successfully")
                    setEditUser(false);
                    setImageSrc([])
                } else {
                    throw new Error('Error updating Company details');

                }
            })
            .catch(error => {
                console.log("error::", error);
                if (error) {
                    warningToast(error.detail);
                } else {
                    warningToast("Something went Wrong");
                }

            });


    }
    return (<>

            <form
                className="card card-custom card-stretch"
                onSubmit={formik.handleSubmit}
            >
                {/* begin::Header */}
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            Profile Information
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            Update your profile information
                        </span>
                    </div>
                    <div className="card-toolbar">
                        {editUser ? (validateOtp || initialValues?.email ? (
                            <div className=" d-flex flex-wrap flex-center">
                                <Button variant="primary" className={"mr-2"}
                                        type="submit"
                                        disabled={formik.touched && !formik.isValid}>

                                    Update Profile
                                    {formik.isSubmitting}
                                    {loading && (
                                        <span className="ml-2 spinner-sm spinner-white"></span>
                                    )}
                                </Button>


                            </div>) : (textShowOtp && !validateOtp) ? (
                            <div className=" d-flex  flex-wrap flex-center">

                                <Button
                                    variant="primary" className={"mr-2"}
                                    disabled={!formik.values.otp || formik.errors.otp || loading}
                                    onClick={e => {
                                        // setValidateOtp(true);
                                        formik.handleSubmit(e);
                                    }}
                                >
                                    <span>Verify OTP</span>
                                    {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                                </Button>

                            </div>) : (<div className=" d-flex flex-wrap flex-center">

                            <Button

                                variant="primary" className={"mr-2"}
                                disabled={formik.isSubmitting || !formik.values.email || formik.errors.email || loading}
                                onClick={() => sendOtpOnEmail(formik.values.email, true)}
                            >
                                <span>Verify Email</span>
                                {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                            </Button>
                        </div>)) : (<Button variant="primary" className={"mr-2"} type="submit"
                                            onClick={handelChangeEditData}>
                            Edit Profile
                            {formik.isSubmitting}
                        </Button>)}

                        {editUser ? (<Button variant="secondary" onClick={handleChangeClose}>
                            Cancel
                        </Button>) : (<Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>)}
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Form */}
                <div className="form">
                    {/* begin::Body */}
                    <div className="card-body">
                        <div className="row">
                            <label className="col-xl-3"></label>
                            <div className="col-lg-9 col-xl-6">
                                <h5 className="font-weight-bold mb-6">Customer Info</h5>
                            </div>
                        </div>


                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                                Avatar
                            </label>
                            <div className="image-input image-input-outline" id="kt_image_1">


                                {editUser ? (
                                    <>
                                        {!imageSrc.length ?
                                            (user?.profile_image_url && !deleteImage ?
                                                    (<div>
                                                        <img className={"image-input-wrapper"}
                                                             style={{
                                                                 width: '120px',
                                                                 height: '120px',
                                                                 borderRadius: '25px',
                                                                 backgroundSize: "contain",
                                                                 backgroundRepeat: "no-repeat"
                                                             }}
                                                             src={toAbsoluteUrl(user.profile_image_url)}/>
                                                    </div>)
                                                    : (<div>
                                                            <img className={"image-input-wrapper"}
                                                                 style={{
                                                                     width: '120px',
                                                                     height: '120px',
                                                                     borderRadius: '25px',
                                                                     backgroundSize: "contain",
                                                                     backgroundRepeat: "no-repeat"
                                                                 }}
                                                                 src={toAbsoluteUrl("/media/users/blank.png")}/>
                                                        </div>
                                                    )

                                            ) : (
                                                <div>
                                                    <img className={"image-input-wrapper"}
                                                         style={{
                                                             width: '120px',
                                                             height: '120px',
                                                             borderRadius: '25px',
                                                             backgroundSize: "contain",
                                                             backgroundRepeat: "no-repeat"
                                                         }}
                                                         src={toAbsoluteUrl(imageSrc)}/>
                                                </div>
                                            )}

                                        <label
                                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="change" data-toggle="tooltip" title="Change avatar"
                                            data-original-title="Change avatar">
                                            <i className="fa fa-pen fa-pencil icon-sm text-muted"></i>
                                            <input
                                                type="file"
                                                name="profile_image"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={(event) => {
                                                    handleImageChange(event)
                                                }}
                                            />
                                        </label>
                                        {user?.profile_image_url ? (
                                            <span
                                                onClick={removeImage}
                                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                data-action="remove"
                                                data-toggle="tooltip"
                                                title="Remove avatar"
                                                data-original-title="Remove avatar"
                                            >

                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
                                        ) : (null)}


                                    </>
                                ) : (
                                    user?.profile_image_url ? (<div>
                                            <img className={"image-input-wrapper"}
                                                 style={{width: '120px', height: '120px', borderRadius: '25px'}}
                                                 src={toAbsoluteUrl(user.profile_image_url)}/>
                                        </div>)
                                        : (<div className="image-input-wrapper"
                                                style={{
                                                    backgroundImage: `url(${toAbsoluteUrl("/media/users/blank.png")}`,
                                                    borderRadius: '25px'
                                                }}></div>)

                                )}


                            </div>


                        </div>
                        <div className="form-group row theme-color">
                            {editUser ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    First Name
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Please Enter your FirstName{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    First Name
                                </label>)}
                            {editUser ? (<div className="col-lg-9 col-xl-6">
                                <input
                                    type="text"
                                    placeholder="First name"
                                    className={`form-control form-control-lg form-control-solid ${getInputClasses("firstname")}`}
                                    name="firstname"
                                    {...formik.getFieldProps("firstname")}
                                />
                                {formik.touched.firstname && formik.errors.firstname ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.firstname}
                                    </div>) : null}
                            </div>) : (<div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                {initialValues.firstname ? initialValues.firstname : '-'}
                            </div>)}
                        </div>
                        <div className="form-group row theme-color">
                            {editUser ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Last Name
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Please Enter your LastName{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Last Name
                                </label>)}
                            {editUser ? (<div className="col-lg-9 col-xl-6">
                                <input
                                    type="text"
                                    placeholder="Last name"
                                    className={`form-control form-control-lg form-control-solid ${getInputClasses("lastname")}`}
                                    name="lastname"
                                    {...formik.getFieldProps("lastname")}


                                />
                                {formik.touched.lastname && formik.errors.lastname ? (
                                    <div className="invalid-feedback">{formik.errors.lastname}</div>) : null}
                            </div>) : (<div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                {initialValues.lastname ? initialValues.lastname : '-'}
                            </div>)}
                        </div>
                        <div className="row">
                            <label className="col-xl-3"></label>
                            <div className="col-lg-9 col-xl-6">
                                <h5 className="font-weight-bold mt-10 mb-6">Contact Info</h5>
                            </div>
                        </div>
                        <div className="form-group row theme-color">
                            {editUser ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Phone Number
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Please Enter your PhoneNumber{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Phone Number
                                </label>)}
                            {editUser ? (<div className="col-lg-9 col-xl-6">
                                <input
                                    type="text"
                                    placeholder="Phone Number"
                                    className={`form-control form-control-lg form-control-solid ${getInputClasses("phone")}`}
                                    name="phone"
                                    {...formik.getFieldProps("phone")}

                                />
                                {formik.touched.phone && formik.errors.phone ? (<div className="invalid-feedback">
                                    {formik.errors.phone}
                                </div>) : null}
                            </div>) : (<div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                {initialValues.phone ? initialValues.phone : '-'}
                            </div>)}


                        </div>
                        <div className="form-group row theme-color">
                            {editUser ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Email Address
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Please Enter your Email Address{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    Email Address
                                </label>)}
                            {editUser ? (<div className="col-lg-9 col-xl-6">
                                <div className="input-group input-group-lg input-group-solid">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className={`form-control form-control-lg form-control-solid ${getInputClasses("email")}`}
                                        name="email"
                                        {...formik.getFieldProps("email")}
                                        disabled={initialValues?.email || validateOtp}
                                        // disabled={textShowOtp}

                                    />
                                </div>
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="input-validation-star">{formik.errors.email}

                                    </div>) : null}
                            </div>) : (<div className="col-lg-9 col-xl-6 d-flex align-items-center">
                                {initialValues.email ? initialValues.email : '-'}
                            </div>)}
                        </div>
                        {textShowOtp && !validateOtp ? (<div className="form-group row theme-color">
                            {editUser ? (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    OTP
                                    <span className={"input-validation-star"}>*</span>
                                    <Tooltip
                                        title={
                                            <div className="tools">
                                                Enter OTP received on your email{" "}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <InfoIcon/>
                                    </Tooltip>
                                </label>
                            ) : (
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                    OTP
                                </label>)}
                            <div className="col-lg-9 col-xl-6">
                                <div className="input-group input-group-lg input-group-solid">
                                    <input
                                        placeholder="OTP"
                                        type="otp"
                                        className={`form-control form-control-lg form-control-solid ${getInputClasses("otp")}`}
                                        disabled={validateOtp}
                                        name="otp"
                                        {...formik.getFieldProps("otp")}
                                        onFocus={handleOTPFocus}
                                    />
                                </div>
                                {formik.touched.otp && formik.errors.otp ? (
                                    <div className="input-validation-star">{formik?.errors?.otp}</div>) : null}
                                <a
                                    className="text-dark-50 text-hover-primary mr-2"
                                    disabled={loading}
                                    onClick={() => sendOtpOnEmail(formik.values.email, true)}
                                >
                                    <span className={"d-flex justify-content-end"}>ResendOTP</span>
                                    {loading && (<span className="ml-3 spinner spinner-white"></span>)}
                                </a>
                            </div>

                        </div>) : (<></>)}


                    </div>
                    {/* end::Body */}
                </div>
                {/* end::Form */}
            </form>
            <>
                <SendEmailNotificationModal
                    show={show}
                    modalClose={modalClose}
                    email={email}
                />
            </>
        </>


    );
}

export default PersonInformation;