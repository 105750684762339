import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {warningToast} from "../../../../../shared/components/ToastMessage";

export function FrameworkManagementEditForm({
                                                saveFramework,
                                                frameworkData,
                                                onHide,
                                            }) {

    const [formData, setFormData] = useState({
        frameworkName: '',
        frameworkDescription: '',
        frameworkVersion: '',
        status: false,
        id: '',
    });

    const isValidate = () => {
        if (!formData.frameworkName)
            warningToast("Please Enter Framework Name");
        else if (!formData.frameworkDescription)
            warningToast("Please Enter Framework Description");
        else if (!formData.frameworkVersion)
            warningToast("Please Enter Framework Version");
        else
            return true;

        return false;
    }

    const handleChange = (e) => {
        let data = {...formData};
        data[e.target.name] = e.target.value;
        setFormData(data);
    }

    const handleSubmit = () => {
        if (isValidate())
            saveFramework(formData);
    }

    useEffect(() => {
        setFormData({
            frameworkName: frameworkData.framework_name || '',
            frameworkDescription: frameworkData.framework_description || '',
            frameworkVersion: frameworkData.framework_version || '',
            status: frameworkData.status || false,
            id: frameworkData.id || null
        });
    }, [frameworkData])

    return (
        <>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="FrameworkName" as={Row}>
                        <Form.Label column sm={4}>
                            Framework Name
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="frameworkName"
                                placeholder="Framework name"
                                value={formData.frameworkName}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group controlId="FrameworkDescription" as={Row}>
                        <Form.Label column sm={4}>
                            Framework Description
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                placeholder="Framework description"
                                name="frameworkDescription"
                                value={formData.frameworkDescription}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group controlId="FrameworkVersion" as={Row}>
                        <Form.Label column sm={4}>
                            Framework Version
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                placeholder="Framework version"
                                name="frameworkVersion"
                                value={formData.frameworkVersion}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Cancel
                </Button>
                <> </>
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary btn-elevate"
                >
                    Save
                </Button>
            </Modal.Footer>
        </>
    );
}
