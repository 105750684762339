import React, { useEffect, useState } from "react";
import ImagePreview from "./ImagePreview";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { warningToast } from "../../../../shared/components/ToastMessage";
import { getDataSetImageByUserIdWithDataSetId } from "../../../../service/annotationTools.api";
import BlockUi from "react-block-ui";
import ImagePreviewRaw from "./ImagePreviewRaw";
import {Button} from "react-bootstrap";

function Images({
  assignButton,
  annotationImageMove,
  selecteCheckbox,
  selectedImages,
  activeKey,
  dataSetId,
  handleChange,
  downloadButtonHandle
}) {
  const [imageListForDataset, setImageListForDataset] = useState([]);
  const [imageListForDatasetLoader, setImageListForDatasetLoader] = useState(
    false
  );

  useEffect(() => {
    if (activeKey === "all") {
      getDataSetImageByUserIdWithDataSetIds(dataSetId, activeKey);
    } else if (activeKey === "annotated") {
      getDataSetImageByUserIdWithDataSetIds(dataSetId, activeKey);
    } else if (activeKey === "raw") {
      getDataSetImageByUserIdWithDataSetIds(dataSetId, activeKey);
    }
  }, [activeKey]);

  const getDataSetImageByUserIdWithDataSetIds = (id, datasetImageName) => {
    setImageListForDatasetLoader(true);
    getDataSetImageByUserIdWithDataSetId(id, datasetImageName)
      .then(response => {
        if (response && response.isSuccess) {
          setImageListForDatasetLoader(false);
          setImageListForDataset(response?.data);
          downloadButtonHandle(response?.data);
        }
      })
      .catch(e => {
        setImageListForDatasetLoader(false);
        warningToast("Something went wrong");
      });
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div
        style={{
            display: "flex",
            margin: "10px 0px",
            alignItems: "center",
            justifyContent: "flex-end"
        }}
      >
        {assignButton ? (
          <Button
              size={"sm"}
            type="button"
            className="btn btn-primary mr-2"
            onClick={annotationImageMove}
          >
            Start Annotation
          </Button>
        ) : (
          <></>
        )}
        {imageListForDataset && imageListForDataset.length > 0 ? (
          <FormControlLabel style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "0px",
              marginRight: "0px",
              margin: "10px 0px",
          }}
            control={
              <Checkbox
                  style={{padding: "0px 3px"}}
                // checked={state.checkedB}
                onChange={event => handleChange(event, imageListForDataset)}
                value="checkedB"
                color="primary"
              />
            }
            label="Select All"
          />
        ) : (
          <></>
        )}
      </div>

      <div  className={`annotatioon-image-card-raw`} style={{ position: "relative" }}>

      <BlockUi tag="div" blocking={imageListForDatasetLoader} color="#147b82">
        {imageListForDataset && imageListForDataset.length > 0 ? (

              <div className={` ${activeKey && activeKey === "raw" ? "grid-row-raw":"grid-row" }`} >
                {imageListForDataset &&
                    imageListForDataset?.map((data, index) => (
                        <>
                          {activeKey && activeKey === "raw" ? (
                              <>
                                <ImagePreviewRaw
                                    selectedImages={selectedImages}
                                    selecteCheckbox={selecteCheckbox}
                                    data={data}
                                    dataIndex={index}
                                    imageListForDataset={imageListForDataset}
                                />


                              </>
                          ):(
                              <div>

                                <ImagePreview
                                    selectedImages={selectedImages}
                                    selecteCheckbox={selecteCheckbox}
                                    data={data}
                                    dataIndex={index}
                                    imageListForDataset={imageListForDataset}
                                />
                              </div>
                          )}
                        </>
                    ))}
              </div>

        ) : (
          <h3 style={{ paddingTop: "40px" }} className="text-center">
            No Data Found
          </h3>
        )}
      </BlockUi>
      </div>
    </div>
  );
}

export default Images;
