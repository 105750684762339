import React, { Suspense } from "react";
import { ContentRoute, LayoutSplashScreen } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";
import {AnnotationToolsPage} from "./AnnotationToolsPage";

function AnnotationTools() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect
            exact={true}
            from="/user"
            to="/user/annotation"
          />
        }
        <ContentRoute
          path="/user/annotation"
          component={AnnotationToolsPage}
        />
      </Switch>
    </Suspense>
  );
}
export default AnnotationTools;
