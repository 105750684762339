import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { warningToast } from "../../../../../shared/components/ToastMessage";

export function DatasetStorageTypeEditForm({
  saveDatasetStorageType,
  datasetStorageTypeData,
  onHide
}) {
  const [formData, setFormData] = useState({
    datasetStorageTypeName: "",
    datasetStorageTypeDescription: "",
    status: false,
    id: ""
  });

  const isValidate = () => {
    if (!formData.datasetStorageTypeName)
      warningToast("Please Enter Dataset Storage Type Name");
    else if (!formData.datasetStorageTypeDescription)
      warningToast("Please Enter Dataset Storage Type Description");
    else return true;

    return false;
  };

  const handleChange = e => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const handleSubmit = () => {
    if (isValidate()) saveDatasetStorageType(formData);
  };

  useEffect(() => {
    setFormData({
      datasetStorageTypeName: datasetStorageTypeData.dataset_storage_name || "",
      datasetStorageTypeDescription:
        datasetStorageTypeData.dataset_storage_description || "",
      status: datasetStorageTypeData.status || false,
      id: datasetStorageTypeData.id || null
    });
  }, [datasetStorageTypeData]);

  return (
    <>
      <Modal.Body>
        <Form>
          <Form.Group controlId="DatasetStorageTypeName" as={Row}>
            <Form.Label column sm={4}>
              Dataset Storage Type Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="datasetStorageTypeName"
                placeholder="Dataset Storage Type name"
                value={formData.datasetStorageTypeName}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="DatasetStorageTypeDescription" as={Row}>
            <Form.Label column sm={4}>
              Dataset Storage Type Description
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Dataset Storage Type description"
                name="datasetStorageTypeDescription"
                value={formData.datasetStorageTypeDescription}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </Button>
        <> </>
        <Button
          type="submit"
          onClick={handleSubmit}
          className="btn btn-primary btn-elevate"
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}
