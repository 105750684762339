import {request} from "../../../../shared/helper/RequestWrapper";

const GET_ALL_FRAMEWORK = "/get_all_frameworks";
const GET_FRAMEWORK_BY_ID = "/get_framework_by_id";
const ADD_FRAMEWORK = "/add_framework";
const UPDATE_FRAMEWORK = "/update_framework";

export async function getFrameworkById(id) {
    return await request({
        endpoint: GET_FRAMEWORK_BY_ID + "?framework_id=" + id,
        method: 'GET'
    });
}

export async function addNewFramework(data) {
    return await request({
        endpoint: ADD_FRAMEWORK,
        method: 'POST',
        body: JSON.stringify(data)
    })
}

export async function updateFramework(data) {
    return await request({
        endpoint: UPDATE_FRAMEWORK,
        method: 'POST',
        body: JSON.stringify(data)
    })
}

export async function getAllFramework(pageNo ,pageSize,search) {
    return await request({
        endpoint: GET_ALL_FRAMEWORK+ `?page=${pageNo}&size=${pageSize}&search=${search}`,
        method: 'GET'

    });
}