import React from "react";
import HelpSectionCard from "./HelpSectionCard";
import image2 from "../../../../shared/img/Project/project-2.PNG";
import image3 from "../../../../shared/img/Project/project-3.PNG";
import image4 from "../../../../shared/img/Project/project-4.PNG";
import image5 from "../../../../shared/img/Dataset/dataset-1.PNG";
import image6 from "../../../../shared/img/Dataset/datsaet-2.PNG";
import image7 from "../../../../shared/img/Dataset/dataset-3.PNG";
import image8 from "../../../../shared/img/Dataset/dataset-4.PNG";
import image9 from "../../../../shared/img/Hyper/hyper-1.PNG";
import image10 from "../../../../shared/img/Hyper/hyper-2.PNG";
import image11 from "../../../../shared/img/Hyper/hyper-3.PNG";
import image12 from "../../../../shared/img/Hyper/hyper-4.PNG";
import image13 from "../../../../shared/img/Experiment/experiment-1.PNG";
import image14 from "../../../../shared/img/Experiment/experiment-2.PNG";
import image15 from "../../../../shared/img/Experiment/experiment-3.PNG";
import image16 from "../../../../shared/img/Experiment/experiment-4.PNG";
import image17 from "../../../../shared/img/Experiment/experiment-5.PNG";
import { Tab, Tabs } from "react-bootstrap";
const HelpPage = () => {
  return (
    <>
      <Tabs
        defaultActiveKey="projets"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="projets" title="Project">
          <HelpSectionCard
            heading={"Add Project"}
            description={
              "Just click on add new projects tab to add new projects in table you have to provide the Project name and Project description."
            }
            image={image2}
          />
          <HelpSectionCard
            heading={"Project Details"}
            description={
              "After adding the Project your Project will shown in this table."
            }
            image={image3}
          />
          <HelpSectionCard
            heading={"Edit Project"}
            description={
              "You can alter or update Project using edit button  if you want ."
            }
            image={image4}
          />
        </Tab>
        <Tab eventKey="dataset" title="Dataset">
          <HelpSectionCard
            heading={"Add New Dataset"}
            description={
              "Just click on add new Dataset tab to add new Dataset in table you have to provide the Dataset Name,Description,Annotaion and Datastorage Type."
            }
            image={image5}
          />
          <HelpSectionCard
            heading={"Dataset Detail"}
            description={
              "After adding the Dataset your Dataset will shown in this table."
            }
            image={image8}
          />

          <HelpSectionCard
            heading={"Upload Dataset"}
            description={"you can upload database into AWS using this feature."}
            image={image6}
          />
          <HelpSectionCard
            heading={"Edit Dataset"}
            description={
              "You can alter or update Dataset using edit button  if you want."
            }
            image={image7}
          />
        </Tab>
        <Tab eventKey="hyper parameters" title="Hyper Parameters">
          <HelpSectionCard
            heading={"Add New Hyper Params"}
            description={
              "Just click on add new Hyper params tab to add new Hyper parameters in table you have to provide the Hyper parameters Learning Rate,Momentum,Weight Decay,Optimizer,Batvh-size,Image-size,Split Ration,transfer learning,number Epochs,Is multipel scale."
            }
            image={image9}
          />
          <HelpSectionCard
            heading={"Hyper Params Details"}
            description={
              "After adding the Hyper-parameters your Hyper-parameters will shown in this table."
            }
            image={image11}
          />

          <HelpSectionCard
            heading={"Edit Hyper Params"}
            description={
              "You can Edit Hyper parameter using edit button  if you want."
            }
            image={image10}
          />
          <HelpSectionCard
            heading={"Hyper Params Details"}
            description={
              "you can view Hyper parameter details in Hyper parameter details."
            }
            image={image12}
          />
        </Tab>
        <Tab eventKey="experiments" title="Experiments">
          <HelpSectionCard
            heading={"Add new Experiment"}
            description={
              "Just click on add new experimenrt tab to add new Experiment  in table you have to provide the Experiment Name,Description,Model,Project,Hyper-parameter,Target Annotation Type,Dataset."
            }
            image={image13}
          />
          <HelpSectionCard
            heading={"Experiment table"}
            description={
              "After adding the Experiment your Experiment detail will shown in this table."
            }
            image={image14}
          />

          <HelpSectionCard
            heading={"Edit Experiment"}
            description={
              "You can Edit Experiment using edit button  if you want."
            }
            image={image15}
          />
          <HelpSectionCard
            heading={"Experiment Model Details"}
            description={
              "you can view details of an Experiment in form of chart in Model detail."
            }
            image={image16}
          />
          <HelpSectionCard
            heading={"Experiment Buttons"}
            description={
              "Test, Train and Deploy buttons are used to test Experiments ,train Experiment and deploy the Experiment."
            }
            image={image17}
          />
        </Tab>
        {/*<Tab eventKey="annotation" title="Annotation">
          No Data Available
        </Tab>*/}
      </Tabs>
    </>
  );
};
export default HelpPage;
